import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Code,
  Divider,
  FormControl,
  FormHelperText,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Switch,
  Tag,
  TagLabel,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import API from '../../api/API';
import { PencilIcon, PlayIcon } from '@heroicons/react/24/solid';
import { IconButtonProps, RJSFSchema } from '@rjsf/utils';
import { JSONSchema7, JSONSchema7TypeName } from 'json-schema';
import {
  LongTextOptions,
  RJSFSchemaSettings,
  RJSFSchemaSettingsProperty,
  TInventoryModelSchema,
} from '../../models/inventory_model';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/chakra-ui';
import UsersContext from '../../contexts/UsersContext';
import { Label, PrimaryButton, TertiaryButton } from '../Layout';
import { PlusIcon } from '@heroicons/react/24/outline';
import ConfirmationAlert from '../ConfirmationAlert';
import { CKEditorWrapper } from '../TextContentEditor/CKEditorWrapper';
import { AddIcon } from '@chakra-ui/icons';
import { Editor } from '@monaco-editor/react';
import { closestCorners, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from '../SortableItem';
import { CodeTestResponse } from '../../models/custom_field_code';
import { FieldTypeId } from '../../models/json_schemas';
import { schemaTypesConfig } from './schema_types';
import axios from 'axios';

declare global {
  interface Window {
    monaco: any;
  }
}

interface TestCodeVariables {
  code: string;
  variables: string;
  execute: boolean;
}

function useMonacoCompletionItems(selectedFieldsOrdered: string[]) {
  const monacoRef = useRef<any>(null);

  useEffect(() => {
    if (monacoRef.current) {
      const disposable =
        monacoRef.current.languages.registerCompletionItemProvider('python', {
          provideCompletionItems: () => {
            const suggestions = selectedFieldsOrdered.map(key => ({
              label: `params.${key}`,
              kind: monacoRef.current.languages.CompletionItemKind.Property,
              insertText: `params.${key}`,
              documentation: `Custom field key: ${key}`,
            }));
            return { suggestions };
          },
        });

      return () => {
        disposable.dispose();
      };
    }
  }, [selectedFieldsOrdered]);

  return monacoRef;
}

interface CustomFieldModalProps {
  schema: TInventoryModelSchema;
  onSave: () => void;
  editKey?: string;
}

interface PutInventoryModelSchemaProps {
  schema: RJSFSchema;
  settings: RJSFSchemaSettings;
  operation: Operation;
  properties: {
    key: string;
    schema: JSONSchema7;
    settings: RJSFSchemaSettingsProperty;
  };
}

type Operation = 'add' | 'remove' | 'update';

const initialProperty: JSONSchema7 = {
  type: 'string',
  title: '',
};

const initialPropertySettings: RJSFSchemaSettingsProperty = {
  typeId: FieldTypeId.SingleLine,
};

const defaultCode =
  'def formula(params):\n    value = 1\n    threshold = 80\n    if value < threshold:\n        return "Low Risk"\n    return "High Risk"\n';

interface FieldWithSettings {
  schema: JSONSchema7;
  settings: RJSFSchemaSettingsProperty;
}

interface FieldsWithSettings {
  [key: string]: FieldWithSettings;
}

export default function CustomFieldModal({
  schema,
  editKey,
  onSave,
}: CustomFieldModalProps) {
  const { refetchOrganization } = useContext(UsersContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [key, setKey] = useState('');
  const [description, setDescription] = useState('');
  const [property, setProperty] = useState<JSONSchema7>(initialProperty);
  const [propertySetting, setPropertySetting] =
    useState<RJSFSchemaSettingsProperty>(initialPropertySettings);
  const [arrayAllowsMany, setArrayAllowsMany] = useState(false);
  const [requiredOnRegistration, setRequiredOnRegistration] = useState(false);
  const [enableRichTextFormatting, setEnableRichTextFormatting] =
    useState(false);
  const [keyError, setKeyError] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [richTextTemplateText, setRichTextTemplateText] = useState('');

  // code
  const [code, setCode] = useState(defaultCode);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [availableFields, setAvailableFields] = useState<string[]>([]);
  const [codeTestResponse, setCodeTestResponse] = useState<CodeTestResponse>();
  const [codeTestVariables, setCodeTestVariables] = useState<string>('{}');
  const [codeTestVariablesError, setCodeTestVariablesError] = useState('');
  const editorRef = useRef(null);
  const monacoRef = useMonacoCompletionItems(selectedFields);

  const filteredAvailableFields = useMemo(() => {
    return Object.keys(schema.schema.properties || {})
      .filter(item => !selectedFields.includes(item))
      .filter(k => k !== key);
  }, [schema.schema.properties, selectedFields, key]);

  useEffect(() => {
    setAvailableFields(filteredAvailableFields);
  }, [filteredAvailableFields]);

  useEffect(() => {
    if (selectedFields.length > 0) {
      setPropertySetting({
        ...propertySetting,
        code: {
          ...propertySetting.code,
          variables: selectedFields,
          source: code,
        },
      });
    }

    const newParams: any = {};
    const variables = JSON.parse(codeTestVariables);
    selectedFields.forEach(key => {
      let value: any = '';
      try {
        const def = schema.schema.properties![key] as JSONSchema7;
        if (def.type === 'number') {
          value = 0;
        } else if (def.type === 'boolean') {
          value = false;
        } else if (def.type === 'array') {
          value = [];
        }
      } catch (e) {
        console.error(`Error with key "${key}"`, e);
      }
      newParams[key] = variables[key] || value;
    });

    setCodeTestVariables(JSON.stringify(newParams, null, 2));
  }, [selectedFields]);
  // end code

  useEffect(() => {
    if (editKey) {
      setKey(editKey);
      const _property = schema.schema.properties![editKey] as JSONSchema7;
      setProperty(_property);
      const _propSettings = schema.settings.properties![
        editKey
      ] as RJSFSchemaSettingsProperty;
      setPropertySetting(_propSettings);
      setDescription(schema.settings.properties![editKey]?.description || '');
      setArrayAllowsMany(schema.settings.properties![editKey]?.many || false);
      setRequiredOnRegistration(
        schema.settings.properties![editKey]?.requiredOnRegistration || false,
      );

      if (propertySetting.typeId === FieldTypeId.MultiLine) {
        const existingOptions: LongTextOptions = propertySetting.options || {};
        setEnableRichTextFormatting(
          existingOptions.enableRichTextFormatting || false,
        );
        setRichTextTemplateText(existingOptions.template || '');
      } else if (_propSettings.typeId === FieldTypeId.CodePython) {
        if (_propSettings.code) {
          setCode(_propSettings.code.source);
          setSelectedFields(_propSettings.code.variables);
        }
      }
    }
  }, [editKey]);

  useEffect(() => {
    // validates key is not used on other properties, if it is, it will show an error and disable the save button
    if (key && !editKey) {
      if (key in schema.schema.properties!) {
        setKeyError(true);
      } else {
        setKeyError(false);
      }
    }
  }, [key, editKey]);

  useEffect(() => {
    if (propertySetting.typeId === FieldTypeId.MultiLine) {
      const existingOptions: LongTextOptions = propertySetting.options || {};
      setEnableRichTextFormatting(
        existingOptions.enableRichTextFormatting || false,
      );
      setRichTextTemplateText(existingOptions.template || '');
    }
  }, [propertySetting]);

  const saveSchema = useMutation(
    ['organizations', 'custom_fields'],
    async ({
      schema,
      settings,
      operation,
      properties,
    }: PutInventoryModelSchemaProps) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PutInventoryModelSchema(
        accessToken,
        schema,
        settings,
        operation,
        properties,
      );
    },
    {
      onSuccess: (data, { operation, properties }) => {
        let verb = 'added';
        if (operation === 'update') {
          verb = 'updated';
        } else if (operation === 'remove') {
          verb = 'removed';
        }
        let title = `Model inventory field ${properties.schema.title} ${verb} successfully`;
        toast({
          title,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onSave();
        onModalClose();
        refetchOrganization(); // to update the custom fields on the inventory model when used via user context.
      },
      onError: (error, variables, context) => {
        let errorMessage =
          'An error occurred while saving the Model Inventory Field';

        if (axios.isAxiosError(error) && error.response) {
          errorMessage = error.response.data?.message || errorMessage;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        toast({
          title: 'Error saving Model Inventory Field',
          description: errorMessage,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      },
    },
  );

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const title = (event.target as HTMLInputElement).value;
    setProperty({ ...property!, title });
    setPropertySetting({ ...propertySetting, label: title });
    if (!editKey) {
      setKey(_.camelCase(title));
    }
  };

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = (event.target as HTMLInputElement).value;
    setKey(key);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const description = (event.target as HTMLTextAreaElement).value;
    setDescription(description);
    setPropertySetting({ ...propertySetting, description });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const typeId = event.target.value;
    const selectedType = schemaTypesConfig.find(t => t.typeId === typeId);

    if (!selectedType) {
      console.error('selectedType not found', typeId);
      return;
    }

    const newProperty: JSONSchema7 = {
      ...property,
      title: property?.title,
      type: selectedType?.type as JSONSchema7TypeName,
      format: selectedType?.format,
    };

    if (selectedType?.format) {
      newProperty.format = selectedType?.format;
    }

    if (selectedType.uniqueItems) {
      newProperty.uniqueItems = selectedType.uniqueItems;
    } else {
      if ('uniqueItems' in newProperty) {
        delete newProperty.uniqueItems;
      }
    }

    // array types need to have an items property, otherwise none.
    if (selectedType?.type === 'array') {
      newProperty.items = { type: 'string' };
    } else {
      if ('items' in newProperty) {
        delete newProperty.items;
      }
    }

    setProperty(newProperty);

    setPropertySetting({
      typeId: typeId,
      description: description,
      entity: selectedType?.entity,
      uiSchema: selectedType?.uiSchema,
      label: property?.title,
      many: selectedType.entity ? arrayAllowsMany : undefined,
    });
  };

  const handleArrayAllowsManyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setArrayAllowsMany(event.target.checked);
    setPropertySetting({
      ...propertySetting,
      many: event.target.checked,
    });
  };

  const handleRequiredOnRegistration = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRequiredOnRegistration(event.target.checked);
    setPropertySetting({
      ...propertySetting,
      requiredOnRegistration: event.target.checked,
    });
  };

  const handleEnableRichTextFormatting = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEnableRichTextFormatting(event.target.checked);
    const existingOptions: LongTextOptions = propertySetting.options || {};
    existingOptions.enableRichTextFormatting = event.target.checked;
    setPropertySetting({
      ...propertySetting,
      options: existingOptions,
    });
  };

  const handleRichTextTemplate = (text: string) => {
    setRichTextTemplateText(text);
    // options will be present from the handleEnableRichTextFormatting function
    const existingOptions: LongTextOptions = propertySetting.options!;
    existingOptions.template = text;
    setPropertySetting({
      ...propertySetting,
      options: existingOptions,
    });
  };

  const handleChangeArrayOfCategories = (formData: { enums: string[] }) => {
    const enum_ = formData.enums.filter(e => e!!);
    if (property.type === 'array') {
      setProperty({
        ...property,
        items: {
          ...(property.items as JSONSchema7),
          enum: enum_.length > 0 ? enum_ : undefined,
        },
      });
    } else if (property.type === 'string') {
      setProperty({
        ...property,
        enum: enum_.length > 0 ? enum_ : undefined,
      });
    }
  };

  const onSaveClicked = () => {
    let operation: Operation = 'add';

    if (Object.keys(schema.schema.properties!).includes(key)) {
      operation = 'update';
    }

    schema.schema.properties![key] = property;
    schema.settings.properties![key] = propertySetting;

    saveSchema.mutate({
      schema: schema.schema,
      settings: schema.settings,
      operation,
      properties: {
        key: key,
        schema: property,
        settings: propertySetting,
      },
    });
  };

  const onDeleteClicked = () => {
    setConfirmDelete(true);
  };

  const onDeleteConfirmedClicked = (confirmed: boolean) => {
    setConfirmDelete(false);

    if (!confirmed) {
      return;
    }
    let operation: Operation = 'remove';

    const deletedSchema = schema.schema.properties![key] as JSONSchema7;
    const deletedSettings = schema.settings.properties![key];

    const schemaCopy = _.cloneDeep(schema);
    delete schemaCopy.schema.properties![key];
    delete schemaCopy.settings.properties![key];

    saveSchema.mutate({
      schema: schemaCopy.schema,
      settings: schemaCopy.settings,
      operation,
      properties: {
        key: key,
        schema: deletedSchema,
        settings: deletedSettings,
      },
    });
  };

  const onModalClose = () => {
    if (!editKey) {
      setKey('');
      setDescription('');
      setProperty(initialProperty);
      setPropertySetting(initialPropertySettings);
      setArrayAllowsMany(false);
      setKeyError(false);
      setSelectedFields([]);
      setAvailableFields(filteredAvailableFields);
      setCode(defaultCode);
      setCodeTestResponse(undefined);
      setCodeTestVariables('{}');
      setCodeTestVariablesError('');
    }
    onClose();
  };

  const saveIsDisabled =
    _.isEmpty(key) ||
    _.isEmpty(property?.title) ||
    saveSchema.isLoading ||
    keyError;

  const currentTypeSetting = schemaTypesConfig.find(
    t => t.typeId === propertySetting?.typeId,
  );
  function AddButton(props: IconButtonProps) {
    const { icon, iconType, ...btnProps } = props;
    return (
      <PrimaryButton {...btnProps} leftIcon={<Icon as={AddIcon} boxSize={3} />}>
        Add Option
      </PrimaryButton>
    );
  }

  const onCodeFieldDragEnd = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = selectedFields.findIndex(o => o === e.active.id);
      const newIdx = selectedFields.findIndex(o => o === e.over!.id);

      const newArray = arrayMove(selectedFields, oldIdx, newIdx);

      setSelectedFields(newArray);
    }
  };

  const fieldsWithSettingsLookup = useMemo(() => {
    const lookup: FieldsWithSettings = {};
    // foreach schema.schema.properties:
    Object.entries(schema.schema.properties || {}).forEach(([key, _schema]) => {
      const _settings = schema.settings.properties![
        key
      ] as RJSFSchemaSettingsProperty;
      lookup[key] = { schema: _schema as JSONSchema7, settings: _settings };
    });
    return lookup;
  }, [schema.schema.properties, schema.settings.properties]);

  const onAddField = (item: string) => {
    setSelectedFields([...selectedFields, item]);
  };

  const onCodeRemoveField = (id: string) => {
    // remove the item from selectedFields:
    setSelectedFields([...selectedFields.filter(item => item !== id)]);
    // add the item to the availableFields:
    setAvailableFields([...availableFields, id]);
  };

  const onCodeChange = (newCode: any) => {
    setCode(newCode);
    setPropertySetting({
      ...propertySetting,
      code: {
        ...propertySetting.code,
        variables: selectedFields,
        source: newCode,
      },
    });
  };

  const testCode = useMutation(
    ['organizations', 'custom_fields', 'code-test'],
    async ({ code, variables, execute }: TestCodeVariables) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PostCodeTest(
        accessToken,
        code,
        JSON.parse(variables),
        execute,
      );
    },
    {
      onSuccess: data => {
        setCodeTestResponse(data);
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error testing formula',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
    },
  );

  const onCodeTestClicked = () => {
    setCodeTestResponse(undefined);
    testCode.mutate({
      code,
      variables: codeTestVariables,
      execute: true,
    });
  };

  const onCodeTestVariablesChange = (newVariables: string) => {
    try {
      JSON.parse(newVariables);
      setCodeTestVariablesError('');
    } catch (e) {
      if (e instanceof Error) {
        setCodeTestVariablesError(e.message);
      }
    }
    setCodeTestVariables(newVariables);
  };

  const getLeftIcon = useMemo(() => {
    return editKey ? (
      <Icon as={PencilIcon} boxSize={3} />
    ) : (
      <Icon as={PlusIcon} boxSize={5} />
    );
  }, [editKey]);

  const handleTypeSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      handleTypeChange(event);
    },
    [handleTypeChange],
  );

  const selectTypeOptions = useMemo(() => {
    return schemaTypesConfig.map(t => (
      <option key={t.typeId} value={t.typeId}>
        {t.name}
      </option>
    ));
  }, [schemaTypesConfig]);

  return (
    <>
      <Button
        onClick={onOpen}
        variant={'ghost'}
        leftIcon={
          editKey ? (
            <Icon as={PencilIcon} boxSize={3} />
          ) : (
            <Icon as={PlusIcon} boxSize={5} />
          )
        }
        size={'sm'}
      >
        {editKey ? 'Edit' : 'Add New Field'}
      </Button>
      <Modal
        size={propertySetting?.typeId === FieldTypeId.CodePython ? '6xl' : 'xl'}
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onModalClose}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editKey && property
              ? `Edit ${property.title} field`
              : 'Add new model inventory field'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack direction={'row'} gap={4}>
              <VStack
                align="flex-start"
                gap={0}
                w={
                  propertySetting?.typeId === FieldTypeId.CodePython
                    ? '33%'
                    : 'full'
                }
              >
                <FormControl mt={4} isRequired>
                  <Label mb={2}>Type</Label>
                  <Select
                    ref={initialRef}
                    value={propertySetting?.typeId}
                    onChange={handleTypeSelectChange}
                  >
                    {selectTypeOptions}
                  </Select>
                </FormControl>

                <FormControl mt={4} isRequired>
                  <Label mb={2}>Name</Label>
                  <Input
                    autoComplete={'off'}
                    value={property?.title}
                    placeholder="Title"
                    onChange={event =>
                      handleTitleChange(
                        event as React.ChangeEvent<HTMLInputElement>,
                      )
                    }
                  />
                </FormControl>

                {/* <FormControl mt={4} isRequired isInvalid={keyError}>
                  <Label mb={2}>Key</Label>
                  <Input
                    disabled={!!editKey}
                    value={key}
                    placeholder="Key"
                    onChange={event =>
                      handleKeyChange(
                        event as React.ChangeEvent<HTMLInputElement>,
                      )
                    }
                  />
                  {keyError ? (
                    <FormErrorMessage>
                      Key is already used, please change to a different one.
                    </FormErrorMessage>
                  ) : (
                    <FormHelperText>
                      This is the identifier on the database. It must be a
                      unique value across all model inventory fields
                    </FormHelperText>
                  )}
                </FormControl> */}

                <FormControl
                  mt={4}
                  display="flex"
                  alignItems="center"
                  hidden={propertySetting?.typeId === FieldTypeId.CodePython}
                >
                  <Switch
                    id="requiredOnRegistration"
                    isChecked={requiredOnRegistration}
                    onChange={handleRequiredOnRegistration}
                    colorScheme={'brand'}
                  />
                  <Label ml={4}>Required on registration</Label>
                </FormControl>

                <FormControl mt={4}>
                  <Label mb={2}>Description</Label>
                  <Textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Describe the field"
                    size="sm"
                  />
                </FormControl>

                {/* For long texts, show toggle to allow for html editing (CKEditor) */}
                {propertySetting?.typeId === FieldTypeId.MultiLine && (
                  <FormControl mt={4} display="flex">
                    <VStack align="flex-start">
                      <HStack spacing={4}>
                        <Switch
                          id="enableRichTextFormatting"
                          isChecked={enableRichTextFormatting}
                          onChange={handleEnableRichTextFormatting}
                        />
                        <Label mb="0">Enable rich text formatting</Label>
                      </HStack>
                      <Box>
                        <FormHelperText>
                          Formatting options include checklists, hyperlinks,
                          headers, code blocks, and more.
                        </FormHelperText>
                      </Box>
                    </VStack>
                  </FormControl>
                )}

                {propertySetting?.typeId === FieldTypeId.MultiLine &&
                  enableRichTextFormatting && (
                    <FormControl mt={4} display="flex">
                      <VStack align="flex-start" w="100%">
                        <Label mb={2}>Template</Label>
                        <Box w="100%">
                          <CKEditorWrapper
                            text={richTextTemplateText}
                            onChange={handleRichTextTemplate}
                            readOnly={false}
                            enabledFeatures={{
                              images: false,
                              comments: false,
                              revisions: false,
                              deleteBlock: false,
                              generateWithAI: false,
                            }}
                            hideTooltip={true}
                            showOutline={true}
                            autoSave={false}
                            withHeight={true}
                          />
                        </Box>
                      </VStack>
                    </FormControl>
                  )}

                {currentTypeSetting?.displayAllowForManyEntities && (
                  <FormControl mt={4} display="flex" alignItems="center">
                    <Switch
                      id="arrayAllowsMany"
                      isChecked={arrayAllowsMany}
                      onChange={handleArrayAllowsManyChange}
                      colorScheme={'brand'}
                    />
                    <Label ml={2}>Allow linking to multiple records</Label>
                  </FormControl>
                )}

                {currentTypeSetting?.displayEnumForm && (
                  <FormControl mt={4}>
                    <Label mb={2}>Options to select from</Label>
                    <FormHelperText>
                      Options defined here will be available for selection.
                    </FormHelperText>
                    <Form
                      schema={{
                        type: 'object',
                        properties: {
                          enums: {
                            type: 'array',
                            title: 'Options',
                            items: {
                              type: 'string',
                            },
                          },
                        },
                      }}
                      formData={{
                        enums:
                          (property.type === 'array'
                            ? (property.items as JSONSchema7)?.enum
                            : property.enum) || [],
                      }}
                      validator={validator}
                      onChange={e => handleChangeArrayOfCategories(e.formData)}
                      uiSchema={{
                        'ui:globalOptions': {
                          label: false,
                        },
                        enums: {
                          'ui:options': {
                            addable: true,
                            orderable: true,
                            removable: true,
                          },
                        },
                        'ui:submitButtonOptions': {
                          norender: true,
                        },
                      }}
                      templates={{ ButtonTemplates: { AddButton } }}
                    />
                  </FormControl>
                )}
              </VStack>
              {propertySetting?.typeId === FieldTypeId.CodePython && (
                <FormControl mt={4} display="flex" w={'full'}>
                  <VStack align="flex-start" gap={4}>
                    <Stack gap={0}>
                      <Label>CALCULATION HANDLER</Label>
                      <FormHelperText>
                        Define a <code>formula(params)</code> function that
                        returns a value using the params dictionary, which
                        includes selected custom field keys. Keep your code
                        simple, avoid imports, and stick to basic operations.
                        Ensure efficiency and avoid complex logic for smooth,
                        secure execution.
                      </FormHelperText>
                    </Stack>
                    <HStack alignItems={'flex-start'} gap={4}>
                      <VStack alignItems={'flex-start'} w={'70%'} gap={4}>
                        <Stack gap={2}>
                          <Label>Available model fields</Label>
                          <Wrap spacing={1}>
                            {availableFields.map((item, index) => (
                              <Tag
                                size={'sm'}
                                key={index}
                                variant="subtle"
                                colorScheme="gray"
                                pl={0}
                              >
                                <IconButton
                                  aria-label="Add"
                                  icon={<Icon as={AddIcon} boxSize={3} />}
                                  onClick={() => onAddField(item)}
                                  size={'xs'}
                                  mr={2}
                                />
                                <TagLabel fontFamily={'mono'}>
                                  {`${item} (${fieldsWithSettingsLookup[item]?.schema.type})`}
                                </TagLabel>
                              </Tag>
                            ))}
                          </Wrap>
                        </Stack>

                        <Divider />
                        <Stack>
                          <VStack alignItems={'flex-start'} gap={0}>
                            <Label>
                              Formula has access to these model fields
                            </Label>
                            <FormHelperText>
                              The order will define how they display when
                              editable.{' '}
                              {selectedFields.length === 0 &&
                                'Select the first field below.'}
                            </FormHelperText>
                          </VStack>
                          <VStack alignItems={'flex-start'} gap={0}>
                            <DndContext
                              collisionDetection={closestCorners}
                              onDragEnd={onCodeFieldDragEnd}
                            >
                              <SortableContext
                                items={selectedFields}
                                strategy={verticalListSortingStrategy}
                              >
                                {selectedFields.map(item => {
                                  return (
                                    <SortableItem
                                      key={item}
                                      id={item}
                                      label={`${item} (${fieldsWithSettingsLookup[item]?.schema.type})`}
                                      isChecked={true}
                                      onRemove={onCodeRemoveField}
                                      textProps={{
                                        fontFamily: 'mono',
                                        textTransform: 'none',
                                        fontWeight: 'normal',
                                      }}
                                      size="sm"
                                    />
                                  );
                                })}
                              </SortableContext>
                            </DndContext>
                          </VStack>
                        </Stack>
                      </VStack>

                      <Box
                        height={'full'}
                        width={'full'}
                        overflow={'hidden'}
                        borderRadius={'md'}
                      >
                        <Editor
                          defaultLanguage="python"
                          theme="vs-dark"
                          defaultValue={code}
                          value={code}
                          onChange={onCodeChange}
                          onMount={(editor, monaco) => {
                            editorRef.current = editor;
                            monacoRef.current = monaco;
                          }}
                        />
                      </Box>
                    </HStack>
                    <Stack
                      w={'100%'}
                      borderLeft={'4px solid'}
                      borderColor={'yellow.200'}
                      p={3}
                      bg={useColorModeValue('white', 'neutral.850')}
                    >
                      <FormHelperText mt={0}>
                        The testing area automatically updates with the keys you
                        add or remove from the model fields. Enter test values
                        for these keys and click "Test formula" to run the code
                        and see the output. This lets you quickly validate your
                        formula before saving.
                      </FormHelperText>
                      <Textarea
                        style={{ fontFamily: 'monospace' }}
                        borderColor={'neutral.200'}
                        onChange={(
                          event: React.ChangeEvent<HTMLTextAreaElement>,
                        ) => {
                          onCodeTestVariablesChange(
                            (event.target as HTMLTextAreaElement).value,
                          );
                        }}
                        fontSize={'sm'}
                        value={codeTestVariables}
                      ></Textarea>
                      <Alert
                        status={'warning'}
                        hidden={codeTestVariablesError.length === 0}
                      >
                        <AlertIcon />
                        <AlertTitle>Test Variables</AlertTitle>
                        <AlertDescription>
                          {codeTestVariablesError}
                        </AlertDescription>
                      </Alert>
                      {codeTestResponse && (
                        <>
                          {codeTestResponse.success ? (
                            <Alert status="success">
                              <AlertIcon />
                              <AlertTitle>
                                The formula function is valid.
                              </AlertTitle>
                              <AlertDescription>
                                <Code>Result: {codeTestResponse?.result}</Code>
                              </AlertDescription>
                            </Alert>
                          ) : (
                            <Alert
                              status="error"
                              flexDirection="column"
                              alignItems="start"
                              fontSize={'sm'}
                              variant="left-accent"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                width="100%"
                              >
                                <AlertIcon />
                                <Box flex="1">
                                  <AlertTitle>
                                    The formula function is not valid.
                                  </AlertTitle>
                                  <AlertDescription>
                                    {codeTestResponse?.message}
                                  </AlertDescription>
                                </Box>
                              </Box>
                              {codeTestResponse?.line_number && (
                                <Box mt={4}>
                                  <AlertDescription>
                                    <strong>Line Number:</strong>{' '}
                                    {codeTestResponse.line_number}
                                  </AlertDescription>
                                </Box>
                              )}
                              {codeTestResponse?.traceback && (
                                <Box mt={4}>
                                  <AlertDescription>
                                    <strong>Traceback:</strong>
                                    <Code
                                      whiteSpace="pre-wrap"
                                      display="block"
                                      mt={2}
                                    >
                                      {codeTestResponse.traceback}
                                    </Code>
                                  </AlertDescription>
                                </Box>
                              )}
                              {codeTestResponse?.type && (
                                <Box mt={4}>
                                  <AlertDescription>
                                    <strong>Error Type:</strong>{' '}
                                    {codeTestResponse.type}
                                  </AlertDescription>
                                </Box>
                              )}
                            </Alert>
                          )}
                        </>
                      )}

                      <PrimaryButton
                        leftIcon={<Icon as={PlayIcon} boxSize={4} />}
                        alignSelf={'flex-end'}
                        onClick={onCodeTestClicked}
                        isLoading={testCode.isLoading}
                        isDisabled={codeTestVariablesError.length > 0}
                      >
                        Test formula
                      </PrimaryButton>
                    </Stack>
                  </VStack>
                </FormControl>
              )}
            </Stack>
            {/*<Text fontSize={'xs'}>*/}
            {/*  <pre>{JSON.stringify(property, null, 2)}</pre>*/}
            {/*  <hr />*/}
            {/*  <pre>{JSON.stringify(propertySetting, null, 2)}</pre>*/}
            {/*</Text>*/}

            {editKey && (
              <ConfirmationAlert
                title={`Deleting field: ${editKey}`}
                dialogBody={'Are you sure?'}
                open={confirmDelete}
                onConfirm={onDeleteConfirmedClicked}
              />
            )}
          </ModalBody>

          <ModalFooter>
            {editKey && (
              <>
                <Button
                  variant={'ghost'}
                  colorScheme="red"
                  mr={3}
                  onClick={onDeleteClicked}
                  isLoading={false}
                >
                  Delete
                </Button>
                <Spacer />
              </>
            )}

            <TertiaryButton onClick={onModalClose} disabled={false}>
              Cancel
            </TertiaryButton>
            <PrimaryButton
              mr={3}
              onClick={onSaveClicked}
              isDisabled={saveIsDisabled}
              isLoading={false}
            >
              Save
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
