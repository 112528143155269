import { useContext, useEffect, useMemo, useState } from 'react';
import ProjectContext from '../../contexts/ProjectContext';
import { TemplateSectionTree } from '../../models/template';
import { Select } from '@chakra-ui/react';

type SelectOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

type Props = {
  readOnly?: boolean;
  sectionId?: string | null;
  setSectionId: (sectionId: string) => void;
  documentType: 'documentation' | 'validation_report';
};

const DocumentSectionSelect = ({
  readOnly = false,
  sectionId,
  setSectionId,
  documentType,
}: Props) => {
  const [documentationSelectIsFocused, setDocumentationSelectIsFocused] =
    useState(false);

  const { templates } = useContext(ProjectContext);
  const [isReadOnly, setIsReadOnly] = useState(readOnly);

  useEffect(() => {
    setIsReadOnly(readOnly);
  }, [readOnly]);

  const templateOptions = useMemo<SelectOption[]>(() => {
    const sectionTree = templates[documentType].sectionTree;

    const options: SelectOption[] = [
      {
        label: 'None',
        value: '',
        disabled: false,
      },
    ];

    // Build select options for each section
    if (sectionTree) {
      // recursive function to traverse the section tree
      const buildOptionGroups: any = (
        section: TemplateSectionTree[],
        indexes: number[],
      ) => {
        section.forEach((s, idx) => {
          let label = indexes.length
            ? `${indexes.join('.')}.${idx + 1}. ${s.title}`
            : `${idx + 1}. ${s.title}`;

          // indent the label based on the length of the indexes array
          if (indexes.length) {
            label = `${[...Array(indexes.length * 4)]
              .map(_ => '\u00A0')
              .join('')} ${label}`;
          }
          if (s.sections) {
            options.push({
              label,
              value: s.id,
              disabled: s.index_only,
            });
            buildOptionGroups(s.sections, [...indexes, idx + 1]);
          } else {
            options.push({
              label,
              value: s.id,
              disabled: s.index_only,
            });
          }
        });
      };

      buildOptionGroups(templates.documentation.sectionTree, []);
    }

    return options;
  }, [documentType, templates]);

  return (
    <Select
      isDisabled={isReadOnly || templateOptions.length === 1}
      onChange={e => {
        if (isReadOnly) return;
        setDocumentationSelectIsFocused(false);
        setSectionId(e.target.value);
      }}
      value={sectionId || ''}
      onFocus={e => setDocumentationSelectIsFocused(true)}
      onBlur={e => setDocumentationSelectIsFocused(false)}
      onClick={e => setDocumentationSelectIsFocused(true)}
    >
      {templateOptions.map(o => (
        <option key={o.value} value={o.value} disabled={o.disabled}>
          {/* We added spaces in front of some option labels
          to simulate indentation, trim those spaces
          if the select control is not in focus */}
          {!documentationSelectIsFocused && sectionId === o.value
            ? o.label.trim()
            : o.label}
        </option>
      ))}
    </Select>
  );
};

export default DocumentSectionSelect;
