import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import API from '../../../api/API';
import { StatusChangedToast } from '../../Layout/Toasts';
import { TextContentEditor } from '../../TextContentEditor';
import { SectionContentsProps } from '../../Layout/DocumentationPage';
import { TProjectMetadata } from '../../../models/metadata';
import { canUpdateMetadata } from '../../../auth/utils';
import { ProjectContext } from '../../../contexts';
import { ActivityFeedWidgetQueryKey } from '../../ActivityFeedWidget';
import { getModelDocumentType } from '../../../models/model_document';
import UsersContext from '../../../contexts/UsersContext';
import { AIGenerationConfig } from '../../TextContentEditor/AITextContentEditor';
import DocumentationContext from '../../../contexts/DocumentationContext';
import { useFlags, EnableConfigFlag } from '../../../hooks/useFlags';
import { isUUID } from 'validator';
import { InventoryModelStages } from '../../../models/inventory_model';

interface UpdateMetadataVariables {
  metadata: TProjectMetadata;
  clearAnnotation?: boolean;
  silent?: boolean;
  documentType?: string;
}

interface PatchRevisionsVariables {
  revisionsData: any[];
  documentType?: string;
}

export function MetadataContentEditor({
  contents,
  removeBlock,
  readOnly,
  overrideDocumentType,
  customAIGenerationConfig,
}: SectionContentsProps) {
  // MetadataContentEditor expects a single contentId
  const metadataContentId = contents.content_id;
  const { project, templates } = useContext(ProjectContext);
  const { currentUser } = useContext(UsersContext);
  const { currentSection } = useContext(DocumentationContext);
  const { qualitativeTextGeneration } = useFlags();
  const queryClient = useQueryClient();
  const id = project?.cuid;
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const [originalText, setOriginalText] = useState('');
  const [currentDocumentType, setCurrentDocumentType] = useState(
    overrideDocumentType || templates.current.documentType,
  );
  const [aiGenerationConfig, setAIGenerationConfig] =
    useState<AIGenerationConfig>();
  const [isReadOnly, setIsReadOnly] = useState(false);

  const { enabled: defaultTextGenEnabled, config: defaultTextGenConfig } =
    (qualitativeTextGeneration as EnableConfigFlag) || {
      enabled: false,
      config: {},
    };

  useEffect(() => {
    setCurrentDocumentType(
      overrideDocumentType || templates.current.documentType,
    );
  }, [templates.current.documentType, overrideDocumentType]);

  useEffect(() => {
    if (!readOnly) {
      setIsReadOnly(
        project?.inventory_model.stage !== InventoryModelStages.ACTIVE,
      );
    } else {
      setIsReadOnly(readOnly);
    }
  }, [readOnly]);

  const queryKey = [
    'project',
    id,
    'metadata',
    currentDocumentType,
    metadataContentId,
  ];
  const documentType = getModelDocumentType(currentDocumentType);
  // TODO: handle errors in the UI with the `error` key

  const hasEditPerms = useMemo(
    () =>
      currentUser
        ? canUpdateMetadata(
            currentUser,
            project?.inventory_model.users!,
            documentType,
          )
        : false,
    [currentUser, project?.inventory_model.users, documentType],
  );

  const { data: metadata, isLoading } = useQuery(
    queryKey,
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetProjectMetadataByContentId(
        accessToken,
        project!,
        metadataContentId!,
        getModelDocumentType(currentDocumentType),
      );
    },
    {
      retry: false,
      // enabled: !isEditing, // avoids reloading the metadata into the revision history while editing,
      // it doesn't work nicely since when `isEditing` is back to true it will cause a fetch,
      // propagating down the server metadata and triggering a save with the old state, it messes up with the
      // content metadata and therefore with its revision history.
      refetchOnWindowFocus: false, // avoids reloading the metadata into the revision history while editing,
      // this one works but shuts down the perception of realtime updates.
      onSuccess: data => {
        setOriginalText(data.text);
      },
    },
  );

  const postMetadata = useMutation(
    queryKey,
    async ({ metadata, silent, documentType }: UpdateMetadataVariables) => {
      // silent is used when saving reference for annotations or attaching new content attachments.
      // when silent it won't register activity feed.
      const accessToken = await getAccessTokenSilently();
      return API.PostProjectMetadata(
        project!,
        getModelDocumentType(currentDocumentType),
        metadata,
        accessToken,
        silent,
        !!documentType,
      );
    },
    {
      onSuccess: (data, { silent }) => {
        // MetadataContentEditor can be used with an event widget that shows the changes that
        // has been done to the metadata. We need to invalidate the widget's query to make sure
        // it is updated after this mutation.
        queryClient.invalidateQueries({
          queryKey: [ActivityFeedWidgetQueryKey],
        });
      },
      onError: error => {
        if (error instanceof Error) {
          // TODO: Track
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            render: () => (
              <StatusChangedToast
                message="Could not save content"
                error={error.message}
                status="error"
              />
            ),
          });
        }
      },
    },
  );

  const postRevisions = useMutation(
    ['revisions'],
    async ({ revisionsData, documentType }: PatchRevisionsVariables) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PatchRevisions(
        accessToken,
        project!,
        metadata!.cuid!,
        revisionsData,
        documentType,
      );
    },
  );

  const onSave = (
    newText: string,
    silent: boolean = false,
    documentType?: string,
  ) => {
    console.log('MetadataContentEditor -> onSave', newText);
    let metadataToSave = metadata;
    if (!metadataToSave) {
      metadataToSave = {
        cuid: undefined,
        content_id: metadataContentId,
        content_type: getModelDocumentType(currentDocumentType),
        text: '',
      };
    }
    metadataToSave.text = newText;
    return postMetadata.mutateAsync({
      metadata: metadataToSave,
      silent,
      documentType,
    });
  };

  const onSaveRevisions = (revisionsData: any[], documentType?: string) => {
    console.log('MetadataContentEditor -> onSaveRevisions', revisionsData);
    return postRevisions.mutateAsync({ revisionsData, documentType });
  };

  const onCancel = (silent: boolean = true) => {
    if (metadata) {
      metadata.text = originalText;
      postMetadata.mutate({ metadata, silent });
    }
  };

  useEffect(() => {
    if (!defaultTextGenEnabled && !customAIGenerationConfig) {
      return;
    }

    (async () => {
      if (customAIGenerationConfig) {
        setAIGenerationConfig(customAIGenerationConfig);
      } else {
        // default setup for qualitative text
        const accessToken = await getAccessTokenSilently();

        let sectionTitle;
        let isSection = false;
        if (metadata && isUUID(metadata.content_id) && currentSection) {
          sectionTitle = `${currentSection.title}`;
        } else {
          isSection = true;
          sectionTitle = `${_.startCase(
            metadata?.content_id.replace(/_/g, ' '),
          )}`;
        }

        setAIGenerationConfig({
          initEvent: {
            eventName: 'generate-completions',
            args: {
              accessToken,
              cuid: project?.cuid!,
              content_id: metadata?.content_id!,
              section_title: sectionTitle,
              is_own_section: isSection,
              content_type: getModelDocumentType(currentDocumentType),
              user_cuid: currentUser?.cuid,
            },
          },
          streamEvent: {
            eventName: `completions-${metadata?.content_id}`,
          },
          options: {
            enableUserPrompt:
              (defaultTextGenConfig?.prompt_enabled as boolean) || false,
          },
        });
      }
    })();
  }, [
    metadata,
    project,
    currentSection,
    customAIGenerationConfig,
    defaultTextGenEnabled,
    defaultTextGenConfig,
  ]);

  return (
    <TextContentEditor
      metadata={metadata}
      text={metadata?.text || ''}
      isLoading={isLoading}
      onSave={onSave}
      onCancel={onCancel}
      onSaveRevisions={onSaveRevisions}
      allowEdit={isReadOnly ? false : hasEditPerms}
      removeBlock={removeBlock}
      aiGenerationConfig={aiGenerationConfig}
    />
  );
}
