import {
  Box,
  Button,
  HStack,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { TemplateSectionTree } from '../../models/template';
import { useContext } from 'react';
import { ProjectContext } from '../../contexts';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { TProjectAnnotation } from '../../api/API';
import { TInventoryModelValidationReportSummary } from '../../models/inventory_model';
import SectionStatusIndicators from '../SectionStatusIndicators';
import OutlineComplianceLevelIndicator from '../OutlineComplianceLevelIndicator';

interface ValidationReportOutlineProps {
  data: TemplateSectionTree[];
  indices?: number[];
  documentationOutline: TInventoryModelValidationReportSummary;
  allowMultiple?: boolean;
  resolveAnnotation: (annotation: TProjectAnnotation) => void;
}

const ValidationReportOutline = ({
  data,
  indices = [],
  documentationOutline,
  resolveAnnotation,
}: ValidationReportOutlineProps) => {
  const { project } = useContext(ProjectContext);
  const navigate = useNavigate();

  const navigateOutline =
    (
      section: TemplateSectionTree,
      project: any,
      parentSection?: TemplateSectionTree,
      guidelineContentId?: string,
    ) =>
    () => {
      let hash = '';
      if (guidelineContentId) {
        hash = `#h-${guidelineContentId}`;
      } else if (parentSection && parentSection.condensed) {
        hash = `#h-${section.id.replace(/_/g, '-')}`;
      }

      let url = `/projects/${project.cuid}/validation-report/${
        parentSection && parentSection.condensed
          ? // e.g. model-overview#h-sub-section
            `${parentSection.id.replace(/_/g, '-')}${hash}`
          : section.id.replace(/_/g, '-') + hash
      }`;

      navigate(url);
    };
  const renderItems = (
    items: TemplateSectionTree[],
    indices: number[],
    parentSection?: TemplateSectionTree,
  ) => {
    return items.map((item: TemplateSectionTree, index: number) => {
      const newIndices = [...indices, index + 1];
      const sectionOfOutline = documentationOutline[item.id];
      const guidelines = (item.contents || []).filter(
        c => c.content_type === 'guideline',
      );

      if (!item.sections || item.sections.length === 0) {
        return (
          <>
            <Button
              py={6}
              pl={4}
              w={'full'}
              mb={'1px'}
              rounded={0}
              border={'none'}
              variant={'ghost'}
              borderTop={'1px solid'}
              onClick={navigateOutline(item, project, parentSection)}
              _hover={{
                bg: useColorModeValue('neutral.100', 'neutral.850'),
                color: useColorModeValue('neutral.900', 'neutral.200'),
              }}
              color={useColorModeValue('neutral.600', 'neutral.200')}
              borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              transition={'all 0.2s ease-in-out'}
            >
              <HStack w={'full'}>
                <Text fontFamily={'monospace'} fontSize={'xs'}>
                  {newIndices.join('.')}.
                </Text>
                <Text>{item.title}</Text>
                <Spacer />
                <SectionStatusIndicators
                  status={sectionOfOutline?.status || 'in_progress'}
                  annotations={sectionOfOutline?.unresolved_threads || []}
                  findings={sectionOfOutline?.findings || []}
                  resolveAnnotation={resolveAnnotation}
                  onAnnotationClick={annotation => {
                    const section =
                      parentSection && parentSection.condensed
                        ? `${parentSection!.id.replace(/_/g, '-')}`
                        : item.id.replace(/_/g, '-');

                    let url = `/projects/${
                      project!.cuid
                    }/validation-report/${section}`;

                    navigate({
                      pathname: url,
                      search: `${createSearchParams({
                        'data-comment': `${annotation.cuid}`,
                      })}`,
                    });
                  }}
                />
              </HStack>
            </Button>

            {guidelines.length > 0 && sectionOfOutline && (
              <Box
                ml={10}
                w={'calc(100% - 1.2rem)'}
                borderLeft={'solid'}
                borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              >
                {guidelines.map((guideline, index) => {
                  const guidelineData =
                    sectionOfOutline.guidelines[guideline.content_id];

                  return (
                    <Button
                      py={6}
                      pl={4}
                      w={'full'}
                      mb={'1px'}
                      rounded={0}
                      border={'none'}
                      variant={'ghost'}
                      borderTop={'1px solid'}
                      onClick={navigateOutline(
                        item,
                        project,
                        parentSection,
                        guideline.content_id,
                      )}
                      _hover={{
                        bg: useColorModeValue('neutral.100', 'neutral.850'),
                        color: useColorModeValue('neutral.900', 'neutral.200'),
                      }}
                      color={useColorModeValue('neutral.600', 'neutral.200')}
                      borderColor={useColorModeValue(
                        'neutral.200',
                        'neutral.800',
                      )}
                      transition={'all 0.2s ease-in-out'}
                    >
                      <HStack w={'full'}>
                        <Text>{guidelineData.guideline.title}</Text>
                        <Spacer />
                        <SectionStatusIndicators
                          status={sectionOfOutline?.status || 'in_progress'}
                          annotations={guidelineData?.unresolved_threads || []}
                          findings={guidelineData?.findings || []}
                          resolveAnnotation={resolveAnnotation}
                          onAnnotationClick={annotation => {
                            const section =
                              parentSection && parentSection.condensed
                                ? `${parentSection!.id.replace(/_/g, '-')}`
                                : item.id.replace(/_/g, '-');

                            let url = `/projects/${
                              project!.cuid
                            }/validation-report/${section}`;

                            navigate({
                              pathname: url,
                              search: `${createSearchParams({
                                'data-comment': `${annotation.cuid}`,
                              })}`,
                            });
                          }}
                        >
                          <OutlineComplianceLevelIndicator
                            assessment={guidelineData.assessment}
                          />
                        </SectionStatusIndicators>
                      </HStack>
                    </Button>
                  );
                })}
              </Box>
            )}
          </>
        );
      } else {
        return (
          <VStack
            gap={'1px'}
            borderTop={'1px solid'}
            alignItems={'flex-start'}
            borderColor={useColorModeValue('neutral.200', 'neutral.800')}
          >
            <Box
              py={3}
              px={4}
              mb={0}
              w={'full'}
              rounded={0}
              border={'none'}
              fontWeight={'semi-bold'}
              color={'neutral.500'}
              borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              transition={'all 0.2s ease-in-out'}
            >
              <HStack w={'full'}>
                <Text fontFamily={'monospace'} fontSize={'xs'}>
                  {newIndices.join('.')}.
                </Text>
                <Text>{item.title}</Text>
                <Spacer />
              </HStack>
            </Box>
            {item.sections && item.sections.length > 0 ? (
              <Box
                ml={5}
                w={'calc(100% - 1.2rem)'}
                borderLeft={
                  item.sections && item.sections.length > 0 ? 'solid' : 'none'
                }
                borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              >
                {renderItems(item.sections, newIndices, item)}
              </Box>
            ) : null}
          </VStack>
        );
      }
    });
  };

  return (
    <Box data-testid="validation-report-outline">
      {renderItems(data, indices)}
    </Box>
  );
};

export default ValidationReportOutline;
