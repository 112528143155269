import {
  ChevronDownIcon,
  ChevronUpIcon,
  DragHandleIcon,
} from '@chakra-ui/icons';
import {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from '@dnd-kit/core';
import {
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Spacer,
  Text,
  useColorModeValue,
  useEditableControls,
} from '@chakra-ui/react';
import {
  TrashIcon,
  PencilIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { HTMLAttributes, forwardRef, useState } from 'react';
import ConfirmationAlert from '../../ConfirmationAlert';

export interface AccordionHeaderProps
  extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  clone?: boolean;
  collapsed?: boolean;
  isEditing?: boolean;
  isDragging?: boolean;
  index?: string;
  title: string;
  disableAddSubSection?: boolean;
  onCollapse?(): void;
  onRemove?(): void;
  onTitleSaved?: (title: string) => void;
  onAddSection?: (position: 'before' | 'after' | 'child') => void;
  handleProps?: {
    listeners?: DraggableSyntheticListeners;
    attributes: DraggableAttributes;
  };
  renderMenu?: () => JSX.Element;
}

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup size="sm" gap={0}>
      <IconButton
        aria-label="submit"
        icon={<Icon as={CheckIcon} boxSize={4} strokeWidth={2} />}
        size="sm"
        {...getSubmitButtonProps()}
      />
      <IconButton
        aria-label="cancel"
        icon={<Icon as={XMarkIcon} boxSize={4} strokeWidth={2} />}
        size="sm"
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Flex display="none" _groupHover={{ display: 'inline-flex' }}>
      <IconButton
        aria-label="edit"
        size="sm"
        icon={<Icon as={PencilIcon} />}
        {...getEditButtonProps()}
      />
    </Flex>
  );
}

export const AccordionHeader = forwardRef<HTMLDivElement, AccordionHeaderProps>(
  (
    {
      clone,
      collapsed,
      onCollapse,
      onRemove,
      handleProps,
      style,
      index,
      title: originalTitle,
      isEditing: isEditingTemplate = false,
      isDragging,
      onTitleSaved,
      onAddSection,
      renderMenu,
    },
    ref,
  ) => {
    const [showDeletionConfirmation, setShowDeletionConfirmation] =
      useState(false);

    if (isDragging) {
      return (
        <Box style={style} ref={ref} height={3} bg="brand.base" mt={'1px'} />
      );
    }

    const handleDeleteSection = (confirmed: boolean) => {
      setShowDeletionConfirmation(false);
      if (confirmed && onRemove) {
        onRemove();
      }
    };

    return (
      <HStack
        bg={useColorModeValue('neutral.50', 'neutral.900')}
        _hover={
          onCollapse
            ? {
                bg: useColorModeValue('neutral.100', 'neutral.850'),
              }
            : {}
        }
        p={1.5}
        pl={4}
        pr={2}
        gap={0}
        role="group"
        data-testid="section-control"
        borderY="1px"
        mt="-1px"
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        onClick={onCollapse}
        style={style}
        ref={ref}
      >
        {isEditingTemplate && handleProps && (
          <IconButton
            variant="ghost"
            size="sm"
            icon={<Icon as={DragHandleIcon} />}
            aria-label="dragHandle"
            {...handleProps.listeners}
            {...handleProps.attributes}
            onClick={e => e.stopPropagation()}
          />
        )}
        {isEditingTemplate && renderMenu && renderMenu()}
        {index && (
          <Text
            fontFamily={'monospace'}
            fontSize={'xs'}
            pointerEvents={'none'}
            mr={1}
          >
            {index}.
          </Text>
        )}
        <Editable
          defaultValue={originalTitle}
          isDisabled={!isEditingTemplate}
          onSubmit={value => onTitleSaved && onTitleSaved(value)}
          submitOnBlur={false}
          as={HStack}
          w={'auto'}
          onClick={e => e.stopPropagation()}
          pointerEvents={isEditingTemplate ? 'auto' : 'none'}
        >
          <EditablePreview />
          <Input
            rounded={'sm'}
            px={2}
            size={'sm'}
            border={'1px solid'}
            borderColor={useColorModeValue('neutral.100', 'neutral.800')}
            focusBorderColor="brand.base"
            backgroundColor={useColorModeValue('white', 'neutral.950')}
            as={EditableInput}
          />
          {isEditingTemplate && <EditableControls />}
        </Editable>
        <Spacer />
        {!clone && isEditingTemplate && (
          <IconButton
            variant="ghost"
            size={'sm'}
            display="none"
            _groupHover={{ display: 'inline-flex' }}
            _hover={{
              bg: useColorModeValue('red.100', 'red.700'),
              color: useColorModeValue('red.600', 'red.200'),
            }}
            icon={<Icon as={TrashIcon} boxSize={4} />}
            aria-label="remove"
            onClick={e => {
              setShowDeletionConfirmation(true);
              e.stopPropagation();
            }}
          />
        )}
        {onCollapse && (
          <Box mx={1}>
            <Icon
              as={collapsed ? ChevronDownIcon : ChevronUpIcon}
              boxSize={5}
            />
          </Box>
        )}
        <ConfirmationAlert
          title="Delete Section"
          dialogBody="Are you sure you want to delete this section? All content in the section will be lost."
          open={showDeletionConfirmation}
          size="2xl"
          cancelButton={<Button variant="ghost">No, Cancel</Button>}
          confirmButton={<Button>Yes, Delete</Button>}
          onConfirm={handleDeleteSection}
        />
      </HStack>
    );
  },
);
