import {
  Box,
  Fade,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { PrimaryButton } from '../../../components/Layout';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../../api/API';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import AddUseCaseModal from './AddUseCaseModal';
import UsersContext from '../../../contexts/UsersContext';
import { TUseCase } from '../../../models/use_case';

interface UseCaseItemProps {
  item: TUseCase;
  canDelete: boolean;
  onDelete: (id: string) => void;
}

const UseCaseItem = forwardRef<HTMLDivElement, UseCaseItemProps>(
  function UseCaseItem({ item, canDelete, onDelete }, ref) {
    return (
      <Box w="full" ref={ref}>
        <Fade in={true} transition={{ enter: { duration: 0.5, delay: 0.2 } }}>
          <HStack
            pr={2}
            pl={4}
            border="1px solid"
            borderColor={useColorModeValue('neutral.200', 'neutral.600')}
            w="full"
            bg={useColorModeValue('white', 'neutral.800')}
            rounded="md"
            role="group"
          >
            <Text maxW="85%" py={3}>
              {item.name}
            </Text>
            <Spacer />
            {canDelete && (
              <IconButton
                variant={'ghost'}
                size="sm"
                icon={<Icon as={TrashIcon} boxSize={4} />}
                aria-label="Remove"
                _groupHover={{ display: 'block' as const }}
                display="none"
                onClick={() => onDelete(item.cuid)}
                _hover={{
                  bg: useColorModeValue('red.100', 'red.700'),
                  color: useColorModeValue('red.600', 'red.200'),
                }}
                color={useColorModeValue('neutral.800', 'neutral.200')}
              />
            )}
          </HStack>
        </Fade>
      </Box>
    );
  },
);

export function UseCasesPanel() {
  const { getAccessTokenSilently } = useAuth0();
  const { currentOrganization } = useContext(UsersContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userHasPermission } = useContext(UsersContext);
  const queryClient = useQueryClient();
  const toast = useToast();
  const [lastAddedItemId, setLastAddedItemId] = useState<string | null>(null);
  const newItemRef = useRef<HTMLDivElement>(null);

  const canReadUseCase = userHasPermission(['read_use_case'], 'all');
  const canAddUseCase = userHasPermission(['add_use_case'], 'all');
  const canDeleteUseCase = userHasPermission(['delete_use_case'], 'all');

  if (!canReadUseCase) {
    return null;
  }

  const { data: useCases = [] } = useQuery(['use-cases'], async () => {
    const accessToken = await getAccessTokenSilently();
    const response = (
      await API.GetOrganizationUseCases(accessToken, currentOrganization)
    ).results;
    return response;
  });

  const deleteUseCaseMutation = useMutation(
    [],
    async ({ cuid }: { cuid: string }) => {
      const accessToken = await getAccessTokenSilently();
      return API.DeleteOrganizationUseCase(
        accessToken,
        currentOrganization,
        cuid,
      );
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('use-cases');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error deleting use case',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const onSuccessUseCase = (data: TUseCase) => {
    setLastAddedItemId(data.cuid);
    onClose();
  };

  useEffect(() => {
    if (lastAddedItemId && newItemRef.current) {
      newItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setLastAddedItemId(null);
    }
  }, [lastAddedItemId, useCases]);

  const onDeleteUseCase = (cuid: string) => {
    deleteUseCaseMutation.mutate({ cuid });
  };

  return (
    <Stack
      w="full"
      gap={4}
      padding={4}
      border="1px solid"
      rounded="md"
      borderColor={useColorModeValue('neutral.100', 'neutral.800')}
      bg={useColorModeValue('neutral.50', 'neutral.900')}
      h="full"
    >
      <HStack spacing={1} w="full" data-testId="add-use-case">
        <Heading as="h2" fontSize="large" fontWeight="semibold">
          Use Cases
        </Heading>
        <Spacer />
        {canAddUseCase && (
          <PrimaryButton
            data-testid="add-use-case-open-modal-button"
            onClick={onOpen}
            rounded="md"
            alignContent={'right'}
            leftIcon={<Icon as={PlusIcon} boxSize={4} />}
          >
            Add
          </PrimaryButton>
        )}
      </HStack>
      <VStack alignItems="flex-start" w="full" gap={4}>
        <Box scrollBehavior="smooth" maxHeight={96} overflowY="auto" w="full">
          <VStack w="full" gap={1} data-testId="use-cases">
            {useCases.map((useCase: TUseCase) => (
              <UseCaseItem
                ref={useCase.cuid === lastAddedItemId ? newItemRef : null}
                key={useCase.cuid}
                item={useCase}
                canDelete={canDeleteUseCase}
                onDelete={onDeleteUseCase}
              />
            ))}
          </VStack>
        </Box>
      </VStack>
      <AddUseCaseModal
        isOpen={isOpen}
        onSuccess={onSuccessUseCase}
        onClose={onClose}
      />
    </Stack>
  );
}
