import { io, Socket } from 'socket.io-client';
import { CONFIG } from './config';

const socketUrl = CONFIG.REACT_APP_API_URL.replace('/api/v1', '/wss');
export const socket = io(socketUrl, {
  autoConnect: true,
});

export const createNewSocket = (): Promise<Socket> => {
  const newSocket = io(socketUrl, {
    autoConnect: true,
  });
  return new Promise((resolve, reject) => {
    newSocket.on('connect', () => {
      return resolve(newSocket);
    });
    newSocket.on('connect_error', (error: any) => {
      return reject(error);
    });
  });
};
