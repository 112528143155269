import { FieldEditProps } from '../../index';
import {
  Center,
  FormLabel,
  HStack,
  Icon,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import CustomFieldEditLayout from '../CustomFieldEditLayout';

const BooleanFieldEdit = ({
  field,
  onEdit,
  onCancel,
  onChange,
}: FieldEditProps) => {
  const [isCheckedState, setIsCheckedState] = useState(false);
  useEffect(() => {
    setIsCheckedState(field.value);
  }, [field]);
  const handleSubmit = () => {
    onEdit({ [field.key]: isCheckedState });
  };

  const handleCancel = () => {
    onCancel({});
  };
  const handleChange = (e: any) => {
    setIsCheckedState(e.target.checked);
    onChange?.({ [field.key]: e.target.checked });
  };
  return (
    <CustomFieldEditLayout
      onCancelClick={onCancel}
      onSaveClick={handleSubmit}
      showEditControls={!!onChange}
    >
      <Center>
        <VStack w="full">
          <HStack gap={2}>
            <FormLabel htmlFor="BooleanToggle" display={'contents'}>
              <Text>No</Text>
              <Icon as={XMarkIcon} boxSize={4} />
            </FormLabel>
            <Switch
              id="BooleanToggle"
              isChecked={isCheckedState}
              onChange={handleChange}
              size={'lg'}
              colorScheme="brand"
            />
            <FormLabel htmlFor="BooleanToggle" display={'contents'}>
              <Icon as={CheckIcon} boxSize={4} />
              <Text>Yes</Text>
            </FormLabel>
          </HStack>
        </VStack>
      </Center>
    </CustomFieldEditLayout>
  );
};

export default BooleanFieldEdit;
