import {
  Box,
  Flex,
  HStack,
  Stack,
  Tag,
  TagLeftIcon,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../../components/LoadingContainer';
import RecordDetailsPage from '../../../../components/Layout/RecordDetailsPage';
import { useContext, useMemo } from 'react';
import { ProjectContext } from '../../../../contexts';
import API, { TProjectAnnotation } from '../../../../api/API';
import { convertToSectionTree } from '../../../../models/template';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import ValidationReportOutline from '../../../../components/ValidationReportOutline';
import {
  ChatBubbleLeftEllipsisIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import RiskAssessmentSummary from '../../../../components/Templates/ModelDocumentation/RiskAssessmentContent/RiskAssessmentSummary';
import TemplateModal from '../../../../components/TemplateModal';
import { TFinding } from '../../../../models';
import AttributesRailValidationReportOverview from '../../../../components/AttributesRailValidationReportOverview';
import ValidationReportHeader from '../../../../components/ValidationReportHeader';

export default function ValidationReportOverview() {
  const { getAccessTokenSilently } = useAuth0();
  const { project, setTemplates, templates } = useContext(ProjectContext);
  const {
    isOpen: isOpenTemplateModal,
    onOpen: onOpenTemplateModal,
    onClose: onCloseTemplateModal,
  } = useDisclosure();

  const { data: vrOutline = {}, refetch: refetchDocumentationOverview } =
    useQuery(
      ['validation-report-overview'],
      async () => {
        const accessToken = await getAccessTokenSilently();
        return await API.GetValidationReportSummary(
          accessToken,
          project!.inventory_model.cuid,
        );
      },
      {
        enabled: !!project,
      },
    );

  const unresolvedConversationsCount = useMemo(() => {
    let count = 0;
    Object.values(vrOutline).forEach(section => {
      count += section.unresolved_threads.length;
      Object.values(section.guidelines).forEach(guideline => {
        count += guideline.unresolved_threads.length;
      });
    });
    return count;
  }, [vrOutline]);

  const { highFindingsCount, mediumFindingsCount, lowFindingsCount } =
    useMemo(() => {
      const counts = {
        highFindingsCount: 0,
        mediumFindingsCount: 0,
        lowFindingsCount: 0,
      };

      const incrementCount = (finding: TFinding) => {
        if (finding.severity.level === 1) counts.highFindingsCount++;
        if (finding.severity.level === 2) counts.mediumFindingsCount++;
        if (finding.severity.level === 3) counts.lowFindingsCount++;
      };

      Object.values(vrOutline).forEach(section => {
        section.findings.forEach(incrementCount);
        Object.values(section.guidelines).forEach(guideline => {
          guideline.findings.forEach(incrementCount);
        });
      });

      return counts;
    }, [vrOutline]);

  const guidelinesContentIds = useMemo(() => {
    return Array.from(
      new Set(
        Object.values(vrOutline)
          .map(o => Object.keys(o.guidelines))
          .flat(),
      ),
    );
  }, [vrOutline]);

  const tree = convertToSectionTree(templates.validation_report.sections || []);

  return (
    <>
      <LoadingContainer isLoading={false}>
        <Flex minHeight="100vh" w={'full'}>
          <Stack
            px={8}
            w={'full'}
            alignItems={'center'}
            overflow={'auto'}
            className="no-scrollbar"
          >
            <RecordDetailsPage
              right={
                <AttributesRailValidationReportOverview
                  project={project!}
                  onOpenTemplateModal={onOpenTemplateModal}
                />
              }
            >
              <ValidationReportHeader project={project!} />
              <VStack gap={8} align="stretch">
                <Box>
                  <RiskAssessmentSummary
                    guidelinesContentIds={guidelinesContentIds}
                  />
                </Box>
                <Stack spacing={4} align="stretch">
                  <HStack justifyContent={'flex-end'} w={'full'}>
                    {unresolvedConversationsCount > 0 && (
                      <Tag size={'md'} rounded={'full'}>
                        <TagLeftIcon
                          boxSize={4}
                          as={ChatBubbleLeftEllipsisIcon}
                        />
                        {unresolvedConversationsCount} Unresolved conversations
                      </Tag>
                    )}
                    {(highFindingsCount ||
                      mediumFindingsCount ||
                      lowFindingsCount) && (
                      <Tag
                        size={'md'}
                        colorScheme="light"
                        // bg={useColorModeValue('white', 'neutral.900')}
                        border={'1px solid'}
                        borderColor={useColorModeValue(
                          'neutral.200',
                          'neutral.800',
                        )}
                        rounded={'full'}
                        pl={0}
                      >
                        <HStack mr={2}>
                          {highFindingsCount && (
                            <Tag size={'sm'} colorScheme="red" rounded={'full'}>
                              <TagLeftIcon
                                boxSize={4}
                                as={ExclamationTriangleIcon}
                              />
                              {highFindingsCount}
                            </Tag>
                          )}
                          {mediumFindingsCount && (
                            <Tag
                              size={'sm'}
                              colorScheme="yellow"
                              rounded={'full'}
                            >
                              <TagLeftIcon
                                boxSize={4}
                                as={ExclamationTriangleIcon}
                              />
                              {mediumFindingsCount}
                            </Tag>
                          )}
                          {lowFindingsCount && (
                            <Tag size={'sm'} rounded={'full'}>
                              <TagLeftIcon
                                boxSize={4}
                                as={ExclamationTriangleIcon}
                              />
                              {lowFindingsCount}
                            </Tag>
                          )}
                        </HStack>
                        Model Findings
                      </Tag>
                    )}
                  </HStack>
                  <Box>
                    <ValidationReportOutline
                      data={tree}
                      allowMultiple={true}
                      documentationOutline={vrOutline}
                      resolveAnnotation={async (
                        annotation: TProjectAnnotation,
                      ) => {
                        const accessToken = await getAccessTokenSilently();
                        API.ResolveAnnotation(
                          accessToken,
                          project!,
                          annotation.cuid!,
                          true,
                        );
                        refetchDocumentationOverview();
                      }}
                    />
                  </Box>
                </Stack>
              </VStack>
            </RecordDetailsPage>
          </Stack>
        </Flex>
      </LoadingContainer>
      {project && (
        <TemplateModal
          project={project}
          activeTemplateDocumentType={'validation_report'}
          isOpen={isOpenTemplateModal}
          onClose={onCloseTemplateModal}
        />
      )}
    </>
  );
}
