import { Template } from '../../models';
import { convertToSectionTree } from '../../models/template';
import { useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DiffEditor } from '@monaco-editor/react';
import { stringify } from 'yaml';
import SelectTemplateVersion from '../SelectTemplateVersion';
import { Label } from '../Layout';
import { useTemplateVersion } from '../../hooks/useTemplates';
import { diffOptions } from '../TemplateEditModal';

interface TemplateCompareModalProps {
  open: boolean;
  templateType: string;
  baseTemplate: Template;
  rightVersion: Template;
  onClose: () => void;
}

export default function TemplateCompareModal({
  open,
  templateType,
  baseTemplate,
  rightVersion,
  onClose,
}: TemplateCompareModalProps) {
  const [selectedRightVersion, setSelectedRightVersion] =
    useState(rightVersion);
  const [rightVersionNumber, setRightVersionNumber] = useState(
    rightVersion.current_version?.version_number,
  );

  const {
    template: fetchedTemplateFromSelectedVersion,
    isLoading: isLoadingFetchingRightTemplate,
  } = useTemplateVersion(
    baseTemplate.cuid,
    rightVersionNumber,
    !!rightVersionNumber,
  );

  useEffect(() => {
    if (fetchedTemplateFromSelectedVersion) {
      setSelectedRightVersion(fetchedTemplateFromSelectedVersion);
    }
  }, [fetchedTemplateFromSelectedVersion]);

  useEffect(() => {
    if (rightVersion) {
      setSelectedRightVersion(rightVersion);
      setRightVersionNumber(rightVersion.current_version?.version_number);
    }
  }, [rightVersion]);

  const leftCode = convertToSectionTree(
    baseTemplate.current_version!.json.sections,
  );

  const rightCode = convertToSectionTree(
    selectedRightVersion.current_version!.json.sections,
  );

  const onSelectedTemplateVersionChange = (versionCuid: string) => {
    const selectedVersion = (baseTemplate.versions || []).find(
      v => v.cuid === versionCuid,
    );
    setRightVersionNumber(selectedVersion?.version_number);
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      size={'full'}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent bg={'neutral.50'} margin={16}>
        <ModalHeader>Compare Versions</ModalHeader>
        <ModalCloseButton data-testid="close-template-modal-btn" />
        <ModalBody>
          <Stack>
            <HStack>
              <Stack flex={1}>
                <Label>Version</Label>
                <SelectTemplateVersion
                  template={baseTemplate}
                  selectedVersion={selectedRightVersion.current_version!}
                  onSelectVersion={onSelectedTemplateVersionChange}
                  includeDescription
                />
                {isLoadingFetchingRightTemplate && (
                  <Progress hasStripe isIndeterminate size={'xs'} />
                )}
              </Stack>
              <Stack flex={1}>
                <Label>Version</Label>
                <Text>{baseTemplate.description}Latest</Text>
              </Stack>
            </HStack>
            <Stack>
              <Box
                height={'calc(90vh - 5rem)'}
                hidden={false}
                overflow={'hidden'}
                borderRadius={'md'}
                border={'1px solid'}
                borderColor={'neutral.200'}
              >
                <DiffEditor
                  original={stringify(leftCode || {})}
                  modified={stringify(rightCode || {})}
                  language="yaml"
                  theme="vs-dark"
                  options={diffOptions}
                />
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
