import { TInventoryModelCustomField } from '../../../../models/inventory_model';
import React from 'react';
import { FieldEditProps } from '../index';
import { getSchemaPropertyByKey } from '../../../../utils';
import RegularFieldEdit from './RegularFieldEdit';
import UserFieldEdit from './UserFieldEdit';
import BooleanFieldEdit from './BooleanFieldEdit';
import TieringFieldEdit from './TieringFieldEdit';
import BusinessUnitFieldEdit from './BusinessUnitFieldEdit';
import UseCaseFieldEdit from './UseCaseFieldEdit';
import HTMLFieldEdit from './HTMLFieldEdit';

interface CustomFieldEditProps {
  customField: TInventoryModelCustomField;
  onEdit: (data: any) => void;
  onCancel: (data: any) => void;
  onChange?: (data: any) => void;
}

interface ISupportedFieldComponents {
  [key: string]: React.FC<FieldEditProps>;
}
const EDIT_FIELD_COMPONENTS: ISupportedFieldComponents = {
  'boolean:checkbox': BooleanFieldEdit,
  string: RegularFieldEdit,
  number: RegularFieldEdit,
  User: UserFieldEdit,
  'string:tiering': TieringFieldEdit,
  'string:business-unit': BusinessUnitFieldEdit,
  'string:use-case': UseCaseFieldEdit,
  'string:html': HTMLFieldEdit,
};

const CustomFieldEdit = ({
  customField,
  onEdit,
  onCancel,
  onChange,
}: CustomFieldEditProps) => {
  const [jsonSchemaProperty, settings] = getSchemaPropertyByKey(
    customField,
    customField.key,
  );
  const fieldType = settings?.entity || settings.typeId;

  let FieldTypeComponent =
    EDIT_FIELD_COMPONENTS[jsonSchemaProperty.type as keyof object] ||
    RegularFieldEdit;

  if (fieldType) {
    FieldTypeComponent =
      EDIT_FIELD_COMPONENTS[fieldType as keyof object] || FieldTypeComponent;
  }

  return (
    <FieldTypeComponent
      field={customField}
      onEdit={onEdit}
      onCancel={onCancel}
      onChange={onChange}
    />
  );
};

export default CustomFieldEdit;
