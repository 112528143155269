import { Button, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import StatusBadge from '../StatusBadge';
import { WorkflowStatusForm } from '../../models/workflow';
import WorkflowStatusFormModal from '../WorkflowStatusFormModal';
import { TStatusesWorkflowStatus } from '../../models/statuses_workflow';
import { FieldValues } from '../../hooks/useFields';

interface WorkflowStatusActionsProps {
  triggerId: string;
  entityCuid: string;
  fromStatus?: TStatusesWorkflowStatus;
  defaultCustomFieldValues: FieldValues;
  onSave: () => void;
}

export default function WorkflowStatusActions({
  triggerId,
  entityCuid,
  fromStatus,
  defaultCustomFieldValues,
  onSave,
}: WorkflowStatusActionsProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedAction, setSelectedAction] = useState<WorkflowStatusForm>();

  const { data, isLoading, refetch } = useQuery(
    ['workflows', 'status_form'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetWorkflowStatusForms(accessToken, triggerId, entityCuid);
    },
  );

  const onSaved = () => {
    onSave();
    refetch();
    setSelectedAction(undefined);
  };

  const onCancel = () => {
    setSelectedAction(undefined);
  };

  if (data && data.length > 0 && !isLoading) {
    return (
      <>
        <VStack alignItems={'flex-start'}>
          <VStack alignItems={'stretch'}>
            {data.map(d => (
              <Button
                data-testid="transition-action"
                onClick={() => setSelectedAction(d)}
                key={d.trigger}
                aria-label={d.trigger}
                leftIcon={<ArrowForwardIcon />}
                height={'auto'}
                p={4}
              >
                <VStack w={'full'} alignItems={'start'}>
                  <Text w={'full'} whiteSpace={'normal'} textAlign={'left'}>
                    {d.buttonLabel}
                  </Text>
                  <Text fontSize={'xs'}>Transitions model to </Text>
                  <StatusBadge
                    bg={d.status?.colors.primary}
                    color={d.status?.colors.tertiary}
                  >
                    {d.status?.name}
                  </StatusBadge>
                </VStack>
              </Button>
            ))}
          </VStack>
        </VStack>
        {selectedAction && (
          <WorkflowStatusFormModal
            triggerId={triggerId}
            entityCuid={entityCuid}
            fromStatus={fromStatus}
            defaultCustomFieldValues={defaultCustomFieldValues}
            action={selectedAction}
            onCancel={onCancel}
            onSave={onSaved}
          />
        )}
      </>
    );
  }

  return null;
}
