import { useContext, useEffect, useState } from 'react';
import { defaultOperators, Field, RuleGroupType } from 'react-querybuilder';
import { JSONSchema7 } from 'json-schema';
import { UsersContext } from '../../../../../contexts';
import { useOrgRoles } from '../../../../../hooks/useOrgRoles';

export default function useQueryBuilder() {
  const { currentOrganization } = useContext(UsersContext);
  const [fields, setFields] = useState<Field[]>([]);
  const { orgRoles, isLoading: isRolesLoading } = useOrgRoles();
  const [query, setQuery] = useState<RuleGroupType>({
    combinator: 'and',
    rules: [],
  });
  const defaultQuery = {
    combinator: 'and',
    rules: [],
  };

  useEffect(() => {
    let _fields: Field[] = [
      ...fields,
      {
        name: 'user.roles',
        label: 'User Roles',
        operators: [
          { name: 'contains', value: 'contains', label: 'contains' } as const,
          {
            name: 'doesNotContain',
            value: 'doesNotContain',
            label: 'does not contain',
          } as const,
        ],
        valueEditorType: 'select',
        values: orgRoles.map(role => ({
          name: role.cuid,
          label: role.name,
        })),
      },
      {
        name: 'user.stakeholders',
        label: 'Model Stakeholder Roles',
        operators: [
          { name: 'contains', value: 'contains', label: 'contains' } as const,
          {
            name: 'doesNotContain',
            value: 'doesNotContain',
            label: 'does not contain',
          } as const,
        ],
        valueEditorType: 'select',
        values: [
          { name: 'Developers', label: 'Developers' },
          { name: 'Validators', label: 'Validators' },
          { name: 'Owners', label: 'Owners' },
        ],
      },
    ];

    function getItemProps(field: JSONSchema7) {
      if (field.type === 'boolean') {
        return {
          valueEditorType: 'checkbox',
          operators: defaultOperators.filter(op => op.name === '='),
        };
      }
      if (field.enum) {
        return {
          valueEditorType: 'select',
          values: field.enum.map(value => ({
            name: value,
            label: value,
          })),
        };
      }
      return {
        valueEditorType: 'text',
      };
    }

    Object.entries(
      currentOrganization?.inventory_model_schema.custom.schema.properties ||
        {},
    ).forEach(f => {
      const key = f[0];
      const field = f[1] as JSONSchema7;
      const item = {
        name: `custom_fields.${key}`,
        label: `Field: ${field.title}`,
        ...getItemProps(field),
      };
      _fields.push(item as Field);
    });

    setFields(_fields);
  }, [currentOrganization, orgRoles, isRolesLoading]);

  return {
    fields,
    query,
    setQuery,
    defaultQuery,
  };
}
