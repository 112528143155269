import { createContext } from 'react';
import { TProject, TemplateSection } from '../models';
import { TemplateSectionTree } from '../models/template';

export interface ProjectTemplatesItem {
  sections: TemplateSection[];
  sectionTree: TemplateSectionTree[];
  lookup: { [key: string]: TemplateSection };
}

export interface CurrentProjectTemplates {
  documentType: string;
  content: ProjectTemplatesItem;
  home?: TemplateSection;
}

export interface ProjectTemplates {
  documentation: ProjectTemplatesItem;
  validation_report: ProjectTemplatesItem;
  monitoring: ProjectTemplatesItem;
  readonly current: CurrentProjectTemplates;
  readonly isReady: boolean;
  readonly isOfflineDocumentation: boolean;
  readonly isOfflineValidationReport: boolean;
}

const initialTemplateItem = {
  sections: [],
  sectionTree: [],
  lookup: {},
};

const initialTemplates: ProjectTemplates = {
  documentation: { ...initialTemplateItem },
  validation_report: { ...initialTemplateItem },
  monitoring: { ...initialTemplateItem },
  get current() {
    return {
      documentType: 'documentation',
      content: { ...initialTemplateItem },
      home: undefined,
    };
  },
  get isReady() {
    return false;
  },
  get isOfflineDocumentation() {
    return false;
  },
  get isOfflineValidationReport() {
    return false;
  },
};

interface IProjectContext {
  project?: TProject;
  setProject?: (project: TProject) => void;
  templates: ProjectTemplates;
  setTemplates: (templates: ProjectTemplates) => void;
}

const ProjectContext = createContext<IProjectContext>({
  setTemplates: () => {},
  templates: initialTemplates,
});

export default ProjectContext;
