import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import API, { TMetricResult } from '../../api/API';
import { ModelDocumentTypeEnum } from '../../models/model_document';

export default function useMetric(
  metricName: string,
  documentType?: ModelDocumentTypeEnum,
  enabled: boolean = true,
) {
  // Always scope the metric to a project identifier
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(
    ['metrics', metricName, id],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetMetricForProject(
        id!,
        accessToken,
        metricName,
        documentType,
      );
    },
    {
      enabled,
      retry: false,
    },
  );

  return { metric: queryResponse.data as TMetricResult, ...queryResponse };
}
