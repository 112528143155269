import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import API, { TFigure } from '../../api/API';
import { keyable } from '../../models/utils';

function useFigures(
  metadata?: keyable,
  run_cuid?: string,
  enabled: boolean = true,
) {
  // Always scope the Figure to a project identifier
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [figures, setFigure] = useState([] as TFigure[]);

  const queryResponse = useQuery(
    ['project', id, 'figures', metadata],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetProjectFigures(id!, accessToken, metadata, run_cuid);
    },
    {
      onSuccess: (figures: TFigure[]) => {
        setFigure(figures);
      },
      enabled,
      retry: false,
    },
  );

  return { figures, ...queryResponse };
}

// TBD: useFigure for single figure
export { useFigures };
