import React from 'react';
import { TInventoryModel } from '../../models/inventory_model';
import { Link as RouterLink } from 'react-router-dom';
import {
  Flex,
  HStack,
  Icon,
  Code,
  LinkBox,
  Square,
  Tag,
  TagLabel,
  Text,
  VStack,
  useColorModeValue,
  Wrap,
  Stack,
} from '@chakra-ui/react';
import { Label } from '../Layout';
import InventoryModelTierLevelBadge from '../InventoryModelTierLevelBadge';
import { CubeTransparentIcon } from '@heroicons/react/24/solid';
import { CubeIcon } from '@heroicons/react/24/outline';
import StatusBadge from '../StatusBadge';
import AvatarProxy from '../AvatarProxy';

interface ModelInventoryItemProps {
  inventoryModel?: TInventoryModel;
  tooltip?: React.ReactNode;
  layout?: 'column' | 'row';
}
export default function ModelInventoryItem({
  inventoryModel,
  tooltip,
  layout = 'column',
}: ModelInventoryItemProps) {
  const owner = inventoryModel?.users.filter(u => u.name === 'Model Owner')[0];
  return (
    <LinkBox
      key={inventoryModel?.cuid}
      as={RouterLink}
      to={`/model-inventory/${inventoryModel?.cuid}/overview`}
      data-testid="model-inventory-grid-item"
    >
      <Flex
        pl={6}
        pr={8}
        py={6}
        gap={8}
        role="group"
        border={'1px solid'}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        align={'stretch'}
        _hover={{
          bg: useColorModeValue('neutral.100', 'neutral.850'),
          color: useColorModeValue('inherit', 'neutral.200'),
        }}
        transition={'all .2s ease-in-out'}
        borderRadius={'md'}
        color="inherit"
        h={'full'}
      >
        <HStack w={'full'} height={'full'} alignItems={'flex-start'} gap={4}>
          <Square>
            <Icon
              boxSize={10}
              color={useColorModeValue('neutral.400', 'neutral.700')}
              _groupHover={{ color: 'neutral.400', display: 'none' }}
              as={CubeTransparentIcon}
            />
            <Icon
              boxSize={10}
              display={'none'}
              color={'neutral.300'}
              _groupHover={{
                color: 'neutral.400',
                display: 'block',
              }}
              as={CubeIcon}
            />
            {!!tooltip && tooltip}
          </Square>
          <Stack
            direction={layout}
            height={'full'}
            width={'full'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            ml={'0 !important'}
          >
            <VStack alignItems={'flex-start'} gap={0} w={'full'}>
              <Text
                fontSize="sm"
                color={useColorModeValue('neutral.600', 'neutral.400')}
              >
                {inventoryModel?.business_unit?.name}
              </Text>
              <HStack alignItems={'baseline'}>
                <Text
                  fontSize={'lg'}
                  fontWeight={'semibold'}
                  color={useColorModeValue('neutral.600', 'neutral.300')}
                  _groupHover={{
                    color: useColorModeValue('neutral.800', 'neutral.200'),
                  }}
                >
                  {inventoryModel?.name}
                </Text>
                <Text
                  fontSize="sm"
                  color={useColorModeValue('neutral.600', 'neutral.400')}
                  whiteSpace={'nowrap'}
                >
                  ({inventoryModel?.model_id})
                </Text>
                {inventoryModel?.version && (
                  <Text
                    fontSize="sm"
                    color={useColorModeValue('neutral.600', 'neutral.400')}
                  >
                    <Code>{inventoryModel?.version}</Code>{' '}
                  </Text>
                )}
              </HStack>

              <Text fontSize={'md'} noOfLines={2}>
                {inventoryModel?.purpose}
              </Text>
            </VStack>
            <Wrap w={'full'} mt={2} spacing={8}>
              <VStack alignItems={'flex-start'}>
                <Label>Tier</Label>
                <InventoryModelTierLevelBadge level={inventoryModel?.tiering} />
              </VStack>
              <VStack alignItems={'flex-start'}>
                <Label>MODEL OWNER</Label>
                <Tag key={owner?.user.cuid} size={'md'} borderRadius="full">
                  <AvatarProxy
                    src={owner?.user.picture}
                    size="xs"
                    name={owner?.user.name}
                    ml={-2}
                    mr={2}
                  />
                  <TagLabel>{owner?.user.name}</TagLabel>
                </Tag>
              </VStack>

              <VStack alignItems={'flex-start'}>
                <Label>Model status</Label>

                <StatusBadge
                  color={
                    inventoryModel?.status
                      ? inventoryModel?.status.colors.tertiary
                      : 'default'
                  }
                  bg={
                    inventoryModel?.status
                      ? inventoryModel?.status.colors.primary
                      : 'default'
                  }
                >
                  {inventoryModel?.status
                    ? inventoryModel?.status.name
                    : 'No status'}
                </StatusBadge>
              </VStack>
            </Wrap>
          </Stack>
        </HStack>
      </Flex>
    </LinkBox>
  );
}
