import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../../api/API';

import { useState } from 'react';
import { Select, useColorModeValue } from '@chakra-ui/react';
import { TBusinessUnit } from '../../../models/business_unit';

// make interface for BusinessUnitsSelect:
export interface BusinessUnitsSelectProps {
  businessUnit: TBusinessUnit | undefined;
  setBusinessUnit: (businessUnit: TBusinessUnit | undefined) => void;
}

export default function BusinessUnitsSelect({
  businessUnit,
  setBusinessUnit,
}: BusinessUnitsSelectProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [businessUnits, setBusinessUnits] = useState<TBusinessUnit[]>([]);

  useQuery(
    ['business-units'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetOrganizationBusinessUnits(accessToken);
    },
    {
      onSuccess: data => {
        setBusinessUnits(data);
      },
    },
  );

  return (
    <Select
      onChange={e =>
        setBusinessUnit(businessUnits.find(bu => bu.cuid === e.target.value))
      }
      placeholder="Select business unit"
      value={businessUnit?.cuid}
      focusBorderColor="brand.base"
      bg={useColorModeValue('white', 'neutral.850')}
    >
      {businessUnits.map(bu => (
        <option key={bu.cuid} value={bu.cuid}>
          {bu.name}
        </option>
      ))}
    </Select>
  );
}
