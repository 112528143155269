import {
  Button,
  CloseButton,
  FormControl,
  HStack,
  Heading,
  Select,
  Spacer,
  Stack,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { Node, useStoreApi } from 'reactflow';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { QueryBuilder, RuleGroupType } from 'react-querybuilder';
import { formatQuery } from 'react-querybuilder/formatQuery';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';
import useQueryBuilder from '../../../hooks/useQueryBuilder';
import { useStatusesWorkflows } from '../../../hooks/useStatuses';
import { WorkflowCategoryNames } from '../../../../../../models/statuses_workflow';
import ConfirmationAlert from '../../../../../../components/ConfirmationAlert';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { StatusSetNodeType } from '../../types';
import { Label } from '../../../../../../components/Layout';

interface AddStatusSetPanelProps {
  node: StatusSetNodeType;
  onAddNode: (node: StatusSetNodeType) => void;
  onDeleteNode: (node: StatusSetNodeType) => void;
}

const AddStatusSetNodePanel = ({
  onAddNode,
  onDeleteNode,
  node,
}: AddStatusSetPanelProps) => {
  const { workflow, setSelectedNodeId } = useWorkflow();
  const storeApi = useStoreApi();
  const [tempNode, setTempNode] = useState<Node>();
  const { query, setQuery, fields, defaultQuery } = useQueryBuilder();
  const { data: statusesWorkflows } = useStatusesWorkflows();
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    setTempNode(node);

    if (node && node.data.transition_callbacks.conditions.length > 0) {
      setQuery(
        parseJsonLogic(
          JSON.parse(node.data.transition_callbacks.conditions[0].args.query),
        ) as RuleGroupType,
      );
    } else {
      setQuery(defaultQuery);
    }
  }, [node]);

  const onSave = () => {
    if (tempNode) {
      const clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.transition_callbacks.conditions = [
        {
          func: 'jsonlogic',
          args: {
            query: JSON.stringify(formatQuery(query, 'jsonlogic')),
          },
        },
      ];
      onAddNode(clonedNode);
    }
  };

  const statusByCategoryInWorkflow = useMemo(() => {
    return statusesWorkflows?.filter(category =>
      category.categories[0].startsWith(
        workflow?.trigger_id.startsWith('Project') ? 'projects' : 'models',
      ),
    );
  }, [statusesWorkflows]);

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // const selected = statuses?.statuses.find(s => s.cuid === e.target.value);
    const flattened = statusesWorkflows?.flatMap(item =>
      item.statuses.map(subItem => ({
        ...subItem,
        entity: item.categories[0].startsWith('projects')
          ? 'Project'
          : 'InventoryModel',
      })),
    );
    const selected = flattened?.find(s => s.cuid === e.target.value);
    if (tempNode && selected) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.entity = selected.entity;
      clonedNode.data.state_callbacks.on_enter[0].args.cuid = selected.cuid;
      setTempNode(clonedNode);
    }
  };
  const onConfirmDeleteStep = (confirmed: boolean) => {
    if (tempNode && confirmed) {
      onDeleteNode(tempNode);
    }
    setConfirmDelete(false);
  };

  const onClose = () => {
    setSelectedNodeId!();
  };

  return (
    <>
      <Stack
        bg={useColorModeValue('neutral.50', 'neutral.900')}
        border={'1px solid'}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        p={4}
        rounded={4}
        shadow={'lg'}
      >
        <VStack gap={4} alignItems={'flex-start'}>
          <HStack w={'full'}>
            <Heading size={'md'}>Configure Status Change </Heading>
            <Spacer />
            <CloseButton onClick={onClose} />
          </HStack>

          <FormControl>
            <Label>When these conditions are met</Label>

            <Stack>
              <QueryBuilder
                fields={fields}
                query={query}
                onQueryChange={setQuery}
              />
            </Stack>
          </FormControl>
          <FormControl>
            <Label>Set status to</Label>
            <Select
              value={tempNode?.data.state_callbacks.on_enter[0].args.cuid || ''}
              onChange={onStatusChange}
              size="sm"
              rounded={'md'}
              focusBorderColor="brand.base"
            >
              <option value="">Select</option>

              {statusByCategoryInWorkflow?.map(category => (
                <optgroup
                  label={
                    WorkflowCategoryNames[
                      category.categories[0] as keyof object
                    ]
                  }
                >
                  {category.statuses.map(status => (
                    <option value={status.cuid}>{status.name}</option>
                  ))}
                </optgroup>
              ))}
            </Select>
          </FormControl>

          <HStack justifyContent={'space-between'} w={'full'}>
            <Button
              onClick={() => setConfirmDelete(true)}
              _hover={{
                bg: 'red.100',
                color: 'red.700',
              }}
            >
              Delete
            </Button>
            <Button onClick={onSave}>Save</Button>
          </HStack>
        </VStack>
      </Stack>
      <ConfirmationAlert
        title={`Deleting step`}
        dialogBody={'Are you sure?'}
        open={confirmDelete}
        onConfirm={onConfirmDeleteStep}
      />
    </>
  );
};

export default AddStatusSetNodePanel;
