import { Box, Heading, Text } from '@chakra-ui/react';

const NotFound = () => {
  return (
    <Box
      mt={8}
      p={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      height="100vh"
    >
      {/* TODO: investigate why 404 is being triggered for normal routes */}
      {/* <Heading as="h1" size="xl">
        404 Not Found
      </Heading>
      <Text fontSize="lg" mt={4}>
        Sorry, the page you are looking for could not be found.
      </Text> */}
    </Box>
  );
};

export default NotFound;
