import { Select, useColorModeValue } from '@chakra-ui/react';
import { TGroup } from '../../models/group';
import { useGroups } from '../../hooks/useGroups';

interface GroupSelectProps {
  group?: TGroup;
  onSelect: (group: TGroup | undefined) => void;
}

export default function GroupSelect({ group, onSelect }: GroupSelectProps) {
  const { groups, isLoading } = useGroups();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const cuid = event.target.value;
    onSelect(groups?.find(group => group.cuid === cuid));
  };

  return (
    <Select
      placeholder="Select group"
      disabled={isLoading}
      onChange={handleChange}
      value={group?.cuid ?? ''}
      focusBorderColor="brand.base"
      bg={useColorModeValue('white', 'neutral.850')}
      data-testid="group-select"
    >
      {groups?.map(group => (
        <option key={group.cuid} value={group.cuid}>
          {group.name}
        </option>
      ))}
    </Select>
  );
}
