import { useState } from 'react';

import { Text, Link } from '@chakra-ui/react';

interface ExpandableTextDisplayProps {
  value: string;
  maxLines: number;
  fontSize: string;
}
export default function ExpandableTextDisplay({
  value,
  maxLines,
  fontSize,
}: ExpandableTextDisplayProps) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      {(value || '').length > 0 && (
        <>
          <Text
            noOfLines={expanded ? 1000 : maxLines}
            fontSize={fontSize}
            whiteSpace={'normal'}
          >
            {value}
          </Text>
          <Link onClick={() => setExpanded(!expanded)} fontSize={fontSize}>
            {expanded ? 'See Less' : 'See More'}
          </Link>
        </>
      )}
    </>
  );
}
