import { useCallback } from 'react';
import {
  useStore,
  getBezierPath,
  BaseEdge,
  EdgeLabelRenderer,
  useReactFlow,
} from 'reactflow';
import { getEdgeParams } from '../../utils';
import { EdgeProps } from '@reactflow/core/dist/esm/types/edges';
import { Box, Icon, IconButton } from '@chakra-ui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

function FloatingEdge({
  id,
  source,
  target,
  markerStart,
  markerEnd,
  style,
  selected,
}: EdgeProps) {
  const { deleteElements } = useReactFlow();

  const sourceNode = useStore(
    useCallback(store => store.nodeInternals.get(source), [source]),
  );
  const targetNode = useStore(
    useCallback(store => store.nodeInternals.get(target), [target]),
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
  );

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const onRemoveEdgeClick = () => {
    if (confirm('Are you sure you want to delete this connection?')) {
      deleteElements({ edges: [{ id }] });
    }
  };

  const calculateEdgeBoundingBox = () => {
    return {
      w: Math.max(Math.abs(sx - tx), 30),
      h: Math.max(Math.abs(sy - ty), 30),
    };
  };

  let _style = { ...style };
  if (selected) {
    _style = {
      ...style,
      strokeWidth: 3,
    };
  }

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerStart={'circle'} // does not work!!!!!
        markerEnd={markerEnd}
        id={id}
        style={_style}
      />
      <EdgeLabelRenderer>
        <Box
          bg={'neutral.400'}
          w={2}
          h={2}
          transform={'auto'}
          translateX={sx - 4}
          translateY={sy - 4}
          rounded={'full'}
          position={'absolute'}
        ></Box>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
            paddingLeft: calculateEdgeBoundingBox().w / 3 + 'px',
            paddingRight: calculateEdgeBoundingBox().w / 3 + 'px',
            paddingTop: calculateEdgeBoundingBox().h / 3 + 'px',
            paddingBottom: calculateEdgeBoundingBox().h / 3 + 'px',
            borderRadius: '100%',
          }}
          className="nodrag nopan hover-reveal"
        >
          <IconButton
            size={'xs'}
            aria-label="Delete"
            rounded={'full'}
            icon={<Icon as={XMarkIcon} boxSize={3} />}
            onClick={onRemoveEdgeClick}
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default FloatingEdge;
