import { FieldDisplayProps } from '../../index';
import { Text } from '@chakra-ui/react';
import NullFieldDisplay from '../NullFieldDisplay';

const StringFieldDisplay = ({ field }: FieldDisplayProps) => {
  const fieldHasValue = !!field.value;
  if (fieldHasValue) {
    return <Text>{field.value}</Text>;
  }
  return <NullFieldDisplay />;
};

export default StringFieldDisplay;
