import React from 'react';
import {
  Center,
  GridItem,
  Grid,
  Image,
  VStack,
  Button,
  Text,
  SlideFade,
  Box,
  Stack,
} from '@chakra-ui/react';

import vmProdShot01 from '../../images/homepage/product-shot-01.png';
import vmProdShot02 from '../../images/homepage/product-shot-02.png';
import vmProdShot03 from '../../images/homepage/product-shot-03.png';
import vmProdShot04 from '../../images/homepage/product-shot-04.png';
import vmProdShot05 from '../../images/homepage/product-shot-05.png';
import vmProdShot06 from '../../images/homepage/product-shot-06.png';
import triangle01 from '../../images/homepage/bg-triangle-01.png';
import triangle02 from '../../images/homepage/bg-triangle-02.png';

type ProductShotProps = {
  headline: string;
  img: string;
  delay: number;
  isInverse?: boolean;
  zIndex?: number;
};

const ProductShot: React.FC<ProductShotProps> = ({
  headline,
  img,
  delay,
  isInverse,
  zIndex,
}) => {
  return (
    <Box zIndex={zIndex} w={'full'} color={'neutral.50'}>
      <SlideFade in offsetY="100px" delay={delay * 0.1}>
        <Stack
          alignItems={'center'}
          direction={{
            base: 'column',
            md: isInverse ? 'row-reverse' : 'row',
          }}
          spacing={'20px'}
        >
          <Box
            width={{ md: '25%' }}
            mt={{ base: '0px', md: isInverse ? '100px' : '-50px' }}
          >
            <Text
              w="100%"
              fontSize={{ base: 'md', '2xl': 'lg' }}
              textAlign={{ base: 'center', md: isInverse ? 'start' : 'end' }}
              fontWeight="semibold"
            >
              {headline}
            </Text>
          </Box>
          <Box pt={{ md: 8 }} width={{ md: '80%' }}>
            <Image boxSize="100%" objectFit="contain" src={img} />
          </Box>
        </Stack>
      </SlideFade>
    </Box>
  );
};

const ProductShots: React.FC = () => {
  return (
    <>
      <Center height={'full'}>
        <Box w={'full'} overflowY={{ md: 'scroll' }} height={{ xl: '100vh' }}>
          <Center>
            <VStack
              spacing={{ base: '50px', md: '-50px', '2xl': '-200px' }}
              px={{ base: 2, md: 20 }}
              py={{ base: 10, md: 5 }}
              maxWidth={'1500px'}
              w={'full'}
            >
              <ProductShot
                headline={'Manage your model inventory'}
                img={vmProdShot01}
                delay={0.5}
                zIndex={6}
              />
              <ProductShot
                headline={'Test and validate your models'}
                img={vmProdShot02}
                delay={1}
                isInverse
                zIndex={5}
              />
              <ProductShot
                headline={'Collaborate on model documentation'}
                img={vmProdShot03}
                delay={1.5}
                zIndex={4}
              />
              <ProductShot
                headline={'Create robust model validation reports'}
                img={vmProdShot04}
                delay={2}
                isInverse
                zIndex={3}
              />
              <ProductShot
                headline={'Track model risks and findings'}
                img={vmProdShot05}
                delay={1.5}
                zIndex={2}
              />
              <ProductShot
                headline={
                  'Manage  model governance workflows for your organization'
                }
                img={vmProdShot06}
                delay={2}
                isInverse
                zIndex={1}
              />
            </VStack>
          </Center>
        </Box>
      </Center>
      <Image
        src={triangle01}
        position={'absolute'}
        top={0}
        left={0}
        pointerEvents={'none'}
      />
      <Image
        src={triangle02}
        position={'absolute'}
        bottom={0}
        right={0}
        pointerEvents={'none'}
      />
    </>
  );
};

export default ProductShots;
