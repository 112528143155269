import {
  Box,
  Center,
  Fade,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
} from '@chakra-ui/react';
import { PrimaryButton, TertiaryButton } from '../../components/Layout';
import Bg1 from '../../images/error_bg1.svg';
import Bg2 from '../../images/error_bg2.svg';
import { useNavigate } from 'react-router-dom';
import { Copyright } from '../../components/Copyright';

interface Props {
  error: any;
  resetErrorBoundary: Function;
}

export default function GenericError({ error, resetErrorBoundary }: Props) {
  const navigate = useNavigate();
  return (
    <Flex direction={'column'} h={'100vh'}>
      <Fade in={true} delay={1.5} transition={{ enter: { duration: 2 } }}>
        <Flex
          h={'50vh'}
          bgImage={Bg1}
          bgRepeat={'repeat'}
          bgPosition={'center bottom'}
          bgBlendMode={'overlay'}
          alignItems={'flex-end'}
          justifyContent={'center'}
        >
          <Box
            rounded={'lg'}
            overflow={'hidden'}
            outline={'10px solid'}
            outlineColor={useColorModeValue('white', 'neutral.950')}
          >
            <Image
              src={Bg2}
              w={'385px'}
              h={'260px'}
              bg={useColorModeValue('white', 'neutral.950')}
            />
          </Box>
        </Flex>
      </Fade>
      <Fade in={true} delay={0.5} transition={{ enter: { duration: 1 } }}>
        <Box h={'50vh'}>
          <Center>
            <VStack
              w={'40vw'}
              minW={'md'}
              maxW={'prose'}
              textAlign={'center'}
              p={12}
              spacing={4}
            >
              <Heading fontSize={'2xl'}>Something went wrong</Heading>
              <Text fontSize={'md'}>
                Our tech heroes had a little stumble, but they're already back
                on their feet, fixing the glitch. Grab a quick breather, and
                we'll be your flawless sidekicks again soon!
              </Text>
              <PrimaryButton
                onClick={() => {
                  window.location.href = '/dashboard';
                }}
              >
                Back to Homepage
              </PrimaryButton>
              <TertiaryButton
                variant={'ghost'}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Try again
              </TertiaryButton>

              <Accordion color={'neutral.500'}>
                <AccordionItem border={'none'}>
                  <AccordionButton>
                    <Box as="span" flex="1" fontSize={'xs'}>
                      {error?.message}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    pb={4}
                    fontFamily={'mono'}
                    fontSize={'xs'}
                    textAlign="left"
                  >
                    {error?.stack}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Box>
                <Copyright mt={20} />
              </Box>
            </VStack>
          </Center>
        </Box>
      </Fade>
    </Flex>
  );
}
