import { Box, Flex, Grid, StackDivider, VStack } from '@chakra-ui/react';
import React from 'react';

interface IRecordDetailsPageOpts {
  right?: React.ReactNode;
  children?: React.ReactNode;
}

export default function RecordDetailsPage({
  right,
  children,
}: IRecordDetailsPageOpts) {
  const mainDetailsWidth = right ? '75%' : '100%';

  return (
    <Grid gap={16} w={'full'} maxWidth={'1220px'} templateColumns={'1fr 212px'}>
      <VStack align="left" w={'100%'} gap={8}>
        {children}
      </VStack>
      {/* Record attributes rail */}
      {right && <Box>{right}</Box>}
    </Grid>
  );
}
