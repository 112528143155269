import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  As,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Tag,
  TagLabel,
  useColorModeValue,
} from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ReactNode, useState } from 'react';
import ConfirmationAlert from '../../../ConfirmationAlert';
import _ from 'lodash';

type DeleteProps = {
  label: string;
  onConfirm: () => void;
};

export type ContentAccordionProps = {
  id: string;
  icon: As;
  title: string;
  tagLabel?: string;
  deleteProps?: DeleteProps;
  children?: ReactNode;
};

export default function ContentAccordion({
  id,
  icon,
  title,
  tagLabel,
  children,
  deleteProps,
}: ContentAccordionProps) {
  const [showDeletionConfirmation, setShowDeletionConfirmation] =
    useState(false);
  return (
    <>
      {!!deleteProps && (
        <ConfirmationAlert
          title={`Remove ${_.startCase(deleteProps.label)}`}
          dialogBody={`Are you sure you want to remove this ${_.lowerCase(
            deleteProps.label,
          )} from the section?`}
          open={showDeletionConfirmation}
          size="3xl"
          cancelButton={<Button variant="ghost">Cancel</Button>}
          confirmButton={
            <Button leftIcon={<Icon as={TrashIcon} strokeWidth={2.5} />}>
              Remove {_.startCase(deleteProps.label)}
            </Button>
          }
          onConfirm={() => {
            deleteProps.onConfirm();
            setShowDeletionConfirmation(false);
          }}
        />
      )}
      <AccordionItem key={`${id}_content.content_id`} role="group">
        <AccordionButton h="48px">
          <HStack flex="1" textAlign="left" fontSize="sm">
            <Icon as={icon} boxSize={4} />
            <Box>{title}</Box>

            <Spacer />
            {tagLabel && (
              <Tag
                rounded={'full'}
                bg={useColorModeValue('neutral.50', 'neutral.850')}
                color={useColorModeValue('neutral.500', 'neutral.500')}
              >
                <TagLabel fontSize={'xs'} fontWeight={'bold'}>
                  {tagLabel}
                </TagLabel>
              </Tag>
            )}
            {!!deleteProps && (
              <IconButton
                variant="ghost"
                size={'sm'}
                visibility={'hidden'}
                _groupHover={{ visibility: 'visible' }}
                _hover={{
                  bg: useColorModeValue('red.100', 'red.700'),
                  color: useColorModeValue('red.600', 'red.200'),
                }}
                icon={<Icon as={TrashIcon} boxSize={4} />}
                aria-label="remove"
                onClick={e => {
                  setShowDeletionConfirmation(true);
                  e.stopPropagation();
                }}
              />
            )}
          </HStack>
          {!!children && <AccordionIcon mr={-1} />}
        </AccordionButton>
        {!!children && (
          <AccordionPanel pb={4} fontSize="sm" pt={0} pl={10}>
            {children}
          </AccordionPanel>
        )}
      </AccordionItem>
    </>
  );
}
