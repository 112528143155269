import { useContext, useState } from 'react';
import { Button, HStack, Icon, Select } from '@chakra-ui/react';
import { UsersContext } from '../../../../../contexts';
import { PlusIcon } from '@heroicons/react/24/outline';
import { getSchemaPropertyByKey } from '../../../../../utils';

interface FieldFormProps {
  onAddField: (field: string) => void;
  addedFields?: string[];
}

const FieldForm = ({ onAddField, addedFields = [] }: FieldFormProps) => {
  const { currentOrganization } = useContext(UsersContext);
  const [field, setField] = useState('');

  const onAddConditionClick = () => {
    onAddField(field);
    setField('');
  };

  const availableFields = Object.keys(
    currentOrganization?.inventory_model_schema.custom.schema.properties || {},
  ).filter(k => !addedFields.includes(k));

  return (
    <HStack>
      <Select
        value={field}
        onChange={e => setField(e.target.value)}
        size="sm"
        rounded={'md'}
        focusBorderColor="brand.base"
      >
        <option value="">Select field</option>
        {availableFields.map(k => {
          const [schema, setting] = getSchemaPropertyByKey(
            currentOrganization!.inventory_model_schema.custom,
            k,
          );
          return <option value={k}>{schema.title}</option>;
        })}
      </Select>

      <Button
        isDisabled={!field}
        onClick={onAddConditionClick}
        size="sm"
        colorScheme="brand"
        leftIcon={<Icon as={PlusIcon} boxSize={4} />}
      >
        Add Field
      </Button>
    </HStack>
  );
};

export default FieldForm;
