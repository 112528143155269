/**
 * TODO: refactor React Router routes so we can define a header layout
 * that fits any given route
 */
import { Box, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { defaultContentWidths } from '..';

export default function MasterHeader({ children }: { children: any }) {
  const currentPath = useLocation();
  // Check if path matches a finding page path to change the header layout
  const isFindingPath = /\/projects\/\w+\/findings\/\w+/.test(
    currentPath.pathname,
  );

  // Temporary layout hack for finding details paths until we refactor
  // all routes to have their own unique layouts
  if (isFindingPath) {
    return (
      <Flex
        id="project-header"
        direction="row"
        justify="center"
        w={'full'}
        maxWidth={'1220px'}
        gap={16}
        color="neutral.800"
        mx={8}
        mt={6}
        mb={4}
        border={'1px solid purple'}
      >
        <Flex direction="column" align="left" w="75%" gap={4}>
          {children}
        </Flex>
        {/* Record attributes rail */}
        <Box w="25%"></Box>
      </Flex>
    );
  }
  // Temporary hack to expand the width of the container whenever
  // we are in the project-overview route
  const isProjectOverview = currentPath.pathname.includes('project-overview');
  const contentWidth = isProjectOverview ? '100%' : defaultContentWidths;

  return (
    <Flex
      id="project-header"
      direction="column"
      w={contentWidth}
      pt={6}
      mt={'0 !important'}
      mb={4}
    >
      {children}
    </Flex>
  );
}
