import { Tag } from '@chakra-ui/react';
import useApprovals from '../../hooks/useApprovals';

export default function ApprovalBadge() {
  const { data } = useApprovals('pending');
  if (data && data.length > 0) {
    return (
      <Tag colorScheme="brand" rounded={'full'} size={'sm'} color={'brand.700'}>
        {data.length}
      </Tag>
    );
  }
  return null;
}
