import { Button, FormControl, HStack, Stack, VStack } from '@chakra-ui/react';
import AddFindingModal from '../AddFindingModal';
import DownloadModal from '../DownloadModal';
import AttributesRail from '../Layout/AttributesRail';
import { TProject } from '../../models';
import { useQueryClient } from 'react-query';
import { Label } from '../Layout';
import DownloadOfflineDocument from '../DownloadOfflineDocument';
import MoreInfoPopOver from '../MoreInfoPopOver';
import DocumentChecker from '../DocumentChecker';

interface Props {
  project: TProject;
  onOpenTemplateModal?: () => void;
  isDocumentComplete?: boolean;
}

export default function AttributesRailDocumentOverview({
  project,
  onOpenTemplateModal,
  isDocumentComplete,
}: Props) {
  const queryClient = useQueryClient();
  return (
    <AttributesRail>
      <VStack align="start">
        <AddFindingModal
          triggerLabel="Add Finding"
          onFindingAdded={() => {
            queryClient.invalidateQueries(['project', project?.cuid]);
            queryClient.invalidateQueries(['documentation-overview']);
          }}
        />

        {!project.template.is_offline ? (
          <DownloadModal
            project={project}
            currentDocumentType={'documentation'}
          />
        ) : (
          <Stack>
            <DownloadOfflineDocument files={project.template.offline_files} />
          </Stack>
        )}
      </VStack>

      {onOpenTemplateModal && (
        <Stack>
          <FormControl role="group">
            <HStack gap={0}>
              <Label>DOCUMENT TEMPLATE</Label>
              <MoreInfoPopOver
                title="Documentation Templates"
                description="Customizable documentation templates offer a standardized approach to creating consistent and comprehensive model documentation."
                link="https://docs.validmind.ai/guide/model-documentation/working-with-documentation-templates.html"
                placement="right-end"
                iconProps={{
                  ml: 2,
                  fill: 'brand.base',
                  opacity: 0,
                  _groupHover: { opacity: 1 },
                }}
              />
            </HStack>
            <Button
              variant={'link'}
              onClick={onOpenTemplateModal}
              whiteSpace={'normal'}
              textAlign={'left'}
              data-testid="view-document-template-btn"
            >{`${project?.template.name} v${project?.template.version_number}`}</Button>
          </FormControl>
        </Stack>
      )}
      <DocumentChecker
        project={project}
        isDocumentComplete={!!isDocumentComplete}
      />
    </AttributesRail>
  );
}
