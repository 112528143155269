import {
  Button,
  ButtonProps,
  Divider,
  Flex,
  Heading,
  HeadingProps,
  HStack,
  Input,
  Link as ChakraLink,
  LinkProps,
  Text,
  TextProps,
  Tooltip,
  useColorModeValue,
  Icon,
  Spacer,
} from '@chakra-ui/react';
import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { RefObject, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs';
import MainContentArea from './MainContentArea';
import MainContentFooter from './MainContentFooter';
import SectionContainer from './SectionContainer';
import { QuestionIcon } from '@chakra-ui/icons';

interface IButtonProps extends ButtonProps {
  ref?: RefObject<any>;
}
interface IHeadingProps extends HeadingProps {}
interface IEditableHeadingProps extends IHeadingProps {
  onCancel?: Function;
  onSave?: Function;
  allowEdit?: boolean;
}
interface ILinkProps extends LinkProps {
  to: string;
}

export const defaultContentWidths = {
  base: '100%',
  // lg: '83%',
  // '2xl': '66%',
};

// A default container size defaults to the responsive values defined above
export const containerSizes = {
  base: defaultContentWidths,
  full: '95%',
  lg: '83%',
  '2xl': '66%',
  '100%': '100%',
};

export const ContentPageTitle = ({ children, ...props }: IHeadingProps) => (
  <Text
    id="content-page-title"
    as="h1"
    mt="0 !important"
    color={'inherit'}
    {...props}
  >
    {children}
  </Text>
);

export const EditableContentPageTitle = ({
  children,
  onCancel,
  onSave,
  allowEdit = true,
  ...props
}: IEditableHeadingProps) => {
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(children);
  const [showEditButton, setShowEditButton] = useState(false);
  const [showTimeout, setShowTimeout] = useState<any>(null);

  useEffect(() => {
    setTitle(children);
  }, [children]);

  const onEditing = () => {
    if (!allowEdit) return;
    setEditing(true);
  };

  const onSaving = () => {
    handleMouseLeave();
    setEditing(false);
    if (onSave) {
      onSave(title);
    }
  };

  const onCanceling = () => {
    handleMouseLeave();
    setEditing(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleMouseEnter = () => {
    if (!allowEdit) return;
    clearTimeout(showTimeout);
    setShowTimeout(setTimeout(() => setShowEditButton(true), 200));
  };

  const handleMouseLeave = () => {
    clearTimeout(showTimeout);
    setShowTimeout(setTimeout(() => setShowEditButton(false), 500));
  };

  return (
    <Flex direction="column" gap={editing ? 2 : 0} mt={'0 !important'}>
      <Breadcrumbs />
      <HStack gap={0}>
        {editing ? (
          <Input
            width="90%"
            bg="white"
            value={title as string}
            onChange={e => setTitle(e.target.value)}
            {...props}
          />
        ) : (
          <Text
            width="90%"
            as="h1"
            mt="0 !important"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...props}
            onClick={onEditing}
          >
            {title}
          </Text>
        )}
        <HStack gap={0}>
          {!editing && showEditButton && (
            <PrimaryButton
              size={'sm'}
              aria-label="Edit"
              onClick={onEditing}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              leftIcon={<PencilIcon width={16} height={16} />}
            >
              Edit
            </PrimaryButton>
          )}
          {editing && (
            <HStack>
              <PrimaryButton
                size={'sm'}
                aria-label="Edit"
                onClick={onSaving}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                leftIcon={<CheckIcon width={16} height={16} />}
              >
                Save
              </PrimaryButton>
              <TertiaryButton
                size={'sm'}
                aria-label="Edit"
                onClick={onCanceling}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                _hover={{
                  bg: useColorModeValue('red.100', 'red.900') + '!important',
                }}
                leftIcon={<XMarkIcon width={16} height={16} />}
              >
                Cancel
              </TertiaryButton>
            </HStack>
          )}
        </HStack>
      </HStack>
    </Flex>
  );
};

/**
 * Equivalent to a h1 tag in content areas that are rendered as markdown
 */
export const ContentPageH1 = ({
  size = '2xl !important',
  children,
  ...props
}: IHeadingProps) => (
  <Heading
    as="h2"
    size={size}
    color={useColorModeValue('neutral.700', 'neutral.300')}
    {...props}
  >
    {children}
  </Heading>
);

/**
 * Equivalent to a h2 tag in content areas that are rendered as markdown
 */
export const ContentPageH2 = ({
  size = 'xl !important',
  children,
  ...props
}: IHeadingProps) => (
  <Heading as="h3" size={size} {...props} color={'inherit'}>
    {children}
  </Heading>
);

// This component will replace SubsectionHeader once we remove progress bars and approve/flag actions
export const PageHeader = ({ children }: { children: any }) => (
  <Flex w={defaultContentWidths} my={4}>
    <ContentPageTitle>{children}</ContentPageTitle>
  </Flex>
);

export const ContentDivider = () => (
  <Divider w={defaultContentWidths} mt={8} mb={4} />
);

export const ContentProjectNameHeading = ({
  size = 'xl !important',
  children,
  ...props
}: IHeadingProps) => (
  <Heading as="h1" size={size} color={'inherit'} {...props}>
    {children}
  </Heading>
);

export const PrimaryButton = ({
  children,
  disabled,
  onClick,
  ...props
}: IButtonProps) => (
  <Button
    variant="solid"
    size="md"
    rounded="md"
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    {children}
  </Button>
);

export const SecondaryButton = ({
  children,
  onClick,
  ...props
}: IButtonProps) => (
  <Button
    variant="solid"
    backgroundColor="neutral.100"
    color="black"
    size="md"
    rounded="md"
    _hover={{ bg: 'neutral.200' }}
    onClick={onClick}
    {...props}
  >
    {children}
  </Button>
);

export const TertiaryButton = ({
  children,
  onClick,
  ...props
}: IButtonProps) => (
  <Button
    variant="solid"
    backgroundColor="transparent"
    color={useColorModeValue('neutral.800', 'neutral.400')}
    size="md"
    rounded="md"
    _hover={{ bg: useColorModeValue('neutral.100', 'neutral.850') }}
    onClick={onClick}
    {...props}
  >
    {children}
  </Button>
);

export const Link = ({ to, ...props }: ILinkProps) => (
  <ChakraLink
    color="#1F6771"
    fontWeight="medium"
    as={RouterLink}
    to={to}
    {...props}
  />
);

interface ILabelProps extends TextProps {
  children: string;
  helpText?: string;
}

export const Label = ({ children, helpText, ...rest }: ILabelProps) => {
  return (
    <HStack align={'stretch'} justify={'left'}>
      <Heading as={'h6'} fontSize={12} textTransform={'uppercase'} {...rest}>
        {children}
      </Heading>
      {helpText && (
        <>
          <Spacer />
          <Tooltip hasArrow label={helpText} placement="auto-start">
            <Icon
              as={QuestionIcon}
              color={useColorModeValue('neutral.300', 'neutral.600')}
              boxSize={4}
            />
          </Tooltip>
        </>
      )}
    </HStack>
  );
};

export { MainContentArea, MainContentFooter, SectionContainer };
