import { FieldEditProps } from '../../../CustomField';
import TierLevelBadgePicker from '../../../../ModelInventoryInputs/TierRatingField';
import { useEffect, useState } from 'react';
import CustomFieldEditLayout from '../CustomFieldEditLayout';
import { Box, useColorModeValue } from '@chakra-ui/react';

const TieringFieldEdit = ({
  field,
  onEdit,
  onCancel,
  onChange,
}: FieldEditProps) => {
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    setSelected(field.value);
    onChange?.({ tiering: selected });
  }, [field]);

  const onSave = () => {
    onEdit({ tiering: selected });
  };

  return (
    <CustomFieldEditLayout
      onCancelClick={onCancel}
      onSaveClick={onSave}
      showEditControls={!!onChange}
    >
      <Box bg={useColorModeValue('white', 'neutral.850')}>
        <TierLevelBadgePicker
          value={selected}
          setValue={setSelected}
          size="xs"
        />
      </Box>
    </CustomFieldEditLayout>
  );
};

export default TieringFieldEdit;
