import { Template } from '../../models';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import Editor from '@monaco-editor/react';
import { stringify } from 'yaml';
import { editorOptions } from '../TemplateEditModal';
import { convertToSectionTree } from '../../models/template';
import { useMutation } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../api/API';
import { useNavigate } from 'react-router-dom';
import { Label } from '../Layout';

interface TemplateDuplicateModalProps {
  open: boolean;
  templateType: string;
  baseTemplate: Template;
  onClose: () => void;
}
export default function TemplateDuplicateModal({
  open,
  templateType,
  baseTemplate,
  onClose,
}: TemplateDuplicateModalProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [newTemplateName, setNewTemplateName] = useState(
    `Copy of ${baseTemplate.name}`,
  );
  const [changesDescription, setChangesDescription] = useState('');

  const duplicateTemplate = useMutation(
    ['templates', baseTemplate.cuid, 'duplicate'],
    async ({
      description,
      new_name,
    }: {
      description: string;
      new_name: string;
    }) => {
      const accessToken = await getAccessTokenSilently();
      return API.DuplicateTemplate(
        accessToken,
        baseTemplate,
        baseTemplate.current_version!,
        description,
        new_name,
      );
    },
    {
      onSuccess: duplicatedTemplate => {
        toast({
          description: `Well done, template duplicated successfully!.`,
          status: 'success',
          isClosable: true,
        });
        navigate(
          `/settings/templates/${duplicatedTemplate.cuid}/${templateType}/${duplicatedTemplate.current_version?.cuid}`,
        );
        onClose();
      },
    },
  );

  const onDuplicate = () => {
    duplicateTemplate.mutate({
      description: changesDescription,
      new_name: newTemplateName,
    });
  };

  const code = convertToSectionTree(
    baseTemplate.current_version!.json.sections,
  );

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      size={'full'}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent bg={'neutral.50'} margin={16}>
        <ModalHeader>Duplicate Template</ModalHeader>
        <ModalCloseButton data-testid="close-template-modal-btn" />
        <ModalBody>
          <Box
            height={'calc(90vh - 22rem)'}
            overflow={'hidden'}
            borderRadius={'md'}
            border={'1px solid'}
            borderColor={'neutral.200'}
          >
            <Editor
              defaultLanguage="yaml"
              value={stringify(code || {})}
              options={editorOptions}
              theme="vs-dark"
            />
          </Box>

          {duplicateTemplate.isError && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle fontSize="sm">
                Could not duplicate template:
              </AlertTitle>
              <AlertDescription fontSize="sm">
                {API.getAPIErrorMessage(duplicateTemplate.error)}
              </AlertDescription>
            </Alert>
          )}
          <Spacer h={8} />

          <Alert status="info">
            <HStack align={'flex-start'}>
              <AlertIcon />
              <Stack>
                <AlertTitle>A new template will be created.</AlertTitle>
                <AlertDescription>
                  <Text>
                    New template will be based on{' '}
                    <strong>
                      Version {baseTemplate.current_version?.version_number}{' '}
                    </strong>
                    of <strong>"{baseTemplate.name}"</strong>.
                  </Text>
                  <Spacer h={4} />
                  <Text>
                    To continue please add a description for the new template.
                  </Text>
                </AlertDescription>
              </Stack>
            </HStack>
          </Alert>

          <Spacer h={8} />
          <FormControl>
            <Label>TEMPLATE NAME</Label>
            <Input
              value={newTemplateName}
              onChange={e => setNewTemplateName(e.target.value)}
              placeholder="Provide a name for the new template."
            ></Input>
          </FormControl>
          <Spacer h={8} />
          <FormControl>
            <Label>TEMPLATE DESCRIPTION</Label>
            <Textarea
              value={changesDescription}
              onChange={e => setChangesDescription(e.target.value)}
              placeholder="Describe what this template will be used for."
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onDuplicate}
            disabled={changesDescription.length === 0}
            isLoading={duplicateTemplate.isLoading}
          >
            Duplicate Template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
