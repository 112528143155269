import {
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import RolesPicker from '../../../components/AddUserRolesModal/RolesPicker';
import { TRole } from '../../../models';
import { AddIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import RolePill from '../../../components/RolePill';

interface InvitationRolesPickerProps {
  assignedRoles: TRole[];
  onAssignRoles: (roles: TRole[]) => void;
  onRemoveRole: (role: TRole) => void;
}

export function InvitationRolesPicker({
  assignedRoles = [],
  onAssignRoles,
  onRemoveRole,
}: InvitationRolesPickerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRoles, setSelectedRoles] = useState<TRole[]>([]);

  return (
    <>
      <HStack gap={2}>
        {assignedRoles.map(role => (
          <RolePill
            key={role.cuid}
            role={role}
            onClose={() => onRemoveRole(role)}
          />
        ))}
        <IconButton
          aria-label={'add-role'}
          data-testid={'add-role-plus-button'}
          rounded={'full'}
          size={'sm'}
          color={'neutral.200'}
          onClick={() => onOpen()}
          icon={<AddIcon color={'neutral.500'} />}
        />
      </HStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnEsc
        closeOnOverlayClick
        size="6xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent p={3} data-testid="add-user-roles-modal">
          <ModalHeader>Assign Roles</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box pb={6}>
              <Text>
                Please choose a role below to assign for selected users. Users
                that already have the chosen role will not be affected.
              </Text>
            </Box>
            <RolesPicker
              assignedRoles={assignedRoles}
              onChange={roles => setSelectedRoles(roles)}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Spacer />
            <Button
              colorScheme="pink"
              data-testid={'assign-roles-button'}
              onClick={() => {
                onAssignRoles([...assignedRoles, ...selectedRoles]);
                onClose();
              }}
            >
              Assign Roles to Invitee
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
