import { TFinding } from '../../models';
import {
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tag,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/icons';
import FindingRow from '../FindingsList/FindingRow';

const FindingIndicator = ({
  findings,
  severity,
}: {
  findings: TFinding[];
  severity: 'High' | 'Medium' | 'Low';
}) => {
  const colorMap = {
    High: 'red',
    Medium: 'yellow',
    Low: 'neutral',
  };
  return (
    <Popover trigger="hover" placement={'left'}>
      <PopoverTrigger>
        <Tag size={'sm'} colorScheme={colorMap[severity]} rounded={'full'}>
          <TagLeftIcon boxSize={4} as={ExclamationTriangleIcon} />
          {findings.length}
        </Tag>
      </PopoverTrigger>
      <PopoverContent shadow={'md'} w={'40rem'}>
        <PopoverArrow />
        <PopoverHeader background={'transparent'}>
          <HStack alignItems={'flex-start'}>
            <Icon as={ExclamationTriangleIcon} boxSize={5} mt={0.5} />
            <Text
              whiteSpace={'normal'}
              fontWeight={'normal'}
              textAlign={'left'}
            >
              {findings.length} Unresolved {severity} Risk Model Findings
            </Text>
          </HStack>
        </PopoverHeader>
        <PopoverBody bg={'white'}>
          {findings.map(finding => (
            <FindingRow finding={finding} />
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FindingIndicator;
