import {
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from '@chakra-ui/react';
import { useOrgRoles } from '../../hooks/useOrgRoles';
import RolePill from '../RolePill';
import { AddIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { TRole } from '../../models';

type Props = {
  selectedRoles: string[];
  roleScope?: string;
  onRoleChange: (role: TRole, method: 'add' | 'delete') => Promise<any>;
};

export default function RoleManager({ selectedRoles, roleScope, onRoleChange }: Props) {
  const { orgRoles, isLoading } = useOrgRoles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const remainingRoles = orgRoles.filter(
    role => !selectedRoles.some(selectedRole => selectedRole === role.cuid),
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <HStack gap={2}>
      {selectedRoles.map(roleCUID => {
        const role = orgRoles.find(orgRole => orgRole.cuid === roleCUID)!;
        const onClose =
          role.is_admin || isSubmitting
            ? undefined
            : async () => {
                setIsSubmitting(true);
                await onRoleChange(role, 'delete');
                setIsSubmitting(false);
              };
        return <RolePill key={role.cuid} role={role} onClose={onClose} />;
      })}
      {remainingRoles.length > 0 && !isSubmitting && (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<Icon as={AddIcon} boxSize={3} />}
            rounded={'full'}
            size={'sm'}
          />
          <MenuList>
            {remainingRoles.filter(role =>
              (!roleScope && !role.scope) || (role.scope === roleScope)).map(role => (
              <MenuItem
                onClick={() => {
                  onRoleChange(role, 'add');
                }}
              >
                <RolePill key={role.cuid} role={role} />
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
      {isSubmitting && <Spinner />}
    </HStack>
  );
}
