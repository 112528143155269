import { SectionContentsProps } from '../../../Layout/DocumentationPage';
import ContentContainer from '../../../Layout/ContentContainer';
import { LoadingContainer } from '../../../LoadingContainer';
import UsersContext from '../../../../contexts/UsersContext';
import { useContext, useMemo } from 'react';
import { isInventoryModelValidator } from '../../../../auth/utils';
import ProjectContext from '../../../../contexts/ProjectContext';
import GuidelineItem from './GuidelineItem';

export default function GuidelineContent({ contents }: SectionContentsProps) {
  const { currentUser } = useContext(UsersContext);
  const { project } = useContext(ProjectContext);
  const { content_id } = contents;

  if (!content_id) {
    return <ContentContainer>Undefined content_id</ContentContainer>;
  }

  const readOnlyFields = useMemo(() => {
    return !isInventoryModelValidator(
      currentUser!,
      project && project.inventory_model,
    );
  }, [currentUser, project, project?.inventory_model]);

  return (
    <LoadingContainer isLoading={false}>
      <GuidelineItem
        key={content_id}
        content_id={content_id}
        readOnlyFields={readOnlyFields}
      />
    </LoadingContainer>
  );
}
