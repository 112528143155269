import {
  Box,
  HStack,
  Heading,
  Spacer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  AvatarGroup,
} from '@chakra-ui/react';
import React from 'react';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import { TGroup, TUser } from '../../models';
import AvatarProxy from '../AvatarProxy';

export interface TGroupWithMembers extends TGroup {
  members: TUser[];
}

interface GroupViewProps {
  group: TGroupWithMembers;
  menu?: React.ReactNode;
}

export default function GroupView({ group, menu }: GroupViewProps) {
  return (
    <Box p={2}>
      <HStack gap={2}>
        <Heading as="h3" fontSize="md" fontWeight="semibold" lineHeight={6}>
          {group.name}
        </Heading>
        {group.is_default ? (
          <Tag
            mt="1px"
            mb="1px"
            minHeight={0}
            borderRadius={22}
            fontSize="xs"
            fontWeight="semibold"
          >
            <TagLeftIcon
              as={ShieldCheckIcon}
              boxSize={18}
              strokeWidth={2.5}
              marginInlineEnd={0}
            />
            <TagLabel marginInlineStart={1} lineHeight={1.5}>
              DEFAULT GROUP
            </TagLabel>
          </Tag>
        ) : null}
        <Spacer />
        <AvatarGroup size="sm" max={5}>
          {group.members.map((user: TUser) => {
            return <AvatarProxy name={user.name} src={user.picture} />;
          })}
        </AvatarGroup>
      </HStack>
      <Text fontSize="sm">{`${group.members.length} Member${
        group.members.length > 1 ? 's' : ''
      }`}</Text>
      <HStack alignItems="flex-end">
        <Text fontSize="sm" mt={2} lineHeight={5}>
          {group.description ?? group.description}
        </Text>
        <Spacer />
        {menu}
      </HStack>
    </Box>
  );
}
