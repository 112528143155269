import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from '@chakra-ui/react';
import React, { ReactNode, useRef } from 'react';

interface ConfirmationAlertProps {
  open: boolean;
  title?: string;
  dialogBody?: string;
  cancelButton?: string | ReactNode;
  confirmButton?: string | ReactNode;
  onConfirm: (confirmed: boolean) => void;
  size?: AlertDialogProps['size'];
}

const ConfirmationAlert = ({
  open,
  title,
  dialogBody,
  cancelButton = 'No, cancel',
  confirmButton = 'Yes, remove it',
  onConfirm,
  size = 'md',
}: ConfirmationAlertProps) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef.current!}
      onClose={() => onConfirm(false)}
      isOpen={open}
      isCentered
      size={size}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        {title && <AlertDialogHeader>{title}</AlertDialogHeader>}
        <AlertDialogCloseButton />
        {dialogBody && <AlertDialogBody>{dialogBody}</AlertDialogBody>}

        <AlertDialogFooter gap={3}>
          {React.isValidElement(cancelButton) ? (
            React.cloneElement(cancelButton, {
              onClick: () => onConfirm(false),
            })
          ) : (
            <Button onClick={() => onConfirm(false)}>{cancelButton}</Button>
          )}

          {React.isValidElement(confirmButton) ? (
            React.cloneElement(confirmButton, {
              onClick: () => onConfirm(true),
            })
          ) : (
            <Button
              colorScheme="red"
              onClick={() => onConfirm(true)}
              data-testid="alert-confirm-button"
            >
              {confirmButton}
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmationAlert;
