import { Flex, useColorModeValue } from '@chakra-ui/react';

export default function MasterDetails({ children }: any) {
  return (
    <Flex
      position="relative"
      id="project-details"
      direction="column"
      align="center"
      justify="start"
      width={'100%'}
      gap={2}
      px={10}
      // pt={24}
      pb={12}
      bg={useColorModeValue('white', 'neutral.950')}
      overflow={'auto'}
      className="no-scrollbar"
    >
      {/* Need to decided on proper spacing according to current page first */}
      {children}
    </Flex>
  );
}
