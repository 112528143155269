import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
} from '@chakra-ui/react';

interface ForbiddenAlertProps {
  title?: string;
  description?: string;
}
export default function ForbiddenAlert({
  title = 'You are not allowed to access this resource',
  description = 'ask to your administrator for more information.',
}: ForbiddenAlertProps) {
  return (
    <Center w={'full'} p={10}>
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </Alert>
    </Center>
  );
}
