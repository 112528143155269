import { useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { SVGProps } from 'react';

function ValidMindLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="150"
      height="33"
      viewBox="0 0 150 33"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      aria-labelledby="validMind validMindDescription"
      role="img"
    >
      <title id="validMindLogo">
        ValidMind - Model Risk Management, Simplified.
      </title>
      <desc id="validMindDescription">
        ValidMind increases the speed and efficiency of model validation
        activities for Financial Institutions.
      </desc>

      <path
        d="M27.6674 0.242188L13.6445 32.0011H10.566L6.12689 23.6861H10.8118L12.0639 25.8915L23.3931 0.242188H27.6674ZM28.9025 12.3475L38.0532 32.0011H33.8298L31.5739 27.2389H23.4579L21.4795 32.0011H17.2816L25.7985 12.3475H28.9025ZM27.4268 18.1428L24.9844 23.6105H30.0219L27.4268 18.1428ZM8.66574 19.9066L10.2393 22.6782H5.58904L4.10933 19.9066H8.66574ZM6.51971 16.127L8.09355 18.8987H3.57148L2.09178 16.127H6.51971ZM4.37393 12.3475L5.94752 15.1192H1.55367L0.0742188 12.3475H4.37393ZM44.5155 28.2971H54.4633V32.0011H40.5974V12.3475H44.5155V28.2971ZM57.0075 12.3475H60.9256L61.0019 32.0011H57.0839L57.0075 12.3475ZM63.5461 12.3475H70.3901C72.5781 12.3475 74.4778 12.7381 76.0891 13.5192C77.7004 14.3003 78.9301 15.4299 79.7782 16.9082C80.6262 18.4032 81.0503 20.1586 81.0503 22.1743C81.0503 25.3323 80.1301 27.7596 78.2898 29.4562C76.4495 31.1528 73.8163 32.0011 70.3901 32.0011H63.5461V12.3475ZM70.1611 28.2971C72.6035 28.2971 74.3717 27.8142 75.4657 26.8483C76.5598 25.8824 77.1068 24.3244 77.1068 22.1743C77.1068 20.0914 76.5386 18.5502 75.4021 17.5507C74.2657 16.5512 72.5187 16.0515 70.1611 16.0515H67.4642V28.2971H70.1611ZM85.664 32.0011H83.5945V12.3475H85.664L92.8365 22.9885L100.009 12.3475H102.05V32.0011H99.9806L100.066 15.4921L93.4885 25.4873H92.2128L85.5506 15.5763L85.664 32.0011ZM108.049 32.0011H105.952L105.866 12.3475H107.964L108.049 32.0011ZM113.907 32.0011H111.866L112.206 12.3475H114.02L126.211 28.4073L126.636 12.3475H128.677L128.167 32.0011H126.381L114.19 15.8852L113.907 32.0011ZM132.381 12.3475H138.898C142.35 12.3475 145.011 13.1992 146.879 14.9025C148.748 16.6058 149.682 19.0297 149.682 22.1743C149.682 25.3937 148.757 27.8364 146.908 29.5023C145.058 31.1681 142.388 32.0011 138.898 32.0011H132.381V12.3475ZM138.798 30.2373C141.795 30.2373 144.019 29.5922 145.47 28.3021C146.921 27.012 147.647 25.0114 147.647 22.3003C147.647 17.009 144.697 14.3633 138.798 14.3633H134.671V30.2373H138.798Z"
        fill={useColorModeValue('brand.base', 'brand.base')}
      />
    </svg>
  );
}

export default ValidMindLogo;
