import { Select } from '@chakra-ui/react';
import { Template } from '../../../models';

export interface TemplatesSelectProps {
  templateCuid: string;
  setTemplateCuid: (templateCuid: string) => void;
  templates?: Template[];
}

export default function TemplatesSelect({
  templates = [],
  templateCuid,
  setTemplateCuid,
}: TemplatesSelectProps) {
  return (
    <Select
      onChange={e => setTemplateCuid(e.target.value)}
      placeholder="Select template"
      value={templateCuid}
      focusBorderColor="brand.base"
    >
      {templates.map(t => (
        <option key={t.cuid} value={t.cuid}>
          {t.name}
        </option>
      ))}
    </Select>
  );
}
