import { FieldEditProps } from '../../../CustomField';
import { useEffect, useState } from 'react';
import UseCasesSelect from '../../../../ModelInventoryInputs/UseCasesSelect';
import CustomFieldEditLayout from '../CustomFieldEditLayout';

const UseCaseFieldEdit = ({
  field,
  onEdit,
  onCancel,
  onChange,
}: FieldEditProps) => {
  const [selected, setSelected] = useState<string>('');

  useEffect(() => {
    setSelected(field.value.cuid);
  }, [field]);

  const onUseCaseCuidChange = (useCaseCuid: string) => {
    setSelected(useCaseCuid);
    onChange?.({ use_case: useCaseCuid });
  };

  const onSave = () => {
    onEdit({ use_case: selected })
  };

  return (
    <CustomFieldEditLayout
      onCancelClick={onCancel}
      onSaveClick={onSave}
      showEditControls={!!onChange}
    >
      <UseCasesSelect
        useCaseCuid={selected}
        setUseCaseCuid={onUseCaseCuidChange}
      />
    </CustomFieldEditLayout>
  );
};

export default UseCaseFieldEdit;
