import { SectionContentsProps } from '../../Layout/DocumentationPage';
import ContentContainer from '../../Layout/ContentContainer';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContentPageH2 } from '../../Layout';
import _ from 'lodash';
import useTestResult from '../../../hooks/useTestResult';
import { LoadingContainer } from '../../LoadingContainer';
import { useFigures } from '../../../hooks/useFigure';
import FigureDisplay from '../../FigureDisplay';
import { MetadataContentEditor } from './MetadataContentEditor';
import { keyable } from '../../../models/utils';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Code,
  Heading,
  HStack,
  IconButton,
  StackDivider,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { ResultSummary } from './ResultSummary';
import { EmptyStateDisplay } from '../../EmptyStateDisplay';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useInView } from 'react-intersection-observer';

import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';
import ConfirmationAlert from '../../ConfirmationAlert';
import InputInfoModal from '../../InputInfoModal';
import { useAuth0 } from '@auth0/auth0-react';
import { ProjectContext } from '../../../contexts';
import API from '../../../api/API';
import { AxiosError } from 'axios';
import { getModelDocumentType } from '../../../models/model_document';
import { InventoryModelStages } from '../../../models/inventory_model';

export const TEST_DESCRIPTION_CONTENT_ID_PREFIX = 'test_description';

export const FailedBadge = ({ size = 'sm' }: { size?: string }) => (
  <Tag size={size} variant="subtle" colorScheme="yellow">
    <TagLeftIcon as={ExclamationTriangleIcon} />
    <TagLabel>Requires attention</TagLabel>
  </Tag>
);

export const PassedBadge = ({ size = 'sm' }: { size?: string }) => (
  <Tag size={size} variant="subtle" colorScheme="green">
    <TagLeftIcon as={CheckCircleIcon} />
    <TagLabel>Satisfactory</TagLabel>
  </Tag>
);
export const formatTestTitleFromID = (testTitle: string) => {
  return `${_.startCase(testTitle.split('.').pop() || '')}`;
};
export const TestParamsRenderer = ({ params }: keyable) => (
  <Alert
    status="info"
    flexDirection="column"
    alignItems={'flex-start'}
    mb={8}
    bg={useColorModeValue('blue.50', 'neutral.850')}
    borderRadius={'md'}
  >
    <HStack alignItems={'flex-start'} w={'full'}>
      <AlertIcon />
      <VStack alignItems={'flex-start'} w={'full'} marginInlineStart={0}>
        <AlertTitle>Test Parameters</AlertTitle>

        <AlertDescription w={'full'}>
          <TableContainer>
            <Table
              size="sm"
              variant="simple"
              rounded={'md'}
              overflow={'hidden'}
              border={'1px solid'}
              borderColor={useColorModeValue('neutral.200', 'neutral.700')}
            >
              <Thead
                bg={useColorModeValue('neutral.200', 'neutral.700')}
                color={useColorModeValue('neutral.700', 'neutral.100')}
              >
                <Tr>
                  <Th>NAME</Th>
                  <Th isNumeric>VALUE</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(params)
                  .filter(([key, value]) => typeof value !== 'object')
                  .map(([key, value]) => (
                    <Tr key={`param-${key}`}>
                      <Td>
                        <Code bg={'transparent'} fontSize={'md'}>
                          {key}
                        </Code>
                      </Td>
                      <Td isNumeric fontSize={'md'}>
                        {value as string}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </AlertDescription>
      </VStack>
    </HStack>
  </Alert>
);

export function TestResultContent({
  contents,
  removeBlock,
  readOnly,
  hideHeader = false,
  overrideDocumentType,
  documentType,
}: SectionContentsProps) {
  const { content_id, options = {} } = contents;
  const testResultContentRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { title } = options;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { project } = useContext(ProjectContext);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  if (!content_id) {
    return <ContentContainer>Undefined content_id</ContentContainer>;
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  });

  const handleClickOutside = (e: any) => {
    if (
      testResultContentRef.current &&
      !(testResultContentRef.current as any).contains(e.target)
    ) {
      setIsFocused(false);
    }
  };

  const { testResult, isLoading, isError, error } = useTestResult(
    content_id,
    documentType ? getModelDocumentType(documentType) : undefined,
    inView,
  );

  let figureMetadata: any = {
    _type: 'threshold_test',
    _name: content_id,
  };
  if (testResult?.ref_id) {
    figureMetadata._ref_id = testResult.ref_id;
  }

  const { figures = [], isLoading: isLoadingFigures } = useFigures(
    figureMetadata,
    testResult?.test_run_cuid,
    !!testResult?.results,
  );

  const testDescriptionContentId = `${TEST_DESCRIPTION_CONTENT_ID_PREFIX}:${testResult?.test_name}`;
  const metadataContents = {
    content_id: testDescriptionContentId,
    content_type: 'text',
  };

  let testTitle = title || testResult?.test_name || '';
  // Grab the last part of the test ID since it's a namespaced ID, e.g. validmind.data_validation.TestNmae
  testTitle = testTitle.split('.').pop();

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed && removeBlock) {
      setIsFocused(false);
      removeBlock(contents);
    }
    setConfirmDelete(false);
  };

  const getTestResultHistoryForPage = useCallback(
    async (page: number, limit: number) => {
      if (project) {
        const token = await getAccessTokenSilently();
        const resp = await API.GetTestResultHistoryForProject(
          project?.cuid,
          token,
          content_id,
          documentType ? getModelDocumentType(documentType) : undefined,
          page,
          limit,
        );
        return {
          history: resp.results,
          total: resp.total,
        };
      }
      return {
        history: [],
        total: 0,
      };
    },
    [project, content_id, documentType],
  );

  const getContent = ({ hideHeader = false }: { hideHeader?: boolean }) => {
    // A 404 error from the API means the developer framework has not sent any data
    // for this content block so we show an empty state
    if (isError && (error as AxiosError)?.response?.status === 404) {
      return (
        <>
          <ContentPageH2>
            {<Text>{_.startCase(content_id.split('.').pop())}</Text>}
          </ContentPageH2>
          <EmptyStateDisplay variant="no-activity">
            <Heading as={'h4'} fontSize={'md'}>
              The data associated with this Test-Driven Block (
              <code>{content_id}</code>) is missing.
            </Heading>
            <Text align={'center'} fontSize={'md'}>
              To populate this content block please make sure to run the
              associated documentation test suite.
            </Text>
            <Text align={'center'} pb={10} fontSize={'md'}>
              Please refer to{' '}
              <a href="https://docs.validmind.ai" target="_blank">
                ValidMind's documentation
              </a>{' '}
              for more information.
            </Text>
          </EmptyStateDisplay>
        </>
      );
    }
    if (testResult) {
      return (
        <LoadingContainer isLoading={isLoading || isLoadingFigures}>
          {!hideHeader && (
            <ContentPageH2 size={'md'}>
              {testResult && (
                <HStack justifyContent={'space-between'}>
                  <Text>
                    {formatTestTitleFromID(
                      title || testResult?.test_name || '',
                    )}
                  </Text>
                  {testResult?.passed ? (
                    <PassedBadge size="lg" />
                  ) : (
                    <FailedBadge size="lg" />
                  )}
                </HStack>
              )}
            </ContentPageH2>
          )}
          <Box mt={4}>
            <InputInfoModal
              createdAt={testResult.created_at}
              inputs={testResult.inputs}
              user={testResult.user}
              getHistoryForPage={getTestResultHistoryForPage}
              contentID={content_id}
              title={formatTestTitleFromID(
                title || testResult?.test_name || '',
              )}
            />
          </Box>
          <ContentContainer>
            <MetadataContentEditor
              readOnly={readOnly}
              key={testDescriptionContentId}
              contents={metadataContents}
              overrideDocumentType={overrideDocumentType}
            />
            {testResult?.params !== null &&
              testResult?.params !== undefined && (
                <TestParamsRenderer params={testResult.params} />
              )}
          </ContentContainer>
          {testResult && testResult.summary && (
            <ResultSummary summary={testResult.summary} />
          )}
          {figures.map((figure: any) => (
            <FigureDisplay figure={figure} />
          ))}
        </LoadingContainer>
      );
    }
  };

  return (
    <Box ref={ref} position={'relative'}>
      {readOnly !== true && (
        <Box
          position={'absolute'}
          hidden={!isFocused}
          top={'-42px'}
          left={'0'}
          boxShadow={'var(--ck-drop-shadow),0 0'}
          w={'min'}
          rounded={'md'}
        >
          <HStack
            divider={
              <StackDivider borderColor="neutral.200" m={'1 !important'} />
            }
            rounded={'md'}
            bg={'white'}
            border={'1px solid var(--chakra-colors-neutral-200)'}
            gap={0}
          >
            {/* <Tooltip
              label="Information about this block"
              hasArrow
              placement="bottom"
              bg={useColorModeValue('neutral.200', 'neutral.700')}
              boxShadow={'none'}
              color={useColorModeValue('neutral.800', 'neutral.200')}
              openDelay={500}
              arrowSize={5}
            >
              <IconButton
                aria-label="Information about this block"
                bg={'white !important'}
                color={'neutral.800 !important'}
                _hover={{ bg: 'neutral.100 !important' }}
                icon={<InformationCircleIcon width={20} height={20} />}
                onClick={() => setConfirmDelete(true)}
              />
            </Tooltip> */}
            <Tooltip
              label="Remove Test-Driven Block"
              hasArrow
              placement="bottom"
              bg={useColorModeValue('neutral.200', 'neutral.700')}
              boxShadow={'none'}
              color={useColorModeValue('neutral.800', 'neutral.200')}
              openDelay={500}
              arrowSize={5}
            >
              <IconButton
                aria-label="Remove Test-Driven Block"
                bg={'white !important'}
                color={'neutral.800 !important'}
                _hover={{
                  color: 'red.600  !important',
                  bg: 'red.100 !important',
                }}
                icon={<TrashIcon width={20} height={20} />}
                onClick={() => setConfirmDelete(true)}
                // TODO: add permissions check here as well
                isDisabled={
                  project?.inventory_model.stage !== InventoryModelStages.ACTIVE
                }
              />
            </Tooltip>
          </HStack>
        </Box>
      )}

      <ConfirmationAlert
        open={confirmDelete}
        title={`Remove "${testResult && _.startCase(testTitle)} Test" block`}
        dialogBody={`Are you sure you'd like to remove this test block?`}
        onConfirm={onDeleteConfirmed}
      />

      <Tooltip
        gutter={0}
        bg={useColorModeValue('neutral.200', 'neutral.700')}
        boxShadow={'none'}
        color={useColorModeValue('neutral.800', 'neutral.200')}
        placement="top-end"
        isDisabled={isFocused}
        label="Click to edit this block"
        openDelay={1000}
        offset={[1, 0]}
        fontSize="md"
        hidden={readOnly === true}
      >
        <Box
          p={2}
          border={'1px solid'}
          borderColor={isFocused ? 'brand.base' : 'transparent'}
          bg={
            isFocused
              ? useColorModeValue('neutral.50', 'neutral.850')
              : 'transparent'
          }
          borderRadius={'md'}
          transition={'all .5s ease-in-out'}
          _hover={
            isFocused
              ? {
                  borderColor: 'brand.base',
                }
              : {
                  borderColor: useColorModeValue('neutral.200', 'neutral.700'),
                }
          }
          onClick={() => {
            if (readOnly) return;
            setIsFocused(true);
          }}
          ref={testResultContentRef}
        >
          {getContent({ hideHeader })}
        </Box>
      </Tooltip>
    </Box>
  );
}
