import React from 'react';
import { HStack } from '@chakra-ui/react';
import { PrimaryButton, TertiaryButton } from '../../../../Layout';

export interface CustomFieldEditLayoutProps {
  children: React.ReactNode;
  onSaveClick: any;
  onCancelClick: any;
  showEditControls?: any;
}

const CustomFieldEditLayout = ({
  children,
  onSaveClick,
  onCancelClick,
  showEditControls,
}: CustomFieldEditLayoutProps) => {
  return (
    <>
      {children}
      {!showEditControls ? (
        <HStack alignSelf={'flex-end'}>
          <TertiaryButton onClick={onCancelClick} size={'sm'} variant={'ghost'}>
            Cancel
          </TertiaryButton>
          <PrimaryButton type="submit" size={'sm'} onClick={onSaveClick}>
            Save
          </PrimaryButton>
        </HStack>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomFieldEditLayout;
