import { Flex } from '@chakra-ui/react';

interface SectionContainerOpts {
  children?: any;
}

export default function SectionContainer({ children }: SectionContainerOpts) {
  return (
    <Flex
      id="section-container-area"
      direction="column"
      width="100%"
      mx={'auto'}
      data-testid="SectionContainer-area"
    >
      {children}
    </Flex>
  );
}
