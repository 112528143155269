import { Square, Text } from '@chakra-ui/react';
interface InventoryModelTierLevelBadgeProps {
  level?: string;
  size?: string;
}
export default function InventoryModelTierLevelBadge({
  level,
  size = 'sm',
}: InventoryModelTierLevelBadgeProps) {
  const shapeSize = size === 'xs' ? 4 : 8;

  switch (level) {
    case '1':
      return (
        <Square
          size={shapeSize === 8 ? 8 : shapeSize}
          borderRadius={4}
          bg={'neutral.900'}
          borderWidth={'1px'}
          borderColor={'whiteAlpha.800'}
        >
          <Square
            size={shapeSize === 8 ? 8 : shapeSize}
            borderRadius={4}
            transform={'rotate(45deg)'}
            bg={'neutral.900'}
            borderWidth={'1px'}
            borderColor={'whiteAlpha.800'}
          >
            <Text
              transform={'rotate(-45deg)'}
              color={'white'}
              fontWeight={'bold'}
              fontSize={size}
            >
              {level}
            </Text>
          </Square>
        </Square>
      );
    case '2':
      return (
        <Square
          size={shapeSize}
          borderRadius={8}
          bg={'neutral.500'}
          transform={'rotate(-45deg)'}
        >
          <Text
            transform={'rotate(45deg)'}
            color={'white'}
            fontWeight={'bold'}
            fontSize={size}
          >
            {level}
          </Text>
        </Square>
      );
    case '3':
      return (
        <Square size={shapeSize} borderRadius={4} bg={'blue.400'}>
          <Text color={'white'} fontWeight={'bold'} fontSize={size}>
            {level}
          </Text>
        </Square>
      );
    case '4':
      return (
        <Square size={shapeSize} borderRadius={'full'} bg={'green.500'}>
          <Text color={'white'} fontWeight={'bold'} fontSize={size}>
            {level}
          </Text>
        </Square>
      );

    default:
      return (
        <Square size={shapeSize} borderRadius={2} bgColor={'neutral.200'}>
          <Text
            transform={'rotate(45deg)'}
            color={'white'}
            fontWeight={'bold'}
            fontSize={size}
          >
            {level}
          </Text>
        </Square>
      );
  }
}
