import { Button, FormControl, HStack, Stack, VStack } from '@chakra-ui/react';
import AddFindingModal from '../AddFindingModal';
import DownloadModal from '../DownloadModal';
import AttributesRail from '../Layout/AttributesRail';
import { TProject } from '../../models';
import { Label } from '../Layout';
import DownloadOfflineDocument from '../DownloadOfflineDocument';
import MoreInfoPopOver from '../MoreInfoPopOver';

interface Props {
  project: TProject;
  onOpenTemplateModal?: () => void;
}

export default function AttributesRailValidationReportOverview({
  project,
  onOpenTemplateModal,
}: Props) {
  return (
    <AttributesRail>
      <VStack align="start">
        <Stack>
          <AddFindingModal triggerLabel="Add Finding" />

          {!project.template_vr.is_offline ? (
            <DownloadModal
              project={project}
              currentDocumentType={'validation_report'}
            />
          ) : (
            <Stack>
              <DownloadOfflineDocument
                files={project.template_vr.offline_files}
              />
            </Stack>
          )}
        </Stack>
      </VStack>

      {onOpenTemplateModal && (
        <VStack align="start">
          <FormControl role="group">
            <HStack gap={0}>
              <Label>VALIDATION REPORT TEMPLATE</Label>
              <MoreInfoPopOver
                title="Validation Report Templates"
                description="Customizable documentation templates offer a standardized approach to creating consistent and comprehensive validation reports."
                link="https://docs.validmind.ai/guide/model-documentation/working-with-documentation-templates.html"
                placement="right-end"
                iconProps={{
                  ml: 2,
                  fill: 'brand.base',
                  opacity: 0,
                  _groupHover: { opacity: 1 },
                }}
              />
            </HStack>
            <Button
              variant={'link'}
              onClick={onOpenTemplateModal}
              whiteSpace={'normal'}
              textAlign={'left'}
              data-testid="view-validation-report-template-btn"
            >{`${project?.template_vr.name} v${project?.template_vr.version_number}`}</Button>
          </FormControl>
        </VStack>
      )}
    </AttributesRail>
  );
}
