import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import { useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  useColorModeValue,
  HStack,
  Icon,
  Tabs,
  TabPanel,
  TabList,
  Tab,
  TabPanels,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import './styles.css';
import { ChartBarSquareIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ContentPageTitle } from '../../components/Layout';
import {
  CURRENT_DASHBOARD_LAYOUT_VERSION,
  CURRENT_REPORT_LAYOUT_VERSION,
  useUserUISettings,
} from '../../hooks/useUserUISettings';
import { ReportLayouts } from '../../models/user';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import GridView from '../../components/GridView';
import { GridViewItem } from '../../components/GridView/GridViewItem';
import { Layout } from 'react-grid-layout';
import ReportChart, { BarCharts } from '../../components/ReportChart';
import {
  AddToEndOfLayout,
  GRID_COLUMN_AMOUNT,
  PrependLayouts,
} from '../../layoutUtils';
import ReportCount, { ReportCountMap } from '../../components/ReportCount';
import _ from 'lodash';

type Props = {};

const migrateReportLayouts = (layout: ReportLayouts): ReportLayouts => {
  if (layout.version === CURRENT_REPORT_LAYOUT_VERSION) {
    return layout;
  }

  // Migrate from v1 to v2:
  // Add counts to top of existing layouts
  if (layout.version === 1) {
    const modelsLayout = layout.models.lg as Layout[];
    const findingsLayout = layout.findings.lg as Layout[];

    const tempLayoutForModelCounts = [] as Layout[];
    const tempLayoutForFindingCounts = [] as Layout[];

    const modelCountKeys = ['count-total-models'];
    const findingCountKeys = [
      'count-total-findings',
      'count-open-findings',
      'count-past-due-findings',
      'count-closed-findings',
    ];

    // make the widgets fill entire "row" of layout
    _.chunk(modelCountKeys, GRID_COLUMN_AMOUNT).forEach(chunk => {
      let countWidth = 1;

      if (chunk.length === 1) {
        countWidth = GRID_COLUMN_AMOUNT;
      } else if (chunk.length === 2) {
        countWidth = GRID_COLUMN_AMOUNT / 2;
      }

      chunk.forEach(key => {
        AddToEndOfLayout(tempLayoutForModelCounts, {
          i: key,
          w: countWidth,
          h: 2,
        });
      });
    });

    // make the widgets fill entire "row" of layout
    _.chunk(findingCountKeys, GRID_COLUMN_AMOUNT).forEach(chunk => {
      let countWidth = 1;

      if (chunk.length === 1) {
        countWidth = GRID_COLUMN_AMOUNT;
      } else if (chunk.length === 2) {
        countWidth = GRID_COLUMN_AMOUNT / 2;
      }

      chunk.forEach(key => {
        AddToEndOfLayout(tempLayoutForFindingCounts, {
          i: key,
          w: countWidth,
          h: 2,
        });
      });
    });

    layout.models.lg = PrependLayouts(tempLayoutForModelCounts, modelsLayout);
    layout.findings.lg = PrependLayouts(
      tempLayoutForFindingCounts,
      findingsLayout,
    );
    layout.version = 2;
  }

  return migrateReportLayouts(layout);
};

export default function Reports({}: Props) {
  const settings = useUserUISettings();
  const [currentTab, setTab] = useState(0);
  const toast = useToast();

  const tabLayouts = migrateReportLayouts(settings.getUserReportLayouts());
  const dashboardLayouts = settings.getDashboardLayouts();

  return (
    <Box
      py={10}
      px={8}
      flex={1}
      w={'full'}
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'neutral.950')}
    >
      <Stack>
        <HStack>
          <Flex width={'full'} justify={'space-between'}>
            <HStack gap={5} pl={2} color={'inherit'}>
              <Icon as={ChartBarSquareIcon} boxSize={10} />
              <ContentPageTitle>
                Reports
                <MoreInfoPopOver
                  title="Reports"
                  description="View insights into your model validation efforts, detailing critical findings, risk exposure, and compliance status."
                  link="https://docs.validmind.ai/guide/model-validation/view-reports.html"
                  placement="right-end"
                  iconProps={{
                    ml: 2,
                  }}
                />
              </ContentPageTitle>
            </HStack>
            {/* <Button
              size="sm"
              colorScheme="brand"
              onClick={() => {
                settings.setUserReportLayouts(getDefaultLayout());
              }}
            >
              Reset All Layouts
            </Button> */}
          </Flex>
        </HStack>
        <Tabs
          size="md"
          colorScheme="brand"
          w={'full'}
          tabIndex={currentTab}
          onChange={setTab}
        >
          <TabList>
            <Tab>Models</Tab>
            <Tab>Findings</Tab>
          </TabList>
          <TabPanels>
            {['models', 'findings'].map(category => {
              const tabLayout = tabLayouts[category as keyof ReportLayouts] as {
                lg: Layout[];
              };
              return (
                <TabPanel key={`tp-${category}`}>
                  <GridView
                    layouts={tabLayout}
                    onLayoutChange={(_, allLayouts) => {
                      settings.setUserReportLayouts({
                        ...tabLayouts,
                        version: CURRENT_REPORT_LAYOUT_VERSION,
                        [category]: allLayouts,
                      });
                    }}
                  >
                    {tabLayout.lg.map(layout => {
                      const key = layout.i;
                      const inDashboardAlready =
                        !!dashboardLayouts.dashboard.lg.find(
                          layout => layout.i === key,
                        );
                      const menuItems = (
                        <MenuItem
                          isDisabled={inDashboardAlready}
                          icon={<Icon as={PlusIcon} boxSize={4} />}
                          onClick={() => {
                            AddToEndOfLayout(dashboardLayouts.dashboard.lg, {
                              i: key,
                              w: layout.w,
                              h: layout.h,
                            });
                            settings.setDashboardLayouts({
                              version: CURRENT_DASHBOARD_LAYOUT_VERSION,
                              dashboard: dashboardLayouts.dashboard,
                            });
                            toast({
                              title: 'Added to Dashboard',
                              status: 'success',
                            });
                          }}
                        >
                          Add to Dashboard
                        </MenuItem>
                      );
                      const isChart = key in BarCharts;

                      if (!isChart) {
                        const reportCountItem = ReportCountMap[key];
                        return (
                          <GridViewItem
                            menuItems={menuItems}
                            key={key}
                            title={reportCountItem.title}
                          >
                            <ReportCount
                              countId={key}
                              {...ReportCountMap[key]}
                            />
                          </GridViewItem>
                        );
                      }

                      const BarChart = BarCharts[key];

                      if (!BarChart) return null;

                      return (
                        <GridViewItem
                          key={key}
                          title={BarChart.title}
                          menuItems={menuItems}
                        >
                          <ReportChart chartId={layout.i} />
                        </GridViewItem>
                      );
                    })}
                  </GridView>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
}
