import { TFieldStatistics } from '../../api/API';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatNumber } from '../../utils';
import { Heading, Text, VStack, useColorModeValue } from '@chakra-ui/react';

interface IHistogramData {
  rows: number;
  label: string;
  percentage: number;
}

interface IBarTooltipProps {
  active?: boolean;
  payload?: any;
  label?: string;
}

const BarTooltip = ({ active, payload, label }: IBarTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <VStack
        p={2}
        gap={1}
        rounded={'md'}
        border={'1px solid'}
        alignItems={'flex-start'}
        bg={useColorModeValue('white', 'neutral.900')}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      >
        <Heading size="sm">{`${label}`}</Heading>
        <Text>
          {`${formatNumber(payload[0].value)} rows (${formatNumber(
            payload[0].payload.percentage,
          )}% of rows)`}
        </Text>
      </VStack>
    );
  }

  return null;
};

/**
 * Returns a histogram with the desired number of bins for a field, depending if it's
 * a cateogrical or numerical field, and if we're rendering a compact histogram
 */
const getBestHistogram = (
  statistics: TFieldStatistics,
  compact: boolean,
  binSize: number,
) => {
  // Backwards compatibility for old data
  if (!statistics.histograms) {
    return statistics.histogram;
  }

  // Could be Categorical or Boolean
  const isCategorical = statistics.type !== 'Numeric';

  if (isCategorical) {
    return statistics.histograms['default']['histogram'];
  }

  // Compact size should always show 10 bins by default
  if (compact) {
    return statistics.histograms['bins_10']['histogram'];
  }

  if (binSize === 0) {
    return statistics.histograms['default']['histogram'];
  }

  return statistics.histograms[`bins_${binSize}`]['histogram'];
};

interface IHistogramProps {
  statistics: TFieldStatistics;
  compact: boolean;
  binSize?: number;
}

export default function Histogram({
  statistics,
  compact,
  binSize = 0,
}: IHistogramProps) {
  const { count: total } = statistics;
  const histogram = getBestHistogram(statistics, compact, binSize);

  if (histogram === undefined) {
    return null;
  }

  let histogramData: IHistogramData[] = [];

  if (histogram.counts) {
    histogram.counts.forEach((count: number, index: number) => {
      const fromLabel = formatNumber(histogram.bin_edges[index]);
      const toLabel = formatNumber(histogram.bin_edges[index + 1]);
      histogramData.push({
        label: `${fromLabel} to ${toLabel}`,
        rows: count as number,
        percentage: (count / total) * 100,
      });
    });
  } else {
    Object.entries(histogram).forEach(([key, value]) => {
      histogramData.push({
        rows: value as number,
        label: key,
        percentage: ((value as number) / total) * 100,
      });
    });
  }

  const processOtherValues = histogramData.length > 1000;
  const isCategorical = statistics.type === 'Categorical';
  const maxNumberToShow = 9;
  if (isCategorical && processOtherValues) {
    const histogramDataSorted = histogramData.sort(
      (a: IHistogramData, b: IHistogramData) => b.rows - a.rows,
    );
    histogramData = histogramDataSorted.slice(0, maxNumberToShow);
    const rows = histogramDataSorted
      .slice(maxNumberToShow)
      .map(o => o.rows)
      .reduce((sum: number, rows: number) => (sum += rows), 0);
    const others = {
      rows,
      label: 'Other Values',
      percentage: (rows / total) * 100,
    };
    histogramData = [...histogramData, others];
  }

  const initialBarsToDisplay = compact ? 10 : 100;
  const brushEndIndex =
    histogramData.length > initialBarsToDisplay
      ? initialBarsToDisplay
      : histogramData.length;

  const responsiveContainerProps = compact
    ? { width: 200, aspect: 5 }
    : { width: '100%', height: '90%' };

  // Calculate this value after we have taken into account "Other Values"
  const maxRows = Math.max(...histogramData.map(o => o.rows));
  return (
    <ResponsiveContainer {...responsiveContainerProps}>
      <BarChart
        data={histogramData}
        margin={{ top: compact ? 5 : 50, right: compact ? 30 : 0 }}
      >
        {!compact && <CartesianGrid strokeDasharray="3 3" />}
        {!compact && <YAxis tick={{ fontSize: 12 }} domain={[0, maxRows]} />}
        <XAxis dataKey="label" hide={compact} tick={{ fontSize: 12 }} />
        <Tooltip allowEscapeViewBox={{ x: true }} content={<BarTooltip />} />
        {histogramData.length > initialBarsToDisplay && (
          <Brush
            dataKey="label"
            height={compact ? 10 : 30}
            endIndex={brushEndIndex}
          />
        )}
        <Bar
          dataKey="rows"
          fill="#DE257E"
          background={compact ? { fill: '#eee' } : undefined}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
