import { MagnifyingGlassPlusIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { TDataset, TFieldStatistics } from '../../api/API';
import { DataTable, defaultGetHeaderProps } from '../DataTable';
import { formatNumber } from '../../utils';
import ContentModal from '../ContentModal';
import { useState } from 'react';
import Histogram from '../Histogram';
import { TDatasetField } from '../../models/dataset_field';
import {
  Button,
  Code,
  HStack,
  Heading,
  IconButton,
  Text,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export interface IReviewProps {
  dataset?: TDataset; // backwards compatibility
  fields?: TDatasetField[];
}

// Only override header styles for the expander header
const getHeaderProps = (column: any) => {
  if (column.id === 'expander') {
    return {
      style: {
        backgroundColor: '#E9E9E9',
        margin: 0,
        padding: 0,
        width: '10px',
      },
    };
  }

  return defaultGetHeaderProps(column);
};

// Once all datasets have `.fields` properly populated and histograms
// have been separated we can rely on those fields instead of dataset.statistics
const getDatasetFieldStatistics = (
  fields: TDatasetField[],
  fieldName: string,
) => {
  const field = fields.find(field => field.id === fieldName);
  if (field) {
    return {
      ...field.statistics,
      histogram: field.histograms.default,
      histograms: field.histograms,
    };
  }

  return null;
};

const HistogramBinSizeButtons = ({
  currentBinSize,
  onButtonClick,
}: {
  currentBinSize: number;
  onButtonClick: (binSize: number) => void;
}) => {
  // Size 0 will map to the default bin size computed for the field
  const binSizes = [0, 5, 10, 20, 50];

  const binSizebuttons = binSizes.map(binSize => {
    if (binSize === 0) {
      return (
        <Button
          size={'sm'}
          key={`hist-bin-size-${binSize}`}
          isDisabled={currentBinSize === binSize}
          onClick={() => onButtonClick(binSize)}
          variant={'outline'}
        >
          Default (auto)
        </Button>
      );
    } else {
      return (
        <Button
          size={'sm'}
          key={`hist-bin-size-${binSize}`}
          isDisabled={currentBinSize === binSize}
          onClick={() => onButtonClick(binSize)}
          variant={'outline'}
        >
          {binSize}
        </Button>
      );
    }
  });

  return <>{binSizebuttons}</>;
};

export function Review({ dataset, fields }: IReviewProps) {
  const [currentStatistics, setCurrentStatistics] =
    useState<TFieldStatistics | null>(null);
  const [currentBinSize, setCurrentBinSize] = useState<number>(0);

  // If fields are passed in, use those instead of the dataset fields
  // dataset.fields is being used for backwards compatibility
  const datasetFields = fields || dataset?.fields || [];

  // Join field descriptions with field statistics
  const tableValues = datasetFields.map((field: any) => ({
    ...getDatasetFieldStatistics(datasetFields, field.id),
    name: field.id,
    description: field.description,
    type: field.type,
    type_options: field.type_options,
  }));

  const getFieldDescription = (row: any) => {
    const fieldName =
      row.type_options !== undefined && row.type_options.primary_key === true
        ? `${row.name} (Primary Key)`
        : row.name;

    if (row.description === undefined) {
      return (
        <Code fontWeight={'bold'} bg={'transparent'}>
          {fieldName}
        </Code>
      );
    }

    return (
      <HStack>
        <Code fontWeight={'bold'} bg={'transparent'}>
          {fieldName}
        </Code>
        <Popover trigger="hover">
          <PopoverTrigger>
            <Icon as={InformationCircleIcon} boxSize={5} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody whiteSpace={'normal'}>{row.description}</PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
    );
  };

  const onHistogramExpanded = (statistics: TFieldStatistics) => {
    setCurrentStatistics(statistics);
  };

  const columns = [
    {
      Header: 'Name',
      accessor: getFieldDescription,
    },
    {
      Header: 'Type',
      accessor: (row: any) =>
        row.type === 'Dummy' ? 'Categorical (Dummy)' : row.type,
    },
    {
      Header: 'Count',
      accessor: (row: any) => formatNumber(row.count),
    },
    {
      Header: 'Distinct',
      accessor: (row: any) => `${(row.distinct * 100).toFixed(2)}%`,
    },
    {
      Header: 'Missing',
      accessor: (row: any) => `${(row.missing * 100).toFixed(2)}%`,
    },
    {
      Header: 'Histogram',
      accessor: (row: TFieldStatistics) => (
        <HStack gap={0}>
          <Histogram statistics={row} compact={true} />
          <IconButton
            aria-label="Expand histogram"
            icon={<Icon as={MagnifyingGlassPlusIcon} boxSize={5} />}
            onClick={() => onHistogramExpanded(row)}
            variant={'ghost'}
          />
        </HStack>
      ),
    },
  ];

  const tableData = React.useMemo(() => tableValues, [tableValues]);
  const tableColumns = React.useMemo(() => columns, []);

  return (
    <>
      {currentStatistics && (
        <ContentModal
          isOpen={!!currentStatistics}
          onRequestClose={() => {
            setCurrentStatistics(null);
            setCurrentBinSize(0);
          }}
          style="center"
        >
          <VStack alignItems={'flex-start'} height={'full'} w={'full'}>
            <Heading as="h2" size="md" mb={4}>
              <HStack>
                <Text>
                  Distribution of values for {currentStatistics.name}{' '}
                </Text>
                <Text color="neutral.500">({currentStatistics.type})</Text>
              </HStack>
            </Heading>
            {/* Selector is only available when the dataset has collected the new 'histograms' field */}
            {currentStatistics.type === 'Numeric' &&
              currentStatistics.histograms && (
                <HStack>
                  <Text>Select a bin size for the histogram:</Text>
                  <HistogramBinSizeButtons
                    currentBinSize={currentBinSize}
                    onButtonClick={setCurrentBinSize}
                  />
                </HStack>
              )}
            <Histogram
              statistics={currentStatistics}
              compact={false}
              binSize={currentBinSize}
            />
          </VStack>
        </ContentModal>
      )}
      <DataTable
        data={tableData}
        columns={tableColumns}
        enableSort={true}
        getHeaderProps={getHeaderProps}
      />
    </>
  );
}
