import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { TProject } from '../../models';

interface UseValidationGuidelineHookProps {
  project: TProject;
  content_id: string;
}
export default function useValidationGuideline({
  project,
  content_id,
}: UseValidationGuidelineHookProps) {
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(
    ['project', project.cuid, 'validation_guideline', content_id],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetValidationGuideline(accessToken, project, content_id);
    },
    {
      retry: false,
    },
  );

  return { testResult: queryResponse.data, ...queryResponse };
}
