import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { Template } from '../../models';

interface UseTemplatesOptions {
  includeCopies: boolean;
}

function useTemplates({ includeCopies }: UseTemplatesOptions) {
  const { getAccessTokenSilently } = useAuth0();
  const [templates, setTemplates] = useState<Template[]>();

  const queryResponse = useQuery(
    ['templates'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetTemplates(accessToken, includeCopies);
    },
    {
      onSuccess: (templates_: Template[]) => {
        setTemplates(templates_);
      },
    },
  );

  return { templates, ...queryResponse };
}

function useTemplateVersion(
  templateId: string,
  versionId: string = 'latest',
  enabled: boolean,
) {
  const { getAccessTokenSilently } = useAuth0();
  const [template, setTemplate] = useState<Template>();
  const queryResponse = useQuery(
    ['template', templateId, 'version', versionId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetTemplateVersion(accessToken, templateId, versionId);
    },
    {
      onSuccess: (template_: Template) => {
        setTemplate(template_);
      },
      enabled,
    },
  );

  return { template, ...queryResponse };
}

export { useTemplates, useTemplateVersion };
