import { TemplateSectionContents } from '../../../models/template';
import { SectionContentsProps } from '../../Layout/DocumentationPage';
import { DatasetSummaryTable } from './DynamicContentTemplates';

export const DynamicComponents: {
  [key: string]: React.FC<SectionContentsProps>;
} = {
  'validmind.data_validation.DatasetDescription': DatasetSummaryTable,
};

/**
 * Checks if there is a dynamic content template for the given contents and
 * returns the content_id that we can use to load the template.
 */
export const getDynamicContentId = (contents: TemplateSectionContents) => {
  const { content_id } = contents;

  if (content_id in DynamicComponents) {
    return content_id;
  }

  return null;
};

export function DynamicContent({ contents }: SectionContentsProps) {
  const { content_id, content_type } = contents;

  if (content_id in DynamicComponents) {
    const Component = DynamicComponents[content_id as keyof object];
    return <Component contents={contents} />;
  }

  console.log(
    `Unsupported dynamic content template (content_id=${content_id}, content_type=${content_type})`,
  );
  return null;
}
