import React, { useContext } from 'react';
import { JSONSchema7 } from 'json-schema';
import UsersContext from '../../../../contexts/UsersContext';
import {
  TInventoryModelCustomField,
  TInventoryModelCustomFieldOptionalSchemaAndSettings,
  RJSFSchemaSettingsProperty,
} from '../../../../models/inventory_model';
import { getSchemaPropertyByKey } from '../../../../utils';
import BooleanFieldDisplay from './BooleanFieldDisplay';
import StringFieldDisplay from './StringFieldDisplay';
import ArrayFieldDisplay from './ArrayFieldDisplay';
import UserFieldDisplay from './UserFieldDisplay';
import DateFieldDisplay from './DateFieldDisplay';
import { FieldDisplayProps } from '../index';
import URIFieldDisplay from './URIFieldDisplay';
import TieringFieldDisplay from './TieringFieldDisplay';
import BusinessUnitFieldDisplay from './BusinessUnitFieldDisplay';
import UseCaseFieldDisplay from './UseCaseFieldDisplay';
import HTMLFieldDisplay from './HTMLFieldDisplay';
import { FieldTypeId } from '../../../../models/json_schemas';

type FieldComponentType = React.FC<FieldDisplayProps>;

const DISPLAY_FIELD_COMPONENTS: Record<string, FieldComponentType> = {
  [FieldTypeId.Date]: DateFieldDisplay,
  [FieldTypeId.Uri]: URIFieldDisplay,
  [FieldTypeId.MultipleSelect]: ArrayFieldDisplay,
  [FieldTypeId.DateTime]: DateFieldDisplay,
  [FieldTypeId.Checkbox]: BooleanFieldDisplay,
  [FieldTypeId.MultiLine]: StringFieldDisplay,
  [FieldTypeId.SingleLine]: StringFieldDisplay,
  [FieldTypeId.HTML]: HTMLFieldDisplay,
  [FieldTypeId.Tiering]: TieringFieldDisplay,
  [FieldTypeId.BusinessUnit]: BusinessUnitFieldDisplay,
  [FieldTypeId.UseCase]: UseCaseFieldDisplay,
  [FieldTypeId.Number]: StringFieldDisplay,
  User: UserFieldDisplay,
};

interface CustomFieldDisplayProps {
  customField: TInventoryModelCustomFieldOptionalSchemaAndSettings;
}

const CustomFieldDisplay = ({ customField }: CustomFieldDisplayProps) => {
  // CustomFieldDisplay can be used in two different contexts:
  // 1. When the custom field comes with its entire context: key, value, schema and settings.
  // 2. When the custom field comes only with key and value, the schema and settings are fetched from
  // the organization's custom fields and enriched down the tree.

  // Schema and settings must be found on the organization's custom fields
  const { currentOrganization } = useContext(UsersContext);
  const defaultSchema = currentOrganization?.inventory_model_schema?.custom
    .schema.properties?.[customField.key] as JSONSchema7;
  const defaultSettings = currentOrganization?.inventory_model_schema?.custom
    .settings.properties?.[customField.key] as RJSFSchemaSettingsProperty;

  // Ensuring the existence of schema and settings down the tree
  customField.schema = customField.schema || {
    properties: { [customField.key]: defaultSchema },
  };
  customField.settings = customField.settings || {
    properties: { [customField.key]: defaultSettings },
  };

  const [property, settings] = getSchemaPropertyByKey(
    customField as TInventoryModelCustomField,
    customField.key,
  );

  const fieldType = settings?.entity || settings.typeId;

  const FieldTypeComponent =
    DISPLAY_FIELD_COMPONENTS[fieldType as keyof object] || StringFieldDisplay;

  return (
    <FieldTypeComponent field={customField as TInventoryModelCustomField} />
  );
};

export default CustomFieldDisplay;
