import { FieldEditProps } from '../../../CustomField';
import BusinessUnitsSelect from '../../../../ModelInventoryInputs/BusinessUnitsSelect';
import { useEffect, useState } from 'react';
import { TBusinessUnit } from '../../../../../models/business_unit';
import CustomFieldEditLayout from '../CustomFieldEditLayout';

const BusinessUnitFieldEdit = ({
  field,
  onEdit,
  onCancel,
  onChange,
}: FieldEditProps) => {
  const [selected, setSelected] = useState<TBusinessUnit>();

  useEffect(() => {
    if (field.value) {
      const businessUnit = field.value as TBusinessUnit;
      setSelected(businessUnit);
    }
  }, [field]);

  const onBusinessUnitChange = (businessUnit?: TBusinessUnit) => {
    onChange?.({ business_unit: businessUnit?.cuid || null });
    setSelected(businessUnit);
  };

  const onSave = () => {
    onEdit({ business_unit: selected?.cuid || null });
  };

  return (
    <CustomFieldEditLayout
      onCancelClick={onCancel}
      onSaveClick={onSave}
      showEditControls={!!onChange}
    >
      <BusinessUnitsSelect
        businessUnit={selected}
        setBusinessUnit={onBusinessUnitChange}
      />
    </CustomFieldEditLayout>
  );
};

export default BusinessUnitFieldEdit;
