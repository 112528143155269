import API from '../../api/API';

export class RevisionHistoryAdapter {
  // This only listens the revisions when they are added or modified.
  private editor: any;
  constructor(editor: any) {
    this.editor = editor;
  }

  static get pluginName() {
    return 'RevisionHistoryAdapter';
  }

  static get requires() {
    return ['RevisionHistory'];
  }

  async init() {
    const revisionHistory = this.editor.plugins.get('RevisionHistory');
    const {
      onEditorAutoSave,
      toast,
      getAccessTokenSilently,
      project,
      metadata,
    } = this.editor.config._config.extraContext;

    if (!metadata) {
      return;
    }

    revisionHistory.adapter = {
      updateRevisions: async (revisionsData: any) => {
        // console.log('RevisionHistoryAdapter.updateRevisions', revisionsData);
        // We notify to the user the revision is saved, also we trigger the autosave.

        // Revision can be added by the editor or by the user.
        // Detect when is added by the user to show a message and trigger the autosave.
        const isAddedByUser = revisionsData.some(
          (r: any) =>
            r.hasOwnProperty('name') &&
            r.name !== '' &&
            r.name !== 'Empty document',
        );

        if (isAddedByUser) {
          await onEditorAutoSave(this.editor);
        }

        return Promise.resolve();
      },
    };

    const accessToken = await getAccessTokenSilently();
    let revisions = await API.GetRevisions(accessToken, project, metadata.cuid);

    for (const revisionData of revisions) {
      revisionHistory.addRevisionData(revisionData);
    }
  }
}
