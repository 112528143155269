import React from 'react';
import { Outlet } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadingContainer } from '../LoadingContainer';

export const PrivateRoute = () => <Outlet />;

const Auth0PrivateRoute = React.memo(
  withAuthenticationRequired(PrivateRoute, {
    onRedirecting: () => (
      <LoadingContainer isLoading={true}>Loading...</LoadingContainer>
    ),
  }),
);

export { Auth0PrivateRoute };
