import { Template } from '../../models';
import { TemplateVersion } from '../../models/template';
import { Select } from '@chakra-ui/react';
import React from 'react';
import { truncateString } from '../../utils';

interface SelectTemplateVersionProps {
  template: Template;
  selectedVersion: TemplateVersion;
  onSelectVersion: (versionCuid: string) => void;
  includeDescription?: boolean;
}

export default function SelectTemplateVersion({
  template,
  selectedVersion,
  onSelectVersion,
  includeDescription = false,
}: SelectTemplateVersionProps) {
  return (
    <Select
      onChange={e => onSelectVersion(e.target.value)}
      value={selectedVersion && selectedVersion.cuid}
    >
      <option selected hidden disabled value="">
        Select version
      </option>
      {(template?.versions || []).map((v, index) => (
        <option value={v.cuid} key={v.cuid}>
          {v.version_number} {index === 0 ? '(Latest)' : ''}
          {includeDescription && `- ${truncateString(v.description, 30)}`}
        </option>
      ))}
    </Select>
  );
}
