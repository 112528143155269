import { MetadataContentEditor } from '../Templates/ModelDocumentation/MetadataContentEditor';
import { TextContentEditor } from './TextContentEditor';

export interface CKEditorFeatures {
  images: boolean;
  comments: boolean;
  revisions: boolean;
  deleteBlock: boolean;
  generateWithAI: boolean;
}

const toolbarItems = {
  items: [
    'undo',
    'redo',
    '|',
    'heading',
    'bold',
    'italic',
    'link',
    'alignment',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    'code',
    'codeBlock',
    'MathType',
    'insertImage',
    '|',
    'comment',
    'commentsArchive',
    'trackChanges',
    'revisionHistory',
    'saveIndicator',
    '|',
    'deleteBlock',
    '|',
    'generateWithAI',
  ],
  shouldGroupWhenFull: true,
};

const toolbarItems2 = {
  items: [
    'undo',
    'redo',
    '|',
    'heading',
    'bold',
    'italic',
    'strikethrough',
    '|',
    'link',
    'alignment',
    'bulletedList',
    'numberedList',
    'todoList',
    '|',
    'blockQuote',
    'codeBlock',
  ],
  shouldGroupWhenFull: true,
};

const generateToolbarItems = (enabledFeatures: CKEditorFeatures) => {
  const items = [
    'undo',
    'redo',
    '|',
    'heading',
    'bold',
    'italic',
    'link',
    'alignment',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    'code',
    'codeBlock',
    'MathType',
  ];

  if (enabledFeatures.images) {
    items.push('insertImage');
  }

  if (enabledFeatures.comments || enabledFeatures.revisions) {
    items.push('|');
    if (enabledFeatures.comments) {
      items.push('comment', 'commentsArchive', 'trackChanges');
    }
    if (enabledFeatures.revisions) {
      items.push('revisionHistory');
    }
  }

  items.push('saveIndicator');

  if (enabledFeatures.deleteBlock) {
    items.push('|', 'deleteBlock');
  }

  if (enabledFeatures.generateWithAI) {
    items.push('|', 'generateWithAI');
  }

  return {
    items: items,
    shouldGroupWhenFull: true,
  };
};

// @ts-ignore - property 'ClassicEditor' exists on this window object,
// it is created with https://github.com/validmind/ckeditor5-build-validmind and
// made available on document load via script tag on index.html
const ClassicEditor = window.ClassicEditor;

const ENABLE_CKEDITOR_INSPECTOR = false;

export {
  MetadataContentEditor,
  TextContentEditor,
  toolbarItems,
  toolbarItems2,
  ClassicEditor,
  generateToolbarItems,
  ENABLE_CKEDITOR_INSPECTOR,
};
