import 'react-datepicker/dist/react-datepicker.css';

import {
  Box,
  Button,
  Center,
  Code,
  Flex,
  Heading,
  HStack,
  Icon,
  ListItem,
  Progress,
  SimpleGrid,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  UnorderedList,
  useColorModeValue,
  Wrap,
} from '@chakra-ui/react';
import { ContentPageTitle, PrimaryButton } from '../../components/Layout';
import { LoadingContainer } from '../../components/LoadingContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  TInventoryModel,
  TModelInventoryFilters,
} from '../../models/inventory_model';
import { useQuery } from 'react-query';
import ModelInventoryItem from '../../components/ModelInventoryItem';
import {
  allFiltersEmpty,
  convertRJSFToCustomFieldJSON,
  getSchemaPropertyByKey,
} from '../../utils';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  FunnelIcon,
} from '@heroicons/react/24/solid';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import {
  CheckIcon,
  CubeIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import ModelInventoryFilters from '../../components/ModelInventoryFilters';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APIRequestSortBy, AvailableSort } from '../../models/utils';
import { BarsArrowDownIcon } from '@heroicons/react/20/solid';
import ListingSearchButton from '../../components/ListingSearchButton';
import ListingSortButton from '../../components/ListingSortButton';
import { Copyright } from '../../components/Copyright';
import ModelInventoryAdd from '../../components/ModelInventoryAdd';
import { EmptyStateDisplay } from '../../components/EmptyStateDisplay';
import CubesIcon from '../../components/icons/CubesIcon';
import { Squares2X2Icon, TableCellsIcon } from '@heroicons/react/24/outline';
import { DataTable } from '../../components/DataTable';
import InventoryModelTierLevelBadge from '../../components/InventoryModelTierLevelBadge';
import GetStartedChecklist from '../../components/GetStartedChecklist';
import StatusBadge from '../../components/StatusBadge';

import useModelSchema from '../../hooks/useModelSchema';
import { isMissing } from '../../components/NewCustomFields/CustomFieldValidator';
import CustomFieldRenderer from '../../components/NewCustomFields/CustomFieldRenderer';
import TableColumnPicker from '../../components/TableColumnPicker';
import { useUserUISettings } from '../../hooks/useUserUISettings';
import AvatarProxy from '../../components/AvatarProxy';
import API from '../../api/API';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';

const availableSorts: AvailableSort[] = [
  {
    fieldLabel: 'Name',
    field: 'name',
    columnId: 'name',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Business Unit',
    field: 'business_unit.name',
    columnId: 'business_unit',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Use',
    field: 'use',
    columnId: 'use',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Version',
    field: 'version',
    columnId: 'version',
    criteria: [
      {
        orderLabel: 'Low to High',
        order: 'asc',
      },
      {
        orderLabel: 'Hight to Low',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Tier',
    field: 'tiering',
    columnId: 'tier',
    criteria: [
      {
        orderLabel: 'Low to High',
        order: 'asc',
      },
      {
        orderLabel: 'High to Low',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Status',
    field: 'status',
    columnId: 'status',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Creation date',
    field: 'created_at',
    columnId: 'created_at',
    criteria: [
      {
        orderLabel: 'Latest first',
        order: 'desc',
      },
      {
        orderLabel: 'Oldest first',
        order: 'asc',
      },
    ],
  },
  {
    fieldLabel: 'Vendor Name',
    field: 'vendor_name',
    columnId: 'vendor_name',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
];

export const MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }: any) => {
      return (
        <Text fontSize={'sm'} fontWeight={'bold'}>
          {row.values.name}
        </Text>
      );
    },
  },
  {
    id: 'business_unit',
    Header: 'Business Unit',
    accessor: 'business_unit.name',
    Cell: ({ value }: any) => {
      return <Text fontSize={'sm'}>{value}</Text>;
    },
  },
  {
    id: 'use',
    Header: 'Use',
    accessor: 'use',
    Cell: ({ value }: any) => {
      return <Text fontSize={'sm'}>{value}</Text>;
    },
  },
  {
    id: 'version',
    Header: 'Version',
    accessor: 'version',
    Cell: ({ value }: any) => {
      return <Code>{value}</Code>;
    },
  },
  {
    id: 'owner',
    Header: 'Owner',
    disableSortBy: true,
    accessor: (row: any) => row.users.filter((u: any) => u.name === 'Model Owner'),
    Cell: ({ value }: any) => {
      return (
        <Box>
          {value.map((user: any) => (
            <Tag key={user.user.cuid} size={'md'} borderRadius="full">
              <AvatarProxy
                src={user.user.picture}
                size="xs"
                name={user.user.name}
                ml={-2}
                mr={2}
              />
              <TagLabel>{user.user.name}</TagLabel>
            </Tag>
          ))}
        </Box>
      );
    },
  },
  {
    id: 'developers',
    Header: 'Developers',
    accessor: (row: any) =>
      row.users.filter((u: any) => u.name === 'Model Developer'),
    disableSortBy: true,
    Cell: ({ value }: any) => {
      return (
        <Box>
          {value.map((user: any) => (
            <Tag key={user.user.cuid} size={'md'} borderRadius="full">
              <AvatarProxy
                src={user.user.picture}
                size="xs"
                name={user.user.name}
                ml={-2}
                mr={2}
              />
              <TagLabel>{user.user.name}</TagLabel>
            </Tag>
          ))}
        </Box>
      );
    },
  },
  {
    id: 'validators',
    Header: 'Validators',
    accessor: (row: any) =>
      row.users.filter((u: any) => u.name === 'Model Validator'),
    disableSortBy: true,
    Cell: ({ value }: any) => {
      return (
        <Box>
          {value.map((user: any) => (
            <Tag key={user.user.cuid} size={'md'} borderRadius="full">
              <AvatarProxy
                src={user.user.picture}
                size="xs"
                name={user.user.name}
                ml={-2}
                mr={2}
              />
              <TagLabel>{user.user.name}</TagLabel>
            </Tag>
          ))}
        </Box>
      );
    },
  },
  {
    id: 'tier',
    Header: 'Tier',
    accessor: 'tiering',
    Cell: ({ value }: any) => {
      return <InventoryModelTierLevelBadge level={value} />;
    },
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }: any) => {
      return (
        <StatusBadge
          color={value ? value.colors.tertiary : 'default'}
          bg={value ? value.colors.primary : 'value'}
        >
          {value ? value.name : 'No status'}
        </StatusBadge>
      );
    },
  },
  {
    id: 'stage',
    Header: 'Stage',
    accessor: 'stage',
    Cell: ({ value }: any) => {
      return (
        <StatusBadge color={'default'} bg={'value'}>
          {value}
        </StatusBadge>
      );
    },
  },
  {
    id: 'is_vendor_model',
    Header: 'Is Vendor Model',
    accessor: 'is_vendor_model',
    disableSortBy: true,
    Cell: ({ value }: any) => {
      if (value) {
        return <Icon as={CheckIcon} boxSize={6} />;
      }
    },
  },
  {
    id: 'vendor_name',
    Header: 'Vendor Name',
    accessor: 'vendor_name',
    Cell: ({ value }: any) => {
      if (value) {
        return <Text fontSize={'sm'}>{value}</Text>;
      }
    },
  },
];

const initialSortBy: APIRequestSortBy = {
  label: 'Creation date',
  field: 'created_at',
  order: 'desc',
  orderLabel: 'Latest first',
};

const defaultColumns = MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS.map(c => c.id);

export default function ModelInventory() {
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);

  const filtersState = searchParams.get('filters');
  const register = searchParams.get('register');
  const [filters, setFilters] = useState<TModelInventoryFilters | undefined>(
    filtersState ? JSON.parse(decodeURIComponent(filtersState)) : undefined,
  );

  const {
    getModelInventoryColumns,
    updateModelInventoryColumns,
    getDisplayTableModel,
    updateDisplayTableModel,
  } = useUserUISettings();

  const storedColumnConfig = getModelInventoryColumns();

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    !!storedColumnConfig ? storedColumnConfig : defaultColumns,
  );

  const storedDisplayTable = getDisplayTableModel();

  const [displayTable, setDisplayTable] = useState(storedDisplayTable);

  const [sortBy, setSortBy] = useState<APIRequestSortBy | undefined>(
    initialSortBy,
  );

  const { isLoading, refetch, isRefetching, data } = useQuery(
    ['inventory-models'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetModelInventory(
        accessToken,
        filters,
        searchQuery,
        page,
        limit,
        sortBy,
      );
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  const {
    isLoading: loadingSchema,
    data: schemaData,
    propertyItems,
  } = useModelSchema();

  const inventoryModels = data?.results || [];

  const navigate = useNavigate();
  useEffect(() => {
    if (filters) {
      setPage(1);
      refetch();

      setSearchParams(
        `?filters=${encodeURIComponent(JSON.stringify(filters))}`,
      );
    }
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [sortBy]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [searchQuery]);

  const requiredCustomFields = useMemo(() => {
    if (schemaData) {
      const requiredSchemaKeys: string[] = [];

      Object.keys(schemaData.settings.properties).forEach(key => {
        if (schemaData.settings.properties[key].requiredOnRegistration) {
          requiredSchemaKeys.push(key);
        }
      });

      return requiredSchemaKeys.map(key => {
        const [schemaProperty, settingsProperty] = getSchemaPropertyByKey(
          schemaData,
          key,
        );
        return convertRJSFToCustomFieldJSON(
          key,
          schemaProperty,
          settingsProperty,
        );
      });
    }
    return [];
  }, [schemaData]);

  const getErrorTooltip = useCallback(
    (im: TInventoryModel) => {
      const { custom_fields } = im;
      const missingRequiredFields: string[] = [];

      if (custom_fields) {
        requiredCustomFields.forEach(requiredField => {
          const requiredEntry = custom_fields[requiredField.key];
          if (isMissing(requiredEntry)) {
            missingRequiredFields.push(requiredField.label);
          }
        });
      }

      let tooltip;

      if (missingRequiredFields.length > 0) {
        tooltip = (
          <>
            <Text mb={3}>Missing required fields: </Text>
            <UnorderedList>
              {missingRequiredFields.map(missingField => (
                <ListItem>{missingField}</ListItem>
              ))}
            </UnorderedList>
          </>
        );
      }

      return tooltip;
    },
    [requiredCustomFields],
  );

  // update table columns with selected custom fields
  const tableColumns = useMemo(() => {
    let tableColumns = [
      {
        Header: ' ',
        Cell: ({ row }: any) => {
          const errorTooltip = getErrorTooltip(row.original);

          return (
            <Box display="flex">
              <Icon boxSize={6} color={'neutral.400'} as={CubeIcon} />
              {!!errorTooltip && (
                <Tooltip label={errorTooltip} hasArrow p={4}>
                  <Icon
                    boxSize={5}
                    as={ExclamationCircleIcon}
                    color="red.500"
                    position="relative"
                    //transform={'translate(-10px, 15px)'}
                    top={2}
                    left={-2}
                  />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    ] as any[];

    selectedColumns.forEach(columnKey => {
      //determine if column is regular field
      const nonCustomField = MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS.find(
        a => a.id === columnKey,
      );

      if (nonCustomField) {
        tableColumns.push(nonCustomField);
      } else {
        // determine if column is custom field
        const customFieldSchema = propertyItems.find(p => p.key === columnKey);

        if (customFieldSchema) {
          tableColumns.push({
            id: customFieldSchema.key,
            Header: customFieldSchema.title,
            accessor: `custom_fields.${customFieldSchema.key}`,
            Cell: ({ value }: any) => {
              const schema = convertRJSFToCustomFieldJSON(
                customFieldSchema.key,
                null,
                customFieldSchema,
              );
              return (
                <CustomFieldRenderer
                  schema={schema}
                  value={value}
                  mode={'display'}
                  onChange={() => {}}
                />
              );
            },
            disableSortBy: true,
          });
        } else {
          console.error('Column not found', columnKey);
        }
      }
    });

    return tableColumns;
  }, [selectedColumns, propertyItems, requiredCustomFields]);

  const onTableSort = (column: any[]) => {
    if (column.length > 0) {
      const c = availableSorts.find(s => s.columnId === column[0].id)!;
      const order = column[0].desc ? 'desc' : 'asc';
      const orderLabel = c.criteria.find(o => o.order === order)?.orderLabel;

      setSortBy({
        label: c.fieldLabel,
        field: c.field,
        order,
        orderLabel,
      });
    } else {
      setSortBy(initialSortBy);
    }
  };

  const defaultTableSortBy = [
    {
      id: sortBy?.field || initialSortBy.field,
      desc: sortBy?.order === 'desc',
    },
  ];

  return (
    <Box
      py={10}
      px={8}
      flex={1}
      w={'full'}
      overflow="auto"
      className="no-scrollbar"
      bg={useColorModeValue('white', 'neutral.950')}
    >
      <LoadingContainer isLoading={isLoading || loadingSchema}>
        <Stack>
          <HStack>
            <Flex width={'full'} justify={'space-between'}>
              <HStack gap={5} pl={2} color={'inherit'}>
                <Icon as={CubesIcon} boxSize={10} />
                <ContentPageTitle>
                  Model Inventory
                  <MoreInfoPopOver
                    title="Model Inventory"
                    description="Track comprehensive details for all your models throughout the model lifecycle."
                    link="https://docs.validmind.ai/guide/model-inventory/working-with-model-inventory.html"
                    placement="right-end"
                    iconProps={{
                      ml: 2,
                      fill: 'brand.base',
                    }}
                  />
                </ContentPageTitle>
              </HStack>

              <ModelInventoryAdd
                open={!!register}
                requiredCustomFields={requiredCustomFields}
              />
            </Flex>
          </HStack>

          <HStack spacing={8} align={'stretch'} justifyContent={'end'}>
            <HStack>
              <ListingSearchButton
                term={searchQuery}
                setTerm={setSearchQuery}
                popOverTitle="Search by model name"
              />
              <ModelInventoryFilters
                filters={filters}
                setFilters={setFilters}
              />
              <TableColumnPicker
                selectedColumnIds={selectedColumns}
                allColumns={[
                  ...MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS.map(c => ({
                    id: c.id,
                    label: c.Header,
                  })),
                  ...propertyItems.map(p => ({ id: p.key, label: p.title })),
                ]}
                setColumns={newColumns => {
                  updateModelInventoryColumns(newColumns);
                  setSelectedColumns(newColumns as any);
                }}
              />

              <ListingSortButton
                sorts={availableSorts}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
              <PrimaryButton
                variant={'ghost'}
                onClick={() => {
                  updateDisplayTableModel(!displayTable);
                  setDisplayTable(!displayTable);
                }}
                data-testid="display-models-toggle"
              >
                {' '}
                <HStack mr={2}>
                  <Icon
                    as={TableCellsIcon}
                    boxSize={5}
                    display={!displayTable ? 'block' : 'none'}
                  />
                  <Icon
                    as={Squares2X2Icon}
                    boxSize={5}
                    display={displayTable ? 'block' : 'none'}
                  />
                </HStack>
                {displayTable ? 'Display Cards' : 'Display Table'}
              </PrimaryButton>
            </HStack>
          </HStack>

          <HStack
            px={5}
            alignItems={'flex-start'}
            gap={(filters && !allFiltersEmpty(filters)) || searchQuery ? 4 : 0}
          >
            {searchQuery && (
              <HStack margin={'0 !important'}>
                <Flex
                  alignItems={'center'}
                  gap={2}
                  color={'neutral.500'}
                  whiteSpace={'nowrap'}
                >
                  <Icon as={MagnifyingGlassIcon} width={5} height={5} />
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Searched:
                  </Text>
                  <Wrap>
                    <Tag key={searchQuery} size={'md'} borderRadius="full">
                      <TagLabel>
                        <strong>Model name contains: </strong>
                        {searchQuery}
                      </TagLabel>
                      <TagCloseButton onClick={() => setSearchQuery('')} />
                    </Tag>
                  </Wrap>
                </Flex>
              </HStack>
            )}
            {filters && !allFiltersEmpty(filters) && (
              <HStack alignItems={'flex-start'}>
                <Flex
                  alignItems={'center'}
                  gap={2}
                  color={'neutral.500'}
                  whiteSpace={'nowrap'}
                >
                  <Icon as={FunnelIcon} width={5} height={5} />
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Filtered by:
                  </Text>
                </Flex>

                <Flex>
                  {filters && (
                    <Wrap>
                      {filters.tiering &&
                        filters.tiering.map(t => (
                          <Tag key={t} size={'md'} borderRadius="full">
                            <TagLabel>
                              <strong>Tier:</strong> {t}
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  tiering: filters.tiering.filter(o => o !== t),
                                })
                              }
                            />
                          </Tag>
                        ))}
                      {filters.business_units &&
                        filters.business_units.map(u => (
                          <Tag key={u.cuid} size={'md'} borderRadius="full">
                            <TagLabel>
                              <strong>Business Unit:</strong> {u.name}
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  business_units:
                                    filters?.business_units.filter(
                                      o => o.cuid !== u.cuid,
                                    ),
                                })
                              }
                            />
                          </Tag>
                        ))}

                      {filters.status &&
                        filters.status.map(s => (
                          <Tag key={s.cuid} size={'md'} borderRadius="full">
                            <TagLabel>
                              <strong>Status:</strong> {s.name}
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  status: filters?.status.filter(
                                    o => o.cuid !== s.cuid,
                                  ),
                                })
                              }
                            />
                          </Tag>
                        ))}

                      {filters.uses &&
                        filters.uses.map(use => (
                          <Tag key={use} size={'md'} borderRadius="full">
                            <TagLabel>
                              <strong>Use:</strong> {use}
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  uses: filters?.uses.filter(o => o !== use),
                                })
                              }
                            />
                          </Tag>
                        ))}

                      {filters.owners &&
                        filters.owners.map(u => (
                          <Tag key={u.cuid} size={'md'} borderRadius="full">
                            <AvatarProxy
                              src={u.picture}
                              size="xs"
                              name={u.name}
                              ml={-1}
                              mr={2}
                            />
                            <TagLabel>
                              <strong>Owner:</strong> {u.name}
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  owners: filters?.owners.filter(
                                    o => o.cuid !== u.cuid,
                                  ),
                                })
                              }
                            />
                          </Tag>
                        ))}
                      {filters.developers &&
                        filters.developers.map(u => (
                          <Tag key={u.cuid} size={'md'} borderRadius="full">
                            <AvatarProxy
                              src={u.picture}
                              size="xs"
                              name={u.name}
                              ml={-1}
                              mr={2}
                            />
                            <TagLabel>
                              <strong>Developer:</strong> {u.name}
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  developers: filters?.developers.filter(
                                    o => o.cuid !== u.cuid,
                                  ),
                                })
                              }
                            />
                          </Tag>
                        ))}
                      {filters.validators &&
                        filters.validators.map(u => (
                          <Tag key={u.cuid} size={'md'} borderRadius="full">
                            <AvatarProxy
                              src={u.picture}
                              size="xs"
                              name={u.name}
                              ml={-1}
                              mr={2}
                            />
                            <TagLabel>
                              <strong>Validator:</strong> {u.name}
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  validators: filters?.validators.filter(
                                    o => o.cuid !== u.cuid,
                                  ),
                                })
                              }
                            />
                          </Tag>
                        ))}
                    </Wrap>
                  )}
                </Flex>
              </HStack>
            )}

            {sortBy && (
              <HStack margin={'0 !important'}>
                <Flex
                  alignItems={'center'}
                  gap={2}
                  whiteSpace={'nowrap'}
                  color={'neutral.500'}
                >
                  <Icon as={BarsArrowDownIcon} boxSize={5} />
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Sorted by:
                  </Text>

                  <Wrap>
                    {sortBy && (
                      <Tag key={sortBy.field} size={'md'} borderRadius="full">
                        <TagLabel>
                          <strong>{sortBy.label}: </strong>
                          {sortBy.orderLabel}
                        </TagLabel>
                      </Tag>
                    )}
                  </Wrap>
                </Flex>
              </HStack>
            )}
          </HStack>

          <Stack spacing={1} pt={isRefetching || isLoading ? 0 : 3}>
            {(isRefetching || isLoading) && (
              <Progress size={'xs'} isIndeterminate colorScheme={'brand'} />
            )}

            {data?.total === 0 && (
              <Center>
                <EmptyStateDisplay variant="no-models">
                  <Heading as={'h4'} fontSize={'md'}>
                    No models found
                  </Heading>
                  <Text align={'center'}>
                    We couldn't find any models with your search or filter
                    combination.
                    <br />
                    Please adjust your criteria and try again.
                  </Text>
                </EmptyStateDisplay>
              </Center>
            )}
            <SimpleGrid
              spacing={2}
              columns={{ base: 1, xl: 2, '2xl': 3 }}
              display={!displayTable ? 'grid' : 'none'}
            >
              {inventoryModels.map(im => {
                let tooltip;
                const errorTooltip = getErrorTooltip(im);

                if (errorTooltip) {
                  tooltip = (
                    <Tooltip label={errorTooltip} hasArrow p={4}>
                      <Icon
                        boxSize={6}
                        as={ExclamationCircleIcon}
                        color="red.500"
                        position="relative"
                        //transform={'translate(-10px, 15px)'}
                        top={3}
                        left={-3}
                      />
                    </Tooltip>
                  );
                }
                return (
                  <ModelInventoryItem
                    key={im.cuid}
                    inventoryModel={im}
                    tooltip={tooltip}
                  />
                );
              })}
            </SimpleGrid>

            <Stack
              display={
                displayTable && data && data?.total > 0 ? 'block' : 'none'
              }
              pt={isRefetching || isLoading ? 1 : 0}
            >
              <DataTable
                data={inventoryModels}
                columns={tableColumns}
                enableSort={true}
                pageSize={limit}
                isInteractive={true}
                onClickRow={(row: any) => {
                  navigate(`/model-inventory/${row.original.cuid}/overview`);
                }}
                manualSortBy={true}
                defaultSortBy={defaultTableSortBy}
                onSort={onTableSort}
              />
            </Stack>
          </Stack>

          {data?.results && data.results.length > 0 && (
            <Center>
              <HStack>
                <Button
                  leftIcon={<Icon as={ArrowLongLeftIcon} boxSize={5} />}
                  onClick={() => setPage(page - 1)}
                  visibility={page === 1 ? 'hidden' : 'visible'}
                  disabled={page === 1}
                >
                  Prev
                </Button>
                <Text fontSize={'sm'}>
                  Showing {(page - 1) * limit + 1} to{' '}
                  {Math.min(page * limit, data.total)} of {data.total} Models
                </Text>
                <Button
                  rightIcon={<Icon as={ArrowLongRightIcon} boxSize={5} />}
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  visibility={data.total / limit <= page ? 'hidden' : 'visible'}
                  disabled={data.total / limit <= page}
                >
                  Next
                </Button>
              </HStack>
            </Center>
          )}
        </Stack>
      </LoadingContainer>
      <Copyright />
      <GetStartedChecklist />
    </Box>
  );
}
