export function getFeedItems(queryText: string, items: any[]) {
  // As an example of an asynchronous action, return a promise
  // that resolves after a 100ms timeout.
  // This can be a server request or any sort of delayed action.
  return new Promise(resolve => {
    setTimeout(() => {
      const itemsToDisplay = items
        // Filter out the full list of all items to only those matching the query text.
        .filter(isItemMatching)
        // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
        .slice(0, 10);

      resolve(itemsToDisplay);
    }, 100);
  });

  // Filtering function - it uses the `name` and `username` properties of an item to find a match.
  function isItemMatching(item: any) {
    // Make the search case-insensitive.
    const searchString = queryText.toLowerCase();

    // Include an item in the search results if the name or username includes the current user input.
    return item.text.toLowerCase().includes(searchString);
  }
}

export function customItemRenderer(item: any) {
  const itemElement = document.createElement('span');

  itemElement.classList.add('custom-item');
  itemElement.id = `mention-list-item-id-${item.id}`;
  itemElement.textContent = `${item.name} `;

  const usernameElement = document.createElement('span');

  usernameElement.classList.add('custom-item-username');
  usernameElement.textContent = '(Role id)';

  itemElement.appendChild(usernameElement);

  return itemElement;
}
