import { Handle, NodeProps, Position } from 'reactflow';
import {
  Box,
  CircularProgress,
  HStack,
  Icon,
  ListItem,
  Stack,
  Tag,
  TagLabel,
  Text,
  UnorderedList,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useStatus } from '../../../hooks/useStatuses';
import { generateRandomCodeForNodes } from '../../../../../../utils';
import NodeBox from '../../layouts/NodeBox';
import StatusBadge from '../../../../../../components/StatusBadge';
import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { PrimaryButton } from '../../../../../../components/Layout';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { StatusFormNodeType } from '../../types';

function StatusFormNode(props: NodeProps) {
  const statusCuid = props.data.state_callbacks.on_enter[0].args.cuid;
  const statusEntity = props.data.state_callbacks.on_enter[0].args.entity;
  const { setSelectedNodeId } = useWorkflow();

  if (!statusCuid || !statusEntity) {
    return (
      <NodeBox
        title={'USER ACTION'}
        icon={CursorArrowRaysIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <HStack px={2} pb={2} w={'full'}>
          <PrimaryButton
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            size={'sm'}
            w={'full'}
          >
            Configure
          </PrimaryButton>
        </HStack>
      </NodeBox>
    );
  }

  const { data: status, isLoading } = useStatus(statusCuid);

  if (isLoading) {
    return (
      <NodeBox
        title={'USER ACTION'}
        icon={CursorArrowRaysIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <Stack justify={'center'} align={'center'}>
          <CircularProgress
            size="40px"
            thickness="2px"
            isIndeterminate
            color="brand.base"
          />
          <Text fontSize={'xs'} color={'inherit'}>
            Please hold...
          </Text>
        </Stack>
      </NodeBox>
    );
  }

  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-neutral-200)',
      'var(--chakra-colors-neutral-800)',
    ),
    borderColor: useColorModeValue(
      'var(--chakra-colors-neutral-50)',
      'var(--chakra-colors-neutral-500)',
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 16,
    width: 16,
  };

  return (
    <NodeBox
      onDoubleClick={() => setSelectedNodeId!(props.id)}
      title={'USER ACTION'}
      icon={CursorArrowRaysIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
      p={0}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...DEFAULT_HANDLE_STYLES, top: -8 }}
      />
      <HStack alignItems={'flex-start'} px={2}>
        <VStack gap={2} alignItems={'flex-start'}>
          <Box pb={2}>
            <VStack
              p={4}
              gap={0}
              w={'full'}
              rounded={'md'}
              alignItems={'start'}
              bg={useColorModeValue('neutral.100', 'neutral.850')}
            >
              <Text
                w={'full'}
                whiteSpace={'normal'}
                textAlign={'left'}
                fontWeight={'bold'}
              >
                {props.data.form.buttonLabel}
              </Text>
              <Text fontSize={'xs'}>
                Transitions {/* TODO: support validation report */}
                {statusEntity === 'InventoryModel' ? 'Model' : 'Document'} to
              </Text>
              <StatusBadge
                bg={status!.colors.primary}
                color={status!.colors.tertiary}
              >
                {status!.name}
              </StatusBadge>
            </VStack>
            {props.data.form.required_fields.length > 0 && (
              <VStack alignItems={'flex-start'}>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  Request fields:
                </Text>
                <UnorderedList>
                  {props.data.form.required_fields.map((field: string) => {
                    return (
                      <ListItem>
                        <Tag
                          key={field}
                          fontSize={'xs'}
                          fontFamily={'monospace'}
                          colorScheme="purple"
                        >
                          <TagLabel>{field}</TagLabel>
                        </Tag>
                      </ListItem>
                    );
                  })}
                </UnorderedList>
              </VStack>
            )}
          </Box>
        </VStack>
      </HStack>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...DEFAULT_HANDLE_STYLES, bottom: -8 }}
      />
    </NodeBox>
  );
}

StatusFormNode.type = 'status_form';
StatusFormNode.autoRuns = false;

StatusFormNode.getDefaultNode = (): StatusFormNodeType => {
  return {
    id: `${StatusFormNode.type}_${generateRandomCodeForNodes()}`,
    type: StatusFormNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_save_status',
            args: { entity: null, cuid: null },
          },
          {
            func: 'run_save_fields',
            args: {
              required_fields: [],
            },
          },
        ],
        on_exit: [],
      },
      form: {
        required_fields: [],
        buttonLabel: 'Submit',
      },
      transition_callbacks: {
        prepare: [
          {
            func: 'validate_form',
            args: {
              required_fields: [],
            },
          },
        ],
        conditions: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default StatusFormNode;
