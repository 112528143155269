import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFlags } from '../../../../hooks/useFlags';
import MasterPage from '../../../../components/Layout/MasterPage';
import MasterDetails from '../../../../components/Layout/MasterDetails';
import { LoadingContainer } from '../../../../components/LoadingContainer';
import RecordDetailsPage from '../../../../components/Layout/RecordDetailsPage';
import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Button,
  Divider,
  HStack,
  Heading,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Label } from '../../../../components/Layout';
import { useContext, useEffect, useState } from 'react';
import MasterSearchBar from '../../../../components/Layout/MasterSearchBar';
import MasterHeader from '../../../../components/Layout/MasterHeader';
import { useTemplateVersion } from '../../../../hooks/useTemplates';
import _ from 'lodash';
import { displayFormatedDateAndTime } from '../../../../utils';
import {
  TemplateVersion,
  Template as TemplateType,
} from '../../../../models/template';
import { CopyIcon } from '@chakra-ui/icons';
import TemplateVersionModels from '../../../../components/TemplateVersionModels';
import TemplateCompareModal from '../../../../components/TemplateCompareModal';
import SelectTemplateVersion from '../../../../components/SelectTemplateVersion';
import TemplateEditModal from '../../../../components/TemplateEditModal';
import TemplateDuplicateModal from '../../../../components/TemplateDuplicateModal';
import AttributesRail from '../../../../components/Layout/AttributesRail';
import { TemplateEditorProvider } from '../../../../components/TemplateEditor/Provider';
import TemplateEditorTree from '../../../../components/TemplateEditor/TemplateEditorTree';
import TemplateEditorPublisher from '../../../../components/TemplateEditor/TemplateEditorPublisher';
import { UsersContext } from '../../../../contexts';
import AvatarProxy from '../../../../components/AvatarProxy';

const ModelsUsingThisTemplate = ({
  template,
  documentType,
}: {
  template: TemplateType;
  documentType: string;
}) => {
  const [allEmpty, setAllEmpty] = useState(true);
  const [loadedCount, setLoadedCount] = useState(0);

  const handleEmptyCheck = (isEmpty: boolean) => {
    setLoadedCount(prevCount => prevCount + 1);
    setAllEmpty(prev => prev && isEmpty);
  };

  const allVersionsLoaded =
    template?.versions && loadedCount === template.versions.length;

  // TODO: Next version fetch all versions at once to avoid fetching over all versions
  //  inside the TemplateVersionModels component:

  return (
    <Stack>
      {(template?.versions || []).map(v => (
        <TemplateVersionModels
          documentType={documentType}
          template={template!}
          templateVersion={v}
          onEmptyCheck={handleEmptyCheck}
        />
      ))}

      {allVersionsLoaded && allEmpty && (
        <Text>There are currently no Models using this template.</Text>
      )}
    </Stack>
  );
};

export default function Template() {
  const { experimentTemplateNewEditingExperience } = useFlags();
  const { id, documentType, versionId } = useParams();
  const { template, isLoading } = useTemplateVersion(id!, 'latest', true);
  const [selectedVersion, setSelectedVersion] = useState<TemplateVersion>();
  const { template: selectedTemplate } = useTemplateVersion(
    id!,
    selectedVersion?.version_number,
    versionId !== 'latest',
  );
  const [showCompareVersion, setShowCompareVersion] = useState(false);
  const [showEditVersion, setShowEditVersion] = useState(false);
  const [showDuplicateVersion, setShowDuplicateVersion] = useState(false);
  const isLatestTemplateSelected =
    selectedVersion?.cuid === template?.current_version?.cuid;
  const navigate = useNavigate();

  useEffect(() => {
    if (template) {
      let selectedVersion = template.current_version;
      if (versionId && versionId !== 'latest') {
        selectedVersion = (template.versions || []).find(
          v => v.cuid === versionId,
        );
      }
      setSelectedVersion(selectedVersion);
    }
  }, [versionId, template]);

  const onSelectVersion = (versionCuid: string) => {
    navigate(
      `/settings/templates/${template!.cuid}/${documentType}/${versionCuid}`,
    );
  };

  const { userHasPermission, currentOrganization } = useContext(UsersContext);

  const canCreateOrDuplicateTemplate = userHasPermission(
    ['create_template', 'duplicate_template'],
    'any',
  );

  return (
    <MasterPage>
      <MasterDetails>
        <LoadingContainer isLoading={isLoading}>
          <RecordDetailsPage
            right={
              <AttributesRail>
                <VStack align="start" spacing={0}>
                  <Label>Template Type</Label>
                  <Text>{_.startCase(documentType!.replace('_', ' '))}</Text>
                </VStack>

                <VStack align="start" spacing={0}>
                  <Label>Description</Label>

                  <Text>
                    {
                      template?.versions?.[template?.versions?.length - 1]
                        ?.description
                    }
                  </Text>
                </VStack>

                <VStack align="start" spacing={1}>
                  <Label>Owner</Label>
                  <HStack spacing={2}>
                    <AvatarProxy
                      size="sm"
                      name={template?.current_version?.created_by?.name}
                      src={template?.current_version?.created_by?.picture}
                    />
                    <Stack spacing={0}>
                      <Text>{template?.current_version?.created_by?.name}</Text>
                    </Stack>
                  </HStack>
                </VStack>

                <VStack align="start" spacing={1}>
                  <Label>Template Created</Label>

                  <Badge px={'2'} py={'0.5'} rounded={'md'}>
                    {displayFormatedDateAndTime(template?.created_at)}
                  </Badge>
                </VStack>

                <VStack align="start" spacing={1}>
                  <Label>Models Using This Template</Label>
                  <ModelsUsingThisTemplate
                    documentType={documentType!}
                    template={template!}
                  />
                </VStack>
                {canCreateOrDuplicateTemplate && (
                  <>
                    <Divider />
                    <Button
                      variant={'ghost'}
                      onClick={() => setShowDuplicateVersion(true)}
                      leftIcon={<CopyIcon />}
                    >
                      Duplicate Template
                    </Button>
                  </>
                )}
              </AttributesRail>
            }
          >
            <VStack w={'full'}>
              <HStack w={'full'}>
                <MasterSearchBar />
              </HStack>
              <MasterHeader>
                <VStack align={'stretch'}>
                  <Breadcrumb
                    fontWeight="medium"
                    fontSize="sm"
                    color="neutral.500"
                  >
                    <BreadcrumbItem>
                      <BreadcrumbLink as={RouterLink} to={`/settings`}>
                        Settings
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        as={RouterLink}
                        to={`/settings/templates`}
                      >
                        Templates
                      </BreadcrumbLink>
                      <BreadcrumbSeparator />
                    </BreadcrumbItem>
                  </Breadcrumb>
                  <Text
                    fontSize={'3xl'}
                    fontWeight="semibold"
                    data-testid="MasterStatusBarText"
                  >
                    {template?.name}
                  </Text>

                  {selectedVersion && (
                    <Box pt={8}>
                      <Stack divider={<StackDivider />} spacing={12}>
                        <Stack spacing={8}>
                          <HStack spacing={4}>
                            <Stack w={'full'} gap={0}>
                              <Heading fontSize={'lg'}>
                                Template version
                              </Heading>
                              <HStack>
                                <SelectTemplateVersion
                                  template={template!}
                                  selectedVersion={selectedVersion!}
                                  onSelectVersion={onSelectVersion}
                                />
                                {!isLatestTemplateSelected && (
                                  <Button
                                    minWidth={'auto'}
                                    onClick={() => setShowCompareVersion(true)}
                                  >
                                    Compare to latest version
                                  </Button>
                                )}
                              </HStack>
                            </Stack>
                          </HStack>

                          {selectedVersion.version_number.toString() !==
                            '1' && (
                            <HStack>
                              <Box flex={1}>
                                <Label>Owner</Label>
                                <HStack spacing={2}>
                                  <AvatarProxy
                                    size="sm"
                                    name={selectedVersion.created_by?.name}
                                    src={selectedVersion.created_by?.picture}
                                  />
                                  <Stack spacing={0}>
                                    <Text>
                                      {selectedVersion.created_by?.name}
                                    </Text>
                                  </Stack>
                                </HStack>
                              </Box>
                              <Box flex={1}>
                                <Label>Version Created</Label>
                                <Badge px={'2'} py={'0.5'} rounded={'md'}>
                                  {displayFormatedDateAndTime(
                                    selectedVersion.created_at,
                                  )}
                                </Badge>
                              </Box>
                            </HStack>
                          )}
                        </Stack>
                        {selectedVersion.version_number.toString() !== '1' && (
                          <Stack>
                            <Heading fontSize={'lg'}>Version Notes</Heading>
                            <Text>{selectedVersion.description}</Text>
                          </Stack>
                        )}
                        <Stack>
                          <Heading fontSize={'lg'}>
                            Models Using This Version
                          </Heading>
                          <TemplateVersionModels
                            documentType={documentType!}
                            template={template!}
                            templateVersion={selectedVersion!}
                            variant={'table'}
                          />
                        </Stack>
                        {documentType && selectedTemplate && (
                          <TemplateEditorProvider
                            template={selectedTemplate}
                            type={documentType}
                          >
                            <Stack>
                              <HStack pb={4} justify={'space-between'}>
                                <Heading fontSize={'lg'}>Outline</Heading>
                                {(experimentTemplateNewEditingExperience as boolean) && (
                                  <TemplateEditorPublisher
                                    templateType={documentType}
                                    isLatestTemplateSelected={
                                      isLatestTemplateSelected
                                    }
                                    setShowEditVersion={setShowEditVersion}
                                  />
                                )}
                              </HStack>
                              <TemplateEditorTree />
                            </Stack>
                          </TemplateEditorProvider>
                        )}
                      </Stack>
                    </Box>
                  )}
                </VStack>
              </MasterHeader>
            </VStack>
          </RecordDetailsPage>
        </LoadingContainer>
      </MasterDetails>
      {documentType && template && selectedTemplate && (
        <>
          <TemplateCompareModal
            templateType={documentType}
            baseTemplate={selectedTemplate}
            rightVersion={template}
            open={showCompareVersion}
            onClose={() => setShowCompareVersion(false)}
          />
          <TemplateEditModal
            open={showEditVersion}
            templateType={documentType}
            baseTemplate={template}
            onClose={() => setShowEditVersion(false)}
          />
          <TemplateDuplicateModal
            open={showDuplicateVersion}
            templateType={documentType}
            baseTemplate={selectedTemplate}
            onClose={() => setShowDuplicateVersion(false)}
          />
        </>
      )}
    </MasterPage>
  );
}
