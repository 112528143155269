import type { MutableRefObject } from 'react';
import { TemplateSectionTree } from '../../../models/template';
import { arrayMove } from '@dnd-kit/sortable';

export interface EditorTemplateSectionTree extends TemplateSectionTree {
  collapsed?: boolean;
}

export type TemplateSectionTrees = TemplateSectionTree[];

export interface FlattenedTemplateSection extends EditorTemplateSectionTree {
  parent_section: string;
  depth: number;
  index: string;
}

export type SensorContext = MutableRefObject<{
  items: FlattenedTemplateSection[];
  offset: number;
}>;

export function toEditorTemplateSectionTrees(
  trees: TemplateSectionTree[],
): EditorTemplateSectionTree[] {
  return trees.map(tree => ({
    ...tree,
    collapsed: true,
    sections: tree.sections ? toEditorTemplateSectionTrees(tree.sections) : [],
  }));
}

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function removeSections(trees: TemplateSectionTrees, id: string) {
  const newTrees = [];

  for (const tree of trees) {
    if (tree.id === id) {
      continue;
    }

    if (tree.sections?.length) {
      tree.sections = removeSections(tree.sections, id);
    }

    newTrees.push(tree);
  }

  return newTrees;
}

export function getProjection(
  sections: FlattenedTemplateSection[],
  activeId: string,
  overId: string,
  dragOffset: number,
  indentationWidth: number,
) {
  const overItemIndex = sections.findIndex(({ id }) => id === overId);
  const activeItemIndex = sections.findIndex(({ id }) => id === activeId);
  const activeItem = sections[activeItemIndex];
  const newItems = arrayMove(sections, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parent_section;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find(item => item.depth === depth)?.parent_section;

    return newParent ?? null;
  }
}

function getMaxDepth({
  previousItem,
}: {
  previousItem: FlattenedTemplateSection;
}) {
  if (previousItem) {
    if (previousItem.contents && previousItem.contents.length > 0) {
      return previousItem.depth
    }

    if (previousItem.guidelines && previousItem.guidelines.length > 0) {
      return previousItem.depth
    }

    return previousItem.depth + 1;
  }

  return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedTemplateSection }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

function flatten(
  trees: TemplateSectionTrees,
  parentId: string | null = null,
  depth = 0,
  indexing = '',
): FlattenedTemplateSection[] {
  return trees.reduce<FlattenedTemplateSection[]>((acc, tree, index) => {
    const treeIdx = indexing ? indexing + `.${index + 1}` : `${index + 1}`;
    return [
      ...acc,
      { ...tree, parent_section: parentId ?? '', depth, index: treeIdx },
      ...(tree.sections
        ? flatten(tree.sections, tree.id, depth + 1, treeIdx)
        : []),
    ];
  }, []);
}

export function flattenTrees(
  items: TemplateSectionTrees,
): FlattenedTemplateSection[] {
  return flatten(items);
}

export function buildTree(
  flattenedSections: FlattenedTemplateSection[],
): EditorTemplateSectionTree[] {
  const root: EditorTemplateSectionTree = {
    id: 'root',
    sections: [],
    title: 'root',
    index: '0',
    parent_section: '',
  };
  const nodes: Record<string, EditorTemplateSectionTree> = { [root.id]: root };
  const items = flattenedSections.map(item => ({ ...item, sections: [] }));

  for (const item of items) {
    const { id, ...others } = item;
    const parentId = item.parent_section ? item.parent_section : root.id;
    const parent = nodes[parentId] ?? items.find(({ id }) => id === parentId);
    nodes[id] = { id, ...others };
    parent.sections?.push(item);
  }

  return root.sections || [];
}

export function setProperty<T extends keyof EditorTemplateSectionTree>(
  trees: EditorTemplateSectionTree[],
  id: string,
  property: T,
  setter: (value: EditorTemplateSectionTree[T]) => EditorTemplateSectionTree[T],
) {
  for (const tree of trees) {
    if (tree.id === id) {
      tree[property] = setter(tree[property]);
      continue;
    }

    if (tree.sections && tree.sections.length) {
      tree.sections = setProperty(tree.sections, id, property, setter);
    }
  }

  return [...trees];
}

export function removeChildrenOf(
  flattenedTemplateSections: FlattenedTemplateSection[],
  ids: string[],
) {
  const excludeParentIds = [...ids];

  return flattenedTemplateSections.filter(section => {
    if (
      section.parent_section &&
      excludeParentIds.includes(section.parent_section)
    ) {
      if (section.sections?.length) {
        excludeParentIds.push(section.id);
      }
      return false;
    }

    return true;
  });
}
