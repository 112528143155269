import { Node, useStoreApi } from 'reactflow';
import { panels } from '../../WorkflowCanvas';
import useWorkflow from '../../../../../../hooks/useWorkflow';

interface NodeSettingsProps {
  onAddNode: (node: Node) => void;
  onDeleteNode: (node: Node) => void;
}
const NodeSettings = ({ onAddNode, onDeleteNode }: NodeSettingsProps) => {
  const { selectedNodeId } = useWorkflow();
  const storeApi = useStoreApi();
  if (selectedNodeId) {
    const selectedNode = storeApi
      .getState()
      .getNodes()
      .find(node => node.id === selectedNodeId);
    if (selectedNode) {
      const Panel = panels[selectedNode.type as keyof object];
      if (Panel) {
        return (
          <Panel
            node={selectedNode}
            onAddNode={onAddNode}
            onDeleteNode={onDeleteNode}
          />
        );
      }
    }
  }

  return null;
};

export default NodeSettings;
