import { useState } from 'react';

export type FieldValues = {
  [key: string]: any;
};

export type FieldErrors = {
  [key: string]: string[];
};

export default function useFields(defaultState?: FieldValues) {
  const [fieldValues, setFieldValues] = useState<FieldValues>(
    defaultState || {},
  );
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const setFieldValue = (key: string, value: any) => {
    setFieldValues((oldValues: FieldValues) => {
      return {
        ...oldValues,
        [key]: value,
      };
    });
  };

  const setFieldError = (key: string, value: string[]) => {
    setFieldErrors((oldErrors: FieldValues) => {
      return {
        ...oldErrors,
        [key]: value,
      };
    });
  };

  return { fieldValues, fieldErrors, setFieldValue, setFieldError };
}
