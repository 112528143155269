import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

const CustomNode = ({
  data,
  isConnectable,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}: NodeProps) => {
  return (
    <>
      <Handle
        type="target"
        position={targetPosition}
        isConnectable={isConnectable}
      />
      {data?.label}
      <Handle
        id="next"
        type="source"
        position={sourcePosition}
        isConnectable={isConnectable}
      />
      <Handle
        id="return"
        type="source"
        position={Position.Left}
        isConnectable={isConnectable}
      />
    </>
  );
};

CustomNode.displayName = 'CustomNode';

export default memo(CustomNode);
