import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';

function useOrgRoles() {
  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, data: orgRoles } = useQuery(['roles'], async () => {
    const accessToken = await getAccessTokenSilently();
    const results = await API.GetOrganizationRoles(accessToken);
    return results;
  });

  return { orgRoles: orgRoles || [], isLoading };
}

export { useOrgRoles };
