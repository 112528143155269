import WorkflowCanvas from '../../components/WorkflowCanvas';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery } from 'react-query';
import API from '../../../../../api/API';
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import WorkflowContext from '../../../../../contexts/WorkflowContext';
import { useState } from 'react';

export default function WorkflowsPage() {
  const { workflowCuid, version } = useParams();
  const [selectedNodeId, setSelectedNodeId] = useState<string>();
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: workflow,
    isLoading,
    refetch,
  } = useQuery(
    ['me', 'organizations', 'workflows', workflowCuid, version],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetWorkflow(accessToken, workflowCuid!, version!);
    },
  );

  const saveSource = useMutation(
    ['me', 'organizations', 'workflows', workflowCuid, version],
    async (source: any) => {
      const accessToken = await getAccessTokenSilently();
      return API.PostWorkflowVersionSource(
        accessToken,
        workflowCuid!,
        version!,
        source!,
      );
    },
    {
      onSuccess: () => refetch(),
    },
  );

  return (
    <LoadingContainer isLoading={isLoading}>
      <WorkflowContext.Provider
        value={{ workflow, saveSource, selectedNodeId, setSelectedNodeId }}
      >
        <WorkflowCanvas />
      </WorkflowContext.Provider>
    </LoadingContainer>
  );
}
