import {
  BookOpenIcon,
  ClockIcon,
  CubeIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  MagnifyingGlassIcon,
  RocketLaunchIcon,
  ShieldCheckIcon,
  SignalIcon,
  TvIcon,
} from '@heroicons/react/24/outline';
import { Action, useKBar, useRegisterActions } from 'kbar';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../api/API';
import useDebounce from '../../hooks/useDebounce';
import { markdownToTxt } from '../../utils';
import { ProjectContext } from '../../contexts';
import {
  flattenPages,
  getSectionFromLookup,
  IMenuItem,
  TemplateSectionTree,
} from '../../models/template';
import { Icon } from '@chakra-ui/icons';
import InventoryModelContext from '../../contexts/InventoryModel';
import { TProject } from '../../models';
import _ from 'lodash';
import { ProjectTemplates } from '../../contexts/ProjectContext';

const getChildSections = (
  project: TProject,
  documentType: string,
  section: TemplateSectionTree,
  includeCondensed: boolean,
) => {
  const childSections =
    section.sections &&
    section.sections.length > 0 &&
    getProjectMenuItemsFromTemplate(
      project,
      documentType,
      section.sections,
      includeCondensed,
    );

  if (includeCondensed || !section.condensed) {
    return childSections;
  }

  return undefined;
};

/**
 * Results from search can contain routes for regular Metadata (text blocks)
 * or for text blocks that are part of a test driven block (metric description
 * or test description).
 *
 * Text blocks for test driven blocks will have a content_id like this:
 *
 *    metric_description:validmind.tests.model_validation.PermutationFeatureImportance
 */
const getURLForMetadataContentId = (
  projectId: string,
  urlPath: string,
  projectTemplates: ProjectTemplates,
) => {
  const pathParts = urlPath.split('/');
  const contentBlockParts = pathParts[pathParts.length - 1].replace('-', '_');
  const isDocumentationPage = pathParts[3] === 'documentation';
  const templateKey = isDocumentationPage
    ? 'documentation'
    : 'validation_report';

  // If contentBlockParts starts with "test-description:" then the link belongs to a text block
  // inside a test driven block. The `content_id` is the last part of the path after the colon.
  const isTestDrivenTextBlock =
    contentBlockParts.startsWith('test_description:') ||
    contentBlockParts.startsWith('metric_description:');

  // TODO: we need extra validation to ensure the content_id is valid and it belongs to the
  // current document.
  //
  // Test driven text block - just redirect to the URL and let the Project component
  // handle the rest with its useEffect hook.
  if (isTestDrivenTextBlock) {
    const resultTitle = contentBlockParts.split('.').pop();

    // Lower case and replace - and spaces with _ to match the content_id format
    const contentId = contentBlockParts.split(':')[1];
    const contentIdLowerCase = contentId.toLowerCase().replace(/[- ]/g, '_');

    // See Project.tsx#getContentBlockPath
    // This code needs to be refactored to avoid duplication
    //
    const templateSections = projectTemplates[templateKey].sections;
    // Look up the contentId in the template by finding a matching content_id inside the section `contents` array
    const section = templateSections.find(section =>
      section.contents?.find(
        content => content.content_id.toLowerCase() === contentIdLowerCase,
      ),
    );

    // Verify if the search result belongs to a content_id that is part of the
    // current documentation. Otherwise remove it from the list of results.
    if (!section) {
      return {
        contentUrl: '',
        pageTitle: '',
      };
    }

    return {
      contentUrl: urlPath,
      pageTitle: `Description for ${_.startCase(resultTitle)}`,
    };
  }

  // Regular text content_id
  const templateLookup = projectTemplates[templateKey].lookup;
  const templatePage = getSectionFromLookup(templateLookup!, contentBlockParts);

  // Verify if the search result belongs to a content_id that is part of the
  // current documentation. Otherwise remove it from the list of results.
  if (!templatePage) {
    return {
      contentUrl: '',
      pageTitle: '',
    };
  }

  const pageTitle = templatePage.title;
  // patch content_id in case the lookup matches with page module content id instead of a page id
  const content_id = templatePage.id;
  const contentUrl = `/projects/${projectId}/${templateKey}/${content_id?.replace(
    /_/g,
    '-',
  )}`;

  return { contentUrl, pageTitle };
};

export const getProjectMenuItemsFromTemplate = (
  project: TProject,
  documentType: string,
  sections: TemplateSectionTree[],
  includeCondensed = false,
): IMenuItem[] =>
  sections.map(
    section =>
      ({
        id: section.id,
        label: section.title,
        path: `/projects/${project.cuid}/${documentType}/${section.id.replace(
          /_/g,
          '-',
        )}`,
        index: section.index,
        condensed: section.condensed,
        indexOnly: section.index_only,
        children: getChildSections(
          project,
          documentType,
          section,
          includeCondensed,
        ),
      } as IMenuItem),
  );

export default function useProjectActions() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [searchResults, setSearchResults] = useState<Action[]>([]);
  const { project, templates } = useContext(ProjectContext);
  const { inventoryModel } = useContext(InventoryModelContext);
  const [searchActions, setSearchActions] = useState<Action[]>([]);
  const [rootSearchActions, setRootSearchActions] = useState<Action[]>([]);

  useEffect(() => {
    if (!inventoryModel) {
      return;
    }
    const projectID = inventoryModel.projects[0];
    const rootSearchActions: Action[] = [
      {
        id: 'model-overview',
        name: `Model Overview`,
        icon: <Icon as={CubeIcon} boxSize={6} />,
        keywords: 'overview summary',
        perform: () => navigate(`/model-inventory/${id}/overview`),
      },
      {
        id: 'document-library',
        name: 'Documentation',
        icon: <Icon as={BookOpenIcon} boxSize={6} />,
        keywords: 'documentation',
        perform: () => navigate(`/projects/${projectID}/project-overview`),
      },
      {
        id: 'validation-report',
        name: 'Validation Report',
        icon: <Icon as={ShieldCheckIcon} boxSize={6} />,
        keywords: 'validation report',
        perform: () =>
          navigate(
            `/projects/${projectID}/validation-report/executive-summary`,
          ),
      },
      {
        id: 'ongoing-monitoring',
        name: 'Ongoing Monitoring',
        icon: <Icon as={TvIcon} boxSize={6} />,
        keywords: 'Ongoing Monitoring',
        perform: () => navigate(`/projects/${id}/monitoring/overview`),
      },
      {
        id: 'model-findings',
        name: 'Model Findings',
        icon: <Icon as={ExclamationTriangleIcon} boxSize={6} />,
        keywords: 'findings',
        perform: () => navigate(`/model-inventory/${id}/findings`),
      },
      {
        id: 'audit-trail',
        name: 'Model Activity',
        icon: <Icon as={SignalIcon} boxSize={6} />,
        keywords: 'audit trail activity',
        perform: () => navigate(`/model-inventory/${id}/activity`),
      },
      {
        id: 'getting-started',
        name: 'Getting Started',
        icon: <Icon as={RocketLaunchIcon} boxSize={6} />,
        keywords:
          'How to get started with your ValidMind documentation project.',
        perform: () => navigate(`/projects/${projectID}/getting-started`),
      },
    ];
    setRootSearchActions(rootSearchActions);
  }, [inventoryModel, templates.isReady]);

  useEffect(() => {
    if (!project || !templates.isReady) {
      return;
    }

    // pages looks like the same as project navigation menu works
    let sections = getProjectMenuItemsFromTemplate(
      project,
      'documentation',
      templates.documentation.sectionTree,
      true,
    );

    const documentation = flattenPages(sections).reduce((acc, curr) => {
      // marked as index-only should not appear in results
      if (curr.indexOnly) {
        return acc;
      }
      acc[curr.id] = curr;
      return acc;
    }, {});

    sections = getProjectMenuItemsFromTemplate(
      project,
      'validation-report',
      templates.validation_report.sectionTree,
      true,
    );

    const validationReport = flattenPages(sections).reduce((acc, curr) => {
      // marked as index-only should not appear in results
      if (curr.indexOnly) {
        return acc;
      }
      acc[curr.id] = curr;
      return acc;
    }, {});

    const projectActions = {
      modelDocumentation: {
        id: 'model-documentation',
        children: documentation,
      },
      validationReport: {
        id: 'validation-report',
        children: validationReport,
      },
    };

    let actions: Action[] = [];
    function collectDocs(tree: any, parentId?: any) {
      for (let i = 0; i < Object.keys(tree).length; i++) {
        const key = Object.keys(tree)[i];
        const curr = tree[key];

        if (curr.children) {
          collectDocs(curr.children, curr.id);
        }
        if (curr.absolutePath) {
          actions.push({
            ...curr,
            parent: parentId,
            perform: function () {
              navigate(curr.absolutePath);
            },
          });
        }
      }
      return actions;
    }
    collectDocs(projectActions);
    setSearchActions(actions);

    const modelID = project?.inventory_model.cuid;
    const rootSearchActions: Action[] = [
      {
        id: 'project-overview',
        name: 'Model Overview',
        icon: <Icon as={CubeIcon} boxSize={6} />,
        keywords: 'model overview summary',
        perform: () => navigate(`/model-inventory/${modelID}/overview`),
      },
      {
        id: 'model-documentation',
        name: 'Documentation',
        icon: <Icon as={BookOpenIcon} boxSize={6} />,
        keywords: 'documentation',

        perform: () => navigate(`/projects/${id}/project-overview`),
      },
      {
        id: 'validation-report',
        name: 'Validation Report',
        icon: <Icon as={ShieldCheckIcon} boxSize={6} />,
        keywords: 'validation report',
        perform: () => navigate(`/projects/${id}/validation-report/overview`),
      },
      {
        id: 'ongoing-monitoring',
        name: 'Ongoing Monitoring',
        icon: <Icon as={TvIcon} boxSize={6} />,
        keywords: 'Ongoing Monitoring',
        perform: () => navigate(`/projects/${id}/monitoring/overview`),
      },
      {
        id: 'model-findings',
        name: 'Model Findings',
        icon: <Icon as={ExclamationTriangleIcon} boxSize={6} />,
        keywords: 'findings',
        perform: () => navigate(`/model-inventory/${modelID}/findings`),
      },
      {
        id: 'model-activity',
        name: 'Model Activity',
        icon: <Icon as={SignalIcon} boxSize={6} />,
        keywords: 'audit trail activity',
        perform: () => navigate(`/model-inventory/${modelID}/activity`),
      },
      {
        id: 'getting-started',
        name: 'Getting Started',
        icon: <Icon as={RocketLaunchIcon} boxSize={6} />,
        keywords:
          'How to get started with your ValidMind documentation project.',
        perform: () => navigate(`/projects/${id}/getting-started`),
      },
    ];
    setRootSearchActions(rootSearchActions);
  }, [project, templates.isReady]);

  const { search, currentRootActionId, visualState } = useKBar(state => ({
    search: state.searchQuery,
    currentRootActionId: state.currentRootActionId,
    visualState: state.visualState,
  }));

  const queryTerm = useDebounce(search.trim(), 600);

  const searchService = useQuery(
    ['project', id, 'search', queryTerm],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetProjectSearch(project!, accessToken, queryTerm);
    },
    {
      enabled: false,
      onSuccess: searchResults => {
        if (searchResults.length > 0) {
          let actions: Action[] = [];
          for (let i = 0; i < searchResults.length; i++) {
            const result = searchResults[i];

            let icon = <Icon as={MagnifyingGlassIcon} boxSize={6} />;
            let contentUrl = '';
            let pageTitle = '';
            let forNestedActionId = '';

            if (result.data.model === 'Metadata') {
              const { contentUrl: url, pageTitle: title } =
                getURLForMetadataContentId(id!, result.data.url, templates);
              contentUrl = url;
              pageTitle = title;
              forNestedActionId = 'model-documentation';
              icon = <Icon as={DocumentTextIcon} boxSize={6} />;
            } else if (result.data.model === 'Finding') {
              forNestedActionId = 'findings';
              contentUrl = result.data.url;
              pageTitle = 'Findings';
              icon = <Icon as={EyeIcon} boxSize={6} />;
            }

            // filter results based on rootSearchActions nested actions
            if (
              currentRootActionId!! &&
              currentRootActionId !== forNestedActionId
            ) {
              continue;
            }

            // No URL could be resolved for this result. Skip it.
            if (!contentUrl) {
              continue;
            }

            actions.push({
              id: `results-${result.data.cuid}`,
              name: pageTitle,
              icon,
              subtitle: markdownToTxt(result.data.headlines),
              keywords: queryTerm,
              parent: currentRootActionId || undefined,
              perform: () =>
                navigate(`${contentUrl}?keywords=${result.keywords.join(',')}`),
              priority: 0,
            });
          }
          setSearchResults(actions);
        }
      },
      onError: _ => {
        /* TODO: track errors */
      },
    },
  );

  useEffect(() => {
    if (queryTerm.length >= 3) {
      searchService.refetch();
    }
    setSearchResults([]);
  }, [queryTerm]);

  // clear results on command palette off
  useEffect(() => {
    if (visualState === 'hidden') {
      setSearchResults([]);
    }
  }, [visualState]);

  useRegisterActions(
    [...rootSearchActions, ...searchActions, ...searchResults],
    [searchResults, rootSearchActions, searchActions, search],
  );
}
