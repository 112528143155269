import { closestCorners, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import SortableItem, { Option } from '../SortableItem';

type SortableListType = {
  selectedIds: string[];
  options: Option[];
  setSelectedIds: (selectedIds: string[]) => void;
};

const SortableChecklist: React.FC<SortableListType> = ({
  selectedIds,
  options,
  setSelectedIds,
}) => {
  const [optionsOrdered, setOptionsOrdered] = useState<Option[]>([]);

  // Rebuild options when options length or selectedIds length changes
  // Do not rebuild if the options are being reordered
  useEffect(() => {
    // include selectedIds first then the rest of the options
    const initialOptions = selectedIds
      .reduce((acc, id) => {
        const found = options.find(o => o.id === id);
        if (found) {
          acc.push(found);
        }
        return acc;
      }, [] as Option[])
      .concat(options.filter(o => !selectedIds.includes(o.id)));
    setOptionsOrdered(initialOptions);
  }, [options.length, selectedIds.length]);

  const dragEnd = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = optionsOrdered.findIndex(o => o.id === e.active.id);
      const newIdx = optionsOrdered.findIndex(o => o.id === e.over!.id);

      const newArray = arrayMove(optionsOrdered, oldIdx, newIdx);

      setOptionsOrdered(newArray);
      // setSelectedIds for every selected item in the newArray
      setSelectedIds(
        newArray
          .filter(({ id }) => selectedIds.includes(id))
          .map(({ id }) => id),
      );
    }
  };

  return (
    <DndContext collisionDetection={closestCorners} onDragEnd={dragEnd}>
      <SortableContext
        items={optionsOrdered}
        strategy={verticalListSortingStrategy}
      >
        {optionsOrdered.map(({ id, label }) => {
          //find the option with the id
          const found = selectedIds.find(selectedId => selectedId === id);
          return (
            <SortableItem
              key={id}
              id={id}
              label={label}
              isChecked={!!found}
              onToggle={(checked: boolean) => {
                //if the item is checked, add it to the list
                if (checked) {
                  const newSelectedIds = optionsOrdered
                    .filter(o => o.id === id || selectedIds.includes(o.id))
                    .map(o => o.id);
                  setSelectedIds(newSelectedIds);
                } else {
                  //if the item is unchecked, remove it from the list
                  setSelectedIds(
                    selectedIds.filter(selectedId => selectedId !== id),
                  );
                }
              }}
            />
          );
        })}
      </SortableContext>
    </DndContext>
  );
};

export default SortableChecklist;
