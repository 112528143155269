import { Handle, Node, NodeProps, Position } from 'reactflow';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { StartNodeData } from '../../types';
import NodeBox from '../../layouts/NodeBox';

function StartNode(props: NodeProps) {
  const data = props.data as StartNodeData;

  return (
    <NodeBox
      rounded={100}
      bg={useColorModeValue('green.100', 'green.800')}
      border={'1px solid'}
      borderColor={'green.500'}
      nodeProps={props}
    >
      <Box p={4}>
        <Text fontWeight={'bold'}>Start</Text>
        <Handle type="source" position={Position.Bottom} />
      </Box>
    </NodeBox>
  );
}

StartNode.type = 'start';
StartNode.autoRuns = true;

StartNode.getDefaultNode = (): Node => {
  return {
    id: 'start',
    type: StartNode.type,
    position: { x: 0, y: 0 },
    data: {},
  };
};

export default StartNode;
