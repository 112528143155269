import { FieldEditProps } from '../../index';
import { useEffect, useState } from 'react';
import RichTextContentEditor from '../../../../RichTextContentEditor';

const HTMLFieldEdit = ({
  field,
  onEdit,
  onCancel,
  onChange,
}: FieldEditProps) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    if (field.value) {
      setHtml(field.value);
    }
  }, [field]);

  return (
    <RichTextContentEditor
      text={html}
      onSave={(editorText: string) => {
        onEdit({ [field.key]: editorText });
        onChange?.({ [field.key]: editorText });
      }}
      allowEdit={true}
      variant={!onChange ? 'confirm-buttons' : 'form-field'}
    />
  );
};

export default HTMLFieldEdit;
