import { Icon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useColorModeValue,
} from '@chakra-ui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

interface ListingSearchButtonProps {
  term: string;
  setTerm: (term: string) => void;
  popOverTitle: string;
}
export default function ListingSearchButton({
  term,
  setTerm,
  popOverTitle,
}: ListingSearchButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const firstFieldRef = React.useRef(null);
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery(term);
  }, [term]);

  const onSearch = async () => {
    setTerm(query);
    handleClose();
  };

  const onClearSearch = () => {
    setQuery('');
    setTerm('');
    handleClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <>
      <Popover
        strategy={'absolute'}
        closeOnEsc={true}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        closeOnBlur={true}
        initialFocusRef={firstFieldRef}
      >
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={MagnifyingGlassIcon} boxSize={5} />}
            variant={'ghost'}
            data-testid="ListingSearchButton-search-button"
          >
            Search
          </Button>
        </PopoverTrigger>

        <Portal>
          <PopoverContent width={'xl'} mr={5} boxShadow={'xl'}>
            <PopoverArrow />
            <PopoverHeader
              py={4}
              px={10}
              fontSize={'lg'}
              fontWeight={'semibold'}
            >
              {popOverTitle}
            </PopoverHeader>

            <PopoverCloseButton size={'md'} m={2} />
            <PopoverBody overflowX={'auto'} py={5} px={10}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={MagnifyingGlassIcon} boxSize={5} />}
                />
                <Input
                  ref={firstFieldRef}
                  placeholder="Search by name"
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                  data-testid="ListingSearchButton-search-input"
                  background={useColorModeValue('white', 'neutral.850')}
                  focusBorderColor="brand.base"
                />
              </InputGroup>
            </PopoverBody>
            <PopoverFooter p={5}>
              <Flex justifyContent={'flex-end'}>
                <Button variant="ghost" mr={3} onClick={onClearSearch}>
                  Clear
                </Button>
                <Button
                  onClick={onSearch}
                  data-testid="ListingSearchButton-search-submit-button"
                >
                  Search
                </Button>
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
}
