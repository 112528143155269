import { TProject } from '../../models';
import DocumentHeader, {
  getDocumentHeaderBreadcrumbs,
} from '../DocumentHeader';
import { useMemo } from 'react';

interface Props {
  project: TProject;
}

const ValidationReportHeader = ({ project }: Props) => {
  const breadcrumbLinks = useMemo(
    () => getDocumentHeaderBreadcrumbs(project, 'Validation Summary'),
    [project],
  );

  return <DocumentHeader project={project} breadcrumbLinks={breadcrumbLinks} />;
};

export default ValidationReportHeader;
