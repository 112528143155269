import {
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useNodes,
} from 'reactflow';
import {
  getSmartEdge,
  pathfindingJumpPointNoDiagonal,
  svgDrawSmoothLinePath,
} from '@tisoap/react-flow-smart-edge';

const useSmartEdges = true;
const CustomEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
  style,
}) => {
  let [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  if (useSmartEdges) {
    const nodes = useNodes();
    let getSmartEdgeResponse = getSmartEdge({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      nodes,
      options: {
        drawEdge: svgDrawSmoothLinePath,
        generatePath: pathfindingJumpPointNoDiagonal,
      },
    }) as { edgeCenterX: number; edgeCenterY: number; svgPathString: string };

    const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse;
    edgePath = svgPathString;
    labelX = edgeCenterX;
    labelY = edgeCenterY;
  }

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={style}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: '#ffcc00',
            padding: 5,
            borderRadius: 4,
            fontSize: 10,
            textAlign: 'center',
            opacity: 0.8,
          }}
        >
          <span className={'block'}>{data.label}</span>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
