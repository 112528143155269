import useMetric from '../../../../hooks/useMetric';
import { TDatasetField } from '../../../../models/dataset_field';
import { Review } from '../../../DataPreparation/Review';
import ContentContainer from '../../../Layout/ContentContainer';

export default function DatasetSummaryTable() {
  const { metric } = useMetric('validmind.data_validation.DatasetDescription');
  const datasetFields = ((metric && metric.value) || []) as TDatasetField[];

  return (
    <ContentContainer allowExpand={true}>
      {datasetFields && <Review fields={datasetFields} />}
    </ContentContainer>
  );
}
