import React from "react";
import { JSONTemplate, Template, TemplateSectionTree } from "../models/template";
import { EditorTemplateSectionTree } from "../components/TemplateEditor/Accordion/utilities";

export interface TemplateEditorState {
  isEditMode: boolean
  baseTemplate: Template
  templateType: string
  changedTemplateTree: EditorTemplateSectionTree[]
}

export interface ITemplateEditorContext {
  state: TemplateEditorState
  setState: React.Dispatch<React.SetStateAction<TemplateEditorState>>
  getChangedSectionTree: () => TemplateSectionTree[]
  getOriginalSectionTree: () => TemplateSectionTree[]
  getCurrentJsonTemplate: () => JSONTemplate
  updateSortableTemplateTree: (tree: EditorTemplateSectionTree[]) => void
}

export const TemplateEditorContext = React.createContext<ITemplateEditorContext>({} as ITemplateEditorContext)
TemplateEditorContext.displayName = 'TemplateEditorContext';
