import API from '../../api/API';
import { StatusTagTextColor } from '../../colors';
import CountDisplay, { CountDisplayProps } from '../CountDisplay';

type Props = {
  countId: string;
};

type TReportCount = {
  [key: string]: CountDisplayProps & {
    title: string;
    category: string;
  };
};

export const ReportCountMap: TReportCount = {
  'count-total-findings': {
    title: 'Total Findings',
    unitLabel: 'Findings',
    category: 'findings',
    getDisplayValue: async accessToken => {
      const count = await API.GetAnalyticsFindingsCount(accessToken, [
        { column: 'finding_cuid', operator: 'is not', value: '$null' },
      ]);
      return count.toString();
    },
  },
  'count-open-findings': {
    title: 'Open Findings',
    unitLabel: 'Findings',
    category: 'findings',
    getDisplayValue: async accessToken => {
      const count = await API.GetAnalyticsFindingsCount(accessToken, [
        { column: 'finding_cuid', operator: 'is not', value: '$null' },
        { column: 'report_status', operator: '=', value: 'Open' },
      ]);
      return count.toString();
    },
    displayTextColor: StatusTagTextColor.open,
  },
  'count-past-due-findings': {
    title: 'Past Due Findings',
    unitLabel: 'Findings',
    category: 'findings',
    getDisplayValue: async accessToken => {
      const count = await API.GetAnalyticsFindingsCount(accessToken, [
        { column: 'finding_cuid', operator: 'is not', value: '$null' },
        { column: 'report_status', operator: '=', value: 'Past Due' },
      ]);
      return count.toString();
    },
    displayTextColor: StatusTagTextColor.past_due,
  },
  'count-closed-findings': {
    title: 'Closed Findings',
    unitLabel: 'Findings',
    category: 'findings',
    getDisplayValue: async accessToken => {
      const count = await API.GetAnalyticsFindingsCount(accessToken, [
        { column: 'finding_cuid', operator: 'is not', value: '$null' },
        { column: 'report_status', operator: '=', value: 'Closed' },
      ]);
      return count.toString();
    },
    displayTextColor: StatusTagTextColor.closed,
  },
  'count-total-models': {
    title: 'Total Models',
    unitLabel: 'Models',
    category: 'models',
    getDisplayValue: async accessToken => {
      const count = await API.GetAnalyticsModelsCount(accessToken);
      return count.toString();
    },
  },
};

export default function ReportCount({ countId }: Props) {
  return <CountDisplay {...ReportCountMap[countId]} />;
}
