import CustomField, { CustomFieldProps } from '../CustomField';

const CoreField = ({
  inventoryModel,
  propertyKey,
  readOnly,
  displayComponent,
  hideLabel,
}: CustomFieldProps) => {
  return (
    <CustomField
      inventoryModel={inventoryModel}
      propertyKey={propertyKey}
      readOnly={readOnly}
      isCoreField={true}
      displayComponent={displayComponent}
      hideLabel={hideLabel}
    />
  );
};

export default CoreField;
