import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import API, { TMetricResultKey, TTestResultKey } from '../../../../api/API';
import { ProjectContext } from '../../../../contexts';
import { useAuth0 } from '@auth0/auth0-react';
import {
  FailedBadge,
  PassedBadge,
  TestResultContent,
} from '../TestResultContent';
import useTestResult from '../../../../hooks/useTestResult';
import { PrimaryButton, TertiaryButton } from '../../../Layout';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
import useMetric from '../../../../hooks/useMetric';
import { MetricContent } from '../MetricContent';

interface RiskAssessmentAddEvidenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectedEvidence: (
    tests: string[],
    metrics: string[],
    documentType: ModelDocumentTypeEnum,
  ) => void;
  testResultContentIds?: string[];
  metricResultContentIds?: string[];
  documentType: ModelDocumentTypeEnum;
}

const TestResultAccordion = ({
  testResultKey,
  documentType,
  selectedTestResults,
  toggleCheckTestResult,
}: {
  testResultKey: TTestResultKey;
  documentType: ModelDocumentTypeEnum;
  selectedTestResults: Set<string>;
  toggleCheckTestResult: (testName: string) => void;
}) => {
  return (
    <AccordionItem
      key={testResultKey.cuid}
      border={'1px solid'}
      borderColor={useColorModeValue(
        'neutral.200 !important',
        'neutral.800 !important',
      )}
      mb={1}
      overflow={'hidden'}
    >
      {({ isExpanded }) => {
        const { testResult, isLoading } = useTestResult(
          testResultKey.test_name,
          documentType,
          isExpanded,
        );
        return (
          <>
            <AccordionButton
              _hover={{
                bg: useColorModeValue('neutral.50', 'neutral.850'),
              }}
            >
              <HStack w={'full'} justifyContent={'space-between'}>
                <Checkbox
                  size={'lg'}
                  isChecked={selectedTestResults.has(testResultKey?.test_name)}
                  onChange={e =>
                    toggleCheckTestResult(testResultKey?.test_name as string)
                  }
                >
                  <HStack w={'full'} justifyContent={'space-between'}>
                    <Text fontSize={'md'} pl={2}>
                      {_.startCase(`${testResultKey.test_name}`)}
                    </Text>
                  </HStack>
                </Checkbox>
                <HStack>
                  {testResultKey?.passed ? <PassedBadge /> : <FailedBadge />}
                  <AccordionIcon />
                </HStack>
              </HStack>
            </AccordionButton>
            <AccordionPanel pb={4}>
              {isLoading && <Text>Loading...</Text>}
              {testResult && (
                <TestResultContent
                  contents={{
                    content_id: testResultKey.test_name,
                    content_type: documentType,
                  }}
                  readOnly
                  hideHeader={true}
                  documentType={documentType}
                  overrideDocumentType={documentType}
                />
              )}
            </AccordionPanel>
          </>
        );
      }}
    </AccordionItem>
  );
};

const MetricResultAccordion = ({
  metricResultKey,
  documentType,
  selectedMetricResults,
  toggleCheckMetricResult,
}: {
  metricResultKey: TMetricResultKey;
  documentType: ModelDocumentTypeEnum;
  selectedMetricResults: Set<string>;
  toggleCheckMetricResult: (metricName: string) => void;
}) => {
  return (
    <AccordionItem
      key={metricResultKey.cuid}
      border={'1px solid'}
      borderColor={useColorModeValue(
        'neutral.200 !important',
        'neutral.800 !important',
      )}
      mb={1}
      borderRadius={'md'}
      overflow={'hidden'}
    >
      {({ isExpanded }) => {
        const { metric: metricResult, isLoading } = useMetric(
          metricResultKey.key,
          documentType,
          isExpanded,
        );
        return (
          <>
            <AccordionButton
              _hover={{
                bg: useColorModeValue('neutral.50', 'neutral.850'),
              }}
            >
              <HStack w={'full'} justifyContent={'space-between'}>
                <Checkbox
                  size={'lg'}
                  isChecked={selectedMetricResults.has(metricResultKey?.key)}
                  onChange={e =>
                    toggleCheckMetricResult(metricResultKey?.key as string)
                  }
                >
                  <HStack w={'full'} justifyContent={'space-between'}>
                    <Text fontSize={'md'} pl={2}>
                      {_.startCase(`${metricResultKey.key}`)}
                    </Text>
                  </HStack>
                </Checkbox>

                <AccordionIcon />
              </HStack>
            </AccordionButton>
            <AccordionPanel pb={4} bg={'neutral.50'}>
              {isLoading && <Text>Loading...</Text>}
              {metricResult && (
                <MetricContent
                  contents={{
                    content_id: metricResultKey.key,
                    content_type: documentType,
                  }}
                  readOnly
                  hideHeader={true}
                  documentType={documentType}
                  overrideDocumentType={documentType}
                />
              )}
            </AccordionPanel>
          </>
        );
      }}
    </AccordionItem>
  );
};

export default function RiskAssessmentAddEvidenceModal({
  isOpen,
  onClose,
  onSelectedEvidence,
  testResultContentIds,
  metricResultContentIds,
  documentType,
}: RiskAssessmentAddEvidenceModalProps) {
  const { project } = useContext(ProjectContext);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedTestResults, setSelectedTestResults] = useState(
    new Set<string>(),
  );
  const [selectedMetricResults, setSelectedMetricResults] = useState(
    new Set<string>(),
  );

  useEffect(() => {
    if (testResultContentIds) {
      setSelectedTestResults(new Set(testResultContentIds));
    }
    if (metricResultContentIds) {
      setSelectedMetricResults(new Set(metricResultContentIds));
    }
  }, [testResultContentIds, metricResultContentIds]);

  const evidenceAuthorType =
    documentType === ModelDocumentTypeEnum.model_documentation
      ? 'Developer'
      : 'Validator';

  const { data: testResultsKeys } = useQuery(
    [project?.cuid, 'test-results-keys', documentType],
    async () => {
      const token = await getAccessTokenSilently();
      // published_only is only true when we're adding evidence from the developer, i.e.
      // evidence from the existing model documentaion.
      // for a validator, we want to see all the validator test results, including the unpublished ones.
      const publishedOnly =
        documentType === ModelDocumentTypeEnum.model_documentation;
      return API.GetProjectTestResultsKeys(
        token,
        project!,
        publishedOnly,
        documentType,
      );
    },
  );

  const { data: metricResultsKeys } = useQuery(
    [project?.cuid, 'metric-results-keys', documentType],
    async () => {
      const token = await getAccessTokenSilently();
      // published_only is only true when we're adding evidence from the developer, i.e.
      // evidence from the existing model documentaion.
      // for a validator, we want to see all the validator test results, including the unpublished ones.
      const publishedOnly =
        documentType === ModelDocumentTypeEnum.model_documentation;
      return API.GetProjectMetricKeys(
        token,
        project!,
        publishedOnly,
        documentType,
      );
    },
  );

  const mergedTests = useMemo(() => {
    // merge test and metrics results into a sorted array
    let newArray: any[] = [];
    if (testResultsKeys) {
      newArray = newArray.concat(
        testResultsKeys.map((testResultKey: TTestResultKey) => ({
          type: 'test',
          ...testResultKey,
        })),
      );
    }

    if (metricResultsKeys) {
      newArray = newArray.concat(
        metricResultsKeys.map((metricResultKey: TMetricResultKey) => ({
          type: 'metric',
          ...metricResultKey,
        })),
      );
    }

    // sort by test_name for tests and key for metrics
    return newArray.sort((a: any, b: any) => {
      const keyA = a.type === 'test' ? a.test_name : a.key;
      const keyB = b.type === 'test' ? b.test_name : b.key;
      return keyA.localeCompare(keyB);
    });
  }, [testResultsKeys, metricResultsKeys]);

  const toggleCheckTestResult = (testName: string) => {
    const updatedSet = new Set(selectedTestResults);

    if (updatedSet.has(testName)) {
      updatedSet.delete(testName);
    } else {
      updatedSet.add(testName);
    }

    setSelectedTestResults(updatedSet);
  };

  const toggleCheckMetricResult = (metricName: string) => {
    const updatedSet = new Set(selectedMetricResults);

    if (updatedSet.has(metricName)) {
      updatedSet.delete(metricName);
    } else {
      updatedSet.add(metricName);
    }

    setSelectedMetricResults(updatedSet);
  };

  const onInsert = () => {
    onSelectedEvidence(
      Array.from(selectedTestResults) as string[],
      Array.from(selectedMetricResults) as string[],
      documentType,
    );
    onClose();
  };

  return (
    <Modal isCentered size={'6xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Link {evidenceAuthorType} Evidence to Validation Report
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Accordion allowToggle>
            {mergedTests.map(mergedTest => {
              if (mergedTest.type === 'test') {
                const testResultKey = mergedTest as TTestResultKey;
                return (
                  <TestResultAccordion
                    testResultKey={testResultKey}
                    documentType={documentType}
                    selectedTestResults={selectedTestResults}
                    toggleCheckTestResult={toggleCheckTestResult}
                  />
                );
              } else {
                const metricResultKey = mergedTest as TMetricResultKey;
                return (
                  <MetricResultAccordion
                    metricResultKey={metricResultKey}
                    documentType={documentType}
                    selectedMetricResults={selectedMetricResults}
                    toggleCheckMetricResult={toggleCheckMetricResult}
                  />
                );
              }
            })}
          </Accordion>
        </ModalBody>

        <ModalFooter>
          <TertiaryButton onClick={onClose}>Cancel</TertiaryButton>
          <Spacer />
          <PrimaryButton onClick={onInsert}>
            Update Linked Evidence
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
