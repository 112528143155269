import { keyable } from './utils';

export interface TStatusesWorkflowStatus {
  id?: number;
  created_at?: number;
  cuid?: string;
  name: string;
  description?: string;
  is_initial?: boolean;
  colors: keyable;
  updated_at?: number;
  workflow_cuid?: string;
}

export interface TStatusesWorkflow {
  categories: string[];
  created_at?: number;
  cuid: string;
  organization_cuid: string;
  statuses: TStatusesWorkflowStatus[];
  title: string;
  description: string;
  updated_at?: number;
}

export const WorkflowCategoryNames = {
  projects: 'Model Documentation',
  models: 'Model Lifecycle',
};

export const WorkflowCategoryDescription = {
  projects: 'Define all the stauses that Model Documentation can be in.',
  models: 'All of the statuses that a Model go through in its lifecycle.',
};
