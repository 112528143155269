import React from 'react';

import { FieldDisplayProps } from '../../../CustomField';
import { Text } from '@chakra-ui/react';
import { TUseCase } from '../../../../../models/use_case';

const UseCaseFieldDisplay = ({ field }: FieldDisplayProps) => {
  const useCase = field.value as TUseCase;
  return <Text>{useCase.name}</Text>;
};

export default UseCaseFieldDisplay;
