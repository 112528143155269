import { Flex } from '@chakra-ui/react';
import { defaultContentWidths } from '..';

/**
 * MainContentArea is high level container for any documentation
 * content that goes in the Documentation or Validation Report pages.
 *
 * We set the container width to 100% and let child SectionContainers
 * set their own width according to module's requirements.
 */
export default function MainContentArea({
  children,
  gap,
}: {
  children: any;
  gap?: number;
}) {
  return (
    <Flex
      id="main-content-area"
      direction="column"
      align="center"
      w={defaultContentWidths}
      maxWidth={'1220px'}
      gap={gap || 12}
    >
      {children}
    </Flex>
  );
}
