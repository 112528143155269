import React from 'react';
import { FieldDisplayProps } from '../../../CustomField';
import { Text } from '@chakra-ui/react';
import { TBusinessUnit } from '../../../../../models/business_unit';

const BusinessUnitFieldDisplay = ({ field }: FieldDisplayProps) => {
  const businessUnit = field.value as TBusinessUnit;
  return <Text>{businessUnit.name}</Text>;
};

export default BusinessUnitFieldDisplay;
