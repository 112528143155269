import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { marked } from 'marked';
import { escape, unescape } from 'lodash';

import { TProjectMetadata } from './models/metadata';
import { SchemaPropertyItem } from './models/json_schemas';
import { JSONSchema7 } from 'json-schema';
import {
  RJSFSchemaSettingsProperty,
  TInventoryModelCustomField,
  TInventoryModelSchema,
} from './models/inventory_model';
import {
  CustomFieldJSONSchema,
  CustomFieldReturnType,
  CustomFieldTypes,
} from './components/NewCustomFields/types';
import { As } from '@chakra-ui/react';
import {
  CheckBadgeIcon,
  CodeBracketIcon,
  HandRaisedIcon,
  StarIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline';

dayjs.extend(relativeTime);

/**
 * https://stackoverflow.com/questions/9553354/how-do-i-get-the-decimal-places-of-a-floating-point-number-in-javascript
 */
function getNumberPrecision(a: number) {
  if (!isFinite(a)) return 0;
  var e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
}

export function int_to_hex(num: number) {
  var hex = Math.round(num).toString(16);
  if (hex.length === 1) hex = '0' + hex;
  return hex;
}

/**
 * Accepts any value and returns a number rounded to up to 2 decimal places.
 * To prevent an unexpected behavior, if the value is not a number return the value
 *
 * @param value any
 * @param decimalPlaces
 * @returns processed value
 */
export function formatNumber(
  value: any,
  decimalPlaces: number | undefined = undefined,
) {
  const number = Number(value);
  const precision = getNumberPrecision(number);

  if (Number.isNaN(number)) {
    return value;
  }

  if (decimalPlaces === undefined) {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: precision > 6 ? 6 : precision,
      maximumFractionDigits: precision > 6 ? 6 : precision,
    });
  }

  return number.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

export function displayFormatedDateAndTime(date: any) {
  return `${dayjs(date * 1000).format('ddd, MMM D, YYYY - H:mm')}h`;
}

export function displayFormatedDate(date: any) {
  return `${dayjs(date * 1000).format('ddd, MMM D, YYYY')}`;
}

// Necessary format for HTML date input
export function displayFormatedYMDDate(date: any) {
  return `${dayjs(date * 1000).format('YYYY-MM-DD')}`;
}

export function displayRelativeDate(date: any) {
  return `${dayjs(date * 1000).fromNow()}`;
}

export const getMetadataText = (
  metadata: TProjectMetadata[],
  section: string,
) => {
  if (!metadata) {
    return '';
  }

  const metadataSection = metadata.find(m => m.content_id === section);
  if (!metadataSection) {
    return;
  }

  return metadataSection.text;
};

export const FindingStatuses = ['open', 'closed'];

export const FindingStatusMap: any = {
  draft: 'Draft',
  open: 'Open',
  closed: 'Closed',
};

// array.splice() equivalent for strings
//
// https://stackoverflow.com/questions/20817618/is-there-a-splice-method-for-strings
export function spliceSlice(
  str: string,
  index: number,
  end: number,
  add: string,
) {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  if (index < 0) {
    index = str.length + index;
    if (index < 0) {
      index = 0;
    }
  }

  return str.slice(0, index) + (add || '') + str.slice(end);
}

const block = (text: string) => text + '\n\n';
const escapeBlock = (text: string) => escape(text) + '\n\n';
const line = (text: string) => text + '\n';
const inline = (text: string) => text;
const newline = () => '\n';
const empty = () => '';

const TxtRenderer: marked.Renderer = {
  // Block elements
  code: escapeBlock,
  blockquote: block,
  html: inline,
  heading: block,
  hr: newline,
  list: text => block(text.trim()),
  listitem: line,
  checkbox: empty,
  paragraph: block,
  table: (header, body) => line(header + body),
  tablerow: text => line(text.trim()),
  tablecell: text => text + ' ',
  // Inline elements
  strong: inline,
  em: inline,
  codespan: inline,
  br: newline,
  del: inline,
  link: (_0, _1, text) => text,
  image: (_0, _1, text) => text,
  text: inline,
  // etc.
  options: {},
};

/**
 * Converts markdown to plaintext using the marked Markdown library.
 * Accepts [MarkedOptions](https://marked.js.org/using_advanced#options) as
 * the second argument.
 *
 * @param markdown the markdown text to txtify
 * @param options  the marked options
 * @returns the unmarked text
 */
export const markdownToTxt = (
  markdown: string,
  options?: marked.MarkedOptions,
): string => {
  const unmarked = marked(markdown, { ...options, renderer: TxtRenderer });
  const unescaped = unescape(unmarked);
  return unescaped.trim();
};

export function allFiltersEmpty(obj: Record<string, any>) {
  return Object.values(obj).every(val => {
    if (Array.isArray(val)) {
      return val.length === 0;
    }
    return val === null || val === undefined;
  });
}

/**
 * Truncates a string to a specified length and appends an ellipsis ('...') if the string exceeds that length.
 * @example
 * // Truncate a string to the default length of 25 characters
 * truncateString("This is a long string that exceeds the default length");
 * // Returns: "This is a long string th..."
 */
export function truncateString(str: string, num: number = 25) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function adjustColor(
  hexColor: string,
  newLuminosity: number,
  newAlpha: number,
): string {
  // Convert hex to RGB
  const hexToRgb = (hex: string): number[] =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b,
      )
      .substring(1)
      .match(/.{2}/g)!
      .map(x => parseInt(x, 16));

  const [r, g, b] = hexToRgb(hexColor);

  // Convert RGB to HSL
  const rgbToHsl = (r: number, g: number, b: number): number[] => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      h =
        max === r
          ? (g - b) / d + (g < b ? 6 : 0)
          : max === g
          ? (b - r) / d + 2
          : (r - g) / d + 4;
      h /= 6;
    }

    return [h, s, l];
  };

  const [h, s, l] = rgbToHsl(r, g, b);

  // Adjust luminosity and alpha
  const adjustedColor = `hsla(${h * 360}, ${
    s * 100
  }%, ${newLuminosity}%, ${newAlpha})`;

  return adjustedColor;
}

export function schemaPropertiesToSchemaPropertyItems(
  schema: TInventoryModelSchema,
): SchemaPropertyItem[] {
  const properties = (schema.schema.properties as JSONSchema7) || {};
  const requiredFields = new Set(schema.schema.required || []);

  return Object.keys(properties).map(key => {
    const { type, title } = properties[key as keyof object] as JSONSchema7;

    return {
      key,
      type,
      title,
      typeId: schema.settings.properties[key].typeId,
      description: schema.settings.properties[key].description,
      requiredOnRegistration:
        schema.settings.properties[key].requiredOnRegistration || false,
    } as SchemaPropertyItem;
  });
}

export function getSchemaPropertyByKey(
  field: TInventoryModelSchema,
  key: string,
): [JSONSchema7, RJSFSchemaSettingsProperty] {
  if (
    field.schema.properties &&
    Object.keys(field.schema.properties).length > 0
  ) {
    const schemaProperty = field.schema.properties[
      key as keyof typeof field.schema.properties
    ] as JSONSchema7;

    const settingsProperty = field.settings.properties[
      key as keyof typeof field.settings.properties
    ] as RJSFSchemaSettingsProperty;

    return [schemaProperty, settingsProperty];
  } else {
    console.error(field);
    throw new Error(
      "^^^ The provided schema must have a 'properties' attribute with at least one property.",
    );
  }
}

export function createCustomField(
  key: string,
  title: string,
  type: string,
  typeId: string,
  value: any,
  uiSchema: any = {},
): TInventoryModelCustomField | any {
  return {
    key: key,
    value: value,
    schema: {
      properties: {
        [key]: {
          type: type,
          title: title,
        },
      },
    },
    settings: {
      properties: {
        [key]: {
          label: title,
          typeId: typeId,
          uiSchema,
        },
      },
    },
  };
}

export function createStringCustomField(
  key: string,
  title: string,
  value: any,
): TInventoryModelCustomField | any {
  return createCustomField(key, title, 'string', 'string:single-line', value);
}

export function createStringMultilineCustomField(
  key: string,
  title: string,
  value: any,
): TInventoryModelCustomField | any {
  const uiSchema = {
    'ui:widget': 'textarea',
  };
  return createCustomField(
    key,
    title,
    'string',
    'string:multi-line',
    value,
    uiSchema,
  );
}

export function convertRJSFToCustomFieldJSON(
  key: string,
  propertySchema: JSONSchema7 | null,
  settingsSchema: RJSFSchemaSettingsProperty,
): CustomFieldJSONSchema {
  let props = {};
  const tokens = settingsSchema.typeId.split(':');
  const type = (tokens[1] || tokens[0]) as CustomFieldTypes;
  const returnType = tokens[0] as CustomFieldReturnType;

  let items = [];

  if (propertySchema) {
    // @ts-ignore
    if (propertySchema.items && propertySchema.items.enum) {
      // @ts-ignore
      items = propertySchema.items.enum;
    } else if (propertySchema.enum) {
      items = propertySchema.enum;
    }
  }

  if (items) {
    props = {
      ...props,
      items,
      many: settingsSchema.many || false,
    };
  }

  // Spread options into props
  if (settingsSchema.options) {
    props = {
      ...props,
      ...settingsSchema.options,
    };
  }

  if (settingsSchema.code) {
    props = {
      ...props,
      code: settingsSchema.code,
    };
  }

  return {
    key,
    type,
    returnType,
    label: settingsSchema.label!,
    description: settingsSchema.description || '',
    props,
    isRequired: settingsSchema.requiredOnRegistration || false,
  };
}

function generateRandomCode(length: number): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function generateRandomCodeForNodes(): string {
  return generateRandomCode(4);
}

interface RoleUIProp {
  icon: As;
  colorScheme: string;
}

export const OrgRoleMap: Record<string, RoleUIProp> = {
  'Customer Admin': {
    icon: HandRaisedIcon,
    colorScheme: 'indigo',
  },
  Validator: {
    icon: CheckBadgeIcon,
    colorScheme: 'pink',
  },
  Developer: {
    icon: CodeBracketIcon,
    colorScheme: 'cyan',
  },
  Staff: {
    icon: StarIcon,
    colorScheme: 'brand',
  },
};

/**
 * Returns the icon and color scheme for a given role and returns a default
 * icon and color scheme if the role is not found.
 */
export const getRoleUIProps = (role: string): RoleUIProp => {
  return OrgRoleMap[role] || { icon: WrenchIcon, colorScheme: 'emerald' };
};

export const getDocumentType = (slug?: string) => {
  if (slug == 'validation-report') {
    return 'validation_report';
  } else if (slug == 'monitoring') {
    return 'monitoring';
  }
  return 'model_documentation';
};
