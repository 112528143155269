import { Stack, StackDivider, Text, useColorModeValue } from '@chakra-ui/react';
import WorkflowApprovalItem from '../WorkflowApprovalItem';
import useApprovals from '../../hooks/useApprovals';
import { ApprovalVoterStatus } from '../../models/approval';

interface WorkflowApprovalActionsProps {
  variant?: 'page';
  voterStatus: ApprovalVoterStatus | null;
  approvalStatus?: 'approved' | 'rejected' | 'pending';
  targetType?: string;
  targetCuid?: string;
}
export default function WorkflowApprovalActions({
  variant,
  voterStatus,
  approvalStatus = 'pending',
  targetType,
  targetCuid,
}: WorkflowApprovalActionsProps) {
  const { data, isLoading } = useApprovals(
    voterStatus,
    approvalStatus,
    targetType,
    targetCuid,
  );

  if (data && data.length > 0) {
    return (
      <Stack spacing={2}>
        {data?.map(voter => (
          <WorkflowApprovalItem variant={variant} voter={voter} />
        ))}
        <StackDivider
          w={'full'}
          h={10}
          borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        />
      </Stack>
    );
  }

  if (!isLoading && variant === 'page') {
    return <Text>No approvals at this moment</Text>;
  }

  return null;
}
