import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../api/API';
import MasterPage from '../../../components/Layout/MasterPage';
import InventoryModelContext from '../../../contexts/InventoryModel';
import { LoadingContainer } from '../../../components/LoadingContainer';
import MasterDetails from '../../../components/Layout/MasterDetails';
import InventoryModelOverview from './Overview';
import { ProjectContext, UsersContext } from '../../../contexts';
import InventoryModelFindings from './Findings';
import InventoryModelActivity from './Activity';
import { KBarProvider } from 'kbar';
import CommandPalette from '../../../components/CommandPalette';
import SidebarContext from '../../../contexts/SidebarContext';
import { AxiosError } from 'axios';
import ForbiddenAlert from '../../../components/ForbiddenAlert';
import GetStartedChecklist from '../../../components/GetStartedChecklist';
import { TPermissionAction } from '../../../models/role';
import { useToast } from '@chakra-ui/react';

export default function InventoryModel() {
  const toast = useToast();
  const currentPath = useLocation();
  const navigate = useNavigate();
  const { project, templates } = useContext(ProjectContext);
  const { currentOrganization } = useContext(UsersContext);
  const { setProject: setSidebarProject } = useContext(SidebarContext);
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [isForbidden, setIsForbidden] = useState(false);

  const { data: inventoryModel } = useQuery(
    ['inventory-model', id],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetInventoryModel(accessToken, id!);
    },
    {
      staleTime: 1000 * 60 * 10,
      onError: (error: AxiosError) => {
        if (error.response?.status === 403) {
          setIsForbidden(true);
        } else if (error.response?.status === 404) {
          navigate('/model-inventory', { replace: true });
          toast({
            title: 'Model not found',
            status: 'error',
            isClosable: true,
          });
        }
      },
    },
  );

  useEffect(() => {
    // Redirect to model overview when visiting the model base path
    if (currentPath.pathname.endsWith(id!)) {
      navigate('overview', { replace: true });
    }
  }, [currentPath.pathname, inventoryModel]);

  useEffect(() => {
    return () => {
      // Resets the sidebar project when leaving the project page
      setSidebarProject(undefined);
    };
  }, [inventoryModel]);

  // get the latest project of the model in order to make documentation and validation report navigable
  const { data: projects } = useQuery(
    ['inventory-model', inventoryModel?.cuid, 'projects'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetInventoryModelProjects(accessToken, inventoryModel!.cuid);
    },
    {
      enabled: !!inventoryModel?.cuid,
      staleTime: 1000 * 60 * 10,
      onError: err => {
        // track errors
      },
    },
  );

  const { data: inventoryModelPermissions } = useQuery(
    [
      'inventory-model',
      inventoryModel?.cuid,
      'permissions',
      project?.cuid,
      currentOrganization?.cuid,
    ],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetUserPermissions(accessToken, 'Model', inventoryModel!.cuid);
    },
    {
      enabled: !!inventoryModel?.cuid,
      staleTime: 1000 * 60 * 10,
      onError: err => {
        // track errors
      },
    },
  );

  const userHasInventoryModelPermission = useCallback(
    (actions: TPermissionAction[], match: 'any' | 'all') => {
      if (!inventoryModelPermissions) return false;
      if (match === 'any') {
        return actions.some(action =>
          inventoryModelPermissions.some(permission => permission === action),
        );
      } else {
        return actions.every(action =>
          inventoryModelPermissions.some(permission => permission === action),
        );
      }
    },
    [inventoryModelPermissions],
  );

  if (projects && projects.length > 0) {
    setSidebarProject(projects[0]);
  }

  if (isForbidden) {
    return (
      <ForbiddenAlert
        title={'You are not allowed to access this model'}
        description={'ask to your administrator for more information.'}
      />
    );
  }

  return (
    <KBarProvider
      options={{
        enableHistory: true,
      }}
    >
      <InventoryModelContext.Provider
        value={{
          inventoryModel,
          userHasInventoryModelPermission,
        }}
      >
        <CommandPalette />
        <ProjectContext.Provider
          value={{
            setTemplates: () => {},
            templates,
            project: projects && projects.length > 0 ? projects[0] : undefined,
          }}
        >
          <MasterPage>
            <MasterDetails>
              <LoadingContainer isLoading={!inventoryModel}>
                <Routes>
                  <Route path="overview" element={<InventoryModelOverview />} />
                  <Route path="findings" element={<InventoryModelFindings />} />
                  <Route path="activity" element={<InventoryModelActivity />} />
                </Routes>
              </LoadingContainer>
            </MasterDetails>
            <GetStartedChecklist />
          </MasterPage>
        </ProjectContext.Provider>
      </InventoryModelContext.Provider>
    </KBarProvider>
  );
}
