import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import { UiSchema } from '@rjsf/utils';
import { FieldEditProps } from '../../index';
import { HStack } from '@chakra-ui/react';
import { PrimaryButton, TertiaryButton } from '../../../../Layout';

const RegularFieldEdit = ({
  field,
  onEdit,
  onCancel,
  onChange,
}: FieldEditProps) => {
  const formData = {
    [field.key]: field.value,
  };

  const customUiSchema = field.settings.properties?.[field.key].uiSchema;

  const uiSchema: UiSchema = {
    'ui:globalOptions': { label: false },
    [field.key]: customUiSchema ?? {},
  };

  const handleSubmit = (e: any) => {
    onEdit(e.formData);
  };

  const handleCancel = () => {
    onCancel({});
  };

  return (
    <Form
      schema={field.schema}
      formData={formData}
      validator={validator}
      onSubmit={handleSubmit}
      uiSchema={uiSchema}
      className="custom-field-edit-form"
      onChange={e => {
        onChange?.(e.formData);
      }}
      liveValidate={!!onChange}
      showErrorList={false}
    >
      {!onChange ? (
        <HStack justifyContent={'flex-end'}>
          <TertiaryButton
            onClick={() => {
              handleCancel();
            }}
            size={'sm'}
            variant={'ghost'}
          >
            Cancel
          </TertiaryButton>
          <PrimaryButton type="submit" size={'sm'}>
            Save
          </PrimaryButton>
        </HStack>
      ) : (
        <></>
      )}
    </Form>
  );
};

export default RegularFieldEdit;
