import { selectAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

export const Select = defineMultiStyleConfig({
  baseStyle: {
    field: {
      background: 'neutral.100',
      _dark: {
        background: 'neutral.850',
        borderColor: 'neutral.800',
      },
      _focus: {
        outline: 'none',
        boxShadow: '0 0 0 1px var(--chakra-colors-brand-400)',
      },
    },
    defaultProps: {
      outline: 'none',
      boxShadow: '0 0 0 1px var(--chakra-colors-brand-400)',
    },
  },
});
