export const SeverityTagColor = {
  Low: 'yellow.100',
  Medium: 'orange.100',
  High: 'red.200',
};

export const SeverityTextColor = {
  Low: 'yellow.800',
  Medium: 'orange.800',
  High: 'red.800',
};

export const StatusTagColor = {
  draft: 'blue.100',
  open: 'blue.100',
  past_due: 'yellow.200',
  closed: 'green.200',
};

export const StatusTagTextColor = {
  draft: 'blue.800',
  open: 'blue.800',
  past_due: 'yellow.800',
  closed: 'green.700',
};