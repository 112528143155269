import * as React from 'react';
import { SVGProps } from 'react';

function ValidMindVLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="33"
      viewBox="0 0 28 33"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      aria-labelledby="validMind validMindDescription"
      role="img"
    >
      <title id="ValidMindVLogo">
        ValidMind - Model Risk Management, Simplified.
      </title>
      <desc id="validMindDescription">
        ValidMind increases the speed and efficiency of model validation
        activities for Financial Institutions.
      </desc>

      <path d="M27.6674 0.402344L13.6445 32.1612H10.566L6.12688 23.8463H10.8118L12.0639 26.0516L23.3931 0.402344H27.6674ZM8.66574 20.0667L10.2393 22.8384H5.58904L4.10933 20.0667H8.66574ZM6.51971 16.2872L8.09355 19.0589H3.57148L2.09178 16.2872H6.51971ZM4.37393 12.5077L5.94752 15.2793H1.55367L0.0742188 12.5077H4.37393Z" />
    </svg>
  );
}

export default ValidMindVLogo;
