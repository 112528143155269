import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  useMatches,
} from 'kbar';
import React from 'react';
import useProjectActions from './ProjectActions';
import { Box, HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react';

const animatorStyle = {
  maxWidth: '600px',
  width: '100%',
  background: 'white',
  color: 'black',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)',
};

const searchStyle = {
  padding: '12px 16px',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
  outline: 'none',
  border: 'none',
  background: 'white',
  color: 'black',
};

const portalStyle = {
  zIndex: 1,
  background: 'rgba(0, 0, 0, 0.2)',
};

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor: any) => ancestor.id === currentRootActionId,
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <Box
        ref={ref}
        fontSize={'sm'}
        fontWeight={'bold'}
        cursor={'pointer'}
        bg={active ? 'neutral.100' : 'transparent'}
        color={active ? 'black' : 'neutral.800'}
      >
        <HStack gap={11} p={2} alignItems={'flex-start'}>
          {action.icon && (
            <Box h={6} w={6}>
              {action.icon}
            </Box>
          )}
          <VStack alignItems={'flex-start'} pl={action.icon ? 0 : 10}>
            <HStack color={useColorModeValue('neutral.600', 'neutral.400')}>
              {ancestors.length > 0 &&
                ancestors.map((ancestor: any) => (
                  <React.Fragment key={ancestor.id}>
                    <HStack>
                      <Text>{ancestor.name}</Text>
                      <Text>/</Text>
                    </HStack>
                  </React.Fragment>
                ))}
              <Text>{action.name}</Text>
            </HStack>
            {action.subtitle && (
              <Text
                dangerouslySetInnerHTML={{ __html: `...${action.subtitle}...` }}
              />
            )}
          </VStack>
        </HStack>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: 'grid', gridAutoFlow: 'column', gap: '4px' }}
          >
            {action.shortcut.map((sc: any) => (
              <kbd key={sc}>{sc}</kbd>
            ))}
          </div>
        ) : null}
      </Box>
    );
  },
);

function RenderResults() {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <Box
            p={3}
            fontSize={'md'}
            color={useColorModeValue('neutral.500', 'neutral.500')}
          >
            <Text>{item}</Text>
          </Box>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId!}
          />
        )
      }
    />
  );
}

export default function CommandPalette() {
  useProjectActions();

  return (
    <KBarPortal>
      <KBarPositioner style={portalStyle}>
        <KBarAnimator style={animatorStyle}>
          <KBarSearch style={searchStyle} />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
}
