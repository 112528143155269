import { createContext } from 'react';
import { TemplateSection } from '../models';
import { TEvent } from '../models/event';

interface IDocumentationContext {
  hasValidationReportResults?: boolean;
  currentSection?: TemplateSection | '';
  latestEvent?: TEvent;
  setLatestEvent: (event?: TEvent) => void;
}

const DocumentationContext = createContext<IDocumentationContext>({
  setLatestEvent: (event?: TEvent) => {},
});

export default DocumentationContext;
