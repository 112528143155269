export const MimeTypeMap = {
  // 3D types
  'model/gltf+json': '3d',
  'model/obj': '3d',
  'model/stl': '3d',
  'model/3mf': '3d',

  // Acrobat types
  'application/pdf': 'acrobat',

  // Android types
  'application/vnd.android.package-archive': 'android',

  // Audio types
  'audio/mpeg': 'audio',
  'audio/wav': 'audio',
  'audio/ogg': 'audio',
  'audio/flac': 'audio',
  'audio/aac': 'audio',

  // Binary types
  'application/octet-stream': 'binary',
  'application/x-msdownload': 'binary',
  'application/x-binary': 'binary',

  // Code types
  'application/javascript': 'code',
  'text/css': 'code',
  'text/html': 'code',
  'application/json': 'code',
  'application/xml': 'code',
  'text/x-python': 'code',
  'text/x-java-source': 'code',
  'text/x-csrc': 'code',
  'text/x-c++src': 'code',
  'application/x-sh': 'code',

  // Compressed types
  'application/zip': 'compressed',
  'application/x-tar': 'compressed',
  'application/gzip': 'compressed',
  'application/x-rar-compressed': 'compressed',
  'application/x-7z-compressed': 'compressed',

  // Document types
  'application/msword': 'document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'document',
  'application/vnd.oasis.opendocument.text': 'document',
  'text/plain': 'document',

  // Drive types
  'application/x-iso9660-image': 'drive',
  'application/x-cd-image': 'drive',

  // Font types
  'font/ttf': 'font',
  'font/otf': 'font',
  'font/woff': 'font',
  'font/woff2': 'font',

  // Image types
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/gif': 'image',
  'image/webp': 'image',
  'image/bmp': 'image',

  // Presentation types
  'application/vnd.ms-powerpoint': 'presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'presentation',
  'application/vnd.oasis.opendocument.presentation': 'presentation',

  // Settings types
  'text/x-shellscript': 'settings',

  // Spreadsheet types
  'application/vnd.ms-excel': 'spreadsheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet': 'spreadsheet',

  // Vector types
  'image/svg+xml': 'vector',
  'application/postscript': 'vector',

  // Video types
  'video/mp4': 'video',
  'video/x-msvideo': 'video',
  'video/mpeg': 'video',
  'video/webm': 'video',
  'video/ogg': 'video',
};
