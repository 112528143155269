import { FieldTypeId } from '../../models/json_schemas';

export interface TypeConfig {
  typeId: FieldTypeId;
  name: string;
  type: 'array' | 'string' | 'boolean' | 'number';
  format?: 'uri' | 'date' | 'date-time' | 'email';
  uniqueItems?: boolean;
  entity?: string;
  displayAllowForManyEntities?: boolean;
  displayEnumForm?: boolean;
  uiSchema?: {
    'ui:widget': string;
  };
  code?: {
    source?: string;
    variables?: string[];
  };
}

export const schemaTypesConfig: TypeConfig[] = [
  { typeId: FieldTypeId.SingleLine, name: 'Single Line Text', type: 'string' },
  { typeId: FieldTypeId.Attachments, name: 'Attachments', type: 'array' },
  {
    typeId: FieldTypeId.MultiLine,
    name: 'Long Text',
    type: 'string',
    uiSchema: { 'ui:widget': 'textarea' },
  },
  {
    typeId: FieldTypeId.SingleSelect,
    name: 'Single Select',
    type: 'string',
    displayEnumForm: true,
  },
  {
    typeId: FieldTypeId.MultipleSelect,
    name: 'Multiple Select',
    type: 'array',
    uniqueItems: true,
    uiSchema: { 'ui:widget': 'checkboxes' },
    displayEnumForm: true,
  },
  { typeId: FieldTypeId.Checkbox, name: 'Checkbox', type: 'boolean' },
  { typeId: FieldTypeId.Number, name: 'Number', type: 'number' },
  { typeId: FieldTypeId.Uri, name: 'URL', type: 'string', format: 'uri' },
  { typeId: FieldTypeId.Date, name: 'Date', type: 'string', format: 'date' },
  {
    typeId: FieldTypeId.DateTime,
    name: 'Date Time',
    type: 'string',
    format: 'date-time',
  },
  { typeId: FieldTypeId.Email, name: 'Email', type: 'string', format: 'email' },
  {
    typeId: FieldTypeId.User,
    name: 'User',
    type: 'array',
    entity: 'User',
    displayAllowForManyEntities: true,
  },
  { typeId: FieldTypeId.CodePython, name: 'Calculation', type: 'string' },
];
