import { FieldDisplayProps } from '../../index';
import { Icon, Text, HStack } from '@chakra-ui/react';
import NullFieldDisplay from '../NullFieldDisplay';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

const BooleanFieldDisplay = ({ field }: FieldDisplayProps) => {
  const fieldHasValue = field.value !== null;

  if (fieldHasValue) {
    return (field.value as boolean) ? (
      <HStack>
        <Icon as={CheckIcon} boxSize={6} />
        <Text>Yes</Text>
      </HStack>
    ) : (
      <HStack>
        <Icon as={XMarkIcon} boxSize={6} />
        <Text>No</Text>
      </HStack>
    );
  }
  return <NullFieldDisplay />;
};

export default BooleanFieldDisplay;
