import {
  Icon,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Link,
  PopoverHeader,
  IconProps,
  Text,
} from '@chakra-ui/react';
import { Label } from '../Layout';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { InfoIcon } from '@chakra-ui/icons';
export interface MoreInfoPopOverProps {
  title: string;
  description?: string;
  link?: string;
  iconProps?: IconProps;
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | undefined;
}

const MoreInfoPopOver = ({
  title,
  description,
  link,
  iconProps,
  placement = 'left-end',
}: MoreInfoPopOverProps) => {
  return (
    <Popover trigger="hover" placement={placement} offset={[0, 10]}>
      <PopoverTrigger>
        <Icon
          transition={'all .2s ease-in-out'}
          boxSize={4}
          as={InfoIcon}
          data-testid={'more-info-trigger'}
          _hover={{
            color: useColorModeValue('neutral.400', 'neutral.200'),
            cursor: 'help',
          }}
          color={useColorModeValue('neutral.500', 'neutral.300')}
          {...iconProps}
        />
      </PopoverTrigger>
      <PopoverContent shadow={'md'}>
        <PopoverArrow />
        <PopoverHeader
          px={4}
          pt={4}
          pb={2}
          roundedTop={'md'}
          bg={'transparent'}
        >
          <Label>{title}</Label>
        </PopoverHeader>
        <PopoverBody
          px={4}
          pt={0}
          pb={2}
          rounded={'md'}
          fontSize={'sm'}
          bg={useColorModeValue('white', 'neutral.900')}
          lineHeight={1.4}
          fontWeight={'normal'}
          color={'var(--chakra-colors-chakra-body-text)'}
        >
          {description && <Text mb={2}>{description}</Text>}
          {link && (
            <Link
              isExternal
              href={link}
              target="_blank"
              fontWeight="bold"
              color="brand.base"
            >
              Open documentation
              <Icon ml={1} boxSize={3} as={ArrowTopRightOnSquareIcon} />
            </Link>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default MoreInfoPopOver;
