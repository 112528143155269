import { defineStyleConfig } from '@chakra-ui/react';

export const Checkbox = defineStyleConfig({
  baseStyle: {},

  // The default size and variant values
  defaultProps: {
    size: 'md',
    colorScheme: 'brand',
  },
});
