import { Box, HStack, Text, VStack, useColorMode } from '@chakra-ui/react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';

const tooltipFormatter = (value: string, entry: any) => {
  return (
    <span style={{ color: 'black', fontSize: 14, fontWeight: 'normal' }}>
      {value}
    </span>
  );
};

const legendFormatter = (value: string) => {
  return (
    <span style={{ color: '#666', fontSize: 14, fontWeight: 'normal' }}>
      {value}
    </span>
  );
};

export type TValidMindBarDef = {
  dataKey: string;
  fill: string;
  stackId: string;
  onClick?: (data: any) => void;
};

type TValidMindBarChart = {
  id: string;
  data: any[];
  barColumnName: string;
  bars: TValidMindBarDef[];
  yAxisLabel?: string;
  showLegendValue?: boolean;
};

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, showValue }: any) => {
  if (active && payload && payload.length) {
    return (
      <VStack
        position={'relative'}
        bgColor="white"
        p={3}
        borderColor="neutral.200"
        borderWidth={1}
        borderStyle="solid"
        spacing={3}
      >
        <Text fontWeight={600}>{label}</Text>
        <VStack alignItems="flex-start">
          {payload
            .filter((p: any) => !!p.value)
            .map((p: any) => (
              <HStack>
                <Box bgColor={p.fill} w={3} h={2} />
                <HStack spacing={2}>
                  <HStack spacing={0}>
                    <Text>{p.name || p.dataKey}</Text>
                    {!!showValue && <Text key={p.dataKey}>:</Text>}
                  </HStack>
                  {!!showValue && <Text key={p.dataKey}>{p.value}</Text>}
                </HStack>
              </HStack>
            ))}
        </VStack>
      </VStack>
    );
  }

  return null;
};

const CustomXAxisTick = ({ x, y, payload, width }: any) => {
  if (payload?.value) {
    return (
      <g transform={`translate(${x},${y})`}>
        <title>{`${payload.value}`}</title>
        <text
          x={0}
          y={0}
          dy={30}
          dx={30}
          textAnchor="middle"
          fill="#666"
          transform="rotate(45)"
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
          }}
        >
          {`${payload.value.substring(0, 10)}${
            payload.value.length > 10 ? '...' : ''
          }`}
        </text>
      </g>
    );
  }
  return null;
};

export const ValidmindBarChart = ({
  id,
  data,
  barColumnName,
  bars,
  yAxisLabel = 'Number of records',
  showLegendValue = true,
}: TValidMindBarChart) => {
  const { colorMode } = useColorMode();

  const estimateMaxTextLength = (): number => {
    let longestStringCharCount = 0;
    data.forEach(datum => {
      const columnTextLength = datum[barColumnName].length;
      if (longestStringCharCount < columnTextLength) {
        longestStringCharCount = columnTextLength;
      }
    });
    return longestStringCharCount;
  };

  //const maxTextLengthPx = estimateMaxTextLength() * 7 + 24;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        stackOffset="sign"
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" y={0} vertical={false} />
        <XAxis
          dataKey={barColumnName}
          style={{
            fontSize: '14px',
          }}
          angle={45}
          minTickGap={-200}
          height={100}
          textAnchor="start"
          interval={0}
          tick={<CustomXAxisTick />}
        />
        <YAxis
          domain={[0, 'dataMax']}
          tickCount={5}
          allowDecimals={false}
          label={{
            value: yAxisLabel,
            dx: -10,
            angle: -90,
            style: {
              fontSize: '14px',
              fontWeight: 'normal',
            },
          }}
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
          }}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          content={<CustomTooltip showValue={showLegendValue} />}
          formatter={tooltipFormatter}
          wrapperStyle={{
            fontSize: '14px',
            fontWeight: 'normal',
            color: 'black',
            zIndex: 1000,
          }}
          cursor={{ fill: colorMode === 'light' ? '#e8ecf4' : '#243764' }}
        />
        {/* <Legend formatter={textFormatter} /> */}
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          formatter={legendFormatter}
          wrapperStyle={{
            paddingLeft: '15px',
            paddingBottom: '75px',
          }}
        />

        {bars.map(bar => (
          <Bar {...bar} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );

  // Code for horizontal bar graph

  // return (
  //   <ResponsiveContainer width="100%" height="100%">
  //     <BarChart
  //       id={id}
  //       key={id}
  //       width={600}
  //       height={300}
  //       data={data}
  //       layout="vertical"
  //       margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
  //     >
  //       <XAxis
  //         domain={[0, 'dataMax']}
  //         type="number"
  //         tickCount={5}
  //         allowDecimals={false}
  //         axisLine={false}
  //         tickLine={false}
  //         tick={{ fill: 'var(--chakra-colors-chakra-body-text)' }}
  //       />
  //       <YAxis
  //         type="category"
  //         dataKey={barColumnName}
  //         width={maxTextLengthPx}
  //         style={{
  //           fontSize: '14px',
  //         }}
  //         tick={{ fill: 'var(--chakra-colors-chakra-body-text)' }}
  //         interval={0}
  //       />
  //       <CartesianGrid strokeDasharray="3 3" y={0} horizontal={false} />
  //       <Tooltip
  //         content={<CustomTooltip showValue={showLegendValue} />}
  //         formatter={textFormatter}
  //         wrapperStyle={{
  //           fontSize: '14px',
  //           fontWeight: 'normal',
  //           color: 'black',
  //           zIndex: 1000,
  //         }}
  //         cursor={{ fill: colorMode === 'light' ? '#e8ecf4' : '#243764' }}
  //       />
  //       <Legend formatter={textFormatter} />
  //       {bars.map(bar => (
  //         <Bar {...bar} />
  //       ))}
  //     </BarChart>
  //   </ResponsiveContainer>
  // );
};
