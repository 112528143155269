import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { AvailableSort, APIRequestSortBy } from '../../models/utils';
import { Icon } from '@chakra-ui/icons';
import { Label } from '../Layout';

function getSortObject(
  sorts: AvailableSort[],
  input: string,
): APIRequestSortBy | null {
  /* This getSortObject function splits the input string by ':' to get the field and order.
  Then, it iterates over the sorts array to find the matching field and order. If a match is found,
  it returns the desired object with the label, field, and order properties.
  If no match is found, it returns null.
   */
  const [field, order] = input.split(':');

  for (const sort of sorts) {
    if (sort.field === field) {
      for (const criteria of sort.criteria) {
        if (criteria.order === order) {
          return {
            label: sort.fieldLabel,
            field: sort.field,
            order: criteria.order,
            orderLabel: criteria.orderLabel,
          };
        }
      }
    }
  }

  return null;
}

interface ListingSortButtonProps {
  sorts: AvailableSort[];
  sortBy?: APIRequestSortBy;
  setSortBy: (sortBy: APIRequestSortBy | undefined) => void;
}
export default function ListingSortButton({
  sorts,
  sortBy,
  setSortBy,
}: ListingSortButtonProps) {
  const [value, setValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (sortBy) {
      setValue(`${sortBy.field}:${sortBy.order}`);
    }
  }, [sortBy]);

  const handleClickSort = (availableSort: AvailableSort, criteria: string) => {
    const sortObject = getSortObject(sorts, criteria);
    if (sortObject) {
      setSortBy(sortObject);
    }
    handleClose();
  };

  return (
    <>
      <Popover
        strategy={'absolute'}
        closeOnBlur={true}
        closeOnEsc={true}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={Bars3BottomLeftIcon} boxSize={5} />}
            variant={'ghost'}
          >
            Sort
          </Button>
        </PopoverTrigger>

        <Portal>
          <PopoverContent
            w={'fit-content'}
            maxW={'90rem'}
            mr={5}
            boxShadow={'xl'}
          >
            <PopoverArrow />
            <PopoverHeader
              py={4}
              px={10}
              fontSize={'lg'}
              fontWeight={'semibold'}
            >
              Select a sort criteria
            </PopoverHeader>

            <PopoverCloseButton size={'md'} m={2} />
            <PopoverBody overflowX={'auto'} py={5} px={10}>
              <SimpleGrid columns={5} spacing={12}>
                {sorts.map(s => {
                  return (
                    <Stack key={s.field}>
                      <Label>{s.fieldLabel}</Label>
                      <RadioGroup
                        onChange={e => handleClickSort(s, e)}
                        value={value}
                      >
                        <Stack direction="column">
                          {s.criteria.map(c => {
                            return (
                              <Radio
                                size={'lg'}
                                key={c.order}
                                value={`${s.field}:${c.order}`}
                                whiteSpace={'nowrap'}
                              >
                                {c.orderLabel}
                              </Radio>
                            );
                          })}
                        </Stack>
                      </RadioGroup>
                    </Stack>
                  );
                })}
              </SimpleGrid>
              {/* </HStack> */}
            </PopoverBody>
            {/* <PopoverFooter p={5}>
              <Flex justifyContent={'flex-end'}>
                <Button variant="ghost" mr={3} onClick={onClearSort}>
                  Reset
                </Button>
                <Button colorScheme="brand" onClick={onSort} disabled={!value}>
                  Sort
                </Button>
              </Flex>
            </PopoverFooter> */}
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
}
