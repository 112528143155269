import { Center, CircularProgress, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export interface ILoadingContainerProps {
  isLoading: boolean;
  children: any | undefined;
}

const LoadingContainer = React.memo(function LoadingContainer({
  isLoading,
  children,
}: ILoadingContainerProps) {
  if (isLoading) {
    return (
      <Center h={'full'} w={'full'}>
        <Stack justify={'center'} align={'center'} p={20}>
          <CircularProgress
            size="40px"
            thickness="2px"
            isIndeterminate
            color="brand.base"
          />
          <Text fontSize={'xs'} color={'inherit'}>
            Please hold...
          </Text>
        </Stack>
      </Center>
    );
  }

  return children;
});

export { LoadingContainer };
