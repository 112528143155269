import {
  Select,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { upperFirst } from 'lodash';
import {
  InventoryModelStage,
  InventoryModelStages,
  TInventoryModel,
} from '../../models/inventory_model';
import EditableField from '../EditableField';
import { useEffect, useMemo, useState } from 'react';
import ConfirmationAlert from '../ConfirmationAlert';
import { useMutation } from 'react-query';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

interface ModelStageFieldProps {
  inventoryModel: TInventoryModel;
  onSave?: () => void;
  readOnly?: boolean;
}

const availableStages: InventoryModelStage[] = [
  InventoryModelStages.ACTIVE,
  InventoryModelStages.ARCHIVED,
  InventoryModelStages.DELETED,
];

const ModelStageField: React.FC<ModelStageFieldProps> = ({
  inventoryModel,
  onSave,
  readOnly = false,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const [stages, setStages] = useState<string[]>(availableStages);
  const [currentStage, setCurrentStage] = useState(inventoryModel.stage);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const navigate = useNavigate();
  const [pendingStage, setPendingStage] = useState<InventoryModelStage | null>(
    null,
  );

  useEffect(() => {
    setCurrentStage(inventoryModel.stage);
  }, [inventoryModel.stage]);

  useEffect(() => {
    if (currentStage === InventoryModelStages.ACTIVE) {
      setStages([InventoryModelStages.ACTIVE, InventoryModelStages.ARCHIVED]);
    } else {
      setStages(availableStages);
    }
  }, [currentStage]);

  const postStage = useMutation(
    async (stage: InventoryModelStage) => {
      const token = await getAccessTokenSilently();
      return API.PostInventoryModelStage(token, inventoryModel, stage);
    },
    {
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error',
            description: error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      },
    },
  );

  const stageChanged = useMemo(() => {
    return currentStage !== inventoryModel.stage;
  }, [inventoryModel.stage, currentStage]);

  const handleSave = async (onFinished: (success: boolean) => void) => {
    if (stageChanged) {
      if (currentStage === InventoryModelStages.ARCHIVED) {
        setPendingStage(InventoryModelStages.ARCHIVED);
        setConfirmArchive(true);
        onFinished(false); // Don't close the editable field yet
      } else if (currentStage === InventoryModelStages.DELETED) {
        setPendingStage(InventoryModelStages.DELETED);
        setConfirmDelete(true);
        onFinished(false); // Don't close the editable field yet
      } else {
        await updateModelStage(currentStage, onFinished);
      }
    } else {
      onFinished(true);
    }
  };

  const updateModelStage = async (
    stage: InventoryModelStage,
    onFinished: (success: boolean) => void,
  ) => {
    postStage.mutate(stage, {
      onSuccess: () => {
        toast({
          description:
            stage === InventoryModelStages.ARCHIVED
              ? `Model "${inventoryModel.name}" has been archived successfully`
              : stage === InventoryModelStages.DELETED
              ? `Model "${inventoryModel.name}" has been deleted successfully`
              : `Model "${inventoryModel.name}" has been updated successfully`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        if (stage === InventoryModelStages.DELETED) {
          navigate('/model-inventory', { replace: true });
        }

        if (onSave) {
          onSave();
        }

        onFinished(true);
      },
      onError: () => {
        onFinished(false);
      },
    });
  };

  const onConfirmArchive = (confirmed: boolean) => {
    if (confirmed && pendingStage === InventoryModelStages.ARCHIVED) {
      updateModelStage(InventoryModelStages.ARCHIVED, success => {
        if (success) {
          setCurrentStage(InventoryModelStages.ARCHIVED);
        }
      });
    }
    setConfirmArchive(false);
    setPendingStage(null);
  };

  const onConfirmDelete = (confirmed: boolean) => {
    if (confirmed && pendingStage === InventoryModelStages.DELETED) {
      updateModelStage(InventoryModelStages.DELETED, success => {
        if (success) {
          setCurrentStage(InventoryModelStages.DELETED);
        }
      });
    }
    setConfirmDelete(false);
    setPendingStage(null);
  };

  return (
    <>
      <ConfirmationAlert
        open={confirmArchive}
        onConfirm={onConfirmArchive}
        title={'Archive Model'}
        dialogBody={'Are you sure you want to archive this model?'}
        confirmButton={'Yes, archive it'}
      />
      <ConfirmationAlert
        open={confirmDelete}
        onConfirm={onConfirmDelete}
        title={'Delete Model'}
        dialogBody={'Are you sure you want to delete this model?'}
        confirmButton={'Yes, delete it'}
      />

      <EditableField
        readOnly={readOnly}
        onCancel={() => {
          setCurrentStage(inventoryModel.stage);
          setPendingStage(null);
        }}
        onSave={handleSave}
        title={'Model Stage'}
        moreInfoPopoverProps={{
          title: 'Model Stage',
          description: 'Keep your model inventory accurate and up to date with your organization’s current resources.',
          link: 'https://docs.validmind.ai/guide/model-inventory/archive-delete-models.html',
          iconProps: {
            ml: 2,
            opacity: 0,
            _groupHover: {opacity: 1},
          },
        }}
        renderField={(mode: 'edit' | 'read-only') => {
          if (mode === 'edit') {
            return (
              <Stack w={'full'}>
                <Select
                  bg={useColorModeValue('white', 'neutral.850')}
                  focusBorderColor="brand.base"
                  value={currentStage}
                  onChange={e => {
                    setCurrentStage(e.target.value as InventoryModelStage);
                  }}
                >
                  {stages.map(s => (
                    <option key={s} value={s}>
                      {upperFirst(s)}
                    </option>
                  ))}
                </Select>
              </Stack>
            );
          }
          return (
            <Stack w={'full'}>
              <Text>{upperFirst(currentStage)}</Text>
            </Stack>
          );
        }}
      />
    </>
  );
};

export default ModelStageField;
