import { useContext, useEffect } from 'react';
import {
  Text,
  VStack,
  Box,
  Heading,
  HStack,
  Stack,
  Spacer,
  Icon,
} from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import Breadcrumbs from '../../../components/Breadcrumbs';
import UserRoleTable from '../../../components/UserRoleTable';
import SidebarContext from '../../../contexts/SidebarContext';
import useOrganizationUsersWithRoles from '../../../hooks/useOrganizationUsersWithRoles';
import { ContentPageTitle, PrimaryButton } from '../../../components/Layout';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';

export default function UserDirectory() {
  const { organizationUsersWithRoles } = useOrganizationUsersWithRoles();
  const { setInSettings } = useContext(SidebarContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);
  const navigateToInviteUser = () => {
    let path = `/settings/invitation`;
    navigate(path);
  };
  return (
    <VStack
      alignItems={'start'}
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w={'full'}
      maxWidth={'7xl'}
      mx={'auto'}
      overflow={'auto'}
      className="no-scrollbar"
    >
      <Box>
        <Breadcrumbs />
        <ContentPageTitle>
          User Directory
          <MoreInfoPopOver
            title="User Management"
            link="https://docs.validmind.ai/guide/configuration/manage-users.html"
            placement="right-end"
            iconProps={{
              ml: 2,
            }}
          />
        </ContentPageTitle>
      </Box>
      <Text>
        Each person accessing ValidMind needs a separate user account and you
        can manage all users on this page.
      </Text>
      <Stack w={'full'}>
        <HStack>
          <Heading size="md">Organization Users</Heading>
          <Spacer />
          <PrimaryButton
            leftIcon={<Icon as={PlusIcon} boxSize={4} />}
            variant={'ghost'}
            onClick={navigateToInviteUser}
            data-testid="invite-users-button"
          >
            <Text>Invite New Users</Text>
          </PrimaryButton>
        </HStack>
        <UserRoleTable
          users={organizationUsersWithRoles}
          enableRoleManagement={true}
          onRoleAdded={() =>
            queryClient.invalidateQueries('organization-users-with-roles')
          }
          onRoleDeleted={() =>
            queryClient.invalidateQueries('organization-users-with-roles')
          }
        />
      </Stack>
    </VStack>
  );
}
