import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { TrashIcon, UserMinusIcon } from '@heroicons/react/24/outline';
import API from '../../../api/API';
import ConfirmationAlert from '../../../components/ConfirmationAlert';
import { PrimaryButton, SecondaryButton } from '../../../components/Layout';
import UserRoleTable from '../../../components/UserRoleTable';
import useOrganizationUsersWithRoles from '../../../hooks/useOrganizationUsersWithRoles';
import { TGroup, TUser } from '../../../models';

interface GroupMemberEditorProps {
  group: TGroup;
  membersToRemove: string[];
}

export default function GroupMemberEditor({
  group,
  membersToRemove,
}: GroupMemberEditorProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { organizationUsersWithRoles } = useOrganizationUsersWithRoles();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [showRemoveMembersConfirmation, setShowRemoveMembersConfirmation] =
    useState(false);
  const [showDeleteGroupConfirmation, setShowDeleteGroupConfirmation] =
    useState(false);
  const [membersToAdd, setMembersToAdd] = useState<string[]>([]);

  useEffect(() => {
    setMembersToAdd([]);
  }, [isOpen]);

  const handleUserSelect = (selectedUsers: TUser[]): TUser[] => {
    setMembersToAdd(selectedUsers.map(({ cuid }) => cuid));
    return selectedUsers;
  };

  const removeMemberMutation = useMutation(
    [],
    async ({
      groupId,
      membersToRemove,
    }: {
      groupId: string;
      membersToRemove: string[];
    }) => {
      const accessToken = await getAccessTokenSilently();
      await API.RemoveUsersFromGroup(accessToken, groupId, membersToRemove);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('groups-members');
      },
    },
  );

  const handleRemoveMember = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      removeMemberMutation.mutate({ groupId: group.cuid, membersToRemove });
    }
    setShowRemoveMembersConfirmation(false);
  };

  const deleteGroupMutation = useMutation(
    [],
    async (groupId: string) => {
      const accessToken = await getAccessTokenSilently();
      await API.DeleteGroup(accessToken, groupId);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('groups');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error deleting group',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const handleDeleteGroup = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      deleteGroupMutation.mutate(group.cuid);
    }
    setShowDeleteGroupConfirmation(false);
  };

  const addToGroupGroupMutation = useMutation(
    [],
    async ({
      groupId,
      membersToAdd,
    }: {
      groupId: string;
      membersToAdd: string[];
    }) => {
      const accessToken = await getAccessTokenSilently();
      await API.AddUsersToGroup(accessToken, groupId, membersToAdd);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('groups-members');
      },
    },
  );

  const handleAddToGroup = async () => {
    addToGroupGroupMutation.mutate({ groupId: group.cuid, membersToAdd });
    onClose();
  };

  return (
    <>
      <HStack pt="6" justifyContent={'space-between'}>
        <SecondaryButton
          isDisabled={
            membersToRemove.length === 0 || showDeleteGroupConfirmation
          }
          variant="ghost"
          fontSize="sm"
          leftIcon={<Icon as={UserMinusIcon} boxSize={5} strokeWidth={2.5} />}
          onClick={() => setShowRemoveMembersConfirmation(true)}
          data-testid="remove-member"
        >
          Remove Member
        </SecondaryButton>
        {!group.is_default && (
          <SecondaryButton
            isDisabled={showRemoveMembersConfirmation}
            variant="ghost"
            _hover={{
              color: 'red.600',
              bg: 'red.100',
            }}
            fontSize="sm"
            leftIcon={<Icon as={TrashIcon} boxSize={5} strokeWidth={2.5} />}
            onClick={() => setShowDeleteGroupConfirmation(true)}
            data-testid="delete-group"
          >
            Delete Group
          </SecondaryButton>
        )}
        <PrimaryButton
          colorScheme="pink"
          fontSize="sm"
          leftIcon={<AddIcon />}
          onClick={() => onOpen()}
          data-testid="add-new-member"
        >
          Add New Member
        </PrimaryButton>
      </HStack>
      <ConfirmationAlert
        open={showRemoveMembersConfirmation}
        title="Remove Member"
        dialogBody={`${membersToRemove.length} member${
          membersToRemove.length > 1 ? 's' : ''
        } will be removed from the "${
          group.name
        }" group and lose access to the assigned models. Would you like to continue?`}
        cancelButton={<Button variant="ghost">Cancel</Button>}
        confirmButton={
          <Button
            leftIcon={<Icon as={UserMinusIcon} strokeWidth={2.5} />}
            _hover={{
              color: 'red.600',
              bg: 'red.100',
            }}
          >
            {`Remove ${membersToRemove.length} Member${
              membersToRemove.length > 1 ? 's' : ''
            }`}
          </Button>
        }
        onConfirm={handleRemoveMember}
        size="3xl"
      />
      <ConfirmationAlert
        open={showDeleteGroupConfirmation}
        title="Delete Group"
        dialogBody={`Are you sure you'd like to delete ${group.name} Group?
          All users will remain in the system but they will lose access to the models the group has access to.`}
        cancelButton={<Button variant="ghost">No, Cancel</Button>}
        confirmButton={
          <Button
            leftIcon={<Icon as={TrashIcon} strokeWidth={2.5} />}
            _hover={{
              color: 'red.600',
              bg: 'red.100',
            }}
          >
            Yes, Delete
          </Button>
        }
        onConfirm={handleDeleteGroup}
        size="3xl"
      />
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalHeader p={0}>Add New Member</ModalHeader>
          <ModalCloseButton mt={3.5} mr={2} />
          <ModalBody pt={4} pl={0} pr={0} data-testid="add-to-group-modal">
            <UserRoleTable
              users={organizationUsersWithRoles}
              enableRoleManagement={false}
              onUserSelect={handleUserSelect}
            />
            <HStack pt={6} justifyContent={'end'}>
              <Button
                isDisabled={membersToAdd.length === 0}
                fontSize="sm"
                leftIcon={<AddIcon />}
                onClick={handleAddToGroup}
              >
                Add to Group
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
