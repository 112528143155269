import {
  RJSFSchemaSettingsProperty,
  TInventoryModel,
  TInventoryModelCustomField,
} from '../../models/inventory_model';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import UsersContext from '../../contexts/UsersContext';
import { JSONSchema7 } from 'json-schema';
import { useToast } from '@chakra-ui/react';

interface PostModelInventoryCustomFieldProps {
  inventoryModel: TInventoryModel;
  propertyKey: string;
  formData: any;
}

const useModelCustomField = (
  inventoryModel: TInventoryModel,
  propertyKey: string,
) => {
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const { currentOrganization } = useContext(UsersContext);
  const queryClient = useQueryClient();

  /**
   * Creates a placeholder object for an inventory model's custom field or core field,
   * based on the current organization's inventory model schema.
   */
  const [placeholder, setPlaceholder] =
    useState<TInventoryModelCustomField | null>();
  /**
   * This function checks both custom and core schemas for the presence of a specified propertyKey, and
   * generates placeholder data accordingly.
   *
   * @returns {TInventoryModelCustomField | null} The placeholder data if the propertyKey
   * is found within the schema properties, or null if not found.
   */
  const createPlaceholder = (): TInventoryModelCustomField | null => {
    /**
     * Helper function to construct placeholder data using the provided schema properties,
     * settings properties, and value for a specific propertyKey.
     *
     * @param {JSONSchema7} schemaProperties - The schema properties of the current organization's inventory model.
     * @param {{ [p: string]: RJSFSchemaSettingsProperty }} settingsProperties - The settings properties of the current organization's inventory model.
     * @param {any} value - The value to be used for the placeholder, derived from the inventory model's custom fields or directly.
     * @returns {TInventoryModelCustomField} The constructed placeholder data object, including schema, settings, and the current value.
     */

    const getPlaceholderData = (
      schemaProperties: JSONSchema7,
      settingsProperties: { [p: string]: RJSFSchemaSettingsProperty },
      value: any,
    ): TInventoryModelCustomField =>
      ({
        schema: {
          type: 'object',
          properties: {
            [propertyKey]: schemaProperties[propertyKey as keyof object],
          },
        } as JSONSchema7,
        settings: {
          properties: {
            [propertyKey]: settingsProperties?.[propertyKey as keyof object],
          },
        },
        key: propertyKey,
        value,
      } as TInventoryModelCustomField);

    // Attempt to generate placeholder data for custom fields
    const customSchemaProperties =
      currentOrganization?.inventory_model_schema.custom.schema.properties;
    const customSettingsProperties =
      currentOrganization?.inventory_model_schema.custom.settings.properties;

    // Check if the propertyKey exists in both custom schema and settings
    if (
      inventoryModel.custom_fields &&
      customSchemaProperties?.[propertyKey] &&
      customSettingsProperties?.[propertyKey]
    ) {
      const value = inventoryModel.custom_fields[propertyKey] ?? null;
      return getPlaceholderData(
        customSchemaProperties,
        customSettingsProperties,
        value,
      );
    }

    // Attempt to generate placeholder data for core fields
    const coreSchemaProperties =
      currentOrganization?.inventory_model_schema.core.schema.properties;
    const coreSettingsProperties =
      currentOrganization?.inventory_model_schema.core.settings.properties;

    // Check if the propertyKey exists in both core schema and settings
    if (
      inventoryModel &&
      coreSchemaProperties?.[propertyKey] &&
      coreSettingsProperties?.[propertyKey]
    ) {
      const value =
        inventoryModel[propertyKey as keyof typeof inventoryModel] ?? null;
      return getPlaceholderData(
        coreSchemaProperties,
        coreSettingsProperties,
        value,
      );
    }

    // Return null if the propertyKey is not found in either custom or core schema properties,
    // when null is returned, the query will be enabled and the data will be fetched from the API.
    return null;
  };

  useEffect(() => {
    setPlaceholder(createPlaceholder());
  }, [propertyKey, currentOrganization, inventoryModel]);

  const query = useQuery(
    ['inventory-model', inventoryModel.cuid, 'custom-field', propertyKey],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetModelInventoryCustomField(
        accessToken,
        inventoryModel,
        propertyKey,
      );
    },
    {
      enabled: placeholder === null,
      placeholderData: placeholder ?? undefined,
    },
  );

  const postCustomFieldData = useMutation(
    ['inventory-model', inventoryModel.cuid, 'custom-field', propertyKey],
    async ({
      inventoryModel,
      propertyKey,
      formData,
    }: PostModelInventoryCustomFieldProps) => {
      const accessToken = await getAccessTokenSilently();
      return API.PostModelInventoryCustomField(
        accessToken,
        inventoryModel,
        propertyKey,
        formData,
      );
    },
    {
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error updating model inventory field',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
      onSuccess: data => {
        if (placeholder) {
          setPlaceholder({
            ...placeholder,
            value: data.value,
          });
        }
        // Invalidate the cache for the inventory model to reflect the updated field
        queryClient.invalidateQueries({
          queryKey: ['inventory-model', inventoryModel.cuid],
        });
      },
    },
  );

  return { ...query, postCustomFieldData };
};

export default useModelCustomField;
