import { createContext } from 'react';
import { TInventoryModel } from '../models/inventory_model';
import { TPermissionAction } from '../models/role';

interface InventoryModelContextType {
  inventoryModel?: TInventoryModel;
  userHasInventoryModelPermission: (
    actions: TPermissionAction[],
    match: 'any' | 'all',
  ) => boolean;
}

const InventoryModelContext = createContext<InventoryModelContextType>({
  userHasInventoryModelPermission: (actions: TPermissionAction[], match: 'any' | 'all') =>
    false,
});

export default InventoryModelContext;
