import { Avatar, AvatarProps } from '@chakra-ui/react';
import { useFlags } from '../../hooks/useFlags';

type AvatarProxyProps = AvatarProps;

const defaultSource = undefined;

const getPictureUrl = (
  pictureUri: string | undefined,
  blacklistedDomains: string[],
) => {
  if (!pictureUri) return defaultSource;

  const isBlacklisted = blacklistedDomains.some(domain =>
    pictureUri.includes(domain),
  );

  return pictureUri && !isBlacklisted ? pictureUri : defaultSource;
};

export default function AvatarProxy(props: AvatarProxyProps) {
  const { releaseBlacklistedDomains } = useFlags();
  let source: any = defaultSource;

  // This will be undefined (false) if the flags are still loading
  if (releaseBlacklistedDomains) {
    const blacklistedDomains = releaseBlacklistedDomains as string[];
    source = getPictureUrl(props.src, blacklistedDomains);
  }

  return <Avatar {...props} src={source} />;
}
