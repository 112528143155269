import React, { AnchorHTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface SmartLinkProps
  extends LinkProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  isExternal?: boolean;
}

export const SmartLink: React.FC<SmartLinkProps> = ({
  to,
  isExternal = false,
  children,
  ...props
}) => {
  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }

  // Assume any relative links are internal
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};
