import { Responsive, WidthProvider } from 'react-grid-layout';
import { GRID_COLUMN_AMOUNT } from '../../layoutUtils';

const ResponsiveGridLayout = WidthProvider(Responsive);

type GridView = {
  layouts: {
    lg: ReactGridLayout.Layout[];
  };
  onLayoutChange?(
    currentLayout: ReactGridLayout.Layout[],
    allLayouts: ReactGridLayout.Layouts,
  ): void;
  children: any;
};

export default function GridView({
  layouts,
  children,
  onLayoutChange,
}: GridView) {
  return (
    <ResponsiveGridLayout
      useCSSTransforms={false}
      layouts={layouts}
      breakpoints={{ lg: 1000 }}
      cols={{ lg: GRID_COLUMN_AMOUNT }}
      rowHeight={100}
      resizeHandles={['se']}
      onLayoutChange={onLayoutChange}
    >
      {children}
    </ResponsiveGridLayout>
  );
}
