import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { Template, TUser } from '../../models';
import { TGroup } from '../../models/group';

interface UseGroupUsersOptions {
  group?: TGroup;
}

function useGroups() {
  const { getAccessTokenSilently } = useAuth0();
  const [groups, setGroups] = useState<TGroup[]>([]);

  const queryResponse = useQuery(
    ['groups'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const { results, total, limit } = await API.GetGroups(accessToken);
      for (
        let currentPage = 2;
        total > (currentPage - 1) * limit;
        currentPage++
      ) {
        const { results: additionalResults } = await API.GetGroups(
          accessToken,
          currentPage,
        );
        results.push(...additionalResults);
      }
      return { results };
    },
    {
      onSuccess: groups_ => {
        setGroups(groups_.results);
      },
    },
  );

  return { groups, ...queryResponse };
}

function useGroupUsers({ group }: UseGroupUsersOptions) {
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState<TUser[]>([]);

  const queryResponse = useQuery(
    ['group', group?.cuid, 'users'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetGroupUsers(accessToken, group!.cuid);
    },
    {
      onSuccess: data => {
        setUsers(data);
      },
      enabled: !!group,
    },
  );

  return { users, ...queryResponse };
}

function useGroupTemplates({ group }: UseGroupUsersOptions) {
  const { getAccessTokenSilently } = useAuth0();
  const [templates, setTemplates] = useState<Template[]>([]);

  const queryResponse = useQuery(
    ['group', group?.cuid, 'templates'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetGroupTemplates(accessToken, group!.cuid);
    },
    {
      onSuccess: data => {
        setTemplates(data.sort((a, b) => a.name.localeCompare(b.name)));
      },
      enabled: !!group,
    },
  );

  return { templates, ...queryResponse };
}

export { useGroups, useGroupUsers, useGroupTemplates };
