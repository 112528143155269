import { useContext, useEffect, useMemo } from 'react';
import {
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ContentPageTitle } from '../../../components/Layout';
import SidebarContext from '../../../contexts/SidebarContext';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingContainer } from '../../../components/LoadingContainer';
import API from '../../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import PermissionsList from '../../../components/PermissionsList';
import EditPermissionsModal from '../../../components/EditPermissionsModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useOrganizationUsersWithRoles from '../../../hooks/useOrganizationUsersWithRoles';
import UserRoleTable from '../../../components/UserRoleTable';
import { AddIcon } from '@chakra-ui/icons';
import CustomFieldForm from '../../../components/CustomFields/CustomField/CustomFieldForm';
import { RolePatchRequest } from '../../../models/role';
import {
  createStringCustomField,
  createStringMultilineCustomField,
} from '../../../utils';

export default function RoleDetails() {
  const { organizationUsersWithRoles } = useOrganizationUsersWithRoles();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { setInSettings } = useContext(SidebarContext);
  const { roleCUID } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const {
    isLoading,
    data: roleDetails,
    refetch,
  } = useQuery(
    ['roles', roleCUID, 'details'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetRolePermissions(accessToken, roleCUID!);
    },
    {
      enabled: roleCUID !== null,
    },
  );

  const filteredUsers = useMemo(() => {
    return organizationUsersWithRoles.filter(user => {
      return user.roles.some(role => role.role.cuid === roleCUID);
    });
  }, [organizationUsersWithRoles, roleCUID]);

  const filteredCategories = useMemo(() => {
    if (!roleDetails) return undefined;
    const newCategories = {} as any;
    let count = 0;
    for (const categoryKey in roleDetails?.categories) {
      const category = roleDetails?.categories[categoryKey];
      const filteredActions = category.filter(action => action.assigned);
      count += filteredActions.length;
      if (filteredActions.length > 0) {
        newCategories[categoryKey] = filteredActions;
      }
    }
    return { categories: newCategories, permissionsCount: count };
  }, [roleDetails]);

  const patchOrganizationRole = useMutation(
    async (data: RolePatchRequest) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PatchOrganizationRole(accessToken, data);
    },
    {
      onSuccess: () => {
        refetch();
        toast({
          title: 'Role updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const onEditName = (data: any) => {
    const name = data.name;
    patchOrganizationRole.mutate({
      cuid: roleCUID!,
      name,
    });
  };

  const onEditDescription = (data: any) => {
    const description = data.description;
    patchOrganizationRole.mutate({
      cuid: roleCUID!,
      description,
    });
  };

  const readOnly = roleDetails?.role.is_admin || roleDetails?.role.is_staff;

  return (
    <LoadingContainer isLoading={isLoading}>
      <EditPermissionsModal
        onClose={onClose}
        roleDetails={roleDetails}
        isOpen={isOpen}
        onSuccess={refetch}
      />
      <VStack
        alignItems="start"
        spacing={8}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={4}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <VStack w={'full'} alignItems={'start'}>
          <Breadcrumbs />

          {roleDetails && (
            <CustomFieldForm
              customField={createStringCustomField(
                'name',
                'Role Name',
                roleDetails.role.name,
              )}
              onEdit={onEditName}
              onCancel={data => {}}
              displayComponent={field => (
                <ContentPageTitle>{field.value}</ContentPageTitle>
              )}
              hideLabel={true}
              isLoading={patchOrganizationRole.isLoading}
              readOnly={readOnly}
            />
          )}
        </VStack>
        <VStack alignItems="start" w={'full'}>
          {roleDetails && (
            <CustomFieldForm
              customField={createStringMultilineCustomField(
                'description',
                'Role Description',
                roleDetails.role.description,
              )}
              onEdit={onEditDescription}
              onCancel={data => {}}
              displayComponent={field => <Text>{field.value}</Text>}
              hideLabel={true}
              isLoading={patchOrganizationRole.isLoading}
              readOnly={readOnly}
            />
          )}
        </VStack>
        <Tabs colorScheme="brand" w="full">
          <TabList>
            <Tab>Permissions</Tab>
            <Tab>Users</Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding={0} w="full">
              <VStack w="full" alignItems="flex-start" mt={8} spacing={4}>
                <HStack justifyContent="space-between" w="full">
                  <Heading size="md">Assigned Permissions</Heading>
                  <Tooltip
                    isDisabled={
                      !roleDetails?.role.is_admin && !roleDetails?.role.is_staff
                    }
                    hasArrow
                    label="You cannot edit permissions for the Customer Admin role."
                    shouldWrapChildren
                    mt="3"
                  >
                    <Button
                      isDisabled={
                        roleDetails?.role.is_admin || roleDetails?.role.is_staff
                      }
                      onClick={onOpen}
                    >
                      Edit Permissions
                    </Button>
                  </Tooltip>
                </HStack>
                {filteredCategories?.categories &&
                filteredCategories?.permissionsCount ? (
                  <PermissionsList
                    mode="view"
                    categories={filteredCategories.categories}
                    permissionsScope={roleDetails ? roleDetails.role.scope : "" }
                  />
                ) : (
                  <Text fontStyle={'italic'}>
                    This role does not have any permissions yet.
                    <br />
                    Click "Edit Permissions" to select permissions to be added
                    to this role.
                  </Text>
                )}
              </VStack>
            </TabPanel>
            <TabPanel padding={0} w="full">
              <VStack w="full" alignItems="flex-start" mt={8} spacing={4}>
                <Heading size="md">
                  Users with "{roleDetails?.role.name}" role
                </Heading>
                {filteredUsers && filteredUsers.length > 0 ? (
                  <UserRoleTable
                    users={filteredUsers}
                    enableRoleManagement={true}
                    onRoleAdded={() =>
                      queryClient.invalidateQueries(
                        'organization-users-with-roles',
                      )
                    }
                    onRoleDeleted={() =>
                      queryClient.invalidateQueries(
                        'organization-users-with-roles',
                      )
                    }
                  />
                ) : (
                  <>
                    <Text fontStyle={'italic'}>
                      No users have this role assigned.
                      <br />
                      To add this role to a user, find the user in the{' '}
                      <Button
                        variant={'link'}
                        colorScheme="brand"
                        onClick={() => navigate('/settings/user-directory')}
                      >
                        User Directory
                      </Button>{' '}
                      and click the{' '}
                      <IconButton
                        aria-label={'add-role'}
                        data-testid={'add-role-plus-button'}
                        rounded={'full'}
                        size={'sm'}
                        onClick={() => {
                          null;
                        }}
                        icon={<Icon as={AddIcon} color={'neutral.500'} />}
                      />{' '}
                      button to select the role you want to add.
                    </Text>
                  </>
                )}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </LoadingContainer>
  );
}
