import { Box } from '@chakra-ui/react';
import { useTemplateEditor } from '../../hooks/useTemplateEditor';
import { Accordion } from './Accordion';

const TemplateEditorTree = () => {
  const {
    state: { changedTemplateTree, isEditMode },
    updateSortableTemplateTree,
  } = useTemplateEditor();

  return (
    <Box>
      <Accordion
        isEditing={isEditMode}
        onTemplateChange={updateSortableTemplateTree}
        editorTemplateSectionTrees={changedTemplateTree}
      />
    </Box>
  );
};

export default TemplateEditorTree;
