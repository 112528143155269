import React from 'react';
import { Flex } from '@chakra-ui/react';

export default function MasterPage({ children }: any) {
  return (
    <Flex id="project-page" width="100%">
      {children}
    </Flex>
  );
}
