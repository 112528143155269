import React from "react"
import { TemplateEditorContext } from "../../contexts/TemplateEditorContext"

export const useTemplateEditor = () => {
  const context = React.useContext(TemplateEditorContext)
  if (context === undefined) {
    throw new Error(`useTemplateEditor must be used within a TemplateEditorProvider`)
  }
  return context
}
