import { FieldDisplayProps } from '../../index';
import NullFieldDisplay from '../NullFieldDisplay';
import React from 'react';
import RichTextContentEditor from '../../../../RichTextContentEditor';

const HTMLFieldDisplay = ({ field }: FieldDisplayProps) => {
  const fieldHasValue = !!field.value;
  if (fieldHasValue) {
    return (
      <RichTextContentEditor
        text={field.value}
        onSave={() => {}}
        allowEdit={false}
      />
    );
  }
  return <NullFieldDisplay />;
};

export default HTMLFieldDisplay;
