import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';

export default function useOrganizationAssessmentOptions() {
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(
    ['organization', 'assessment-options'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetOrganizationAssessmentOptions(accessToken);
    },
  );

  return { assessmentOptions: queryResponse.data, ...queryResponse };
}
