import ReactModal from 'react-modal';

interface IContentModalProps {
  children: any;
  isOpen: boolean;
  onRequestClose(event: React.MouseEvent | React.KeyboardEvent): void;
  style?: string;
}

const modalOverlay: React.CSSProperties = {
  background: 'rgba(0, 0, 0, .4)',
};

const centerModalStyles: ReactModal.Styles = {
  overlay: modalOverlay,
  content: {
    position: 'absolute',
    top: '10%',
    left: '15%',
    right: '15%',
    bottom: '10%',
    border: '0',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '8px',
    outline: 'none',
    padding: '20px',
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  },
};

const sideModalStyles: ReactModal.Styles = {
  overlay: modalOverlay,
  content: {
    position: 'absolute',
    top: '0%',
    left: '66%',
    right: '0%',
    bottom: '0%',
    border: '0',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '8px',
    outline: 'none',
    padding: '20px',
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  },
};

const modalStyles = {
  side: sideModalStyles,
  center: centerModalStyles,
};

ReactModal.setAppElement('#root');

export default function ContentModal({
  children,
  isOpen,
  onRequestClose,
  style = 'side',
}: IContentModalProps) {
  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyles[style as keyof object]}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
    >
      {children}
    </ReactModal>
  );
}
