import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { PrimaryButton } from '../Layout';

export default function AcceptInvitationButton({
  inviteCuid,
}: {
  inviteCuid: string;
}) {
  const { loginWithRedirect } = useAuth0();
  const { data, isLoading } = useQuery([], async () => {
    return API.GetInvitationCheck(inviteCuid);
  });

  if (isLoading) {
    return <Text>Please wait...</Text>;
  }

  if (data?.result === false) {
    return (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription>This invitation expired.</AlertDescription>
      </Alert>
    );
  }

  const appState = {
    inviteCuid: inviteCuid,
  };
  const appStateJSON = encodeURIComponent(JSON.stringify(appState));

  if (data?.user_exists === true) {
    const options = {
      appState: { appStateJSON: appStateJSON },
    }
    return (
      <PrimaryButton onClick={() => {
        loginWithRedirect(options)}
      }>
        Accept Invitation
      </PrimaryButton>
    )
  }

  const options = {
    appState: { appStateJSON: appStateJSON },
    screen_hint: 'signup',
  }

  return (
    <PrimaryButton onClick={() => loginWithRedirect(options)}>
      Accept Invitation
    </PrimaryButton>
  );
}
