import * as React from 'react';
import { SVGProps } from 'react';

function CubesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      width="20"
      height="21"
      fill="currentColor"
      {...props}
    >
      <g clipPath="url(#clip0_4476_50126)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5304 3.06057C6.66896 2.99283 6.83104 2.99283 6.9696 3.06057L11.9696 5.50502C12.1412 5.5889 12.25 5.76321 12.25 5.95421V9.79593L9.25 11.2686V13.8885L6.96248 14.9624C6.82537 15.0267 6.66647 15.0255 6.5304 14.959L1.53039 12.5145C1.35881 12.4306 1.25 12.2563 1.25 12.0653V5.95421C1.25 5.76321 1.35881 5.5889 1.53039 5.50502L6.5304 3.06057ZM2.25 6.75521L6.25 8.71076V13.7088L2.25 11.7532V6.75521ZM7.25 13.7227L8.25 13.2532V10.957C8.25 10.7663 8.35848 10.5922 8.52967 10.5082L11.25 9.17282V6.75521L7.25 8.71076V13.7227ZM6.75 7.8421L10.6116 5.95421L6.75 4.06632L2.88841 5.95421L6.75 7.8421Z"
          // fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5304 8.06057C13.669 7.99283 13.831 7.99283 13.9696 8.06057L18.9696 10.505C19.1412 10.5889 19.25 10.7632 19.25 10.9542V17.0653C19.25 17.2563 19.1412 17.4306 18.9696 17.5145L13.9696 19.959C13.831 20.0267 13.669 20.0267 13.5304 19.959L8.5304 17.5145C8.35881 17.4306 8.25 17.2563 8.25 17.0653V10.9542C8.25 10.7632 8.35881 10.5889 8.5304 10.505L13.5304 8.06057ZM9.25 11.7552L13.25 13.7108V18.7088L9.25 16.7532V11.7552ZM14.25 18.7088L18.25 16.7532V11.7552L14.25 13.7108V18.7088ZM13.75 12.8421L17.6116 10.9542L13.75 9.06632L9.88841 10.9542L13.75 12.8421Z"
          // fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4476_50126">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.759766)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CubesIcon;
