import { defineStyleConfig } from '@chakra-ui/react';

export const Popover = defineStyleConfig({
  baseStyle: {
    content: {
      rounded: 'lg',
    },
    popper: {
      borderColor: 'neutral.200',
      rounded: 'md',
      _dark: {
        borderColor: 'neutral.800',
      },
    },
    body: {
      bg: 'neutral.50',
      borderColor: 'transparent',
      _dark: {
        bg: 'neutral.900',
      },
    },
    header: {
      borderColor: 'transparent',
      bg: 'neutral.50',
      _dark: {
        borderColor: 'transparent',
        bg: 'neutral.900',
      },
    },
    footer: {
      borderColor: 'transparent',
      bg: 'neutral.50',
      _dark: {
        borderColor: 'transparent',
        bg: 'neutral.900',
      },
    },
  },

  // The default size and variant values
  defaultProps: {
    size: 'md',
  },
});
