import { useEffect, useState } from 'react';
import {
  TemplateEditorContext,
  TemplateEditorState,
} from '../../contexts/TemplateEditorContext';
import {
  JSONTemplate,
  Template,
  TemplateSection,
  convertToSectionTree,
} from '../../models/template';
import {
  EditorTemplateSectionTree,
  flattenTrees,
  toEditorTemplateSectionTrees,
} from './Accordion/utilities';
import _ from 'lodash';

interface TemplateEditorProviderProps {
  template: Template;
  type: string;
}

export const TemplateEditorProvider = ({
  children,
  template,
  type,
}: React.PropsWithChildren<TemplateEditorProviderProps>) => {
  const jsonTemplate = template.current_version!.json;

  const [state, setState] = useState<TemplateEditorState>(() => {
    return {
      isEditMode: false,
      baseTemplate: template,
      templateType: type,
      changedTemplateTree: toEditorTemplateSectionTrees(
        convertToSectionTree(jsonTemplate.sections),
      ),
    };
  });

  useEffect(() => {
    setState({
      isEditMode: false,
      baseTemplate: template,
      templateType: type,
      changedTemplateTree: toEditorTemplateSectionTrees(
        convertToSectionTree(jsonTemplate.sections),
      ),
    });
  }, [template, type]);

  const getChangedSectionTree = () =>
    convertToSectionTree(getCurrentJsonTemplate().sections);
  const getOriginalSectionTree = () =>
    convertToSectionTree(jsonTemplate.sections);

  const updateEditorTemplateSectionTrees = (
    tree: EditorTemplateSectionTree[],
  ) => {
    setState(prev => ({ ...prev, changedTemplateTree: tree }));
  };

  const getCurrentJsonTemplate = (): JSONTemplate => {
    const flattenedSections = flattenTrees(state.changedTemplateTree);
    const cleansedSections = flattenedSections.map(item => {
      let cleanedItem = _.omit(item, [
        'collapsed',
        'depth',
        'index',
        'sections',
      ]) as TemplateSection;
      if (!item.parent_section) {
        cleanedItem = _.omit(cleanedItem, [
          'parent_section',
        ]) as TemplateSection;
      }
      return cleanedItem;
    });
    return {
      ...jsonTemplate,
      sections: cleansedSections,
    };
  };

  return (
    <TemplateEditorContext.Provider
      value={{
        state,
        setState,
        getChangedSectionTree,
        getOriginalSectionTree,
        updateSortableTemplateTree: updateEditorTemplateSectionTrees,
        getCurrentJsonTemplate,
      }}
    >
      {children}
    </TemplateEditorContext.Provider>
  );
};
