import { VStack } from '@chakra-ui/react';

const AttributesRail = ({ children }: any) => {
  return (
    <VStack
      align="start"
      gap={10}
      width="100%"
      marginTop={32}
      marginBottom={32}
    >
      {children}
    </VStack>
  );
};

export default AttributesRail;
