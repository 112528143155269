import { Box, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const NullFieldDisplay = () => {
  return (
    <Box
      border={'1px dashed'}
      borderColor={useColorModeValue('neutral.300', 'neutral.700')}
      h={10}
      rounded={'md'}
      py={2}
      px={4}
    ></Box>
  );
};

export default NullFieldDisplay;
