import { tagAnatomy as parts } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys)

export const Tag = defineMultiStyleConfig({
  variants: {
    'role-pill': definePartsStyle((props) => {
      const { colorScheme, theme } = props
      const { bg: bgBadge } = theme.components.Badge.variants.subtle(props)
      return {
        container: {
          bg: mode(`${colorScheme}.200`, bgBadge)(props),
          borderRadius: "full",
          minHeight: 8,
          py: 1.5,
          px: 3,
        }
      }
    })
  }
})
