import { Box, Flex, Stack, useDisclosure, VStack } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { ProjectContext } from '../../../../contexts';
import { convertToSectionTree } from '../../../../models/template';
import { useQuery } from 'react-query';
import API from '../../../../api/API';
import { LoadingContainer } from '../../../../components/LoadingContainer';
import RecordDetailsPage from '../../../../components/Layout/RecordDetailsPage';
import DocumentOutline from '../../../../components/DocumentOutline';
import MonitoringHeader from '../../../../components/MonitoringHeader';
import AttributesRailMonitoringOverview from '../../../../components/AttributesRailMonitoringOverview';
import TemplateModal from '../../../../components/TemplateModal';

export default function MonitoringOverview() {
  const { getAccessTokenSilently } = useAuth0();
  const { project, setTemplates, templates } = useContext(ProjectContext);
  const {
    isOpen: isOpenTemplateModal,
    onOpen: onOpenTemplateModal,
    onClose: onCloseTemplateModal,
  } = useDisclosure();
  const tree = convertToSectionTree(templates.monitoring.sections || []);

  const {
    data: documentationOutline = {},
    refetch: refetchDocumentationOverview,
  } = useQuery(
    ['monitoring-overview'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetDocumentationSummary(
        accessToken,
        project!.inventory_model.cuid,
        'monitoring',
      );
    },
    {
      enabled: !!project,
    },
  );

  return (
    <>
      <LoadingContainer isLoading={false}>
        <Flex minHeight="100vh" w={'full'}>
          <Stack
            px={8}
            w={'full'}
            alignItems={'center'}
            overflow={'auto'}
            className="no-scrollbar"
          >
            <RecordDetailsPage
              right={
                <AttributesRailMonitoringOverview
                  project={project!}
                  onOpenTemplateModal={onOpenTemplateModal}
                />
              }
            >
              <MonitoringHeader project={project!} />

              <VStack spacing={12} align="stretch">
                <Box>
                  <DocumentOutline
                    data={tree}
                    allowMultiple={true}
                    documentationOutline={documentationOutline}
                    resolveAnnotation={() => {}}
                    documentTypeUrl={'monitoring'}
                  />
                </Box>
              </VStack>
            </RecordDetailsPage>
          </Stack>
        </Flex>
      </LoadingContainer>
      {project && (
        <TemplateModal
          project={project}
          activeTemplateDocumentType={'monitoring'}
          isOpen={isOpenTemplateModal}
          onClose={onCloseTemplateModal}
        />
      )}
    </>
  );
}
