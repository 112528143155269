import { FieldDisplayProps } from '../../index';
import _ from 'lodash';
import { HStack, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import NullFieldDisplay from '../NullFieldDisplay';
import UsersContext from '../../../../../contexts/UsersContext';
import AvatarProxy from '../../../../AvatarProxy';

const UserFieldDisplay = ({ field }: FieldDisplayProps) => {
  const { organizationUsers } = useContext(UsersContext);
  const fieldHasValue = field.value !== null;
  if (fieldHasValue) {
    const users = _.filter(organizationUsers, u =>
      _.includes(field.value, u.cuid),
    );
    if (users) {
      return (
        <>
          {users.map(user => (
            <HStack key={user.cuid}>
              <AvatarProxy
                size={'sm'}
                name={user.name}
                src={user.picture || 'https://placekitten.com/100/100'}
              />
              <Text>{user.name}</Text>
            </HStack>
          ))}
        </>
      );
    }
    return <Text>{field.value}</Text>;
  }
  return <NullFieldDisplay />;
};

export default UserFieldDisplay;
