import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  EllipsisVerticalIcon,
  UserIcon,
  MoonIcon,
  SunIcon,
  ArrowLeftEndOnRectangleIcon,
} from '@heroicons/react/24/outline';
import UsersContext from '../../contexts/UsersContext';
import { getUserRolesForOrg } from  '../../models/user';
import { useNavigate } from 'react-router-dom';
import { useUserUISettings } from '../../hooks/useUserUISettings';
import AvatarProxy from '../AvatarProxy';

export function UserDisplay({
  isExpanded,
  moreThanOneOrg,
}: {
  isExpanded: boolean;
  moreThanOneOrg: boolean;
}) {
  const { updateColorMode } = useUserUISettings();
  const [modeName, setModeName] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { colorMode } = useColorMode();
  const { logout } = useAuth0();
  const { currentUser, currentOrganization } = useContext(UsersContext);
  const [showComplete, setShowComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (colorMode === 'dark') {
      setIsDarkMode(true);
      setModeName('Light Mode');
    } else {
      setModeName('Dark Mode');
      setIsDarkMode(false);
    }
  }, [colorMode]);

  const handleDarkMode = () => {
    // updateColorMode will trigger the toggleColorMode in App.tsx
    if (colorMode === 'dark') {
      updateColorMode('light');
    } else {
      updateColorMode('dark');
    }
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setShowComplete(isExpanded);
      },
      isExpanded ? 200 : 0,
    );

    return () => clearTimeout(timeout);
  }, [isExpanded]);

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const onSeeMyProfile = () => {
    navigate('settings/profile');
  };

  return (
    <HStack gap={2} alignItems={'flex-start'}>
      <AvatarProxy
        size="sm"
        name={currentUser?.name}
        src={currentUser?.picture}
        mt={1}
      />

      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        flex={1}
        hidden={!showComplete}
      >
        <Stack spacing={0}>
          <Text
            fontSize={'sm'}
            fontWeight={500}
            color={useColorModeValue('neutral.600', 'neutral.300')}
          >
            {currentUser?.name}
          </Text>
          <Collapse in={showComplete} animateOpacity>
            <Text
              fontSize={'xs'}
              color={useColorModeValue('neutral.500', 'neutral.400')}
            >
              {getUserRolesForOrg(currentUser, currentOrganization?.cuid).map(r => r.role.name).join(', ')}
            </Text>
            {moreThanOneOrg && (
              <Text
                mt={2}
                fontSize={'xs'}
                fontWeight={'bold'}
                hidden={!isExpanded}
                color={useColorModeValue('neutral.500', 'neutral.400')}
              >
                {currentOrganization?.name}
              </Text>
            )}
          </Collapse>
        </Stack>
        <Menu placement={'top-end'}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
            variant="ghost"
          />
          <MenuList boxShadow={'xl'}>
            <MenuItem
              icon={<Icon as={ArrowLeftEndOnRectangleIcon} boxSize={5} />}
              onClick={onLogout}
            >
              Logout
            </MenuItem>
            <MenuItem
              icon={<Icon as={UserIcon} boxSize={5} />}
              onClick={onSeeMyProfile}
            >
              See my profile
            </MenuItem>
            <MenuItem
              icon={<Icon as={isDarkMode ? SunIcon : MoonIcon} boxSize={5} />}
              onClick={handleDarkMode}
            >
              Switch to {modeName}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  );
}
