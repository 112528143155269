import { CustomFieldProps, CustomFieldSchemaCodeProps } from '../types';
import {
  Alert,
  AlertIcon,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  VStack,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { startsWith } from 'lodash';

export default function CustomCodeResult({
  schema,
  mode,
  value,
  onChange,
}: CustomFieldProps) {
  if (startsWith(value, 'ERR:')) {
    return (
      <Alert size={'small'} status="warning" overflow={'unset'}>
        <VStack alignItems={'flex-start'}>
          <AlertIcon />
          <Text fontSize={'smaller'}>
            Make sure you have defined the following fields:
            <UnorderedList>
              {(schema.props as CustomFieldSchemaCodeProps).code.variables.map(
                variable => (
                  <ListItem key={variable}>{variable}</ListItem>
                ),
              )}
            </UnorderedList>
          </Text>

          <Popover trigger="hover">
            <PopoverTrigger>
              <Text fontSize={'sm'}>More Info</Text>
            </PopoverTrigger>
            <PopoverContent shadow={'md'}>
              <PopoverArrow />
              <PopoverBody fontFamily={'mono'} fontSize={'sm'}>
                {value}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </VStack>
      </Alert>
    );
  }
  return <Text>{value}</Text>;
}
