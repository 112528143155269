import { Box, Flex, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import {
  ArrowsRightLeftIcon,
  CheckIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

interface IContentContainerOpts {
  id?: string;
  children?: any;
  containerSize?: string;
  allowExpand?: boolean;
  allowEdit?: boolean;
  isEditing?: boolean;
  onEdit?: Function;
  onSave?: Function;
  onCancel?: Function;
}

export default function ContentContainer({
  id,
  children,
  containerSize,
  allowExpand = false,
  allowEdit = false, // Only available for metadata based ContentContainers for now
  isEditing = false, // Only available for metadata based ContentContainers for now
  onEdit = undefined,
  onSave = undefined,
  onCancel = undefined,
}: IContentContainerOpts) {
  const [expanded, setExpanded] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [showEditButton, setShowEditButton] = React.useState(false);
  const [showTimeout, setShowTimeout] = React.useState<any>(null);

  React.useEffect(() => {
    setEditing(isEditing);
  }, [isEditing]);

  // - Default to 100% width and 960px max width
  // - If expanded, set max width to 90% and remove the max width (set to 100%)
  let desiredContainerSize = '100%';
  let desiredMaxWidth = '1220px';
  if (expanded) {
    desiredMaxWidth = '90%';
  } else if (containerSize) {
    desiredContainerSize = containerSize;
  }

  const onEditing = () => {
    setEditing(true);
    if (onEdit) {
      onEdit();
    }
  };

  const onSaving = () => {
    handleMouseLeave();
    setEditing(false);
    if (onSave) {
      onSave();
    }
  };

  const onCanceling = () => {
    handleMouseLeave();
    setEditing(false);
    if (onCancel) {
      onCancel();
    }
  };

  const toolbarRightPosition = '-37px';

  const handleMouseEnter = () => {
    clearTimeout(showTimeout);
    setShowTimeout(setTimeout(() => setShowEditButton(true), 200));
  };

  const handleMouseLeave = () => {
    clearTimeout(showTimeout);
    setShowTimeout(setTimeout(() => setShowEditButton(false), 500));
  };

  return (
    <Flex
      id="content-container-wrapper"
      direction="row"
      justify="center"
      w="100%"
      data-testid="ContentContainerWrapper-area"
    >
      <Flex
        id="content-container-contents"
        direction="column"
        alignItems="stretch"
        width={desiredContainerSize}
        maxWidth={desiredMaxWidth}
        gap={4}
        position="relative"
        rounded="sm"
        // Only show outline for editable contents only
        outline={
          allowEdit && showEditButton && !isEditing && !allowExpand
            ? 'solid 2px'
            : undefined
        }
        transition={'all 0.3s ease-out'}
        outlineColor="neutral.300"
        outlineOffset={2}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onDoubleClick={
          allowEdit && showEditButton && !isEditing && !allowExpand
            ? onEditing
            : undefined
        }
        data-testid="ContentContainer-area"
      >
        {children}
        <Box width="32px" position="absolute" right={'-37px'} top={'-4px'}>
          {allowExpand && (
            <Box>
              <Tooltip
                label={expanded ? 'Contract' : 'Expand'}
                hasArrow
                placement="left"
              >
                <IconButton
                  size={'xs'}
                  id="expand-btn"
                  data-button-type="expander"
                  aria-label="Expand/Contract"
                  icon={<Icon as={ArrowsRightLeftIcon} boxSize={4} />}
                  onClick={() => setExpanded(!expanded)}
                />
              </Tooltip>
            </Box>
          )}
          {allowEdit && !editing && showEditButton && (
            <Tooltip label="Edit" hasArrow placement="left">
              <IconButton
                size={'sm'}
                aria-label="Edit"
                bg={'neutral.200'}
                _hover={{
                  bg: 'neutral.300',
                }}
                data-testid="metadata-edit-btn"
                onClick={onEditing}
                onMouseEnter={() => setShowEditButton(true)}
                onMouseLeave={() =>
                  setTimeout(() => setShowEditButton(false), 500)
                }
                icon={<Icon as={PencilIcon} w={4} h={4} strokeWidth={2} />}
              />
            </Tooltip>
          )}

          {allowEdit && editing && (
            <Flex direction={'column'} gap={1}>
              <Tooltip label="Save" hasArrow placement="left">
                <IconButton
                  size={'sm'}
                  aria-label="Save"
                  icon={<Icon as={CheckIcon} w={4} h={4} strokeWidth={3} />}
                  onClick={() => onSaving()}
                />
              </Tooltip>
              <Tooltip label="Cancel" hasArrow placement="left">
                <IconButton
                  size={'sm'}
                  bg={'neutral.200'}
                  aria-label="Cancel"
                  _hover={{
                    bg: 'neutral.300',
                  }}
                  icon={<Icon as={XMarkIcon} w={4} h={4} strokeWidth={3} />}
                  onClick={() => onCanceling()}
                />
              </Tooltip>
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
