import { FormControl, HStack, Input, Text, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import { Label } from '../Layout';

interface TitleDescriptionFormProps {
  titleLabel: string;
  title: string;
  titlePlaceholder: string;
  onTitleChange: (updatedGroupName: string) => boolean;
  descriptionLabel: string;
  description: string;
  descriptionPlaceholder: string;
  onDescriptionChange: (updatedGroupDescription: string) => boolean;
  descriptionCharacterLimit?: number;
}

export default function TitleDescriptionForm({
  titleLabel,
  title,
  titlePlaceholder,
  onTitleChange,
  descriptionLabel,
  description,
  descriptionPlaceholder,
  onDescriptionChange,
  descriptionCharacterLimit,
}: TitleDescriptionFormProps) {
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [descriptionCharacterCount, setDescriptionCharacterCount] = useState(0);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);

  return (
    <>
      <FormControl data-testid="form-field-title" isInvalid={!isTitleValid}>
        <Label mb={2}>{titleLabel}</Label>
        <Input
          type="text"
          placeholder={titlePlaceholder}
          value={title}
          onChange={e => setIsTitleValid(onTitleChange(e.target.value))}
        />
      </FormControl>
      <FormControl
        data-testid="form-field-description"
        isInvalid={!isDescriptionValid}
      >
        <Label mb={2}>{descriptionLabel}</Label>
        <Textarea
          placeholder={descriptionPlaceholder}
          value={description}
          onChange={e => {
            const inputText = e.target.value;
            setDescriptionCharacterCount(inputText.length);
            setIsDescriptionValid(onDescriptionChange(inputText));
          }}
        />
        {descriptionCharacterLimit && (
          <HStack justifyContent="flex-end">
            <Text
              fontSize="xs"
              lineHeight={1.5}
              color={isDescriptionValid ? 'neutral.500' : 'red.500'}
            >
              {`${descriptionCharacterCount} / ${descriptionCharacterLimit}`}
            </Text>
          </HStack>
        )}
      </FormControl>
    </>
  );
}
