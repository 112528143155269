import { Box, HStack, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { Assessment, AssessmentOption } from '../../models/guideline';
import useOrganizationAssessmentOptions from '../../hooks/useOrganizationAssessmentOptions';

export interface OutlineComplianceLevelIndicatorProps {
  assessment: Assessment | null;
}

const OutlineComplianceLevelIndicator = ({
  assessment,
}: OutlineComplianceLevelIndicatorProps) => {
  const { assessmentOptions, isLoading } = useOrganizationAssessmentOptions();
  const complianceLevelWidth: number = 1.5;
  if (!assessment?.option) return null;
  return (
    <Tooltip
      label={assessment?.option ? assessment?.option?.name : 'No assessment'}
      placement={'right'}
      hasArrow
      rounded={'md'}
    >
      <HStack
        h={4}
        gap={0.5}
        rounded={'full'}
        overflow={'hidden'}
        border={'2px solid'}
        boxShadow={useColorModeValue(
          `
        0 0 0 2px var(--chakra-colors-neutral-200)`,
          `
        0 0 0 2px var(--chakra-colors-neutral-700)
        `,
        )}
        bg={useColorModeValue('white', 'neutral.900')}
        borderColor={useColorModeValue('white', 'neutral.900')}
      >
        {(assessmentOptions || [])
          .sort((a, b) => a.order - b.order)
          .map((assessmentOption: AssessmentOption) => {
            return (
              <Box
                key={assessmentOption.cuid}
                h={'full'}
                w={complianceLevelWidth}
                bg={
                  assessmentOption.cuid === assessment?.option?.cuid
                    ? assessmentOption.style.bg
                    : useColorModeValue('neutral.100', 'neutral.700')
                }
                border={
                  assessmentOption.cuid === assessment?.option?.cuid
                    ? '0'
                    : '1px solid'
                }
                borderColor={useColorModeValue('neutral.100', 'neutral.700')}
              ></Box>
            );
          })}
      </HStack>
    </Tooltip>
  );
};

export default OutlineComplianceLevelIndicator;
