import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import Plot, { PlotParams } from 'react-plotly.js';
import API, { TFigure } from '../../api/API';
import { useQuery } from 'react-query';
import { LoadingContainer } from '../LoadingContainer';
import { useEffect, useRef, useState } from 'react';

interface FigureDisplayProps {
  metric?: string;
  feature?: string;
  figure: TFigure;
}

interface PlotlyFigureData {
  figure: TFigure;
  data: any;
  layout: any;
}

const StaticFigureDisplay = ({ figure }: FigureDisplayProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box w="90%" my={4} alignSelf="center">
        <button onClick={onOpen} style={{ width: '100%' }}>
          <Image src={figure.url} margin="auto" />
        </button>
      </Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxH="90%" maxW="90%" width="auto" height="auto">
          <ModalCloseButton />
          <ModalBody px={10} alignItems="center">
            <Flex justifyContent="center" mx="auto">
              <Image alignSelf="center" src={figure.url} maxW="100%" />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const PlotlyFigureDisplay = ({ figure }: FigureDisplayProps) => {
  const { data, isLoading } = useQuery(
    ['figures', figure.key, 'json'],
    async () => {
      return API.GetJSONImage(figure.json_url);
    },
  );

  const jsonImage = data as PlotlyFigureData | undefined;

  return (
    <LoadingContainer isLoading={isLoading}>
      <Box
        w="90%"
        my={4}
        alignSelf="center"
        maxWidth="100%"
        overflow="auto"
        className="no-scrollbar"
      >
        {jsonImage && (
          <ResponsivePlot
            figure={figure}
            data={jsonImage.data}
            layout={jsonImage.layout}
          />
        )}
      </Box>
    </LoadingContainer>
  );
};

const ResponsivePlot = ({
  figure,
  data,
  layout: initialLayout,
}: PlotlyFigureData) => {
  const [dimensions, setDimensions] = useState<any>({ width: 0, height: 0 });
  const container = useRef<HTMLDivElement>(null);
  const initialAspectRatio = initialLayout.width / initialLayout.height;

  useEffect(() => {
    const updateDimensions = () => {
      if (container.current) {
        const parentWidth = container.current.offsetWidth;
        setDimensions({
          width: parentWidth,
          height: parentWidth / initialAspectRatio,
        });
      }
    };

    // Use ResizeObserver to update dimensions when the browser window
    // is resized or when the sidebar is opened/closed

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver(updateDimensions);

    // Observe the parent container
    container.current && resizeObserver.observe(container.current);

    // Update dimensions immediately
    updateDimensions();

    // Cleanup function to disconnect the observer
    return () => resizeObserver.disconnect();
  }, []);

  const layout: PlotParams['layout'] = {
    ...initialLayout,
    autosize: true,
    width: initialLayout.width || dimensions.width,
    height: initialLayout.height || dimensions.height,
    // paper_bgcolor: 'rgba(0,0,0,0)',
    // plot_bgcolor: 'rgba(0,0,0,0)',
  };

  // Confusion matrix plots need to be reversed
  if (figure.key === 'confusion_matrix' && layout.xaxis) {
    layout.xaxis.autorange = 'reversed';
  }

  return (
    <div ref={container} style={{ width: '100%', height: 'auto' }}>
      <Plot
        data={data}
        layout={layout}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

const FigureDisplay = ({ figure }: FigureDisplayProps) => {
  return figure.json_url ? (
    <PlotlyFigureDisplay figure={figure} />
  ) : (
    <StaticFigureDisplay figure={figure} />
  );
};

export default FigureDisplay;
