import { FieldDisplayProps } from '../../index';
import { Text } from '@chakra-ui/react';
import React from 'react';
import NullFieldDisplay from '../NullFieldDisplay';
import { displayFormatedDateAndTime } from '../../../../../utils';
import dayjs from 'dayjs';

const DateFieldDisplay = ({ field }: FieldDisplayProps) => {
  const fieldHasValue = field.value !== null;
  const unixTime = dayjs(field.value).unix();
  if (fieldHasValue) {
    return <Text>{displayFormatedDateAndTime(unixTime)}</Text>;
  }
  return <NullFieldDisplay />;
};

export default DateFieldDisplay;
