import { Node } from 'reactflow';
import {
  HStack,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import StatusSetNode from '../../nodes/StatusSetNode';
import { SignalIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import StatusFormNode from '../../nodes/StatusFormNode';
import ApprovalNode from '../../nodes/ApprovalNode';
import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import { DragHandleIcon } from '@chakra-ui/icons';
import useWorkflow from '../../../../../../hooks/useWorkflow';

const NodeButton = ({
  icon,
  label,
  nodeType,
}: {
  icon: any;
  label: string;
  nodeType: string;
}) => {
  const onDragStart = (event: any) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <HStack
      py={2}
      pl={2}
      pr={4}
      gap={2}
      bg={useColorModeValue('neutral.100', 'neutral.700')}
      rounded={'md'}
      onDragStart={onDragStart}
      draggable
      _hover={{
        bg: useColorModeValue('neutral.200', 'neutral.600'),
      }}
      fontWeight={'bold'}
      transition={'background-color 0.2s ease'}
    >
      <Icon as={DragHandleIcon} boxSize={3} cursor={'grab'} />
      <Text whiteSpace={'nowrap'}>{label}</Text>
      <Spacer />
      <Icon as={icon} boxSize={6} />
    </HStack>
  );
};

const NodesBar = () => {
  const { selectedNodeId } = useWorkflow();
  return (
    <VStack
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      p={4}
      hidden={selectedNodeId !== undefined}
      rounded={'md'}
    >
      <Text>Drag a step onto the canvas, then select it to configure.</Text>
      <HStack>
        <NodeButton
          icon={SignalIcon}
          label="Status Change"
          nodeType={StatusSetNode.type}
        />
        <NodeButton
          icon={CursorArrowRaysIcon}
          label="User Action"
          nodeType={StatusFormNode.type}
        />
        <NodeButton
          icon={UserGroupIcon}
          label="Approval"
          nodeType={ApprovalNode.type}
        />
      </HStack>
    </VStack>
  );
};

export default NodesBar;
