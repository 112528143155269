import { TemplateSection, TProject } from '../../../models';
import {
  TemplateSectionContents,
  TemplateSectionTree,
} from '../../../models/template';

/**
 * addBlockOptimisticUI is a function that creates a new project object with an updated
 * content block inserted at a specific index in a template section. This is part of an
 * optimistic UI strategy, where we want to immediately show the changes in the UI while
 * the request is still being processed in the background.
 *
 * @param {TProject} project - The current project object.
 * @param {TemplateSection} templateSection - The template section where the content block is to be inserted.
 * @param {number} index - The index at which the new content block should be inserted in the template section.
 * @param {TemplateSectionContents} content - The new content block to be inserted.
 *
 * @returns {TProject} - A new project object with the updated content block inserted at the specified index.
 */
export function addBlockOptimisticUI(
  project: TProject,
  templateSection: TemplateSection,
  index: number,
  content: TemplateSectionContents,
): TProject {
  return {
    ...project,
    template: {
      ...project?.template,
      template: {
        ...project?.template?.template,
        sections: project?.template?.template.sections.map(section => {
          if (templateSection.id === section.id) {
            let newContents = [...(section.contents || [])];
            newContents.splice(index + 1, 0, content);
            return {
              ...section,
              contents: newContents,
            };
          }
          return section;
        }),
      },
    },
  };
}

/**
 * removeBlockOptimisticUI function removes a block from a project template section and returns a new project state.
 * This is part of an optimistic UI strategy, where we want to immediately show the changes in the UI while
 * the request is still being processed in the background.
 *
 * @param project - the current project which is of type TProject.
 * @param templateSection - the template section from where the block will be removed.
 * @param index - the index of the block in the contents array of the template section. Not used in the current implementation of the function.
 * @param sectionToRemove - the section content to be removed from the template section.
 *
 * @returns A new project state after removing the block from the template section.
 *
 * Note: This function does not mutate the original project object but returns a new one with the updated state.
 */
export function removeBlockOptimisticUI(
  project: TProject,
  templateSection: TemplateSection,
  index: number,
  sectionToRemove: TemplateSectionContents,
): TProject {
  return {
    ...project,
    template: {
      ...project?.template,
      template: {
        ...project?.template?.template,
        sections: project?.template?.template.sections.map(section => {
          if (templateSection.id === section.id) {
            let newContents = [...(section.contents || [])];
            return {
              ...section,
              contents: newContents.filter(
                s => s.content_id !== sectionToRemove.content_id,
              ),
            };
          }
          return section;
        }),
      },
    },
  };
}

/**
 * findFirstSection function helps to set the home page of the template section tree.
 */
export function findFirstSection(
  sections: TemplateSectionTree[],
): TemplateSectionTree | undefined {
  // Go through each page and each page's pages recursively in
  // the table of contents and find one that is not index_only
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (!section.index_only) {
      return section;
    }
    if (section.sections) {
      const firstPage = findFirstSection(section.sections);
      if (firstPage) {
        return firstPage;
      }
    }
  }
}
