import {
  Box,
  HStack,
  Heading,
  IconButton,
  StackDivider,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import useMetric from '../../../hooks/useMetric';
import { SectionContentsProps } from '../../Layout/DocumentationPage';
import ContentContainer from '../../Layout/ContentContainer';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContentPageH2 } from '../../Layout';
import { useFigures } from '../../../hooks/useFigure';
import FigureDisplay from '../../FigureDisplay';
import { LoadingContainer } from '../../LoadingContainer';
import _ from 'lodash';
import { MetadataContentEditor } from './MetadataContentEditor';
import { ResultSummary } from './ResultSummary';
import { EmptyStateDisplay } from '../../EmptyStateDisplay';
import { TrashIcon } from '@heroicons/react/24/outline';
import ConfirmationAlert from '../../ConfirmationAlert';
import { useInView } from 'react-intersection-observer';
import InputInfoModal from '../../InputInfoModal';
import { useAuth0 } from '@auth0/auth0-react';
import { ProjectContext } from '../../../contexts';
import API from '../../../api/API';
import { AxiosError } from 'axios';
import { getModelDocumentType } from '../../../models/model_document';
import { InventoryModelStages } from '../../../models/inventory_model';

export const METRIC_DESCRIPTION_CONTENT_ID_PREFIX = 'metric_description';

export function MetricContent({
  contents,
  removeBlock,
  readOnly,
  overrideDocumentType,
  documentType,
}: SectionContentsProps) {
  const { content_id, options = {} } = contents;
  const { show_title = true, title } = options;
  const [isFocused, setIsFocused] = useState(false);
  const metricContentRef = useRef(null);
  const metricContentTooltipRef = useRef(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { project } = useContext(ProjectContext);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  if (!content_id) {
    return <ContentContainer>Undefined content_id</ContentContainer>;
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  });

  const handleClickOutside = (e: any) => {
    if (
      metricContentRef.current &&
      !(metricContentRef.current as any).contains(e.target)
    ) {
      setIsFocused(false);
    }
  };

  const { metric, isLoading, isError, error } = useMetric(
    content_id,
    documentType ? getModelDocumentType(documentType) : undefined,
    inView,
  );

  let figureMetadata: any = {
    _type: 'metric',
    _name: content_id,
  };
  if (metric?.ref_id) {
    figureMetadata._ref_id = metric.ref_id;
  }
  const { figures = [], isLoading: isLoadingFigures } = useFigures(
    figureMetadata,
    metric?.test_run_cuid,
    !!metric?.value,
  );

  const metricDescriptionContentId = `${METRIC_DESCRIPTION_CONTENT_ID_PREFIX}:${metric?.key}`;
  const metadataContents = {
    content_id: metricDescriptionContentId,
    content_type: 'text',
  };

  let metricTitle = title || metric?.key || '';
  // Grab the last part of the test ID since it's a namespaced ID, e.g. validmind.data_validation.TestNmae
  metricTitle = metricTitle.split('.').pop();
  const metricTitleDisplay = _.startCase(metricTitle);

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed && removeBlock) {
      setIsFocused(false);
      removeBlock(contents);
    }
    setConfirmDelete(false);
  };

  const getMetricHistoryForPage = useCallback(
    async (page: number, limit: number) => {
      if (project) {
        const token = await getAccessTokenSilently();
        const resp = await API.GetMetricHistoryForProject(
          project?.cuid,
          token,
          content_id,
          documentType ? getModelDocumentType(documentType) : undefined,
          page,
          limit,
        );
        return {
          history: resp.results,
          total: resp.total,
        };
      }
      return {
        history: [],
        total: 0,
      };
    },
    [project, content_id],
  );

  const getContent = () => {
    // A 404 error from the API means the developer framework has not sent any data
    // for this content block so we show an empty state
    if (isError && (error as AxiosError)?.response?.status === 404) {
      return (
        <>
          <ContentPageH2>
            {<Text>{_.startCase(content_id.split('.').pop())}</Text>}
          </ContentPageH2>
          <EmptyStateDisplay variant="no-activity">
            <Heading as={'h4'} fontSize={'md'}>
              The data associated with this Test-Driven Block (
              <code>{content_id}</code>) is missing.
            </Heading>
            <Text align={'center'} fontSize={'md'}>
              To populate this content block please make sure to run the
              associated documentation test suite.
            </Text>
            <Text align={'center'} pb={10} fontSize={'md'}>
              Please refer to{' '}
              <a href="https://docs.validmind.ai" target="_blank">
                ValidMind's documentation
              </a>{' '}
              for more information.
            </Text>
          </EmptyStateDisplay>
        </>
      );
    }

    if (metric) {
      return (
        <LoadingContainer isLoading={isLoading || isLoadingFigures}>
          <ContentPageH2>
            {metric && show_title && <Text>{metricTitleDisplay}</Text>}
          </ContentPageH2>
          <Box mt={4}>
            <InputInfoModal
              createdAt={metric.created_at}
              inputs={metric.inputs}
              user={metric.user}
              getHistoryForPage={getMetricHistoryForPage}
              contentID={content_id}
              title={metricTitleDisplay}
            />
          </Box>
          <MetadataContentEditor
            readOnly={readOnly}
            key={metricDescriptionContentId}
            contents={metadataContents}
            overrideDocumentType={overrideDocumentType}
          />
          {metric && metric.summary && (
            <ResultSummary summary={metric.summary} />
          )}
          <ContentContainer>
            {figures.map((figure: any) => (
              <FigureDisplay figure={figure} key={figure.cuid} />
            ))}
          </ContentContainer>
        </LoadingContainer>
      );
    }
  };

  return (
    <Box ref={ref} position={'relative'}>
      {readOnly !== true && (
        <Box
          position={'absolute'}
          hidden={!isFocused}
          top={'-42px'}
          left={'0'}
          boxShadow={'var(--ck-drop-shadow),0 0'}
          rounded={'md'}
          ref={metricContentTooltipRef}
        >
          <HStack
            divider={
              <StackDivider borderColor="neutral.200" m={'1 !important'} />
            }
            rounded={'md'}
            bg={'white'}
            border={'1px solid var(--chakra-colors-neutral-200)'}
            gap={0}
          >
            {/* <Tooltip
              label="Information about this block"
              hasArrow
              placement="bottom"
              bg={useColorModeValue('neutral.200', 'neutral.700')}
              boxShadow={'none'}
              color={useColorModeValue('neutral.800', 'neutral.200')}
              openDelay={500}
              arrowSize={5}
            >

            </Tooltip> */}

            <Tooltip
              label="Remove Test-Driven Block"
              hasArrow
              placement="bottom"
              bg={useColorModeValue('neutral.200', 'neutral.700')}
              boxShadow={'none'}
              color={useColorModeValue('neutral.800', 'neutral.200')}
              openDelay={500}
              arrowSize={5}
            >
              <IconButton
                aria-label="Remove Test-Driven Block"
                bg={'white !important'}
                color={'neutral.800 !important'}
                _hover={{
                  color: 'red.600  !important',
                  bg: 'red.100 !important',
                }}
                icon={<TrashIcon width={20} height={20} />}
                onClick={() => setConfirmDelete(true)}
                // TODO: add permissions check here as well
                isDisabled={
                  project?.inventory_model.stage !== InventoryModelStages.ACTIVE
                }
              />
            </Tooltip>
          </HStack>
        </Box>
      )}

      <ConfirmationAlert
        open={confirmDelete}
        title={`Remove "${metric && metricTitleDisplay}" Block`}
        dialogBody={`Are you sure you'd like to remove "${
          metric && metricTitleDisplay
        }" block?`}
        onConfirm={onDeleteConfirmed}
      />
      <Tooltip
        gutter={0}
        bg={useColorModeValue('neutral.200', 'neutral.700')}
        boxShadow={'none'}
        color={useColorModeValue('neutral.800', 'neutral.200')}
        placement="top-end"
        isDisabled={isFocused}
        label="Click to edit this block"
        openDelay={1000}
        offset={[1, 0]}
        fontSize="md"
        hidden={readOnly}
      >
        <Box
          p={2}
          border={'1px solid'}
          borderColor={isFocused ? 'brand.base' : 'transparent'}
          bg={
            isFocused
              ? useColorModeValue('neutral.50', 'neutral.850')
              : 'transparent'
          }
          borderRadius={'md'}
          transition={'all .5s ease-in-out'}
          _hover={
            isFocused
              ? {
                  borderColor: 'brand.base',
                }
              : {
                  borderColor: useColorModeValue('neutral.200', 'neutral.700'),
                }
          }
          onClick={() => {
            if (readOnly) return;
            setIsFocused(true);
          }}
          ref={metricContentRef}
        >
          {getContent()}
        </Box>
      </Tooltip>
    </Box>
  );
}
