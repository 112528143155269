import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'base',
    _focus: {
      outline: 'none',
      boxShadow: '0 0 0 1px var(--chakra-colors-brand-400)',
    },
  },

  variants: {
    solid: {
      bg: 'neutral.100',
      color: 'neutral.900',
      _hover: {
        bg: 'neutral.200',
        color: 'neutral.800',
      },
      _dark: {
        bg: 'neutral.850',
        color: 'neutral.100',
        _hover: {
          bg: 'neutral.800',
          color: 'neutral.200',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },
  },
  defaultProps: {},
});
