import { Flex } from '@chakra-ui/react';
import { ContentProjectNameHeading } from '../Layout';
import { TProject } from '../../models';
import { useLocation } from 'react-router-dom';

interface IProjectStatusBarProps {
  project?: TProject;
}

export default function ProjectStatusBar({ project }: IProjectStatusBarProps) {
  const currentPath = useLocation();
  const isValidationReportPath = /\w+\/validation-report/.test(
    currentPath.pathname,
  );
  const isMonitoringPath = /\w+\/monitoring/.test(currentPath.pathname);

  const documentType = isValidationReportPath
    ? 'Validation Report'
    : isMonitoringPath
    ? 'Ongoing Monitoring'
    : 'Model Documentation';

  const title = `${project?.inventory_model.name} - ${documentType}`;

  return (
    <Flex direction="column" gap={2}>
      <Flex justify="start" data-testid="heading-title">
        <ContentProjectNameHeading data-testid="model-documentation-title">
          {title}
        </ContentProjectNameHeading>
      </Flex>
    </Flex>
  );
}
