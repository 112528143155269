import { defineStyleConfig } from '@chakra-ui/react';

export const FormLabel = defineStyleConfig({
  baseStyle: {
    fontSize: 'xs',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
  },

  // The default size and variant values
  defaultProps: {
    colorScheme: 'brand',
  },
});
