import { Text } from '@chakra-ui/react';
import { CustomFieldProps, CustomFieldSchemaAutoCompleteProps } from '../types';
import AutoCompleteTextInput from '../../AutoCompleteTextInput';
import NullFieldDisplay from '../Generic/NullFieldDisplay';

const StringFieldDisplay = ({ value }: { value: string }) => {
  return <Text>{value}</Text>;
};

export default function CustomAutoComplete({
  schema,
  mode,
  value,
  onChange,
}: CustomFieldProps) {
  const props = schema.props as CustomFieldSchemaAutoCompleteProps;

  if (mode === 'display' || mode === 'read-only') {
    if (!!value) {
      return <StringFieldDisplay value={value} />;
    } else if (mode === 'read-only') {
      return <NullFieldDisplay />;
    } else {
      return <Text>No value provided</Text>;
    }
  }

  return (
    <AutoCompleteTextInput
      value={value}
      onChange={value => onChange(value)}
      options={props.suggestions}
    />
  );
}
