import { useAuth0 } from '@auth0/auth0-react';
import { VStack, Text, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export type CountDisplayProps = {
  getDisplayValue: (accessToken: string) => Promise<string>;
  displayTextColor?: string;
  unitLabel: string;
};

export default function CountDisplay({
  getDisplayValue,
  unitLabel,
  displayTextColor,
}: CountDisplayProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [formattedCount, setFormattedCount] = useState<string | null>();

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const value = await getDisplayValue(accessToken);
      setFormattedCount(value);
      setIsLoading(false);
    })();
  }, [getDisplayValue]);

  return (
    <VStack
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="100%"
      w="100%"
    >
      {!isLoading && (
        <Text
          fontSize={60}
          lineHeight="55px"
          color={displayTextColor}
          fontWeight="bold"
        >
          {formattedCount}
        </Text>
      )}
      {isLoading && <Spinner />}
      <Text>{unitLabel}</Text>
    </VStack>
  );
}
