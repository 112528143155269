// https://github.com/chakra-ui/chakra-ui/issues/634

import { extendTheme } from '@chakra-ui/react';
import { Tag } from './tag';
import { Input } from './input';
import { Tabs } from './tabs';
import { Select } from './select';
import { Textarea } from './textarea';
import { Modal } from './modal';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { FormLabel } from './formlabel';
import { Popover } from './popover';
import { adjustColor } from '../utils';

const customPrimary = '#DF2780';
// const customPrimary = '#BADA55';
// const customPrimary = '#FFCCFF';
// const customPrimary = '#FFCC00';

export const theme = extendTheme({
  useSystemColorMode: false,
  initialColorMode: 'dark',
  colors: {
    brand: {
      25: adjustColor(customPrimary, 97, 255),
      50: adjustColor(customPrimary, 95, 255),
      100: adjustColor(customPrimary, 90, 255),
      200: adjustColor(customPrimary, 80, 255),
      300: adjustColor(customPrimary, 70, 255),
      400: adjustColor(customPrimary, 60, 255),
      base: customPrimary,
      500: adjustColor(customPrimary, 45, 255),
      600: adjustColor(customPrimary, 40, 255),
      700: adjustColor(customPrimary, 30, 255),
      800: adjustColor(customPrimary, 20, 255),
      850: adjustColor(customPrimary, 10, 255),
      900: adjustColor(customPrimary, 5, 255),
    },
    neutral: {
      50: '#F8FAFC',
      100: '#F1F5F9',
      200: '#E2E8F0',
      300: '#CBD5E1',
      400: '#94A3B8',
      500: '#64748B',
      600: '#475569',
      700: '#334155',
      800: '#1E293B',
      850: '#172133',
      900: '#0F172A',
      950: '#0E1526',
    },
    amber: {
      50: '#fffbeb',
      100: '#fef3c7',
      200: '#fde68a',
      300: '#fcd34d',
      400: '#fbbf24',
      500: '#f59e0b',
      600: '#d97706',
      700: '#b45309',
      800: '#92400e',
      900: '#78350f',
    },
    lime: {
      50: '#f7fee7',
      100: '#ecfccb',
      200: '#d9f99d',
      300: '#bef264',
      400: '#a3e635',
      500: '#84cc16',
      600: '#65a30d',
      700: '#4d7c0f',
      800: '#3f6212',
      900: '#365314',
    },
    emerald: {
      50: '#ecfdf5',
      100: '#d1fae5',
      200: '#a7f3d0',
      300: '#6ee7b7',
      400: '#34d399',
      500: '#10b981',
      600: '#059669',
      700: '#047857',
      800: '#065f46',
      900: '#064e3b',
    },
    sky: {
      50: '#f0f9ff',
      100: '#f0f9ff',
      200: '#f0f9ff',
      300: '#7dd3fc',
      400: '#7dd3fc',
      500: '#0ea5e9',
      600: '#0ea5e9',
      700: '#0ea5e9',
      800: '#0ea5e9',
      900: '#0ea5e9',
    },
    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a',
    },
    indigo: {
      50: '#eef2ff',
      100: '#e0e7ff',
      200: '#c7d2fe',
      300: '#a5b4fc',
      400: '#818cf8',
      500: '#6366f1',
      600: '#4f46e5',
      700: '#4338ca',
      800: '#3730a3',
      900: '#312e81',
    },
    violet: {
      50: '#f5f3ff',
      100: '#ede9fe',
      200: '#ddd6fe',
      300: '#c4b5fd',
      400: '#a78bfa',
      500: '#8b5cf6',
      600: '#7c3aed',
      700: '#6d28d9',
      800: '#5b21b6',
      900: '#4c1d95',
    },
    purple: {
      50: '#faf5ff',
      100: '#f3e8ff',
      200: '#e9d5ff',
      300: '#d8b4fe',
      400: '#c084fc',
      500: '#a855f7',
      600: '#9333ea',
      700: '#7e22ce',
      800: '#6b21a8',
      900: '#581c87',
    },
    fuchsia: {
      50: '#fdf4ff',
      100: '#fae8ff',
      200: '#f5d0fe',
      300: '#f0abfc',
      400: '#e879f9',
      500: '#d946ef',
      600: '#c026d3',
      700: '#a21caf',
      800: '#86198f',
      900: '#701a75',
    },
    pink: {
      50: '#FDF2F8',
      100: '#FCE7F3',
      200: '#FBCFE8',
      300: '#F9A8D4',
      400: '#F472B6',
      500: '#EC4899',
      600: '#DB2777',
      700: '#BE185D',
      800: '#9D174D',
      900: '#831843',
    },
  },
  fonts: {
    body: `'Inter', sans-serif`,
    heading: `'Inter', sans-serif`,
  },
  styles: {
    global: {
      body: {
        bg: 'white',
        lineHeight: '1.6',
        _dark: {
          bg: 'neutral.950',
        },
      },
      h1: {
        fontSize: '3xl',
        fontWeight: 'semibold',
        lineHeight: '1.2',
      },
      h2: {
        fontSize: '2xl',
        fontWeight: 'bold',
        lineHeight: '1.2',
      },
      h3: {
        fontSize: 'lg',
        fontWeight: 'bold',
        lineHeight: '1.2',
      },
    },
  },
  components: {
    Button,
    Checkbox,
    FormLabel,
    Input,
    Modal,
    Popover,
    Select,
    Tabs,
    Tag,
    Textarea,
  },
});
