import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  FormControl,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import API from '../../../api/API';
import { TRole } from '../../../models/role';
import { Label } from '../../../components/Layout';

interface AddRoleModalProps {
  isOpen: boolean;
  onSuccess: (data: TRole) => void;
  onClose: () => void;
  roleScope: string;
}

export default function AddRoleModal({
  isOpen,
  onClose,
  onSuccess,
  roleScope,
}: AddRoleModalProps) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [roleName, setRoleName] = useState<string>('');
  const [roleDescription, setRoleDescription] = useState<string>('');

  const addRoleMutation = useMutation(
    [],
    async ({ name, description, scope }: {
      name: string;
      description: string;
      scope: string;
    }) => {
      const accessToken = await getAccessTokenSilently();
      const is_admin = false;

      return API.PostRole(accessToken, { name, description, is_admin, scope });
    },
    {
      onSuccess: data => {
        onSuccess(data);
        void queryClient.invalidateQueries('roles');
        setRoleName('');
        setRoleDescription('');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error creating role',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const onAddRole = () => {
    addRoleMutation.mutate({
      name: roleName,
      description: roleDescription,
      scope: roleScope,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent data-testid="add-new-role-modal">
        <ModalBody>
          <ModalHeader pl={0}>Add New Role</ModalHeader>
          <ModalCloseButton />
          <Stack spacing={4}>
            <FormControl data-testid="form-field-role-name">
              <Label mb={2}>Name</Label>
              <Input
                type="text"
                placeholder="Role name"
                value={roleName}
                onChange={e => setRoleName(e.target.value)}
              />
            </FormControl>
            <FormControl data-testid="form-field-role-description">
              <Label mb={2}>Description</Label>
              <Textarea
                placeholder="Describe what this users with this role will be able to do."
                value={roleDescription}
                onChange={e => setRoleDescription(e.target.value)}
                rows={4}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={4}>
            Cancel
          </Button>
          <Spacer />
          <Button
            colorScheme="pink"
            leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            isDisabled={!(roleName && roleDescription)}
            onClick={() => onAddRole()}
          >
            Add Role
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
