import InventoryModelTierLevelBadge from '../../InventoryModelTierLevelBadge';
import { HStack, useRadioGroup } from '@chakra-ui/react';
import RadioCard from '../../RadioCard';

type TierLevelBadgePickerProps = {
  value?: string;
  setValue: (v: string | undefined) => void;
  size: string;
};

export default function TierLevelBadgePicker({
  value,
  setValue,
  size,
}: TierLevelBadgePickerProps) {
  const options = ['1', '2', '3', '4'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'tier',
    defaultValue: value,
    onChange: v => setValue(v),
  });

  const group = getRootProps();

  return (
    <HStack {...group} borderWidth={1} borderRadius={'md'} p={2}>
      {options.map(value => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} label={`Risk tier: ${value} `} {...radio}>
            <InventoryModelTierLevelBadge level={value} size={size} />
          </RadioCard>
        );
      })}
    </HStack>
  );
}
