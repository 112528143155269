import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export const Input = defineMultiStyleConfig({
  baseStyle: {
    field: {
      _dark: {
        background: 'neutral.850',
        borderColor: 'neutral.800',
      },
    },
    defaultProps: {
      colorScheme: 'brand',
    },
  },
});
