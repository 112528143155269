import { KBarProvider, useKBar } from 'kbar';
import {
  Box,
  Flex,
  HStack,
  Kbd,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import CommandPalette from '../../CommandPalette';

export default function MasterSearchBar() {
  const { query } = useKBar();

  // constant that tracks if user is on a mac or pc
  const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;

  function onHandleClick() {
    query.toggle();
  }

  return (
    <>
      <Flex
        pt={1}
        pb={2}
        px={4}
        mt={8}
        width={96}
        zIndex={2}
        rounded="lg"
        border="1px"
        cursor="pointer"
        color="neutral.500"
        alignItems={'center'}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        onClick={onHandleClick}
        data-testid="master-search-bar"
      >
        <HStack pt={1}>
          <SearchIcon />
          <Text ml={2}>Search or Navigate</Text>
        </HStack>
        <Spacer />
        <Box fontSize={'lg'}>
          <Kbd>{isMac ? '⌘' : 'ctrl'}</Kbd> + <Kbd>k</Kbd>
        </Box>
      </Flex>
      <KBarProvider
        options={{
          enableHistory: true,
        }}
      >
        <CommandPalette />
      </KBarProvider>
    </>
  );
}
