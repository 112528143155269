import { Box, Stack, Text, useColorModeValue } from '@chakra-ui/react';

export const StatusChangedToast = ({
  message,
  error,
  status,
}: {
  message: string;
  error?: string;
  status: string;
}) => (
  <Box
    bg={status === 'success' ? useColorModeValue('green.50','green.900') : useColorModeValue('red.50','red.900')}
    color={'inherit'}
    p={4}
    rounded="lg"
    shadow="xl"
  >
    <Stack>
      <Text fontWeight="bold">{message}</Text>
      {status === 'error' && <Text fontSize="sm">{error}</Text>}
    </Stack>
  </Box>
);
