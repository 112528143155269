import {
  VStack,
  HStack,
  Switch,
  Icon,
  Text,
  FormLabel,
} from '@chakra-ui/react';
import { CustomFieldProps } from '../types';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import NullFieldDisplay from '../Generic/NullFieldDisplay';
import { useEffect } from 'react';

export default function CustomSwitchProps({
  mode,
  value,
  onChange,
}: CustomFieldProps) {
  // Set initial value to false if null
  useEffect(() => {
    if (value === undefined || value === null) {
      onChange(false);
    }
  }, [value]);

  if (mode === 'display' || mode === 'read-only') {
    if (mode === 'read-only' && (value === undefined || value === null)) {
      return <NullFieldDisplay />;
    } else {
      return (value as boolean) ? (
        <HStack>
          <Icon as={CheckIcon} boxSize={6} />
          <Text>Yes</Text>
        </HStack>
      ) : (
        <HStack>
          <Icon as={XMarkIcon} boxSize={6} />
          <Text>No</Text>
        </HStack>
      );
    }
  }

  return (
    <VStack w="full">
      <HStack gap={2}>
        <FormLabel htmlFor="BooleanToggle" display={'contents'}>
          <Text>No</Text>
          <Icon as={XMarkIcon} boxSize={4} />
        </FormLabel>
        <Switch
          id="BooleanToggle"
          isChecked={value}
          onChange={e => onChange(e.target.checked)}
          size={'lg'}
          colorScheme="brand"
        />
        <FormLabel htmlFor="BooleanToggle" display={'contents'}>
          <Icon as={CheckIcon} boxSize={4} />
          <Text>Yes</Text>
        </FormLabel>
      </HStack>
    </VStack>
  );
}
