import { TProject } from '../../models';
import DocumentHeader, {
  getDocumentHeaderBreadcrumbs,
} from '../DocumentHeader';
import { useMemo } from 'react';

interface Props {
  project: TProject;
}

const DocumentationHeader = ({ project }: Props) => {
  const breadcrumbLinks = useMemo(
    () => getDocumentHeaderBreadcrumbs(project, 'Documentation'),
    [project],
  );

  return <DocumentHeader project={project} breadcrumbLinks={breadcrumbLinks} />;
};

export default DocumentationHeader;
