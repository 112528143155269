import {
  AutoComplete,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteInput,
  AutoCompleteGroup,
} from '@choc-ui/chakra-autocomplete';
import _ from 'lodash';
import { useEffect, useState } from 'react';

type Props = {
  value?: string;
  options: string[] | (() => Promise<string[]>);
  onChange: (value: string) => void;
};

export default function AutoCompleteTextInput({
  value,
  options,
  onChange,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [optionsToUse, setOptionsToUse] = useState<string[]>([]);

  useEffect(() => {
    if (_.isFunction(options)) {
      (async () => {
        setIsLoading(true);
        const res = await options();
        setOptionsToUse(res);
        setIsLoading(false);
      })();
    } else {
      setOptionsToUse(options);
    }
  }, [options]);

  return (
    <AutoComplete
      isLoading={isLoading}
      openOnFocus
      emptyState={false}
      freeSolo
      closeOnSelect
      closeOnBlur
      prefocusFirstItem={false}
      value={value}
      emphasize={{ textDecoration: 'underline' }}
      onSelectOption={e => {
        onChange(e.item.label);
      }}
    >
      <AutoCompleteInput
        focusBorderColor="brand.base"
        autoComplete="off"
        onChange={e => {
          onChange(e.target.value);
        }}
      />
      <AutoCompleteList>
        {optionsToUse.map(opt => (
          <AutoCompleteItem
            key={`aci-${opt}`}
            // This is needed because we don't want
            // the autocomplete to act like
            // a select dropdown
            value={`opt-${Math.random()}`}
            label={opt}
          >
            {opt}
          </AutoCompleteItem>
        ))}
        {!!value && (
          <AutoCompleteGroup showDivider>
            <AutoCompleteItem
              fixed
              key={`vendor-create`}
              value={value}
              textColor={'brand.base'}
            >
              Use "{value}"
            </AutoCompleteItem>
          </AutoCompleteGroup>
        )}
      </AutoCompleteList>
    </AutoComplete>
  );
}
