export class UsersInit {
  private editor: any;
  constructor(editor: any) {
    this.editor = editor;
  }

  static get pluginName() {
    return 'UsersInit';
  }

  static get requires() {
    return ['Users'];
  }

  init() {
    try {
      const usersPlugin = this.editor.plugins.get('Users');
      const { users, me } = this.editor.config._config.extraContext;
      for (const user of users) {
        usersPlugin.addUser(user);
      }

      usersPlugin.defineMe(me.cuid);
    } catch (e) {}
  }
}
