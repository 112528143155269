import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../api/API';
import { schemaPropertiesToSchemaPropertyItems } from '../../utils';
import { SchemaPropertyItem } from '../../models/json_schemas';

const useModelSchema = (onSuccess?: (data: SchemaPropertyItem[]) => void) => {
  const { getAccessTokenSilently } = useAuth0();
  const { data, refetch, isLoading } = useQuery(
    ['organizations', 'custom-fields'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const resp = await API.GetInventoryModelSchema(accessToken);
      const schemaPropertyItems = schemaPropertiesToSchemaPropertyItems(resp);
      onSuccess?.(schemaPropertyItems);
      return resp;
    },
    {
      staleTime: 1000 * 60 * 60,
      placeholderData: {
        schema: {
          type: 'object',
          properties: {},
          required: [],
        },
        settings: {
          properties: {},
        },
      },
    },
  );

  const propertyItems = data ? schemaPropertiesToSchemaPropertyItems(data) : [];

  return { data, propertyItems, refetch, isLoading };
};

export default useModelSchema;
