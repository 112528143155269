import { CSSProperties, PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@chakra-ui/react';
import { AccordionHeader, AccordionHeaderProps } from './AccordionHeader';

export interface AccordionItemProps
  extends AccordionHeaderProps,
    PropsWithChildren {
  id: string;
  depth: number;
  indentationWidth: number;
  isEditing?: boolean;
  onAddRiskBlockModalOpen?: (sectionId: string) => void;
  onRemoveRiskAssessmentBlock?: (
    sectionId: string,
    riskAreaContentId: string,
  ) => void;
  renderMenu?: () => JSX.Element;
}

export function AccordionItem({
  children,
  depth,
  id,
  collapsed,
  index,
  title,
  clone,
  indentationWidth,
  isEditing = false,
  disableAddSubSection,
  onRemove,
  onCollapse,
  onTitleSaved,
  renderMenu,
}: AccordionItemProps) {
  const {
    attributes,
    isDragging,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  return (
    <>
      <Box
        id={id}
        data-testid="section"
        ref={setDroppableNodeRef}
        style={style}
        paddingLeft={clone ? 4 : `${indentationWidth * depth}px`}
      >
        <AccordionHeader
          index={index}
          title={title}
          disableAddSubSection={disableAddSubSection}
          isEditing={isEditing}
          isDragging={isDragging}
          handleProps={
            isEditing
              ? {
                  attributes,
                  listeners,
                }
              : undefined
          }
          collapsed={collapsed}
          onRemove={isEditing ? onRemove : undefined}
          onCollapse={onCollapse}
          onTitleSaved={onTitleSaved}
          ref={setDraggableNodeRef}
          renderMenu={renderMenu}
        />
        {!collapsed && !isDragging && (
          <Box paddingLeft={`${indentationWidth}px`}>{children}</Box>
        )}
      </Box>
    </>
  );
}
