import { Tag } from '@chakra-ui/react';

interface StatusBadgeProps {
  color?: string;
  bg?: string;
  children?: React.ReactNode;
  props?: any;
  size?: string;
}

export default function StatusBadge({
  color,
  bg,
  children,
  props,
  size = 'sm',
}: StatusBadgeProps) {
  return (
    <Tag
      color={color}
      bg={bg}
      data-testid="status-badge"
      {...props}
      rounded={'full'}
      fontWeight={'bold'}
      textTransform={'uppercase'}
      size={size}
      px={2}
      py={1}
    >
      {children}
    </Tag>
  );
}
