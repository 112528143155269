export enum ModelDocumentTypeEnum {
  model_documentation = 'model_documentation',
  validation_report = 'validation_report',
  monitoring = 'monitoring',
}

export function getModelDocumentType(
  documentType: string,
): ModelDocumentTypeEnum {
  // We should use `model_documentation` for everything. Right now the
  // URL route /documentation is preventing us so we have to add this ugly hack.
  let newDocumentType = documentType;
  if (documentType === 'documentation') {
    newDocumentType = 'model_documentation';
  }
  const types = Object.keys(ModelDocumentTypeEnum);
  const values = Object.values(ModelDocumentTypeEnum) as string[];
  return types[
    values.indexOf(newDocumentType) as keyof object
  ] as ModelDocumentTypeEnum;
}
