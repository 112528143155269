import {
  Center,
  CircularProgress,
  Fade,
  HStack,
  Skeleton,
  Spacer,
  Stack,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  TInventoryModel,
  TInventoryModelCustomField,
} from '../../../models/inventory_model';

import {
  convertRJSFToCustomFieldJSON,
  getSchemaPropertyByKey,
} from '../../../utils';
import useModelCustomField from '../../../hooks/useModelCustomField';
import { QuestionIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import ManagedField from '../../ManagedField';
import { Label } from '../../Layout';

export interface FieldDisplayProps {
  field: TInventoryModelCustomField;
  onEdit?: (data: any) => void;
}

export interface FieldEditProps extends FieldDisplayProps {
  onEdit: (data: any) => void;
  onCancel: (data: any) => void;
  onChange?: (data: any) => void;
}

export interface CustomFieldProps {
  inventoryModel: TInventoryModel;
  propertyKey: string;
  isCoreField?: boolean;
  readOnly?: boolean;
  displayComponent?: (field: TInventoryModelCustomField) => JSX.Element;
  hideLabel?: boolean;
}

interface CustomFieldTitleProps {
  property: any;
  settings: any;
  hideLabel?: boolean;
  isRequired?: boolean;
}
const CustomFieldTitle = ({
  settings,
  property,
  hideLabel,
  isRequired,
}: CustomFieldTitleProps) => {
  if (hideLabel) {
    return <></>;
  }
  return (
    <HStack w={'full'} alignItems={'flex-start'}>
      <Label>{property.title!}</Label>
      {isRequired && <Label color="red.500">*</Label>}
      <Spacer />
      {settings.description && (
        <Tooltip
          hasArrow
          label={settings.description!}
          placement={'left-start'}
          offset={[-4, 8]}
        >
          <QuestionIcon
            color={useColorModeValue('neutral.300', 'neutral.700')}
            visibility={'hidden'}
            _groupHover={{ visibility: 'visible' }}
          />
        </Tooltip>
      )}
    </HStack>
  );
};
const ManagedCustomField = ({
  inventoryModel,
  propertyKey,
  readOnly = false,
  hideLabel = false,
}: CustomFieldProps) => {
  const {
    data: customFieldData,
    isLoading,
    postCustomFieldData,
  } = useModelCustomField(inventoryModel, propertyKey);
  const onEdit = async (value: any, onSuccess: () => void) => {
    if (readOnly) {
      return;
    }

    await postCustomFieldData.mutateAsync({
      inventoryModel,
      propertyKey,
      formData: {
        [propertyKey]: value,
      },
    });

    onSuccess();
  };

  if (postCustomFieldData.isLoading && customFieldData) {
    const [property, settings] = getSchemaPropertyByKey(
      customFieldData,
      propertyKey,
    );
    return (
      <Stack
        w={'full'}
        gap={2}
        borderRadius={'2px'}
        cursor={'wait'}
        bg={useColorModeValue('white', 'neutral.950')}
        boxShadow={'0px 0px 0px 8px transparent, 0px 0px 0px 9px transparent'}
      >
        <CustomFieldTitle
          settings={settings}
          property={property}
          hideLabel={hideLabel}
        />
        <Center>
          <Fade
            in={true}
            transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}
          >
            <CircularProgress
              size="40px"
              thickness="2px"
              isIndeterminate
              color="brand.base"
            />
          </Fade>
        </Center>
      </Stack>
    );
  }

  if (isLoading) {
    return (
      <Stack w={'full'}>
        <Skeleton height={4} w={`${_.random(20, 90, false)}%`} />;
        <Skeleton height={10} w={'full'} />;
      </Stack>
    );
  }

  if (customFieldData) {
    const [property, settings] = getSchemaPropertyByKey(
      customFieldData,
      propertyKey,
    );

    const schema = convertRJSFToCustomFieldJSON(
      propertyKey,
      property,
      settings,
    );

    return (
      <ManagedField
        jsonSchema={schema}
        initialValue={customFieldData.value}
        onEdit={onEdit}
        readOnly={readOnly}
        hideLabel={hideLabel}
      />
    );
  }

  return <></>;
};

export default ManagedCustomField;
