import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { Select, useColorModeValue } from '@chakra-ui/react';

import API from '../../../api/API';
import UsersContext from '../../../contexts/UsersContext';
import { TUseCase, TUseCaseCategory } from '../../../models/use_case';

export interface UseCasesSelectProps {
  useCaseCuid: string;
  setUseCaseCuid: (useCaseCuid: string) => void;
}

interface CategoryTreeNode {
  category: TUseCaseCategory;
  useCases: TUseCase[];
  children: Map<string, CategoryTreeNode>;
}

function buildCategoryTree(
  categories: TUseCaseCategory[],
  useCases: TUseCase[],
  parentCuid: string | null = null,
) {
  let tree = new Map<string, CategoryTreeNode>();

  categories
    .filter(category => category.parent?.cuid == parentCuid)
    .forEach(category => {
      let categoryCuid = category.cuid;
      tree.set(categoryCuid, {
        category,
        useCases: useCases.filter(
          useCase => useCase.category?.cuid === categoryCuid,
        ),
        children: buildCategoryTree(categories, useCases, categoryCuid),
      });
    });

  return tree;
}

function expandChildrenUseCases(
  nodes: Map<string, CategoryTreeNode>,
  prefix: string,
) {
  let nodeOptions: any = [];

  if (nodes.size == 0) {
    return;
  }

  nodes.forEach((node: CategoryTreeNode) => {
    node.useCases.map((useCase: any) => {
      nodeOptions.push(
        <option value={useCase.cuid}>
          {prefix}
          {node.category.name} - {useCase.name}
        </option>,
      );
    });
    if (node.children.size > 0) {
      expandChildrenUseCases(node.children, node.category.name + ' - ');
    }
  });

  return nodeOptions;
}

function generateSelect(tree: Map<string, CategoryTreeNode>) {
  let html = '';
  let selectOptions: any = [];

  tree.forEach((node: CategoryTreeNode) => {
    selectOptions.push(
      <optgroup label={node.category.name}>
        {node.useCases.map((useCase: any) => {
          return <option value={useCase.cuid}>{useCase.name}</option>;
        })}
        {expandChildrenUseCases(node.children, '')}
      </optgroup>,
    );
  });

  return selectOptions;
}

export default function UseCasesSelect({
  useCaseCuid,
  setUseCaseCuid,
}: UseCasesSelectProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { currentUser, currentOrganization } = useContext(UsersContext);

  const { data: categoriesData, isLoading: isLoadingCategories } = useQuery(
    ['use_case_categories'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetOrganizationUseCaseCategories(
        accessToken,
        currentOrganization,
      );
    },
  );
  const { data: useCasesData, isLoading: isLoadingUseCases } = useQuery(
    ['use_cases'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetOrganizationUseCases(accessToken, currentOrganization);
    },
  );

  if (isLoadingUseCases || isLoadingCategories) {
    return <Select>Please wait...</Select>;
  }

  let tree = buildCategoryTree(categoriesData?.results || [], useCasesData?.results || []);
  let selectOptions = generateSelect(tree);

  return (
    <Select
      onChange={e => setUseCaseCuid(e.target.value)}
      placeholder="Select use case"
      value={useCaseCuid}
      focusBorderColor="brand.base"
      bg={useColorModeValue('white', 'neutral.850')}
    >
      {selectOptions}
    </Select>
  );
}
