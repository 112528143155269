import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import ModelDocumentationOverview from './Overview';
import { useContext, useEffect, useState, useCallback } from 'react';
import { TComment } from '../../api/API';
import API from '../../api/API';
import { LoadingContainer } from '../../components/LoadingContainer';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, useColorModeValue } from '@chakra-ui/react';

import { CommentsContext, ProjectContext, UsersContext } from '../../contexts';

import GettingStarted from './GettingStarted';
import { ViewFinding } from './Findings';
import DocumentationPage from '../../components/Layout/DocumentationPage';
import { KBarProvider } from 'kbar';
import CommandPalette from '../../components/CommandPalette';
import { TProject } from '../../models/project';
import NotFound from '../NotFound';
import { DataExploration } from '../DataExploration';
import LayoutContext from '../../contexts/LayoutContext';
import InventoryModelContext from '../../contexts/InventoryModel';
import { ProjectTemplates } from '../../contexts/ProjectContext';
import {
  addIndexNumbersToSections,
  convertSectionsToLookup,
  convertToSectionTree,
} from '../../models/template';
import { useCurrentProject } from '../../hooks/useProjects';
import SidebarContext from '../../contexts/SidebarContext';
import GetStartedChecklist from '../../components/GetStartedChecklist';
import ValidationReportOverview from './Overview/ValidationReport';
import OfflineDocumentationOverview from './Overview/Offline';
import MonitoringOverview from './Overview/Monitoring';
import { TPermissionAction } from '../../models/role';
import { TInventoryModel } from '../../models/inventory_model';

const ProjectPage = ({ children }: any) => (
  <Flex id="project-page" width="100%">
    {children}
  </Flex>
);

const ProjectDetails = ({ isDocumentationPage, children }: any) => (
  <Flex
    position="relative"
    id="project-details"
    direction="column"
    align={isDocumentationPage ? 'stretch' : 'center'}
    justify="start"
    width={'100%'}
    gap={isDocumentationPage ? 0 : 4}
    pb={isDocumentationPage ? 0 : 12}
    bg={useColorModeValue('white', 'neutral.950')}
    overflow={'auto'}
    className="no-scrollbar"
  >
    {children}
  </Flex>
);

/**
 * Documentation and Validation Report pages require
 * a submenu to render their subpages. This submenu
 * is rendered with the SideNavSubmenu component.
 */
const sideNavRequiresSubmenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  // Simple check for now, but will need to be more robust
  const requiresSubmenu =
    currentPath.includes('documentation') ||
    currentPath.includes('validation-report') ||
    currentPath.includes('monitoring');

  return requiresSubmenu;
};

/**
 * Some internal links can route to a content block URL, i.e.
 *    /projects/cln579h9l01banu8hgc7bbqbk/documentation/test-description:validmind.data-validation.ClassImbalance
 *
 * In this case, we want to redirect to the parent section URL, i.e.
 *
 *   /projects/cln579h9l01banu8hgc7bbqbk/documentation/data-quality
 *
 * To extract the parent section we will have to locate the content block in the
 * documentation template.
 *
 * NOTE:
 *  - This method will stop working once we allow for duplicate `content_id`s in the template
 *  - This routing logic should be moved to the backend to account for deleted blocks
 */
const getContentBlockPath = (
  projectId: string,
  projectTemplates: ProjectTemplates,
  path: string,
) => {
  const pathParts = path.split('/');
  const contentBlockParts = pathParts[pathParts.length - 1].replace('-', '_');

  // If contentBlockParts starts with "test-description:" then the link belongs to a text block
  // inside a test driven block. The `content_id` is the last part of the path after the colon.
  const isTextBlock =
    contentBlockParts.startsWith('test_description:') ||
    contentBlockParts.startsWith('metric_description:');

  if (!isTextBlock) {
    return '';
  }

  const isDocumentationPage = pathParts[3] === 'documentation';
  const templateKey = isDocumentationPage
    ? 'documentation'
    : 'validation_report';

  // Lower case and replace - and spaces with _ to match the content_id format
  const firstColonIndex = contentBlockParts.indexOf(':');
  const contentId = contentBlockParts.substring(firstColonIndex + 1);
  const contentIdLowerCase = contentId.toLowerCase().replace(/[- ]/g, '_');

  const templateSections = projectTemplates[templateKey].sections;
  // Look up the contentId in the template by finding a matching content_id inside the section `contents` array
  const section = templateSections.find(section =>
    section.contents?.find(
      content => content.content_id.toLowerCase() === contentIdLowerCase,
    ),
  );

  if (!section) {
    return '';
  }

  // Replace _ with - to match the URL format
  const sectionIdPath = section.id.replace(/_/g, '-');
  return `/projects/${projectId}/${templateKey}/${sectionIdPath}`;
};

export default function Project() {
  const { currentOrganization } = useContext(UsersContext);  
  const { templates: initialTemplates } = useContext(ProjectContext);
  const { setProject: setSidebarProject } = useContext(SidebarContext);
  const { id } = useParams();
  const { project } = useCurrentProject();
  const currentPath = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setLoading] = useState(true);
  const [templates, setTemplates] = useState(initialTemplates);
  const [comments, setComments] = useState<TComment[]>([]);
  const [rightSidebarHidden, setRightSidebarHidden] = useState<boolean>(true);
  const requiresSubmenu = sideNavRequiresSubmenu();

  const [currentProject, setCurrentProject] = useState<TProject>();
  const [inventoryModel, setInventoryModel] = useState<TInventoryModel>();

  useEffect(() => {
    if (project) {
      setCurrentProject(project);
      setInventoryModel(project?.inventory_model), setSidebarProject(project);
    }
    return () => {
      // Resets the sidebar project when leaving the project page
      setSidebarProject(undefined);
    };
  }, [project]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const descriptionPath = currentPath.pathname.replace('-', '_');

      // Redirect to overview when visiting the project base path
      if (
        currentPath.pathname.endsWith(id!) ||
        currentPath.pathname.endsWith('documentation')
      ) {
        navigate('project-overview', { replace: true });
        // Redirect to project summary if visiting the validation report base path
      } else if (currentPath.pathname.endsWith('validation-report')) {
        navigate('validation-report/overview', { replace: true });
        // Check if the path has test-description:
      } else if (currentPath.pathname.endsWith('monitoring')) {
        navigate('monitoring/overview', { replace: true });
      } else if (
        descriptionPath.includes('test_description:') ||
        descriptionPath.includes('metric_description:')
      ) {
        const contentBlockPath =
          templates.isReady &&
          getContentBlockPath(id!, templates, currentPath.pathname);

        if (contentBlockPath) {
          navigate(contentBlockPath + currentPath.search + currentPath.hash, {
            replace: true,
          });
        }
      }

      setLoading(false);
    })();
  }, [currentProject, currentPath.pathname, templates.isReady]);

  // Load the necessary template context
  useEffect(() => {
    if (currentProject) {
      const allTemplates: ProjectTemplates = {
        ...templates,
        get isReady() {
          return (
            Object.keys(allTemplates.documentation.lookup).length > 0 ||
            Object.keys(allTemplates.validation_report.lookup).length > 0 ||
            Object.keys(allTemplates.monitoring.lookup).length > 0
          );
        },
        get isOfflineDocumentation() {
          return currentProject.template.is_offline;
        },
        get isOfflineValidationReport() {
          return currentProject.template_vr.is_offline;
        },
        get isOfflineMonitoring() {
          return currentProject.template_monitoring.is_offline;
        },
      };

      type templateKeyType = 'template' | 'template_vr' | 'template_monitoring';
      const templateKeys: templateKeyType[] = [
        'template',
        'template_vr',
        'template_monitoring',
      ];
      templateKeys.forEach((templateKey: templateKeyType) => {
        // for offline docs there are not templates defined in db

        if (currentProject[templateKey].is_offline) {
          return;
        }

        const startIndex = currentProject[templateKey].template.start_index;
        const templateSections = currentProject[templateKey].template.sections;

        const lookup = convertSectionsToLookup(templateSections);
        const sectionTree = convertToSectionTree(templateSections);
        addIndexNumbersToSections(sectionTree, lookup, '', startIndex);

        if (templateKey === 'template_vr') {
          allTemplates.validation_report.sections = templateSections;
          allTemplates.validation_report.sectionTree = sectionTree;
          allTemplates.validation_report.lookup = lookup;
        } else if (templateKey === 'template_monitoring') {
          allTemplates.monitoring.sections = templateSections;
          allTemplates.monitoring.sectionTree = sectionTree;
          allTemplates.monitoring.lookup = lookup;
        } else {
          allTemplates.documentation.sections = templateSections;
          allTemplates.documentation.sectionTree = sectionTree;
          allTemplates.documentation.lookup = lookup;
        }
      });

      setTemplates(allTemplates);
    }
  }, [currentProject]);

  const { data: inventoryModelPermissions } = useQuery(
    [
      'inventory-model',
      inventoryModel?.cuid,
      'permissions',
      project?.cuid,
      currentOrganization?.cuid,
    ],    
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetUserPermissions(accessToken, 'Model', inventoryModel!.cuid);
    },
    {
      enabled: !!inventoryModel?.cuid,
      staleTime: 1000 * 60 * 10,
      onError: err => {
        // track errors
      },
    },
  );

  const userHasInventoryModelPermission = useCallback(
    (actions: TPermissionAction[], match: 'any' | 'all') => {
      if (!inventoryModelPermissions) return false;
      if (match === 'any') {
        return actions.some(action =>
          inventoryModelPermissions.some(permission => permission === action),
        );
      } else {
        return actions.every(action =>
          inventoryModelPermissions.some(permission => permission === action),
        );
      }
    },
    [inventoryModelPermissions],
  );

  return (
    <KBarProvider
      options={{
        enableHistory: true,
      }}
    >
      <ProjectContext.Provider
        value={{
          project: currentProject,
          setProject: setCurrentProject,
          templates,
          setTemplates,
        }}
      >
        <LayoutContext.Provider
          value={{
            rightSidebarHidden,
            setRightSidebarHidden,
          }}
        >
          <InventoryModelContext.Provider
            value={{
              inventoryModel: currentProject?.inventory_model,
              userHasInventoryModelPermission,
            }}
          >
            <CommandPalette />
            <CommentsContext.Provider value={{ comments, setComments }}>
              <ProjectPage>
                <ProjectDetails isDocumentationPage={requiresSubmenu}>
                  <LoadingContainer isLoading={isLoading}>
                    {currentProject && (
                      <Routes>
                        <Route
                          path="project-overview"
                          element={
                            currentProject.template.is_offline ? (
                              <OfflineDocumentationOverview documentType="model_documentation" />
                            ) : (
                              <ModelDocumentationOverview />
                            )
                          }
                        />

                        <Route
                          path="validation-report/overview"
                          element={
                            currentProject.template_vr.is_offline ? (
                              <OfflineDocumentationOverview documentType="validation_report" />
                            ) : (
                              <ValidationReportOverview />
                            )
                          }
                        />

                        <Route
                          path="monitoring/overview"
                          element={
                            currentProject.template_monitoring.is_offline ? (
                              <OfflineDocumentationOverview documentType="monitoring" />
                            ) : (
                              <MonitoringOverview />
                            )
                          }
                        />

                        <Route
                          path="data-exploration"
                          element={<DataExploration />}
                        />
                        <Route
                          path="findings/:finding_cuid"
                          element={<ViewFinding />}
                        />
                        <Route
                          path="getting-started"
                          element={<GettingStarted project={currentProject!} />}
                        />

                        <Route
                          path=":documentType/:pageId"
                          element={<DocumentationPage />}
                        />
                        {/* links direct to the project's documentation or validation-report,
                        DocumentationPage should resolve and redirect to its homepage url to satisfy route above */}
                        <Route
                          path=":documentType"
                          element={<DocumentationPage />}
                        />

                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    )}
                  </LoadingContainer>
                </ProjectDetails>
                <GetStartedChecklist />
              </ProjectPage>
            </CommentsContext.Provider>
          </InventoryModelContext.Provider>
        </LayoutContext.Provider>
      </ProjectContext.Provider>
    </KBarProvider>
  );
}
