import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useFlags as LDUseFlags } from 'launchdarkly-react-client-sdk';
import API from '../../api/API';
import { CONFIG } from '../../config';
import { TFeatureFlag } from '../../models/flags';

interface EnableConfigFlag {
  enabled: boolean;
  config: Record<string, unknown>;
}

const APIFlags = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [flags, setFlags] = useState<TFeatureFlag>({});

  const flagNames = [
    {
      camel: 'releaseSc4294RolesAndPermissions',
      snake: 'release.sc-4294.roles-and-permissions',
    },
    {
      camel: 'experimentTemplateNewEditingExperience',
      snake: 'experiment.template.new-editing-experience',
    },
    {
      camel: 'experimentSc3369TestInterpretations',
      snake: 'experiment.sc-3369.test-interpretations',
    },
    {
      camel: 'experimentSc3369RiskAssessmentGeneration',
      snake: 'experiment.sc-3369.risk-assessment-generation',
    },
    {
      camel: 'releaseSc3255SsoFlow',
      snake: 'release.sc-3255.sso-flow',
    },
    {
      camel: 'releaseBlacklistedDomains',
      snake: 'release.blacklisted.domains',
    },
    {
      camel: 'validcheckRecommendationsRegulation',
      snake: 'validcheck-recommendations-regulation',
    },
  ];

  const queryResponse = useQuery(
    ['flags'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetFeatureFlags(accessToken, flagNames);
    },
    {
      onSuccess: (flags_: TFeatureFlag) => {
        setFlags(flags_);
      },
    },
  );

  return flags;
};

const useFlags = CONFIG.USE_LAUNCHDARKLY ? LDUseFlags : APIFlags;

export type { EnableConfigFlag };
export { useFlags };
