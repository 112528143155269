import { VStack, Text, HStack, Box, Divider } from '@chakra-ui/react';

const ChartTooltip = ({
  active,
  payload,
  label,
  showValue,
  labelFormatter,
  extraContent,
}: any) => {
  if (active && payload && payload.length) {
    return (
      <VStack
        position={'relative'}
        bgColor="white"
        p={3}
        borderColor="neutral.200"
        borderWidth={1}
        borderStyle="solid"
        spacing={3}
      >
        <Text fontWeight={600}>{labelFormatter?.(label) || label}</Text>
        <VStack alignItems="flex-start">
          {payload
            .filter((p: any) => !!p.value)
            .map((p: any) => (
              <>
                <HStack>
                  <Box bgColor={p.fill} w={3} h={2} />
                  <HStack spacing={2}>
                    <HStack spacing={0}>
                      <Text>{p.name || p.dataKey}</Text>
                      {!!showValue && <Text key={p.dataKey}>:</Text>}
                    </HStack>
                    {!!showValue && <Text key={p.dataKey}>{p.value}</Text>}
                  </HStack>
                </HStack>
                {extraContent && (
                  <>
                    <Divider />
                    {extraContent}
                  </>
                )}
              </>
            ))}
        </VStack>
      </VStack>
    );
  }

  return null;
};

export default ChartTooltip;
