import { FieldDisplayProps } from '../../index';
import { HStack, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import NullFieldDisplay from '../NullFieldDisplay';

const ArrayFieldDisplay = ({ field }: FieldDisplayProps) => {
  const fieldHasValue = !!field.value;
  if (fieldHasValue) {
    return (
      <HStack spacing={2}>
        <Wrap>
          {field.value.map((v: string) => (
            <WrapItem>
              <Tag key={v} variant="solid">
                {v}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </HStack>
    );
  }
  return <NullFieldDisplay />;
};

export default ArrayFieldDisplay;
