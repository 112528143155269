import React from 'react';
import ContentContainer from '../Layout/ContentContainer';
import { Flex } from '@chakra-ui/react';
import { TFinding } from '../../models/finding';
import FindingRow from './FindingRow';
import { TInventoryModel } from '../../models/inventory_model';

interface IFindingsListProps {
  findings: TFinding[];
  onFindingDeleted: (deletedFinding: TFinding) => void;
  onFindingDeleteError: (error: Error) => void;
  inventoryModel?: TInventoryModel;
}

export default function FindingsList({
  findings,
  onFindingDeleted,
  onFindingDeleteError,
  inventoryModel,
}: IFindingsListProps) {
  return (
    <ContentContainer>
      <Flex direction="column" gap={1} borderColor="blue.500" w="100%">
        {findings?.map((finding, index) => (
          <FindingRow
            key={`finding-${index}`}
            finding={finding}
            inventoryModel={inventoryModel}
          />
        ))}
      </Flex>
    </ContentContainer>
  );
}
