import { createContext } from 'react';
import { TRiskArea } from '../models/risk_area';

type TRiskAreaContext = {
  riskAreas: TRiskArea[];
  setRiskAreas: (riskAreas: TRiskArea[]) => void;
}

const RiskAreaContext = createContext<TRiskAreaContext>({
  riskAreas: [],
  setRiskAreas: (riskAreas: TRiskArea[]) => {},
});

export default RiskAreaContext;
