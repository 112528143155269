import { ChangeEvent, useEffect, useState } from 'react';
import {
  HStack,
  Heading,
  Spacer,
  Switch,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { Label } from '../Layout';
import { useUserUISettings } from '../../hooks/useUserUISettings';

export default function UIColorSchemeSettings() {
  const { updateColorMode } = useUserUISettings();
  const [modeName, setModeName] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === 'dark') {
      setModeName('Dark Mode');
      setIsDarkMode(true);
    } else {
      setModeName('Light Mode');
      setIsDarkMode(false);
    }
  }, [colorMode]);

  const handleDarkMode = () => {
    // updateColorMode will trigger the toggleColorMode in App.tsx
    if (colorMode === 'dark') {
      updateColorMode('light');
    } else {
      updateColorMode('dark');
    }
  };

  return (
    <VStack
      border={'1px solid'}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderRadius={'lg'}
      width={'full'}
      p={4}
      spacing={4}
      alignItems={'flex-start'}
      gap={4}
    >
      <Heading as="h2" fontSize="lg" fontWeight="semibold" color={'inherit'}>
        User Interface Preferences
      </Heading>
      <HStack alignItems={'flex-start'} w={'full'}>
        <Switch
          id="ui-dark-mode"
          size="lg"
          isChecked={isDarkMode}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleDarkMode();
          }}
          colorScheme="brand"
        />
        <Label>Enable dark mode</Label>
        <Spacer />
        <Text fontSize={'sm'} fontWeight={'semibold'}>
          {modeName}
        </Text>
      </HStack>
    </VStack>
  );
}
