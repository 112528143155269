import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Collapse,
  Divider,
  Heading,
  HStack,
  IconButton,
  Select,
  Stack,
  StackProps,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { APIRequestSortBy } from '../../models/utils';
import { EventFilters, TEvent } from '../../models/event';
import EventList from '../EventList';
import DocumentationContext from '../../contexts/DocumentationContext';
import { Icon } from '@chakra-ui/icons';
import { Label } from '../Layout';

const sortOptions: APIRequestSortBy[] = [
  { label: 'Most recent first', field: 'created_at', order: 'desc' },
];

interface IActivityFeedWidgetProps extends StackProps {
  variant?:
    | 'user-summary'
    | 'model-inventory-summary'
    | 'project-summary'
    | 'documentation';
  filters?: EventFilters;
  seeAllUrl?: string;
  itemsPerPage?: number;
  expanded?: boolean;
}

// identify this query on the components that need to cause refetch by cache invalidation when new events are created
// for example, the documentation section activity needs to refetch the cache after Metadata is posted.
export const ActivityFeedWidgetQueryKey = 'ActivityFeedWidget';

export default function ActivityFeedWidget({
  variant,
  filters,
  seeAllUrl,
  itemsPerPage = 10,
  expanded = true,
}: IActivityFeedWidgetProps) {
  // *
  // ActivityFeedWidget is a component that displays a list of activities for a given project, inventory model, or user,
  // hence the optional props.
  // This widget is being used in the following places:
  // - Project Home: src/pages/Project/Overview/index.tsx
  // - Model Inventory Home: src/pages/ModelInventory/InventoryModel/Overview/index.tsx
  // - User Home: src/pages/Dashboard/index.tsx
  // - Documentation: src/components/Layout/DocumentationPage/index.tsx
  // *
  const { getAccessTokenSilently } = useAuth0();
  let [events, setEvents] = useState<TEvent[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(itemsPerPage);
  const [sortBy, setSortBy] = useState<APIRequestSortBy>(sortOptions[0]);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: expanded });
  const [isCollapsible, setIsCollapsible] = useState(false);
  const [tabs, setTabs] = useState(['All']);
  const [tabIndex, setTabIndex] = useState(tabs.indexOf('All'));
  const [filtersWithTabs, setFiltersWithTabs] = useState(filters);
  const [showOnlyMentions, setShowOnlyMentions] = useState(true);
  const { latestEvent, setLatestEvent } = useContext(DocumentationContext);

  useEffect(() => {
    // if the widget is implemented as not expandable by default then we would assume is wanted collapsible.
    // isCollapsible toggles the chevron icon and the toggle functionality.
    if (!expanded) {
      setIsCollapsible(true);
    }
  }, []);

  useEffect(() => {
    setTabs([
      'All',
      'Comments',
      'Status Updates',
      'Model Updates',
      'Test Results',
    ]);
  }, []);

  let { data, isLoading, refetch } = useQuery(
    [ActivityFeedWidgetQueryKey, variant, filtersWithTabs, page],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetEvents(
        accessToken,
        page,
        limit,
        filtersWithTabs,
        sortBy,
      );
    },
    {
      onSuccess: data => {
        setEvents(data.results);
      },
    },
  );

  useEffect(() => {
    let withTabs: EventFilters = {
      tab: tabs[tabIndex],
      ...(tabs[tabIndex] === 'Comments' && { only_mentions: showOnlyMentions }),
    };
    if (filters) {
      withTabs = {
        ...withTabs,
        ...filters,
      };
    }
    setFiltersWithTabs(withTabs);
    setPage(1);
  }, [filters, tabIndex, showOnlyMentions]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    refetch();
  }, [sortBy]);

  useEffect(() => {
    if (events.length > 0) {
      const event = events[0]; // The first event is the newest event on this feed.

      // As this widget can be used in multiple blocks:
      // Checks if it's necessary to update the latest event in the DocumentationContext which is used
      // up the tree on SubsectionHeaderForDocumentation to display last updated date and user.
      if (!latestEvent || latestEvent.created_at < event.created_at) {
        setLatestEvent(event);
      }
    }
  }, [events, latestEvent]);

  const showPagination = data && data.total > limit;

  return (
    <Stack spacing={0} width="100%" maxH="100%">
      <>
        {/* <Divider borderWidth={1} mt={'0 !important'} /> */}
        {variant === 'documentation' && (
          <Divider
            my={4}
            borderWidth={1}
            display={events.length < 1 ? 'none' : 'Block'}
          />
        )}
        <VStack align="stretch" display="Flex" maxH="100%">
          <HStack justifyContent={'space-between'}>
            <HStack hidden={false}>
              <Heading
                as={'h3'}
                fontSize={'lg'}
                onClick={onToggle}
                hidden={variant === 'user-summary'}
                data-testid="activity-feed-widget-title"
              >
                {variant === 'documentation'
                  ? 'Section Activity'
                  : 'Recent Activity'}
              </Heading>

              {isCollapsible && (
                <IconButton
                  variant={'ghost'}
                  aria-label={'Toggle'}
                  onClick={onToggle}
                  icon={
                    isOpen ? (
                      <Icon as={ChevronUpIcon} boxSize={5} />
                    ) : (
                      <Icon as={ChevronDownIcon} boxSize={5} />
                    )
                  }
                />
              )}
            </HStack>
            <Collapse in={isOpen} animateOpacity>
              <HStack justifyContent={'end'} spacing={0}>
                <Label whiteSpace={'nowrap'} mr={2}>
                  Sort by
                </Label>
                <Select
                  onClick={e => e.stopPropagation()}
                  bg={useColorModeValue('neutral.100', 'neutral.950')}
                  value={sortBy?.label}
                  colorScheme="brand"
                  focusBorderColor="brand.base"
                  onChange={event =>
                    setSortBy(
                      sortOptions.find(s => s.label === event.target.value)!,
                    )
                  }
                >
                  {sortOptions.map(s => (
                    <option key={s.label} value={s.label}>
                      {s.label}
                    </option>
                  ))}
                </Select>
              </HStack>
            </Collapse>
          </HStack>

          <Collapse
            in={isOpen}
            animateOpacity
            style={{
              display: 'flex',
              overflow: 'hidden',
              flexDirection: 'column',
            }}
          >
            <Box maxH="100%">
              <EventList
                isLoading={isLoading}
                events={events}
                tabs={tabs}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                showOnlyMentions={showOnlyMentions}
                setShowOnlyMentions={setShowOnlyMentions}
                variant={variant || 'user-summary'}
              />
            </Box>
          </Collapse>
        </VStack>
      </>
    </Stack>
  );
}
